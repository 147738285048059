import React from "react"
import styled, { useTheme } from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"

const AttachedButton = ({
    icon,
    onClick = () => { },
    secondary,
    width = 24,
    height = 24,
    iconSize = 18,
    disabled = false,
    className,
    id
}) => {
    const theme = useTheme()
    var optionalProps = {}
    if (id) {
        optionalProps.id = id
    }

    let iconProps = {}
    if (iconSize) {
        iconProps.size = iconSize
    } else if (width && height) {
        iconProps.width = width * 0.8
        iconProps.height = height * 0.8
    }

    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            secondary={secondary}
            width={width}
            height={height}
            className={className}
            {...optionalProps}
        >
            <Icon
                icon={icon}
                fill={secondary ? theme.foregroundSecondary : Colors.white}
                {...iconProps}
            />
        </Button>
    )
}

const Container = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    padding: 5px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    background-color: ${(props) => props.secondary ? props.theme.backgroundSecondary : props.theme.inputForeground};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);

    &:hover {
        background-color: ${(props) => props.secondary ? props.theme.backgroundSecondaryHover : props.theme.inputForegroundHover};
    }
`

const Button = styled(Container)`
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};

    svg {
        margin: 0;
    }
`

export default AttachedButton
