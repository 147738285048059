import * as React from "react"
import { useTheme } from "styled-components"

function Receipt({ fill, width = 18 }) {
    const theme = useTheme()
    const iconBaseWidth = 18
    const iconBaseHeight = 20
    const height = width * (iconBaseHeight / iconBaseWidth)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 6h6a1 1 0 100-2H7a1 1 0 000 2zm-2 4h8a1 1 0 100-2H5a1 1 0 000 2zm0 4h8a1 1 0 000-2H5a1 1 0 000 2zM17 0H1a1 1 0 00-1 1v18a1 1 0 001.6.8l2.07-1.55 2.06 1.55a1 1 0 001.2 0L9 18.25l2.07 1.55a1 1 0 001.2 0l2.06-1.55 2.07 1.55a1 1 0 001.45-.277A1 1 0 0018 19V1a1 1 0 00-1-1zm-1 17l-1.07-.8a1 1 0 00-1.2 0l-2.06 1.55L9.6 16.2a1 1 0 00-1.2 0l-2.07 1.55-2.06-1.55a1 1 0 00-1.2 0L2 17V2h14v15z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default Receipt
