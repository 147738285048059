import * as React from "react"
import { useTheme } from "styled-components"

function SvgFinger({ fill, width = 19 }) {
    const theme = useTheme()
    const height = width * (20 / 19)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.5 12.5l-1.71-.855a2 2 0 00-2.31.374l-.922.923a.1.1 0 00-.013.126l3.658 5.487a1 1 0 00.832.445h8.684a1 1 0 00.97-.758l1.596-6.38a1 1 0 00-.614-1.178l-4.25-1.618A1 1 0 0012.067 9H10.5V3a2 2 0 10-4 0v9.5z"
                stroke={fill || theme.inputForeground}
                strokeWidth={2}
            />
        </svg>
    )
}

export default SvgFinger
