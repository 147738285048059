import styled, { useTheme } from "styled-components"
import Colors from "../assets/colors"

const DashBoardLoading = () => {
    const theme = useTheme()
    return (
        <AnimatedContainer>
            <Column>
                <Row justifyContent="space-between">
                    <Header marginBottom="32px" />
                    <SubHeader />
                </Row>
                <Row>
                    <Contract />
                </Row>
            </Column>

            <FullBox backgroundColor={theme.backgroundSecondary} column>
                <SubHeader marginBottom="16px" width="380px" />
                <Row>
                    <ActionCard />
                </Row>
            </FullBox>
        </AnimatedContainer>
    )
}

const ArchiveLoading = () => {
    return (
        <AnimatedContainer>
            <Row marginBottom="16px">
                <Tab />
                <Tab />
                <Tab />
            </Row>
            <Row>
                <Contract />
                <Contract />
                <Contract />
            </Row>
        </AnimatedContainer>
    )
}

const SettingsLoading = () => {
    return (
        <AnimatedContainer>
            <Row>
                <ColorBox height="500px" width="411px" color={Colors.white} />
                <ColorBox height="316px" width="411px" color={Colors.white} />
            </Row>
        </AnimatedContainer>
    )
}

const ContractsLoading = () => {
    return (
        <AnimatedContainer>
            <Row marginBottom="16px">
                <Tab />
                <Tab />
                <Tab />
            </Row>
            <Row marginBottom="16px">
                <ColorBox height="212px" width="265px" color={Colors.white} />
                <ColorBox height="212px" width="265px" color={Colors.white} />
                <ColorBox height="212px" width="265px" color={Colors.white} />
            </Row>
        </AnimatedContainer>
    )
}

const ContractLoading = ({ asWidget = false }) => {
    const theme = useTheme()
    return (
        <AnimatedContainer>
            <FullBox asWidget={asWidget} column>
                <Header marginBottom="24px" />
                <Row>
                    <ColorBox height="781px" width="62%" color={Colors.white} />
                </Row>
            </FullBox>
            <ColorBox
                height="107px"
                width="100%"
                color={theme.backgroundSecondary}
                removeBorderRadius
            >
                <Row
                    alignItems="center"
                    justifyContent="space-between"
                    padding="0 72px"
                >
                    <Row width="572px">
                        <ColorBox
                            height="32px"
                            width="180px"
                            color={theme.inputForegroundSecondary}
                        />
                        <ColorBox
                            height="32px"
                            width="180px"
                            color={theme.inputForegroundSecondary}
                        />
                        <ColorBox
                            height="32px"
                            width="180px"
                            color={theme.inputForegroundSecondary}
                        />
                    </Row>
                    <Row width="190px" alignItems="center">
                        <ColorBox
                            height="20px"
                            width="60px"
                            color={theme.inputForegroundSecondary}
                        />
                        <ColorBox
                            height="42px"
                            width="114px"
                            color={theme.inputForegroundSecondary}
                        />
                    </Row>
                </Row>
            </ColorBox>
        </AnimatedContainer>
    )
}

/* Containers */

const Container = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.column ? "column" : "row")};
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    padding: ${(props) => props.padding || "0px"};
    background-color: ${(props) => props.backgroundColor || "transparent"};
    margin-bottom: ${(props) => props.marginBottom || "0px"};
`

const AnimatedContainer = styled(Container)`
    flex: 1;
    flex-direction: column;
    animation: fading 1.5s infinite;

    @keyframes fading {
        0% {
            opacity: 0.65;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.65;
        }
    }
`
const FullBox = styled(Container)`
    flex: 1;
    padding: 16px 16px 0;
    @media screen and (min-width: 768px) {
        padding: ${(props) =>
        props.asWidget ? "32px 16px 0 36px" : "100px 64px 0 48px"};
    }
`

const Row = styled(Container)`
    width: ${(props) => props.width || "100%"};
    flex-wrap: wrap;
    align-items: ${(props) => props.alignItems || "flex-start"};
    justify-content: ${(props) => props.justifyContent || "flex-start"};

    > *:not(:last-child) {
        margin-right: 16px;
    }
`

const Column = styled(Container)`
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;

    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }

    > * {
        margin-bottom: 16px;
    }

    > *:not(:last-child) {
        margin-right: 16px;
    }
`

/* Text */

const TextBox = styled.div`
    height: 32px;
    width: ${(props) => props.width || "300px"};
    max-width: calc(100% - 32px);
    border-radius: 8px;
    margin-bottom: ${(props) => props.marginBottom || "0px"};
`

const Header = styled(TextBox)`
    background-color: ${Colors.gray[500]};
`

const SubHeader = styled(TextBox)`
    background-color: ${(props) => props.theme.inputForegroundSecondary};
`

const ColorBox = styled.div`
    display: flex;
    align-items: center;
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    background-color: ${(props) => props.color};
    border-radius: ${(props) => (props.removeBorderRadius ? "0px" : "8px")};
`

/* Components */

const ActionCard = styled(ColorBox)`
    height: 244px;
    width: 192px;
    background: ${Colors.white};
`

const Contract = styled(ColorBox)`
    height: 151px;
    width: 210px;
    background: ${Colors.white};
`

const FileUpload = styled(ColorBox)`
    background-color: transparent;
    border: 2px dashed ${Colors.gray[300]};
`

const Tab = styled(ColorBox)`
    height: 37px;
    width: 74px;
    background-color: ${(props) => props.theme.inputForegroundSecondary};
`

export {
    ArchiveLoading,
    ContractLoading,
    ContractsLoading,
    DashBoardLoading,
    SettingsLoading
}
