import React from "react"

const Spinner__DEPRECATED = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="11" stroke="#EDE9FE" strokeWidth="2" />
            <path
                d="M12 1C13.4445 1 14.8749 1.28452 16.2095 1.83733C17.5441 2.39013 18.7567 3.20038 19.7782 4.22183C20.7996 5.24327 21.6099 6.4559 22.1627 7.79048C22.7155 9.12506 23 10.5555 23 12"
                stroke="#6D28D9"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    )
}

export default Spinner__DEPRECATED
