import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import { P_Mini_M } from "../text"

const CategoryType = {
    declaration: { icon: "Declaration", title: "Erklæring" },
    economy: { icon: "Money", title: "Økonomi" },
    health: { icon: "Heart", title: "Helse" },
    leisure: { icon: "Ball", title: "Fritid" },
    pet: { icon: "Dog", title: "Kjæledyr" },
    property: { icon: "House", title: "Eiendom" },
    transportation: { icon: "Car", title: "Transportmidler" },
    work: { icon: "Building", title: "Jobb" }
}

const CategoryTag = ({ type }) => {
    const categoryType = CategoryType[type]
    return categoryType ? (
        <Container>
            <Icon icon={categoryType.icon} fill={Colors.gray[700]} height={14} width={14} />
            <Title>{categoryType.title}</Title>
        </Container>
    ) : null
}

const Container = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    background-color: ${Colors.gray[100]};
    border-radius: 8px;
    border: none;
`

const Title = styled(P_Mini_M)`
    color: ${Colors.gray[700]};
    margin: 0 0 0 8px;
`

export default CategoryTag
