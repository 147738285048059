import React from "react"

const Declaration = ({ fill, height, width }) => {
    return (
        <svg
            width={width || "14"}
            height={height || "14"}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.9999 7C12.8231 7 12.6535 7.07024 12.5285 7.19526C12.4035 7.32029 12.3333 7.48986 12.3333 7.66667V11.6667C12.3333 11.8435 12.263 12.013 12.138 12.1381C12.013 12.2631 11.8434 12.3333 11.6666 12.3333H2.33325C2.15644 12.3333 1.98687 12.2631 1.86185 12.1381C1.73682 12.013 1.66659 11.8435 1.66659 11.6667V2.33333C1.66659 2.15652 1.73682 1.98695 1.86185 1.86193C1.98687 1.7369 2.15644 1.66667 2.33325 1.66667H6.33325C6.51006 1.66667 6.67963 1.59643 6.80466 1.4714C6.92968 1.34638 6.99992 1.17681 6.99992 1C6.99992 0.823189 6.92968 0.65362 6.80466 0.528595C6.67963 0.403571 6.51006 0.333333 6.33325 0.333333H2.33325C1.80282 0.333333 1.29411 0.544047 0.919038 0.91912C0.543966 1.29419 0.333252 1.8029 0.333252 2.33333V11.6667C0.333252 12.1971 0.543966 12.7058 0.919038 13.0809C1.29411 13.456 1.80282 13.6667 2.33325 13.6667H11.6666C12.197 13.6667 12.7057 13.456 13.0808 13.0809C13.4559 12.7058 13.6666 12.1971 13.6666 11.6667V7.66667C13.6666 7.48986 13.5963 7.32029 13.4713 7.19526C13.3463 7.07024 13.1767 7 12.9999 7ZM2.99992 7.50667V10.3333C2.99992 10.5101 3.07016 10.6797 3.19518 10.8047C3.32021 10.9298 3.48977 11 3.66659 11H6.49325C6.58099 11.0005 6.66796 10.9837 6.74919 10.9505C6.83041 10.9173 6.90429 10.8685 6.96659 10.8067L11.5799 6.18667L13.4733 4.33333C13.5357 4.27136 13.5853 4.19762 13.6192 4.11638C13.653 4.03515 13.6705 3.94801 13.6705 3.86C13.6705 3.77199 13.653 3.68485 13.6192 3.60362C13.5853 3.52238 13.5357 3.44864 13.4733 3.38667L10.6466 0.526667C10.5846 0.464181 10.5109 0.414585 10.4296 0.380739C10.3484 0.346893 10.2613 0.329468 10.1733 0.329468C10.0852 0.329468 9.99811 0.346893 9.91687 0.380739C9.83563 0.414585 9.76189 0.464181 9.69992 0.526667L7.81992 2.41333L3.19325 7.03333C3.13146 7.09563 3.08258 7.1695 3.0494 7.25073C3.01623 7.33195 2.99941 7.41893 2.99992 7.50667ZM10.1733 1.94L12.0599 3.82667L11.1133 4.77333L9.22659 2.88667L10.1733 1.94ZM4.33325 7.78L8.28659 3.82667L10.1733 5.71333L6.21992 9.66667H4.33325V7.78Z"
                fill={fill || "#374151"}
            />
        </svg>
    )
}

export default Declaration
