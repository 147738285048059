import styled from "styled-components"
import Button from "../components/button"
import Icon from "../components/icon"
import { P_Large_M, H2, P } from "../components/text"

import { PRICE_PER_SIGNING } from "../utils/constants"

import { useFirstSigner } from "../providers/FirstSignerProvider"
import Spinner from "../components/Spinner"

import Colors from "../assets/colors"
import { useEffect, useState } from "react"

const InfoBoxPrice = ({
    title = "Pris",
    icon = "Money",
    signers,
    signatureType,
    isLoading,
    onStartButtonClick,
    templatePrice
}) => {
    const { state: firstSignerState } = useFirstSigner()

    const getPrice = async () => {
        if (!signatureType) {
            return "0"
        }
        return signatureType === "electronic"
            ? signers.length * PRICE_PER_SIGNING.no
            : signers.length * PRICE_PER_SIGNING.manual
    }

 

    const getButtonText = () => {
        const requiresPayment =
            signatureType === "electronic"
                ? signers.length * PRICE_PER_SIGNING.no
                : signers.length * PRICE_PER_SIGNING.manual

        if (requiresPayment) {
            if (firstSignerState.firstSignerIndex !== null) {
                return "Betal og start signering"
            }
            return "Betal og send til signering"
        } else {
            if (firstSignerState.firstSignerIndex !== null) {
                return "Start signering"
            }
            return "Send til signering"
        }
    }


    const [contractPriceString, setContractPriceString] = useState("")
    const [contractCheckoutDesc, setContractCheckoutDesc] = useState("")
    useEffect(() => {
        const getPriceString = async () => {
            const price = await getPrice()
            const contract_price = templatePrice? templatePrice: 0;
            if (price === 0) {
                return "0 kr."
            }
            setContractPriceString(`${price+contract_price} kr.`)
            return `${price+contract_price} kr.`
        }
        getPriceString()
    }, [signers,signatureType, templatePrice])

    useEffect(() => {
        const getContractCheckoutDesc = async () => {
            let signatures = signers?.length;
            let contractPrice = templatePrice;
            const price = await getPrice()
            let checkoutDesc = `
                    ${signatures} signatur${signatures > 0 ? "er" : ""} 
                    ${price} kr. ${contractPrice > 0 ? ` og 1 kontrakt ${templatePrice} kr.` : ""}
                `;
            setContractCheckoutDesc(checkoutDesc)
            return checkoutDesc;
        }
        getContractCheckoutDesc()
    }, [signers,signatureType, templatePrice])
    
    

    return (
        <Box>
            <HeadingWithIcon>
                <P_Large_M>
                    {icon && <Icon icon={icon} />}
                    {title}
                </P_Large_M>
            </HeadingWithIcon>
            <KeyFigure>
                {!signatureType && <H2>0 kr.</H2>}
                {signatureType && <H2>{contractPriceString}</H2>}
                <P>
                    {/* {signers?.length} signatur 
                    {templatePrice > 0 ? ` og ${templatePrice}  kr. contract price`: ""}
                    {`${signers.length > 1 ? "er" : ""}`} */}
                    {contractCheckoutDesc}
                </P>
            </KeyFigure>
            {onStartButtonClick && (
                <Button
                    primary
                    disabled={isLoading}
                    text={
                        isLoading ? (
                            <Spinner size="20" inline />
                        ) : (
                            getButtonText()
                        )
                    }
                    onClick={onStartButtonClick}
                />
            )}
        </Box>
    )
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: ${(props) => (props.big ? "48px" : "16px")};
    margin-top: 24px;
    @media screen and (min-width: 768px) {
        margin-right: 24px;
    }
    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    > * {
        margin-bottom: 12px;
    }
`

const HeadingWithIcon = styled.div`
    p {
        display: flex;
        align-items: center;
    }
    p svg {
        padding-right: 8px;
    }
`

const KeyFigure = styled.div`
    text-align: center;
    h2 {
        margin-bottom: 8px;
    }
    p {
        color: ${Colors.gray[500]};
    }
`

export default InfoBoxPrice
