import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { Overline, P_Small } from "../text"

const Step = ({
    stepPrefix = "",
    count,
    description,
    filled,
    onClick = () => { }
}) => {
    return (
        <Container onClick={onClick} filled={filled}>
            <Line className={"line"} filled={filled} />
            <Title>{stepPrefix + " " + count}</Title>
            <Description>{description}</Description>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;

    &:hover .line {
        background-color: ${(props) => props.filled ? props.theme.foregroundHover : Colors.gray[300]};
    }
`

const Line = styled.div`
    height: 4px;
    width: 100%;
    background-color: ${(props) =>
        props.filled ? props.theme.inputForeground : "transparent"};
    margin-bottom: 16px;
`

const Title = styled(Overline)`
    color: ${Colors.gray[500]};
    margin: 0;
`

const Description = styled(P_Small)`
    color: ${Colors.gray[900]};
    margin: 0;
`

export default Step
