import React from "react"
import styled, { useTheme } from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import { P_Medium, P_Mini } from "../text"

const ActionCard = ({ description, icon, onClick, title }) => {
    const theme = useTheme()
    return (
        <Container onClick={onClick}>
            <IconContainer>
                <Icon
                    icon={icon}
                    fill={theme.foregroundPrimary}
                    height={40}
                    width={40}
                />
            </IconContainer>
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    background-color: ${(props) => props.theme.backgroundTertiary};
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: ease-in-out 0.4s;

    &:hover {
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
`

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    width: 160px;
    background-color: ${(props) => props.theme.backgroundPrimary};
    border-radius: 8px;
    margin-bottom: 8px;
`

const Title = styled(P_Medium)`
    color: ${(props) => props.theme.foregroundPrimary};
    margin: 0 0 4px 0;
`

const Description = styled(P_Mini)`
    color: ${Colors.gray[500]};
    margin: 0;
`

export default ActionCard
