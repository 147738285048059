import { useEffect } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { H5, P } from "../../components/text"
import Notice from "../../components/notice"

import Prism from "prismjs"
import "../../assets/css/prism.css"

import WithSidebarMainContent from "../../components/withSidebarMainContent"

const DocumentationWidget = ({ path }) => {
    useEffect(() => {
        Prism.highlightAll()
    }, [])
    return (
        <WithSidebarMainContent>
            <TopContainer>
                <Backlink to="/docs">&larr; Back to docs</Backlink>
                <Title>Widget</Title>
                <Paragraph>
                    You can use our widget to offer creation of contracts on
                    your own site. Check out{" "}
                    <a href="https://lawyered-widget-demo.netlify.app/">
                        the demo
                    </a>
                    . Read on to discover how to get started.
                </Paragraph>
                <Paragraph>
                    Firstly; you'll need to get in touch with us so we can
                    provide you with some identification keys. This is easy;{" "}
                    <a href="mailto:info@lawyered.no">drop us an e-mail</a> or
                    get in touch with us in the chat below. What we need from
                    you:
                </Paragraph>
                <ul>
                    <li>
                        Company or service name: Your front-facing name used in
                        contexts like "Your Service powered by Lawyered".
                    </li>
                    <li>
                        A person we can contact; with name, e-mail and phone
                        number.
                    </li>
                    <li>
                        Which website domain(s) you plan to integrate Lawyered
                        on.
                    </li>
                </ul>
                <Paragraph>
                    You should also send us a logo or logomark that we can use
                    to combine our branding with yours.
                </Paragraph>
                <Paragraph>
                    In return, we'll give you access in the form of a{" "}
                    <strong>Client ID</strong>, <strong>API key</strong> and a{" "}
                    <strong>Client Secret</strong>; one of each for both staging
                    and production environments.
                </Paragraph>
                <Paragraph>
                    When you're all set up with the keys you need, you'll need
                    to import our widget script somewhere on your site:
                </Paragraph>
                <Notice
                    info
                    text="The below URL is for the staging environment, which is safe for testing. Whenever you're ready to go to production, replace lawyered.stage.js with lawyered.production.js."
                />
                <pre>
                    <code className="language-javascript">
                        {`<script src="https://app.lawyered.no/widget/lawyered.stage.js"></script>`}
                    </code>
                </pre>
                <Paragraph>
                    Next, decide where you want the Lawyered-button to show up;
                    place a div with a unique ID:
                </Paragraph>
                <pre>
                    <code className="language-javascript">
                        {`<div id="putTheButtonHerePlease"></div>`}
                    </code>
                </pre>
                <Paragraph>
                    We'll reference this element when we instantiate the
                    Lawyered-class:
                </Paragraph>
                <pre>
                    <code className="language-javascript">{`<script>
    // When DOM is ready...
    ;(function () {
        const lawyered = new Lawyered({
            contractSlug: "kjopsavtale" /* required */,
            clientId: "3fd200a1-96d0-4a90-ac32-7e5afd23beab" /* required */,
            apiKey: "f29f077e-10a9-4f37-b128-02cfab4ca4a0" /* required */,
            externalReference: "PartnerensReferanseID" /* optional */,
            elementParentId: "putTheButtonHerePlease" /* optional */
        })

        window.addEventListener("message", (event) => {
            try {
                const data = event.data
                if (data && data.source && data.source === "lawyered") {
                    console.log(\u0060status\u0060, data.status)
                    if (data.status === "COMPLETED") {
                        console.log(\u0060contractId\u0060, data.contractId)
                        lawyered.closeModal()
                    } else if (data.status === "CANCELLED") {
                        lawyered.closeModal()
                    }
                }
            } catch (e) {
                // Handle errors here
                console.error("Caught an error parsing event", event)
            }
        })
    })()
</script>`}</code>
                </pre>
                <MethodSignature>Lawyered(options)</MethodSignature>
                <Paragraph>
                    <Code>options</Code> is an object with the following entries
                </Paragraph>
                <DetailList>
                    <li>
                        <div>
                            <Code>contractSlug</Code> <Badge>required</Badge>
                        </div>
                        <p>
                            {" "}
                            Refer to{" "}
                            <A to="/docs/templates">
                                our list of templates to find the right one.
                            </A>
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>clientId</Code> <Badge>required</Badge>
                        </div>
                        <p>
                            {" "}
                            You'll get this from us. This identifies you as a
                            partner.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>apiKey</Code> <Badge>required</Badge>{" "}
                        </div>
                        <p>
                            You'll get this from us as well. A «client» can have
                            several API-keys, e.g. with different allowed
                            domains.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>externalReference</Code>{" "}
                            <Badge optional>optional</Badge>
                        </div>
                        <p>
                            {" "}
                            A string you can attach to contracts generated
                            through the widget, as your reference. We don't use
                            this for anything, this is strictly for your
                            convenience. The API endpoint{" "}
                            <a href="#api">
                                <Code>/client-contracts</Code>
                            </a>{" "}
                            accepts a parameter <Code>external_reference</Code>,
                            so you can filter by this value later on.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>elementParentId</Code>{" "}
                            <Badge optional>optional</Badge>
                        </div>
                        <p>
                            {" "}
                            The ID of the DOM-element where we'll place the
                            "Create contract with Lawyered"-button. If you omit
                            this, you'll need to trigger opening of the Lawyered
                            modal yourself with{" "}
                            <Code>Lawyered.openModal()</Code>.
                        </p>
                    </li>
                </DetailList>
                <h4>Constructor</h4>
                <Paragraph>
                    When <Code>{`new Lawyered({ ...options })`}</Code> is called
                    with options, we load some{" "}
                    <a href="/widget/lawyered.css" target="_blank">
                        styles
                    </a>{" "}
                    and insert them into your DOM. If{" "}
                    <Code>elementParentId</Code> is present, we add a button to
                    this element and bind this to <Code>openModal()</Code> on
                    click.
                </Paragraph>
                <h4>Methods</h4>
                <Paragraph>
                    These methods can be called after widget instantiation (e.g.{" "}
                    <Code>{`new Lawyered({ ...options })`}</Code>
                </Paragraph>
                <ul>
                    <li>
                        <Code>openModal()</Code> opens the modal. Not needed if{" "}
                        <Code>elementParentId</Code> is present in
                        instantiation.
                    </li>
                    <li>
                        <Code>closeModal()</Code> closes the modal. If you want
                        to add your own cancel button, you can use this.
                    </li>
                </ul>
                <h4>Event handling</h4>
                <Paragraph>
                    We use <Code>postMessage</Code> to pass data back to you
                    from the widget. The example above includes this snippet:
                </Paragraph>
                <pre>
                    <code className="language-javascript">
                        {`window.addEventListener("message", (event) => {
    try {
        const data = event.data
        if (data && data.source && data.source === "lawyered") {
            console.log(\u0060status\u0060, data.status)
            if (data.status === "COMPLETED") {
                console.log(\u0060contractId\u0060, data.contractId)
                lawyered.closeModal()
            } else if (data.status === "CANCELLED") {
                lawyered.closeModal()
            }
        }
    } catch (e) {
        // Handle errors here
        console.error("Caught an error parsing event", event)
    }
})`}
                    </code>
                </pre>
                <Paragraph>
                    <Code>event.data.status</Code> has two possible values;
                </Paragraph>
                <ul>
                    <li>
                        <Code>COMPLETED</Code> means the contract has been
                        successfully filled out, filed and sent to further
                        processing. Hence, this doesn't necessarily mean it's
                        complete; further end user actions such as signing
                        and/or final processing of the PDF document may still be
                        outstanding. You can use our API endpoint{" "}
                        <a href="#api">
                            <Code>/client-contracts</Code>
                        </a>{" "}
                        to follow up on status and get download links for the
                        final document.
                        <br />
                        <br />
                        When <Code>COMPLETED</Code> is sent, the ID of the newly
                        created contract is passed along in{" "}
                        <Code>event.data.contractId</Code>.
                    </li>
                    <li>
                        <Code>CANCELLED</Code> means the user has expressed an
                        intent to cancel the process. You should call{" "}
                        <Code>closeModal()</Code> when this happens; or handle
                        appropriately.
                    </li>
                </ul>
                <h4 id="api">Further on: API</h4>
                <Paragraph>
                    We made an endpoint available to you, where you can track
                    the status of each contract your users have generated
                    through the widget:
                </Paragraph>
                <Notice
                    info
                    text="The below domain URL is for the staging environment, which is safe for testing. Whenever you're ready to go to production, replace 'europe-west1-lawyered-no-dev' with 'europe-west1-lawyered-no'."
                />
                <Paragraph>
                    <Code>GET</Code>
                    <Code>
                        https://europe-west1-lawyered-no-dev.cloudfunctions.net/client-contracts?client_id=CLIENT_ID
                    </Code>
                </Paragraph>
                <Paragraph>
                    where <Code>CLIENT_ID</Code> is the same client ID you use
                    in the widget. This won't give you access to anything,
                    though, unless you pass along a header with the request;
                    namely <Code>X-Client-Authorization</Code> with your secret
                    key as its value. This secret key is provided to you along
                    with your client ID and API-keys.
                </Paragraph>
                <Paragraph>
                    An authenticated call to this endpoint will return JSON;
                    more specifically an array of objects with all contracts
                    generated with your client ID:
                </Paragraph>
                <pre>
                    <code className="language-javascript">
                        {`[
    {
        "id": "4aazRn7jSYXH2fQOvVEA",
        "externalReference": "your-custom-reference-a",
        "signatureType": "electronic",
        "targetEmail": "email@example.com",
        "title": "Kjøpsavtale",
        "signingCompleted": false,
        "detailsUrl": "https://europe-west1-lawyered-no-dev.cloudfunctions.net/client-contracts?client_id=3fd200a1-96d0-4a90-ac32-7e5afd23beab&contract_id=4aazRn7jSYXH2fQOvVEA"
    },
    {
        "id": "LEa6KjKnXSA1CaR6nA85",
        "externalReference": "your-custom-reference-b",
        "signatureType": "manual",
        "targetEmail": "email@example.com",
        "distributedTo": [
            {
                "destination": "email@example.com",
                "sentAt": {
                    "_seconds": 1636450578,
                    "_nanoseconds": 543000000
                },
                "type": "email"
            }
        ],
        "title": "Kjøpsavtale",
        "signingCompleted": true,
        "detailsUrl": "https://europe-west1-lawyered-no-dev.cloudfunctions.net/client-contracts?client_id=3fd200a1-96d0-4a90-ac32-7e5afd23beab&contract_id=LEa6KjKnXSA1CaR6nA85"
    },
    ...
]`}
                    </code>
                </pre>
                <Paragraph>
                    If you attached an <Code>externalReference</Code> when doing{" "}
                    <Code>{`new Lawyered({ ...options })`}</Code>, you can add{" "}
                    <Code>external_reference=YOUR_CUSTOM_REFERENCE</Code> to the
                    query to filter the results:
                </Paragraph>
                <Notice
                    info
                    text="The below domain URL is for the staging environment, which is safe for testing. Whenever you're ready to go to production, replace 'europe-west1-lawyered-no-dev' with 'europe-west1-lawyered-no'."
                />
                <Paragraph>
                    <Code>GET</Code>
                    <Code>
                        https://europe-west1-lawyered-no-dev.cloudfunctions.net/client-contracts?client_id=CLIENT_ID&external_reference=YOUR_CUSTOM_REFERENCE
                    </Code>
                </Paragraph>
                <Paragraph>
                    where <Code>YOUR_CUSTOM_REFERENCE</Code> is the string
                    matching your custom reference. Note that this will return
                    an array like above as well, since externalReference doesn't
                    have to be unique.
                </Paragraph>
                <Paragraph>
                    You can follow the <Code>detailsUrl</Code> (still using the{" "}
                    <Code>X-Client-Authorization</Code> in the headers) to get a
                    few more details; for a fully signed, completed contract,
                    you'd get something like this:
                </Paragraph>
                <pre>
                    <code className="language-javascript">
                        {`{
    "id": "LEa6KjKnXSA1CaR6nA85",
    "externalReference": "your-custom-reference-b",
    "signatureType": "manual",
    "targetEmail": "email@example.com",
    "distributedTo": [
        {
            "destination": "email@example.com",
            "sentAt": {
                "_seconds": 1636450578,
                "_nanoseconds": 543000000
            },
            "type": "email"
        }
    ],
    "title": "Kjøpsavtale",
    "signingCompleted": true,
    "download": {
        "ready": true,
        "url": "https://storage.googleapis.com/lawyered-no-dev.appspot.com/[...]"
    }
}`}
                    </code>
                </pre>
                <Paragraph>
                    Calls to the endpoint including the contract ID (i.e. the
                    detailsUrl) will always include the <Code>download</Code>
                    -object. Since contracts for various reasons may take some
                    time to complete, you can check if{" "}
                    <Code>download.ready</Code> is true or false.
                </Paragraph>
                <Paragraph>
                    For a contract not fully signed, it would look more like the
                    following. Note the the <Code>signingLinks</Code>-array;
                    this is so you can provide your users with signing links
                    should they have lost or missed them.
                </Paragraph>
                <Paragraph>
                    Note that we only return each signer's initials, not their
                    full name.
                </Paragraph>
                <pre>
                    <code className="language-javascript">
                        {`{
    "id": "AQUlc0pxrOsrj83c4Fsh",
    "externalReference": "your-custom-reference-c",
    "signatureType": "manual",
    "targetEmail": "email@example.com",
    "title": "Kjøpsavtale",
    "signingCompleted": false,
    "download": {
        "ready": false
    },
    "signingLinks": [
        {
            "initials": "G. M.",
            "url": "https://stage.app.lawyered.no/s/M8PCRW"
        }
    ]
}`}
                    </code>
                </pre>
                <h4>Response details</h4>
                <Paragraph>
                    Here's an outline of what may be returned from a call to{" "}
                    <Code>/client-contracts</Code>:
                </Paragraph>
                <DetailList>
                    <li>
                        <div>
                            <Code>id</Code> <Type>string</Type>
                        </div>
                        <p> our ID of the contract.</p>
                    </li>
                    <li>
                        <div>
                            <Code>externalReference</Code> <Type>string</Type>
                        </div>
                        <p>
                            {" "}
                            your reference of the contract. This is what you
                            attached when you did{" "}
                            <Code>{`new Lawyered({ ...options })`}</Code>.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>signatureType</Code>
                            <Type>enum</Type>
                            <Code>electronic | manual</Code>
                        </div>
                        <p>
                            {" "}
                            Electronic signatures are signatures validated by
                            e.g. BankID; manual signatures are handwritten or an
                            uploaded photo of one.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>targetEmail</Code> <Type>string[email]</Type>
                        </div>
                        <p>
                            {" "}
                            The e-mail address the user entered when asked where
                            they would like the final contract sent to.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>distributedTo</Code>{" "}
                            <Type>array[object]</Type>
                        </div>
                        <p>
                            {" "}
                            Populated when a notification (namely e-mail) with
                            the completed contract is sent to a target address.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>title</Code>
                            <Type>string</Type>
                        </div>
                        <p> The title of the contract (template)</p>
                    </li>
                    <li>
                        <div>
                            <Code>signingCompleted</Code>
                            <Type>boolean</Type>
                        </div>
                        <p>
                            This is false until the contract has been signed by
                            all parties.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>download</Code>
                            <Type>object</Type>
                        </div>
                        <p>
                            <Code>download.ready</Code> is false until the
                            contract is fully generated and available to
                            download. <Code>download.url</Code> is only present
                            when the contract is available for download.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>signingLinks</Code> <Type>array[object]</Type>
                        </div>
                        <p>
                            {" "}
                            List of outstanding signatures with URLs for each
                            one. Each objects contains <Code>initals</Code>, a
                            more or less anonymized expression of the signer's
                            name, and <Code>url</Code>.
                        </p>
                    </li>
                    <li>
                        <div>
                            <Code>detailsUrl</Code> <Type>string</Type>
                        </div>
                        <p>
                            {" "}
                            A link to a more detailed description of a contract.
                            This is where you'll find the download link to the
                            completed contract.
                        </p>
                    </li>
                </DetailList>
            </TopContainer>
        </WithSidebarMainContent>
    )
}

const A = styled(Link)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-decoration: underline;
    &:visited {
        color: ${(props) => props.theme.foregroundPrimary};
    }
`

const DetailList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 8px;
    max-width: calc(800px + 1em);
    li {
        display: flex;
        padding: 16px 16px 4px 16px !important;
        flex-direction: column;
        border-bottom: 1px solid lightgray;
        &:last-of-type {
            border-bottom: none;
        }
        div {
            display: flex;
            align-items: center;
        }
        div code {
            display: flex;
            flex: 0 1 auto;
            align-self: flex-start;
        }
    }
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px;
    @media screen and (min-width: 768px) {
        padding: 32px 64px 40px 48px;
    }

    ul {
        li {
            padding-bottom: 20px;
            line-height: 1.5em;
        }
    }

    a,
    a:visited {
        color: ${(props) => props.theme.foregroundPrimary};
        text-decoration: underline;
    }
    a:hover {
        text-decoration: none;
    }
`

const BottomContainer = styled.div`
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 0 32px 32px;
    @media screen and (min-width: 768px) {
        padding: 0 48px 32px 48px;
    }
`

const Backlink = styled(Link)`
    padding: 12px 12px 12px 0;
    margin-bottom: 36px;
    border-bottom: 2px solid transparent;
    text-decoration: none !important;
    &:visited {
        text-decoration: none !important;
    }
    &:hover {
        text-decoration: none !important;
        border-bottom: 2px solid ${Colors.black};
        color: ${Colors.black};
    }
`

const Title = styled(H5)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 16px;
`

const Paragraph = styled(P)`
    margin-bottom: 16px;
    max-width: 75%;
`

const MethodSignature = styled.h4`
    font-family: monospace;
    font-size: 18px;
`

const Badge = styled.span`
    color: ${(props) => (props.optional ? "#8792a2" : "#e56f4a")};
    font-size: 10px;
    letter-spacing: 0.12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 6px;
`

const Type = styled.span`
    font-size: 10px;
    letter-spacing: 0.12px;
    font-weight: 600;
    font-style: italic;
    margin: 0 6px;
`

const Code = styled.code`
    background-color: #ede9fe;
    font-size: 13px;
    border: 1px solid #ddd6fe;
    padding: 4px 6px;
    border-radius: 4px;
    white-space: pre-wrap;
    margin-top: 0;
    display: inline;
`

export default DocumentationWidget
