export const deleteDraft = (templateId) => {
    const templateDrafts = localStorage.getItem("lawyered.drafts")
    if (templateDrafts) {
        const templateDraftsData = JSON.parse(templateDrafts)
        if (templateDraftsData[templateId]) {
            delete templateDraftsData[templateId]
            localStorage.setItem(
                "lawyered.drafts",
                JSON.stringify(templateDraftsData)
            )
        }
    }
}

/*
    Clear temporary local storage data used in creation process.
    If user is anonymous, we save the contract ID for linking
    it to the user if they register later
*/
export const handleLocalStorageDataOnCompletion = (
    moveCurrentContractIdToLStorage = true,
    templateId = null
) => {
    if (moveCurrentContractIdToLStorage) {
        const currentContractId = localStorage.getItem(
            "lawyered.current.contract_id"
        )
        const unlinkedContractIds = localStorage.getItem(
            "lawyered.unlinked_contract_ids"
        )
        if (!unlinkedContractIds) {
            localStorage.setItem(
                "lawyered.unlinked_contract_ids",
                JSON.stringify([currentContractId])
            )
        } else {
            const tempUnlinkedContractIds = JSON.parse(unlinkedContractIds)
            tempUnlinkedContractIds.push(currentContractId)
            localStorage.setItem(
                "lawyered.unlinked_contract_ids",
                JSON.stringify(tempUnlinkedContractIds)
            )
        }
    }
    localStorage.removeItem("lawyered.current.contract_id")
    localStorage.removeItem("lawyered.current.pdf")
    localStorage.removeItem("lawyered.current.pdf_url")

    if (templateId) {
        deleteDraft(templateId)
    }
}

export const clearCurrentContractMetaData = () => {
    localStorage.removeItem("lawyered.current.contract_id")
    localStorage.removeItem("lawyered.current.pdf")
    localStorage.removeItem("lawyered.current.pdf_url")
    localStorage.removeItem("lawyered.current.bulk_contract_id")
    localStorage.removeItem("lawyered.current.current_contract_id")
    localStorage.removeItem('lawyered.bulk.contract.coupon')
}
