import styled from "styled-components"
import parse from "html-react-parser"
import DOMPurify from "dompurify"

import ContractTemplate from "../components/contractTemplate"
import Tab from "../components/tab"
import { useTemplates } from "../hooks/useTemplates"
import { renderError } from "../utils/errors"

import { ContractsLoading } from "./Loading"
import { useEffect, useState } from "react"
import { buildQueryStringForClientAuth } from "../utils/queryString"
import { useBulkContracts } from "../hooks/useBulkContracts"
import { getSingleContractOrderStatus } from "../utils/api"

const Templates = ({ filter: queryTemplatesFilter }) => {
    const token = localStorage.getItem("lawyered.user.token")
    const { bulkContracts, orders} = useBulkContracts()
    const { templates, isLoading, isError } = useTemplates()
    const [filteredTemplates, setFilteredTemplates] = useState([])

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1)
    const [categories, setCategories] = useState([])

    let linkQueryString = buildQueryStringForClientAuth()

    const [templateData, setTemplateData] = useState([]);


    const TemplatePriceCheckForBulkContract = async (templateId) => {
        if (templateId) {
            // Check if templateId exists in bulkContractData
            const bulkContract = bulkContracts?.find(
                (contract) => contract.data.contracts?.includes(templateId)
            );

            // If bulkContract is found, check if its bulkContractId exists in ordersData
            if (bulkContract) {
                return orders?.some(
                    (order) => order.data.bulkContractId === bulkContract.id
                );
            }

        }

        return false;
    }

    useEffect(() => {
        async function fetchData() {
          const updatedTemplateData = [];
      
          for (let i = 0; i < templates.length; i++) {
            const template = templates[i];
            let updatedPrice = template.template_price ?? 0;
            const isThisContractBoughtByBulkContract = await TemplatePriceCheckForBulkContract(template.id);
      
            if (!isThisContractBoughtByBulkContract) {
              try {
                const response = await getSingleContractOrderStatus(template?.id, token);
                if (response?.length > 0) {
                  updatedPrice = 0;
                } else {
                  updatedPrice = template.template_price ?? 0;
                }
              } catch (error) {
                console.error(error);
              }
            } else {
              updatedPrice = 0;
            }
      
            template.updated_price = updatedPrice; 
            // Add the updated_price key to the template data
            // const updatedTemplate = { ...template, updated_price: updatedPrice };
      
            updatedTemplateData.push(template);
            //set all tempalte data at the last index
            if(i+1 === templates.length){
                setTemplateData(updatedTemplateData);
            }
          }
      
        }
      if(templates){
          fetchData();
      }
      }, [templates]); // Empty dependency array to run this effect only once on component mount
      
    


    useEffect(() => {
        if (templates && templates.length) {
            const templateCategories = templates.map(
                (template) => template.category.main
            )
            const updatedCategories = []
            templateCategories.forEach((templateCategory) => {
                const exists = updatedCategories.find(
                    ({ title }) => templateCategory === title
                )
                if (exists) {
                    exists.count++
                } else {
                    updatedCategories.push({
                        title: templateCategory,
                        count: 1
                    })
                }
            })
            setCategories(updatedCategories)
        }
    }, [templates])

    // const RenderCategories = () => {
    //     return (
       
    //     )
    // }

    const RenderTemplatesList = ({list, queryTemplatesFilter}) => {
           
        return (
            <TemplateList>
                {list
                    ?.filter((template) =>
                        queryTemplatesFilter
                            ? queryTemplatesFilter.includes(template.name)
                            : template
                    )
                    ?.map((template) => {
                        
                        return (
                            <li key={template.id}>
                               
                                    <ContractTemplate
                                        description={parse(
                                            DOMPurify.sanitize(
                                                template.description
                                            )
                                        )}
                                        category={template.category.main}
                                        title={template.title}
                                        price={template?.updated_price?? template.template_price}
                                        template_url={`/create/${template.name}${linkQueryString}`}
                                    />
                               
                            </li>
                        )
                    })}
            </TemplateList>
        )
    }

    // const renderTemplates = () => {
    //     return renderTemplatesList(
    //         filteredTemplates.length ? filteredTemplates : templates,
    //         queryTemplatesFilter
    //     )
    // }
    

    if (isLoading) {
        return <ContractsLoading />
    } else {
        return (
            <>
                {isError && isError.code !== 404 && renderError(isError)}
                {!isError && !queryTemplatesFilter && templateData && 
                    <>
                        <Row>
                            <Tab
                                key={`category-tab-clear`}
                                title={`Alle (${templateData.length})`}
                                selected={selectedCategoryIndex === -1}
                                onClick={() => {
                                    setFilteredTemplates([])
                                    setSelectedCategoryIndex(-1)
                                }}
                            />
                            {categories.map((item, index) => {
                                const title = item.title + " (" + item.count + ")"
                                const selected = index === selectedCategoryIndex
                                const onClick = () => {
                                    setSelectedCategoryIndex(index)
                                    setFilteredTemplates(
                                        templateData?.filter(
                                            (template) =>
                                                template.category.main === item.title
                                        )
                                    )
                                }
                                return (
                                    <Tab
                                        key={`category-tab-${index}`}
                                        title={title}
                                        selected={selected}
                                        onClick={onClick}
                                    />
                                )
                            })}
                        </Row>
                    </>
                }
                {!isError &&
                    (filteredTemplates.length || templateData.length) &&
                    // renderTemplates()
                    <RenderTemplatesList list={filteredTemplates.length >0 ? filteredTemplates : templateData} queryTemplatesFilter={queryTemplatesFilter}  />
                    }
            </>
        )
    }
}

const Row = styled.div`
    width: 264px; /* Equals ContractTemplate Container total width */
    display: grid;
    grid-template-columns: 50% 50%;

    @media screen and (min-width: 600px) {
        width: 100%;
        display: flex;
    }

    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;

    > *:not(:last-child) {
        margin-right: 16px;
    }
`

const TemplateList = styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    > li {
        list-style: none;
        display: flex;
        margin: 0 16px 16px 0;
        a {
            display: flex;
            text-decoration: none;
            color: inherit;
        }
    }
    @media screen and (min-width: 768px) {
        flex-direction: row;
        > li {
            margin: 0 16px 16px 0;
        }
    }
`

export default Templates
