import * as React from "react"
import { useTheme } from "styled-components"

function PencilOnSheet({ fill, width = 21 }) {
    const theme = useTheme()
    const height = width
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19 11a1 1 0 00-1 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h6a1 1 0 000-2H3a3 3 0 00-3 3v14a3 3 0 003 3h14a3 3 0 003-3v-6a1 1 0 00-1-1zm-15 .76V16a1 1 0 001 1h4.24a.999.999 0 00.71-.29l6.92-6.93L19.71 7a1.001 1.001 0 000-1.42l-4.24-4.29a1 1 0 00-1.42 0l-2.82 2.83-6.94 6.93a1.001 1.001 0 00-.29.71zm10.76-8.35l2.83 2.83-1.42 1.42-2.83-2.83 1.42-1.42zM6 12.17l5.93-5.93 2.83 2.83L8.83 15H6v-2.83z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default PencilOnSheet
