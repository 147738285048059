import { useState, useContext } from "react"
import styled from "styled-components"

import { FirebaseAuth } from "../../providers/AuthProvider"

import Colors from "../../assets/colors"
import { H5, P, P_Small } from "../../components/text"

import Button from "../../components/button"
import InputGroup from "../../components/inputGroup"
import Feedback from "../../components/feedback"
import DividerWithText from "../../components/dividerWithText"
import { ReactComponent as ContinueWithVipps } from "../../assets/images/continue-with-vipps.svg"

const Login = ({
    isSignupEmailSent = false,
    isLoading = false,
    authErrors = [],
    error,
    onSubmit = () => {},
    vippsSignIn
}) => {
    const [email, setEmail] = useState("")

    const handleNextClick = (event) => {
        event.preventDefault()
        if (email) {
            onSubmit({ email })
        }
    }

    const { getFriendlyErrorMessage } = useContext(FirebaseAuth)

    return (
        <Container>
            <form>
                <InnerContainer>
                    <Title>{"Logg inn eller lag konto"}</Title>
                    {!isSignupEmailSent && (
                        <>
                            <ContinueWithVippsWrapper>
                                <ContinueWithVipps
                                    style={{
                                        cursor: "pointer",
                                        alignSelf: "center"
                                    }}
                                    onClick={() => vippsSignIn()}
                                />
                            </ContinueWithVippsWrapper>
                            <DividerWithText text="eller" />

                            {/*
                            <Button
                                text={"Fortsett med Vipps"}
                                onClick={() => vippsSignIn()}
                            />
                            */}

                            <InputGroup
                                title={"E-post"}
                                placeholder={"E-post-adresse"}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                readonly={isSignupEmailSent || isLoading}
                                value={email}
                            />
                            <P_Small
                                style={{
                                    marginTop: 0,
                                    fontSize: "0.8em",
                                    opacity: 0.7
                                }}
                            >
                                Vi sender deg en e-post med en lenke du kan
                                klikke på for å logge inn.
                            </P_Small>
                            <Button
                                text={"Neste"}
                                onClick={(event) => handleNextClick(event)}
                                id="button-start"
                                disabled={isSignupEmailSent || isLoading}
                                type="submit"
                            />
                            {error && (
                                <Feedback type="warning" description={error} />
                            )}
                        </>
                    )}
                    {isSignupEmailSent && (
                        <Feedback
                            type="info"
                            description="Supert! Vi har sendt deg en e-post med en lenke du kan
                        klikke på for å logge inn."
                        />
                    )}
                    {authErrors.length > 0 && (
                        <Feedback
                            type="error"
                            description={getFriendlyErrorMessage(
                                authErrors[authErrors.length - 1]
                            )}
                        />
                    )}
                </InnerContainer>
            </form>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const InnerContainer = styled.div`
    width: 300px;
    @media screen and (min-width: 768px) {
        width: 360px;
    }
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-align: center;
`

const ContinueWithVippsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > svg:hover {
        opacity: 0.8;
    }
`

export default Login
