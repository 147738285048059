import React, { useRef, useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"

const Title = ({ didChangeTitle = (value) => { }, showIcon = true, title }) => {
    const [value, setValue] = useState(title)
    const [selected, setSelected] = useState(false)
    const inputRef = useRef()

    const onClick = () => {
        setSelected(true)
        inputRef.current.focus()
    }

    const onBlur = () => {
        const value = inputRef.current.value
        didChangeTitle(value)
        setSelected(false)
    }
    const onChange = (event) => setValue(event.target.value)

    return (
        <Container onClick={onClick} selected={selected}>
            <Input
                onBlur={onBlur}
                onChange={onChange}
                ref={inputRef}
                type={"text"}
                value={value}
                readOnly={false}
            />
            {showIcon && <Icon icon={"Pen"} />}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    color: ${Colors.gray[500]};
    background-color: ${(props) =>
        props.selected ? props.theme.backgroundSecondary : "transparent"};
    border: 2px solid transparent;
    border-radius: 8px;

    &:hover {
        background-color: ${(props) => props.theme.backgroundPrimary};
        border: 2px solid ${(props) => props.theme.inputForegroundSecondary};
    }
`

const Input = styled.input`
    font-size: 24px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    background-color: transparent;

    &:focus {
        outline: none;
    }
`

export default Title
