export const STEP = {
    UPLOAD: "0_UPLOAD_FILE",
    FORM: "1_FILL_FORM",
    SUMMARY: "2_SHOW_SUMMARY",
    SIGNING_DETAILS: "3_SIGNING_DETAILS",
    PAYMENT: "4_PAYMENT",
    SIGNING: "5_SIGNING",
    COMPLETE: "6_COMPLETE",
    ENTER_TARGET_EMAIL: "A_ENTER_TARGET_EMAIL",
    STATUS: "STATUS",
    CHECKOUT: "CHECKOUT",
}

export const STRIPE = {
    PK_TEST:
        "pk_test_51IqEKSLnNwp5XNU1E3jEtf2jN8P0OQCI4GFCpDakBB9fdCPawJnCScGNFbV3hWjf28wxX4dxyCB0qAYboxGUJ4A900uzCb4k6B",
    PK_PROD:
        "pk_live_51IqEKSLnNwp5XNU1ySwSM9O53CHWfL4hVGUpcMUb2cp5m950WOiwLgFDJDambH1J6A5Lbqo5oA9xbf6WGzbt1RmP00mgEV4Y15"
}

export const SIGNING_METHOD = {
    no_bankid_mobile: "BankID på mobil",
    no_bankid: "BankID"
}

export const PRICE_PER_SIGNING = {
    no: 35,
    manual: 20
}
