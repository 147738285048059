import * as React from "react"
import Colors from "../colors"

function Logo({ fill = Colors.black, width = 451 }) {
    const height = width * (71 / 452)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 451 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#prefix__clip0)" fill={fill}>
                <path d="M43.5 63.5v6.4H0V0h16.6v63.5h26.9z" />
                <path d="M99.9 69.9H80.7L75.4 55H50.1S46 66.3 44.8 69.9h-5L64.5 0h8.7l26.7 69.9zm-26-20L62.8 19.5c-.4 0 .2 0-.1.1-3.4 9.9-7.2 19.8-10.8 30.4h22v-.1zM176.6.1l-26.1 70.5-17.6-40-14.7 40L86.8.1H104l18.7 42.6 7-19.1L119.2.1h17.2l18.7 42.6L170.9.1h5.7z" />
                <path d="M230.9.1l-20.5 32.1V70h-16.6V33.5L170.9.1h17.5L207 27.3 224.3 0h6.6v.1zM279.5 63.5v6.4h-43.3V.1h40.7v6.4h-24.1v22.2h15.7v6.4h-15.7v28.5h26.7v-.1zM340.3 69.9h-18.2l-17-29.7h-3.4v29.7h-16.6V.1h16.6c16.2 0 35.5 4.4 35.5 20.1 0 10.1-6.7 15.4-15.9 17.9l19 31.8zm-38.6-33.8c13.4 0 19.7-6 19.7-15.3 0-10.1-6.5-15.8-19.7-15.8v31.1zM389.6 63.5v6.4h-43.3V.1H387v6.4h-24.1v22.2h15.7v6.4h-15.7v28.5h26.7v-.1zM395.2.1h13.1c24.3 0 42.3 11.9 42.3 35 0 24.8-21.2 34.9-42.9 34.9h-12.5V.1zm16.4 64.3c13.7 0 21-12.2 21-29.4 0-17-6.7-29.4-21-29.4v58.8z" />
            </g>
            <defs>
                <clipPath id="prefix__clip0">
                    <path fill="#fff" d="M0 0h450.6v70.6H0z" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default Logo
