import { Redirect } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { FirebaseAuth } from "../providers/AuthProvider"

import Colors from "../assets/colors"
import { H5, P_Mini_M } from "../components/text"

import { getAuthUserData } from "../utils/api"

const MagicLogin = () => {
    const [email, setEmail] = useState("")

    const {
        handleSignin,
        approved,
        errors,
        token,
        authStateChangedWithUserReady
    } = useContext(FirebaseAuth)

    const [shouldRedirectToOnboarding, setShouldRedirectToOnboarding] =
        useState(false)

    useEffect(() => {
        if (authStateChangedWithUserReady) {
            // We need to wait for a user-object to be ready to
            // be able to convert it into a permanent user.
            const storedEmail = localStorage.getItem("lawyered.user.email")
            if (storedEmail) {
                setEmail(storedEmail)
                handleSignin(window.location.href)
            }
        }
    }, [authStateChangedWithUserReady])

    useEffect(() => {
        async function approvedCb() {
            const authUserData = await getAuthUserData(token)
            if (
                authUserData.userData.dbData?.phoneNumber ||
                authUserData.userData.dbData?.email
            ) {
                window.location.href = "/dashboard"
            } else {
                setShouldRedirectToOnboarding(true)
                // window.location.href = "/onboarding"
            }
        }

        if (approved) {
            approvedCb()
        }
    }, [approved])

    if (shouldRedirectToOnboarding) {
        return <Redirect to="/onboarding" />
    }

    return (
        <Container>
            <InnerContainer>
                <Title>{"Logger deg inn..."}</Title>
                <Message>
                    Autentiserer <Bold>{email}</Bold>...
                </Message>
                {/* TODO prettify this a bit */}
                {errors.length > 0
                    ? errors.map((error) => (
                        <p style={{ color: "red" }}>{error.message}</p>
                    ))
                    : null}
            </InnerContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100vh;
    background-color: ${(props) => props.theme.backgroundPrimary};
    justify-content: center;
    align-items: center;
`

const InnerContainer = styled.div`
    width: 300px;
    @media screen and (min-width: 768px) {
        width: 360px;
    }
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-align: center;
`

const Message = styled(P_Mini_M)`
    color: ${Colors.gray[500]};
    text-align: center;
`

const Bold = styled(Message)`
    font-weight: 700;
    display: inline;
`

export default MagicLogin
