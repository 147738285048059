import apiUrl, { stagingOrProdApiUrl } from "./apiUrl"
import { isObjectEmpty } from "./objectHelpers"

const doAuthedFetch = async (type = "GET", url = "", data = {}, token) => {
    const options = {
        method: type,
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        }
    }
    if (type !== "GET") {
        options.body = JSON.stringify(data)
    }
    return fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            if (data) {
                return data
            } else {
                console.error("doAuthedFetch data error", data.error)
                return { success: false, error: data.error }
            }
        })
        .catch((error) => {
            console.error("doAuthedFetch request error", error)
            return { success: false }
        })
}

const buildContractPreview = async (templateId, fieldData, token) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/contracts?template_id=${templateId}&preview=true`,
        { fields: fieldData },
        token
    )
    return response
}

const buildContract = async (
    templateId,
    fieldData,
    signatureType,
    token,
    widgetClientId,
    widgetExternalReference
) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/contracts?template_id=${templateId}`,
        {
            fields: fieldData,
            signatureType,
            clientId: widgetClientId,
            clientExternalReference: widgetExternalReference
        },
        token
    )
    return response
}

const saveContractTargetEmail = async (contractId, token, email) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/save_contract_target_email?contract_id=${contractId}`,
        { email },
        token
    )
    return response
}

const generatePdf = async (contractId, token) => {
    const response = await doAuthedFetch(
        "PATCH",
        `${apiUrl}/contracts?contract_id=${contractId}`,
        {},
        token
    )
    return response
}

const getContract = async (contractId, token) => {
    const response = await doAuthedFetch(
        "GET",
        `${apiUrl}/contracts?contract_id=${contractId}`,
        {},
        token
    )
    return response
}

const createSigningShortcodes = async (contractId, token, signatureType) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/signing_shortcodes?contract_id=${contractId}`,
        { signatureType },
        token
    )
    return response
}

const getSigningShortcodes = async (contractId, token) => {
    const response = await doAuthedFetch(
        "GET",
        `${apiUrl}/signing_shortcodes?contract_id=${contractId}`,
        {},
        token
    )
    return response
}

const uploadAttachmentsToContract = async (contractId, token, files) => {
    var headers = new Headers()
    headers.append("Authorization", `Bearer ${token}`)

    var formdata = new FormData()
    files.forEach((file) => {
        console.log(`file`, file)
        formdata.append("file[]", file, file.name)
    })

    var requestOptions = {
        method: "POST",
        headers: headers,
        body: formdata,
        redirect: "follow"
    }

    return fetch(
        `${apiUrl}/attachments?contract_id=${contractId}`,
        requestOptions
    )
        .then((response) => response.json())
        .catch((error) => console.log("error", error))
}

const uploadSignature = async (shortcode, base64signature) => {
    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            shortcode,
            signature: base64signature
        }),
        redirect: "follow"
    }

    return fetch(`${apiUrl}/save_signature`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error))
}

const uploadSignatureImage = async (shortcode, file) => {
    var headers = new Headers()

    var formdata = new FormData()
    formdata.append("file", file, file.name)

    var requestOptions = {
        method: "POST",
        headers: headers,
        body: formdata,
        redirect: "follow"
    }

    return fetch(
        `${apiUrl}/save_signature_image?shortcode=${shortcode}`,
        requestOptions
    )
        .then((response) => response.json())
        .catch((error) => console.log("error", error))
}

const addSigners = async (contractId, signers, templatePrice, token) => {
    const response = await doAuthedFetch(
        "PUT",
        `${apiUrl}/contracts?contract_id=${contractId}`,
        { signers, templatePrice },
        token
    )
    return response
}

const createPaymentIntent = async (contractId, stripeCustomerId, couponId, token) => {
    console.error("===========GET stripe here==========", stripeCustomerId)
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/payments?action=create_payment_intent`,
        { contractId, stripeCustomerId, couponId },
        token
    )
    return response
}

const createPaymentIntentBulkContracts = async (bulkContractId, stripeCustomerId, couponId, token) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/payments?action=create_payment_intent_bulk_contracts`,
        { bulkContractId, stripeCustomerId, couponId },
        token
    )
    return response
}

const getStripeCustomer = async (token) => {
    const response = await doAuthedFetch(
        "GET",
        `${apiUrl}/payments?action=get_customer`,
        {},
        token
    )
    return response
}

const sendToSigning = async (contractId, token) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/sign?contract_id=${contractId}`,
        {},
        token
    )
    return response
}

const getSigningStatus = async (contractId, token) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/signing_status?contract_id=${contractId}`,
        {},
        token
    )
    return response
}

const getAuthUserData = async (token) => {
    const local_token = localStorage.getItem("lawyered.user.token")
    const response = await doAuthedFetch("GET", `${apiUrl}/user`, {}, local_token)
    return response
}

const updateUserData = async (token, userData) => {
    const local_token = localStorage.getItem("lawyered.user.token")
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/user`,
        userData,
        local_token
    )
    return response
}

const identifyAuthTypeForUser = async (token, emailOrPhoneNumber) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/user/id`,
        { id: emailOrPhoneNumber },
        token
    )
    return response
}

const saveCompany = async (token, companyData) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/company`,
        companyData,
        token
    )
    return response
}

const sendManualSigningLinkByEmail = async (token, shortcode, email) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/notifications/signing/manual?shortcode=${shortcode}&email=${email}`,
        { shortcode, email },
        token
    )
    return response
}

const getShortcodeMeta = async (shortcode) => {
    try {
        const response = await fetch(
            `${apiUrl}/signing_shortcode?shortcode=${shortcode}`
        )
        const data = await response.json()
        return data
    } catch (error) {
        return {
            success: false,
            error
        }
    }
}

const getContractTextForManualSigning = async (
    shortcode,
    contractReadingKey
) => {
    try {
        const response = await fetch(
            `${apiUrl}/contract_read?shortcode=${shortcode}&contract_reading_key=${contractReadingKey}`
        )
        const data = await response.json()
        return data
    } catch (error) {
        return {
            success: false,
            error
        }
    }
}

const getFeatureAvailability = async (token, feature = null) => {
    const response = await doAuthedFetch("GET", `${apiUrl}/features`, {}, token)
    if (response && isObjectEmpty(response)) {
        return false
    }
    if (feature && response.hasOwnProperty(feature)) {
        return response[feature]
    }
    return response
}

const initiateVippsPayment = async (token, phoneNumber, contractId, couponId) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/vipps/initiate`,
        { phoneNumber, contractId, couponId },
        token
    )
    return response
}

const initiateVippsPaymentBulkContracts = async (token, phoneNumber, bulkContractId, couponId) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/vipps/initiate_bulk_contracts`,
        { phoneNumber, bulkContractId, couponId },
        token
    )
    return response
}

const addCardPaymentOrders = async (token, paymentOrderData) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/payment_order`,
        { ...paymentOrderData },
        token
    )
    return response
}

const getVippsTransactionStatus = async (token, orderId, pollingCounter) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/vipps/status${pollingCounter % 5 === 0 ? "?refresh=1" : ""}`,
        { orderId },
        token
    )
    return response
    /*
    var headers = new Headers()
    headers.append("Content-Type", "application/json")

    var requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
            orderId
        }),
        redirect: "follow"
    }

    return fetch(
        `${apiUrl}/vipps/status${pollingCounter % 5 === 0 ? "?refresh=1" : ""}`,
        requestOptions
    )
        .then((response) => response.json())
        .catch((error) => console.log("error", error))
    */
}

const checkClientAccess = async (clientId, apiKey, token) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/check_client_access`,
        { clientId, apiKey },
        token
    )
    return response
}

const getContractDownloadUrl = async (contractId, token) => {
    const response = await doAuthedFetch(
        "GET",
        `${apiUrl}/signed_contract?contract_id=${contractId}`,
        {},
        token
    )
    return response
}

const uploadUserPdf = async ({ token, files, title }) => {
    var headers = new Headers()
    headers.append("Authorization", `Bearer ${token}`)

    var formdata = new FormData()
    files.forEach((file) => {
        console.log(`file`, file.name)
        formdata.append("file[]", file, file.name)
        formdata.append("title", title ? title : file.name)
    })

    var requestOptions = {
        method: "POST",
        headers: headers,
        body: formdata,
        redirect: "follow"
    }

    return fetch(`${apiUrl}/upload?to_images=1`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error))
}

const buildContractForUploadedPdf = async ({
    token,
    userPdfId,
    signatureType,
    widgetClientId,
    widgetExternalReference
}) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/contracts?user_pdf_id=${userPdfId}`,
        {
            signatureType,
            clientId: widgetClientId,
            clientExternalReference: widgetExternalReference
        },
        token
    )
    return response
}

const transferAnonymouslyCreatedContractsToUser = async ({
    token,
    contracts
}) => {
    const promises = []
    contracts.forEach((contract) => {
        promises.push(
            doAuthedFetch(
                "POST",
                `${apiUrl}/user/transfer-anonymous-contract`,
                { contract },
                token
            )
        )
    })
    const responses = await Promise.allSettled(promises)
    return responses
}

const grantAccessToContract = async ({ token, contracts }) => {
    const promises = []
    contracts.forEach((contract) => {
        promises.push(
            doAuthedFetch(
                "POST",
                `${apiUrl}/user/grant-access-to-contract`,
                { contract },
                token
            )
        )
    })
    const responses = await Promise.allSettled(promises)
    return responses
}

const identifyWithVipps = async ({
    firebaseToken,
    code,
    codeVerifier,
    scope,
    state
}) => {
    var headers = new Headers()
    headers.append("Authorization", `Bearer ${firebaseToken}`)
    headers.append(
        "Content-Type",
        `application/x-www-form-urlencoded;charset=UTF-8`
    )

    const data = {
        code,
        code_verifier: codeVerifier,
        scope,
        state
    }

    var body = []
    for (var property in data) {
        var encodedKey = encodeURIComponent(property)
        var encodedValue = encodeURIComponent(data[property])
        body.push(encodedKey + "=" + encodedValue)
    }
    body = body.join("&")

    var requestOptions = {
        method: "POST",
        headers: headers,
        body: body,
        redirect: "follow"
    }

    return fetch(`${apiUrl}/user/vipps-callback`, requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error))
}


const getSingleContractOrderStatus = async (contractId, token) => {
    const response = await doAuthedFetch(
        "GET",
        `${apiUrl}/payment_order?contract_id=${contractId}&order_status_for=SINGLE_CONTRACT`,
        {},
        token
    )
    return response
}

const addOrderForBulkContractUsingPromocode = async (bulkContractId, couponId, token) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/promocode?action=add_order_for_bulk_contract_using_promocode`,
        { bulkContractId, couponId },
        token
    )
    return response
}

const applyPromoCode = async (coupon, documentId, token) => {
    const response = await doAuthedFetch(
        "POST",
        `${apiUrl}/coupon?couponeCode=${coupon}&document_id=${documentId}`, {},
        token
    )
    return response
}

export {
    buildContractPreview,
    buildContract,
    generatePdf,
    uploadAttachmentsToContract,
    addSigners,
    createPaymentIntent,
    createPaymentIntentBulkContracts,
    sendToSigning,
    getSigningStatus,
    getAuthUserData,
    updateUserData,
    identifyAuthTypeForUser,
    saveCompany,
    getContract,
    createSigningShortcodes,
    getSigningShortcodes,
    uploadSignature,
    uploadSignatureImage,
    getStripeCustomer,
    sendManualSigningLinkByEmail,
    getShortcodeMeta,
    getContractTextForManualSigning,
    getFeatureAvailability,
    initiateVippsPayment,
    initiateVippsPaymentBulkContracts,
    addCardPaymentOrders,
    getVippsTransactionStatus,
    saveContractTargetEmail,
    checkClientAccess,
    getContractDownloadUrl,
    uploadUserPdf,
    buildContractForUploadedPdf,
    transferAnonymouslyCreatedContractsToUser,
    grantAccessToContract,
    identifyWithVipps,
    getSingleContractOrderStatus,
    addOrderForBulkContractUsingPromocode,
    applyPromoCode
}
