import firebase from "firebase"
import "firebase/auth"
import "firebase/app"

const firebaseConfig = {
    development: {
        apiKey: "AIzaSyCwz5C5vrye8GtMDpKRinDuULwMBk5K-u8",
        authDomain: "lawyered-no-dev.firebaseapp.com",
        projectId: "lawyered-no-dev",
        storageBucket: "lawyered-no-dev.appspot.com",
        messagingSenderId: "993439561725",
        appId: "1:993439561725:web:61261a2afdde22979ccb94",
        measurementId: "G-8FK5RNKRQD"
    },
    production: {
        apiKey: "AIzaSyCDor3AOylNe3jjOrs22y9Z-WidPwbo_fo",
        authDomain: "lawyered-no.firebaseapp.com",
        projectId: "lawyered-no",
        storageBucket: "lawyered-no.appspot.com",
        messagingSenderId: "1074261296426",
        appId: "1:1074261296426:web:0a9e8a68fe37b6a719ca53",
        measurementId: "G-FHY8LXLYF6"
    }
}

const getConfig = () => {
    const l = window.location.hostname
    if (l === "app.lawyered.no" || l === "lawyered-no.web.app") {
        return firebaseConfig.production
    }
    return firebaseConfig.development
}

firebase.initializeApp(getConfig())
firebase.analytics()
firebase.auth()

if (window.location.hostname === "localhost") {
    firebase.auth().useEmulator("http://localhost:9099")
}

export const auth = firebase.auth

export default firebaseConfig
