import React, { useState } from "react"
import styled, { useTheme } from "styled-components"
import Checkbox from "./Checkbox"

import { Popover } from "react-tiny-popover"

import Colors from "../../assets/colors"
import Icon from "../icon"
import Notice from "../notice"

const CheckboxContract = ({
    name,
    onChange = () => {},
    label,
    value,
    helpText,
    checked = false,
    required = true
}) => {
    const theme = useTheme()
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [isChecked, setIsChecked] = useState(checked)

    const handleChange = (checked) => {
        setIsChecked(checked)
        onChange(name, checked)
    }

    const icon = () => {
        return (
            <CheckboxWrapper selected={isChecked}>
                <Icon icon={"Checkmark"} />
            </CheckboxWrapper>
        )
    }

    return (
        <Container hasOptionalMarker={!required}>
            <Checkbox
                icon={icon()}
                name={name}
                checked={isChecked}
                onChange={(checked) => handleChange(checked)}
                borderColor={Colors.gray[300]}
                borderRadius={4}
                borderWidth={1}
                size={16}
                style={{ cursor: "pointer" }}
                labelStyle={{
                    marginLeft: 8,
                    userSelect: "none",
                    color: theme.inputForeground
                }}
                label={label}
                labelClassName="checkbox-label"
                containerClassName="checkbox-container"
                className="checkbox"
                required={required}
                value={value}
            />
            {!required && <OptionalMarker>Valgfritt</OptionalMarker>}
            {helpText && (
                <Popover
                    isOpen={isPopoverOpen}
                    positions={["right", "top", "bottom", "left"]} // preferred positions by priority
                    content={<Notice info text={helpText} />}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    containerClassName="popoverOuter"
                >
                    <PopoverWrapper
                        onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                    >
                        <Icon icon="Info" />
                    </PopoverWrapper>
                </Popover>
            )}
        </Container>
    )
}

const Container = styled.div`
    padding: 2px 4px 0;
    background-color: ${(props) =>
        props.filled ? props.theme.backgroundPrimary : Colors.gray[50]};
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 1px solid ${Colors.gray[200]};
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};

    margin: ${(props) =>
        props.hasOptionalMarker
            ? "16px 8px 24px 0 !important"
            : "-2px 8px 24px 0 !important"};
    @media screen and (min-width: 768px) {
        margin: ${(props) =>
            props.hasOptionalMarker
                ? "-2px 8px 24px 8px !important"
                : "-2px 8px 24px 8px !important"};
    }

    min-height: 28px;
    position: relative;
    display: flex;

    width: 100%;
    @media screen and (min-width: 768px) {
        width: auto;
    }

    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }

    span {
        min-height: auto !important;
        margin-bottom: 0 !important;
    }
    .checkbox {
        margin-top: 3px;
        margin-left: 1px;
    }
    .checkbox-label {
        margin-left: 8px;
        padding: 0 4px 4px 0;
        margin-top: 2px;
        line-height: 21px;
        display: flex;
        flex: 1;
    }
    .checkbox-container {
        align-items: flex-start !important;
    }
`

const CheckboxWrapper = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border: 1px solid ${Colors.gray[300]};
    border-radius: 4px;
    background-color: ${(props) =>
        props.selected
            ? props.theme.inputForeground
            : props.theme.backgroundSecondary};
`

const OptionalMarker = styled.span`
    text-transform: uppercase;
    color: ${Colors.gray[500]};
    position: absolute;
    top: -16px;
    right: 0;
    font-size: 11px;
`

const PopoverWrapper = styled.div`
    padding: 2px;
    z-index: 1024;
    position: absolute;
    right: 4px;
    @media screen and (min-width: 768px) {
        position: relative;
        right: auto;
    }
`

export default CheckboxContract
