import styled from "styled-components"
import { H5, P, P_Small_M } from "../text"
import Spinner from "../Spinner"
import parse from "html-react-parser"
import Colors from "../../assets/colors"

const ContractPreview = ({
    title,
    content,
    isOverlay = false,
    copyProtection = true,
    showCopyProtectionText = true
}) => {
    return (
        <PreviewWrapper isOverlay={isOverlay}>
            <div>
                <H5>{title}</H5>
                {!content && <Spinner size="32" />}
                {content && <PreviewContent>{parse(content)}</PreviewContent>}
            </div>

            <Disclaimer>
                <P_Small_M>Kontrakt utarbeidet av Lawyered</P_Small_M>
            </Disclaimer>
            {copyProtection && (
                <Overlay transparent={!showCopyProtectionText}>
                    {showCopyProtectionText && <p>FORHÅNDSVISNING</p>}
                </Overlay>
            )}
        </PreviewWrapper>
    )
}

const PreviewWrapper = styled.div`
    position: ${(props) => (props.isOverlay ? "absolute" : "relative")};
    width: 100%;
    height: 100%;
    background-color: ${Colors.white};
    border: 2px solid ${Colors.gray[100]};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h5 {
        text-align: center;
        margin-bottom: 24px;
    }
`

const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.transparent
            ? "rgba(255, 255, 255, 0.0)"
            : "rgba(255, 255, 255, 0.5)"};
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    p {
        transform-origin: center center;
        transform: rotate(-22.5deg);
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 28px;
        font-weight: 700;
    }
    @media only screen and (min-width: 768px) {
        p {
            font-size: 42px;
        }
    }
`

const PreviewContent = styled(P)`
    color: ${Colors.gray[700]};
    width: 100%;
    ins {
        color: ${(props) => props.theme.foregroundSecondary};
        font-weight: 500;
        text-decoration: none;
    }
    span.subheader {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        margin: 20px 0 4px 0;
        display: block;
    }
`

const Disclaimer = styled.div`
    background: ${Colors.gray[100]};
    border-radius: 8px;
    padding: 8px;
    margin: 24px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default ContractPreview
