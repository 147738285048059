import { useEffect, useState } from "react"
import styled from "styled-components"
import { useParams, useLocation, useHistory } from "react-router-dom"
import { isMobile } from "react-device-detect"
import useWindowSize from "../hooks/useWindowSize"

import apiUrl from "../utils/apiUrl"

import Colors from "../assets/colors"
import { P_Medium, P_Small_M } from "../components/text"

import Signature from "../components/Signature"
import { uploadSignature } from "../utils/api"
import Icon from "../components/icon"
import Spinner from "../components/Spinner"
import { __error } from "../utils/errors"

const SignManual = ({ path }) => {
    let { shortcode } = useParams()
    const [shortcodeData, setShortcodeData] = useState(null)
    const [signatureData, setSignatureData] = useState(null)
    const [contractData, setContractData] = useState(null)

    const location = useLocation()
    const history = useHistory()

    const [loading, setLoading] = useState(null)
    const [isCompleted, setIsCompleted] = useState(false)
    const [error, setError] = useState(null)

    const windowSize = useWindowSize()

    useEffect(() => {
        const query = location.search
        const params = new URLSearchParams(query)
        if (!!params.get("completed")) {
            setIsCompleted(true)
        }
    }, [location])

    useEffect(() => {
        async function getShortcodeMeta(shortcode) {
            setLoading("Henter info...")
            try {
                const response = await fetch(
                    `${apiUrl}/signing_shortcode?shortcode=${shortcode}`
                )
                const data = await response.json()
                if (data.success) {
                    setShortcodeData(data.shortcode)
                    setContractData(data.contract)
                    setLoading(null)
                } else {
                    setError(__error(data.error?.code))
                    setLoading(null)
                }
            } catch (error) {
                console.log(`error`, error)
                setError("error")
                setLoading(null)
            }
        }

        if (shortcode) {
            getShortcodeMeta(shortcode)
        }
    }, [shortcode])

    const onSignatureCompleted = async (data) => {
        setSignatureData(data)
        setLoading("Laster opp signatur...")
        try {
            const response = await uploadSignature(shortcode, data)
            if (response.success) {
                history.push(`/s/${shortcode}/manual?completed=1`)
            }
            setLoading(null)
            setIsCompleted(true)
        } catch (error) {
            console.log(`error`, error)
            setError("error")
            setLoading(null)
        }
    }

    if (loading) {
        return (
            <Fullscreen>
                <Spinner text={loading} />
            </Fullscreen>
        )
    }

    if (isCompleted) {
        return (
            <Fullscreen>
                <Circle bg={Colors.green[600]}>
                    <Icon icon="Checkmark" width="21" fill={Colors.white} />
                </Circle>
                <P_Medium>
                    {contractData && `«${contractData.contractTitle}» signert`}
                </P_Medium>
                <P_Small_M>Du kan nå lukke dette vinduet.</P_Small_M>
            </Fullscreen>
        )
    }

    if (error) {
        return (
            <Fullscreen>
                <Circle bg={Colors.red[400]}>
                    <Icon icon="Checkmark" width="21" fill={Colors.white} />
                </Circle>
                <P_Medium>{error}</P_Medium>
            </Fullscreen>
        )
    }

    return (
        <>
            {isMobile && windowSize.height > windowSize.width && (
                <BlockerOverlay>
                    <Icon icon="RotateDevice" width="128" />
                </BlockerOverlay>
            )}
            <TopContainer>
                {shortcodeData && (
                    <>
                        <Signature
                            onCompleted={(data) => onSignatureCompleted(data)}
                            name={`${
                                shortcodeData.signerInfo.firstName || ""
                            } ${shortcodeData.signerInfo.lastName || ""} ${
                                (shortcodeData.signerInfo.organizationInfo &&
                                    shortcodeData.signerInfo.organizationInfo
                                        .companyName) ||
                                ""
                            }`}
                        />
                    </>
                )}
            </TopContainer>
        </>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        width: 768px;
    }
`

const BlockerOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1024;
    background-color: ${(props) => props.theme.backgroundPrimary};
`

const Circle = styled.span`
    display: flex;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.bg};
    border-radius: 50%;
`

const Fullscreen = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.backgroundPrimary};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        padding: 8px 32px;
        text-align: center;
    }
`

const QR = styled.div`
    width: 128px;
    border-radius: 8px;
    overflow: hidden;
`

export default SignManual
