import React from "react"
import styled from "styled-components"
import parse from "html-react-parser"

import Colors from "../../assets/colors"
import { P_Small_M, P_Small } from "../text"

import RadioButton from "../radioButton"

const RadioButtonLabelDesc = ({ description, onChange, selected, title }) => {
    return (
        <Container onClick={onChange} selected={selected}>
            <div>
                <RadioButton selected={selected} />
            </div>
            <RightContainer>
                <Title>{title}</Title>
                {description && <Description>{parse(description)}</Description>}
            </RightContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    padding: 16px;
    cursor: pointer;
    border: ${(props) =>
        props.selected
            ? "1px solid " + props.theme.inputForegroundSecondary
            : "1px solid transparent"};
    background-color: ${(props) =>
        props.selected ? props.theme.backgroundPrimary : props.theme.backgroundSecondary};
`

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 12px;
`

const Title = styled(P_Small_M)`
    color: ${Colors.gray[700]};
`

const Description = styled(P_Small)`
    color: ${Colors.gray[500]};
    white-space: pre-wrap;
`

export default RadioButtonLabelDesc
