import styled from "styled-components"

import Colors from "../../assets/colors"
import { H5, P_Small, P, P_Large_M, H2 } from "../../components/text"
import Button from "../../components/button"

const FullscreenError = ({ error, componentStack, resetError }) => {
    return (
        <Fullscreen>
            <FullscreenInner>
                <FullscreenInnerHeader>
                    <H5>Huff, da :(</H5>
                    <P>
                        Her skjedde det en feil. Men det er vår skyld, ikke din.
                    </P>
                    <P_Small>Feilen er logget, og vi har fått beskjed.</P_Small>
                </FullscreenInnerHeader>
                <code>{error.toString()}</code>
                <Button primary text={"Prøv igjen"} onClick={resetError} />
            </FullscreenInner>
        </Fullscreen>
    )
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 0 24px 24px 0;
    min-width: 360px;

    background: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`

const Fullscreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => props.theme.backgroundPrimary};
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FullscreenInner = styled(Box)`
    width: ${(props) =>
        props.wide ? "calc(480px - 32px)" : "calc(360px - 32px)"};
    margin: 0;
    transition: width 225ms ease-out;

    code {
        text-align: center;
    }
`

const FullscreenInnerHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: 24px 0 0;
    align-items: center;
    text-align: center;
    p {
        margin-top: 16px;
    }
`

export default FullscreenError
