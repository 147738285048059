import React, { useRef, useState } from "react"
import styled from "styled-components"

import { Popover } from "react-tiny-popover"

import Colors from "../../assets/colors"
import Icon from "../icon"
import danger from "../../assets/icons/svg/Danger.svg"
import warning from "../../assets/icons/svg/Warning.svg"
import Notice from "../notice"

const TextInputContract = ({
    icon,
    onChange = () => { },
    placeholder,
    helpText,
    type,
    name,
    id,
    label,
    size,
    prefix,
    suffix,
    shouldDisplayValidationErrors,
    value = undefined,
    asInput = true,
    readonly = false,
    required = true,
    dismissable = false,
    onDismiss = () => { }
}) => {
    const [isFocused, setIsFocused] = useState(false)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

    const inputRef = useRef()

    const onClick = () => {
        setIsFocused(true)
        inputRef.current.focus()
    }
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)

    return (
        <Wrapper hasLabel={!!label}>
            {label && <label htmlFor={id || name}>{label}</label>}
            {prefix && <span className="fieldPrefix">{prefix}</span>}
            <Container isFocused={isFocused} onClick={onClick}>
                {icon && <Icon icon={icon} fill={Colors.gray[400]} />}
                <Input
                    ref={inputRef}
                    type={type || "text"}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                    size={size}
                    name={name}
                    id={id || name}
                    readOnly={readonly}
                    required={required}
                    className={shouldDisplayValidationErrors ? "invalid" : ""}
                    asInput={asInput}
                    hasHelpText={!!helpText}
                />

                {helpText && (
                    <Popover
                        isOpen={isPopoverOpen}
                        positions={["top", "bottom", "right", "left"]} // preferred positions by priority
                        content={<Notice info text={helpText} />}
                        containerStyle={{ zIndex: 2048 }}
                        onClickOutside={() => setIsPopoverOpen(false)}
                        containerClassName="popoverOuter"
                    >
                        <PopoverWrapper
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <Icon icon="Info" />
                        </PopoverWrapper>
                    </Popover>
                )}
                {dismissable && (
                    <IconContainer onClick={onDismiss}>
                        <Icon icon={"Cancel"} />
                    </IconContainer>
                )}
            </Container>
            {suffix && <span className="fieldSuffix">{suffix}</span>}
        </Wrapper>
    )
}

const getFieldSize = (type, size) => {
    if (type && ["date", "number"].includes(type)) {
        if (type === "date") {
            return "150px"
        }
        if (type === "number") {
            return "90px"
        }
        return "auto"
    }
    if (size) {
        return `${size}em`
    }
    return "auto"
}

const Wrapper = styled.div`
    display: flex;
    flex: ${(props) => (props.hasLabel ? "1 0 100%" : "0")};
    flex-direction: ${(props) =>
        props.hasLabel ? "column" : "row"}; /* example: 'samarbeidsavtale' */
    align-items: baseline;
    margin-bottom: 16px;

    @media screen and (min-width: 600px) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    @media screen and (min-width: 768px) {
        flex-direction: column;
        flex-wrap: wrap;
    }

    @media screen and (min-width: 900px) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    label {
        display: flex;
        padding-right: 16px;
        justify-content: flex-start;
        margin-bottom: 8px;
        @media screen and (min-width: 600px) {
            flex-basis: 40%;
            justify-content: flex-end;
        }
        @media screen and (min-width: 768px) {
            flex-basis: auto;
            justify-content: flex-start;
        }
        @media screen and (min-width: 900px) {
            flex-basis: 40%;
            justify-content: flex-end;
        }
        align-items: center;
        text-align: right;
    }
`

const Container = styled.div`
    display: flex;
    @media screen and (min-width: 900px) {
        flex-basis: 60%;
    }
    margin-top: 0;
`

const Input = styled.input`
    padding: 2px ${(props) => (props.type === "date" || props.type==="number" ? "8px" : "32px")} 2px 8px;
    margin: ${(props) => (props.asInput ? "-2px" : 0)} 8px 0 2px;
    height: 26px;
    background-color: ${(props) =>
        props.asInput ? Colors.gray[50] : "transparent"};
    box-shadow: ${(props) =>
        props.asInput
            ? "0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)"
            : "transparent"};
    border-radius: 8px;
    border: 1px solid
        ${(props) => (props.asInput ? Colors.gray[200] : "transparent")};
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    font-size: 16px;
    display: flex;
    cursor: pointer;
    color: ${(props) => props.theme.inputForeground};
    width: ${(props) => getFieldSize(props.type, props.size)};
    max-width: ${(props) =>
        props.hasHelpText ? "calc(100vw - 164px)" : "calc(100vw - 152px)"};
    @media screen and (min-width: 768px) {
        max-width: none;
    }

    &:focus {
        outline: none;
    }

    &.invalid:invalid {
        border: 1px solid ${Colors.red[600]};
        background-image: url(${danger});
        background-repeat: no-repeat;
        background-position: ${(props) =>
        props.type === "date" ? "calc(80% - 6px)" : "calc(100% - 6px)"}
            7px;
        background-size: 18px 15px;
    }
`

const IconContainer = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0 4px 0 0;
    margin-right: 8px;
`

const PopoverWrapper = styled.div`
    padding: 2px;
    margin: 0 4px 0 0;
    z-index: 1024;
    display: flex;
    align-items: center;
`

export default TextInputContract
