import * as React from "react"

function Danger({ fill = "#DC2626", width = 24 }) {
    const height = width * (21 / 24)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 15a1 1 0 100 2 1 1 0 000-2zm10.67 1.47l-8.05-14a3 3 0 00-5.24 0l-8 14A3 3 0 003.94 21h16.12a3 3 0 002.61-4.53zm-1.73 2a1 1 0 01-.88.51H3.94a1 1 0 01-.88-.51 1 1 0 010-1l8-14a1 1 0 011.78 0l8.05 14a1 1 0 01.05 1.02v-.02zM12 7a1 1 0 00-1 1v4a1 1 0 002 0V8a1 1 0 00-1-1z"
                fill={fill}
            />
        </svg>
    )
}

export default Danger
