import { useState } from "react"
import styled from "styled-components"
import Colors from "../assets/colors"
import Spinner from "../components/Spinner"
import Button from "../components/button"
import { P_Small, P_Small_M, H5 } from "../components/text"
import TextInput from "../components/textInput"
import { sendManualSigningLinkByEmail } from "../utils/api"

const SendSigningRequestNotification = ({
    shortcode,
    signatureType = "manual",
    inputPlaceholder = "Partens e-post-adresse",
    inputLabel = null,
    submitButtonText = "Send signeringslink på e-post",
    asModal = false,
    openModalButtonText = null
}) => {
    const [didSendRequest, setDidSendRequest] = useState(false)
    const [signerEmailAddress, setSignerEmailAddress] = useState(
        shortcode.signerInfo.email || ""
    )
    const [isLoading, setIsLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentOpenModalButtonText, setCurrentOpenModalButtonText] =
        useState(openModalButtonText)

    const handleSendManualSigningLinkByEmail = async () => {
        const token = localStorage.getItem("lawyered.user.token")
        try {
            setIsLoading(true)
            const response = await sendManualSigningLinkByEmail(
                token,
                shortcode.code,
                signerEmailAddress
            )
            if (response && response.success) {
                setDidSendRequest(true)
                setCurrentOpenModalButtonText("Sendt")
                setTimeout(() => {
                    setIsModalVisible(false)
                }, 2000)
            }
            setIsLoading(false)
        } catch (error) {
            console.error("SendSigningRequestNotification::error", error)
            setIsLoading(false)
        }
    }

    const renderContent = () => {
        return (
            <Wrapper>
                {inputLabel && <Label>{inputLabel}</Label>}
                <TextInput
                    placeholder={inputPlaceholder}
                    value={signerEmailAddress}
                    type="email"
                    onChange={(event) => {
                        setSignerEmailAddress(event.target.value)
                    }}
                />
                <Button
                    onClick={() => handleSendManualSigningLinkByEmail()}
                    small
                    primary={asModal}
                    secondary={!asModal}
                    disabled={
                        didSendRequest || isLoading || !signerEmailAddress
                    }
                    icon={didSendRequest ? "Checkmark" : null}
                    text={
                        didSendRequest ? (
                            "Sendt"
                        ) : isLoading ? (
                            <Spinner size="24" />
                        ) : (
                            submitButtonText
                        )
                    }
                />
            </Wrapper>
        )
    }

    const openButton = (
        <Button
            onClick={() => setIsModalVisible(true)}
            small
            secondary
            disabled={didSendRequest}
            text={currentOpenModalButtonText}
        />
    )

    if (asModal && !isModalVisible) {
        return <Wrapper>{openButton}</Wrapper>
    }

    if (asModal && isModalVisible) {
        return (
            <Wrapper>
                {openButton}
                <Modal>
                    <ModalInner>
                        <Title>Send signeringslink på e-post</Title>
                        {renderContent()}
                        <Button
                            secondary
                            small
                            text="Avbryt"
                            onClick={() => setIsModalVisible(false)}
                        />
                    </ModalInner>
                </Modal>
            </Wrapper>
        )
    }

    return renderContent()
}

const Modal = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.backgroundSecondary}99; /* rgba(255, 255, 255, 0.5); */
    display: flex;
    align-items: center;
    justify-content: center;
`

const ModalInner = styled.div`
    width: 300px;
    @media screen and (min-width: 768px) {
        width: 300px;
    }
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundSecondary};
    text-align: center;
`

const Label = styled(P_Small_M)`
    margin-bottom: 4px;
`

const Wrapper = styled.div`
    margin: 16px 0 0;
    input {
        font-size: 0.9rem;
        margin-bottom: 8px;
    }
    button {
        margin-bottom: 8px;
    }
`

export default SendSigningRequestNotification
