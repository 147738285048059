import React from "react"

const Cancel = ({ fill, width = 16 }) => {
    const height = width
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.94004 7.99988L13.14 3.80655C13.2656 3.68101 13.3361 3.51075 13.3361 3.33321C13.3361 3.15568 13.2656 2.98542 13.14 2.85988C13.0145 2.73434 12.8442 2.66382 12.6667 2.66382C12.4892 2.66382 12.3189 2.73434 12.1934 2.85988L8.00004 7.05988L3.80671 2.85988C3.68117 2.73434 3.51091 2.66382 3.33337 2.66382C3.15584 2.66382 2.98558 2.73434 2.86004 2.85988C2.7345 2.98542 2.66398 3.15568 2.66398 3.33321C2.66398 3.51075 2.7345 3.68101 2.86004 3.80655L7.06004 7.99988L2.86004 12.1932C2.79756 12.2552 2.74796 12.3289 2.71411 12.4102C2.68027 12.4914 2.66284 12.5785 2.66284 12.6665C2.66284 12.7546 2.68027 12.8417 2.71411 12.9229C2.74796 13.0042 2.79756 13.0779 2.86004 13.1399C2.92202 13.2024 2.99575 13.252 3.07699 13.2858C3.15823 13.3197 3.24537 13.3371 3.33337 13.3371C3.42138 13.3371 3.50852 13.3197 3.58976 13.2858C3.671 13.252 3.74473 13.2024 3.80671 13.1399L8.00004 8.93988L12.1934 13.1399C12.2554 13.2024 12.3291 13.252 12.4103 13.2858C12.4916 13.3197 12.5787 13.3371 12.6667 13.3371C12.7547 13.3371 12.8419 13.3197 12.9231 13.2858C13.0043 13.252 13.0781 13.2024 13.14 13.1399C13.2025 13.0779 13.2521 13.0042 13.286 12.9229C13.3198 12.8417 13.3372 12.7546 13.3372 12.6665C13.3372 12.5785 13.3198 12.4914 13.286 12.4102C13.2521 12.3289 13.2025 12.2552 13.14 12.1932L8.94004 7.99988Z"
                fill={fill || "#9CA3AF"}
            />
        </svg>
    )
}

export default Cancel
