import React, { useRef } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"

const SearchMenu = () => {
    const inputRef = useRef()
    const onClick = () => inputRef.current.focus()

    return (
        <Container onClick={onClick}>
            <Input
                ref={inputRef}
                type={"text"}
            />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    cursor: pointer;
    color: ${Colors.gray[500]};
    background-color: ${Colors.white};
    border: 1px solid ${Colors.gray[300]};
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
`

const Input = styled.input`
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`

export default SearchMenu
