import React from "react"
import styled, { useTheme } from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import { P_Medium, P_Small_M } from "../text"

const Button = ({
    icon,
    iconRight,
    onClick = () => { },
    secondary,
    small,
    text,
    type = "button",
    disabled = false,
    block = true,
    id,
    allowMultiLine = false,
    arrowDirection,
    className
}) => {
    const theme = useTheme()
    var optionalProps = {}
    if (id) {
        optionalProps.id = id
    }
    if (className) {
        optionalProps.className = className
    }
    let iconProps = {}
    if (arrowDirection) {
        iconProps.direction = arrowDirection
    }

    if (text === undefined && icon) {
        return (
            <Circle
                disabled={disabled}
                onClick={onClick}
                secondary={secondary}
                small={small}
                {...optionalProps}
            >
                <Icon
                    icon={icon}
                    fill={secondary ? theme.foregroundSecondary : Colors.white}
                    {...iconProps}
                />
            </Circle>
        )
    } else {
        return (
            <Container
                disabled={disabled}
                onClick={onClick}
                iconRight={iconRight}
                secondary={secondary}
                type={type}
                block={block}
                allowMultiLine={allowMultiLine}
                {...optionalProps}
            >
                {icon && !iconRight && (
                    <Icon
                        icon={icon}
                        fill={secondary ? theme.foregroundSecondary : Colors.white}
                        {...iconProps}
                    />
                )}
                {!small && <Title secondary={secondary}>{text}</Title>}
                {small && <SmallTitle secondary={secondary}>{text}</SmallTitle>}
                {icon && iconRight && (
                    <Icon
                        icon={icon}
                        fill={secondary ? theme.foregroundSecondary : Colors.white}
                        {...iconProps}
                    />
                )}
            </Container>
        )
    }
}

const Container = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 42px;
    width: ${(props) => (props.block ? "100%" : "auto")};
    padding: 12px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    background-color: ${(props) => props.secondary ? props.theme.backgroundSecondary : props.theme.inputForeground};
    border-radius: 8px;
    border: none;
    white-space: ${(props) => (props.allowMultiLine ? "normal" : "nowrap")};

    svg {
        margin: ${(props) => (props.iconRight ? "0 0 0 12px" : "0 12px 0 0")};
    }

    &:hover {
        background-color: ${(props) => props.secondary ? props.theme.backgroundSecondaryHover : props.theme.inputForegroundHover};
    }
`

const Circle = styled(Container)`
    height: ${(props) => (props.small ? "34px" : "42px")};
    width: ${(props) => (props.small ? "34px" : "42px")};
    border-radius: 100px;

    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};

    svg {
        margin: 0;
    }
`

const Title = styled(P_Medium)`
    color: ${(props) =>
        props.secondary ? props.theme.foregroundSecondary : Colors.white} !important;
    font-family: "Inter";
`

const SmallTitle = styled(P_Small_M)`
    color: ${(props) =>
        props.secondary ? props.theme.foregroundSecondary : Colors.white} !important;
    font-family: "Inter";
`

export default Button
