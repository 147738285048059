import { useEffect, useContext, useState } from "react"
import styled from "styled-components"

import { useLocation } from "react-router-dom"
import { useIntercom } from "react-use-intercom"
import { FirebaseAuth } from "../providers/AuthProvider"
import { getAuthUserData } from "../utils/api"

const AppContainer = ({ children }) => {
    const { currentUser, token } = useContext(FirebaseAuth)
    const location = useLocation()
    const { boot, update } = useIntercom()

    const getIntercomOptions = () => {
        const intercomOptions = {
            //customLauncherSelector: "#bootIntercom",
            //hideDefaultLauncher: true
            verticalPadding: 20
        }
        const pathTest = new RegExp("/(?:upload|create).*")
        if (pathTest.test(location.pathname)) {
            intercomOptions.verticalPadding = 120
        }
        return intercomOptions
    }

    const [user, setUser] = useState({})
    const [didUserDataFetch, setDidUserDataFetch] = useState(false)

    async function getUser() {
        const userData = await getAuthUserData(token)

        const dbData = userData?.userData?.dbData
        const user = {
            name: dbData?.name || null,
            email: dbData?.email || currentUser.email || null,
            phoneNumber: dbData?.phoneNumber || currentUser.phoneNumber || null
        }
        setUser(user)
        setDidUserDataFetch(true)
        return user
    }

    async function bootWithUser() {
        const fetchedUser = await getUser()

        boot({
            name: fetchedUser ? fetchedUser.name : "Ukjent",
            email: fetchedUser ? fetchedUser.email : null,
            ...getIntercomOptions()
        })
    }

    async function updateWithUser() {
        const fetchedUser = await getUser()

        update({
            name: fetchedUser ? fetchedUser.name : "Ukjent",
            email: fetchedUser ? fetchedUser.email : null,
            ...getIntercomOptions()
        })
    }

    useEffect(() => {
        if (!currentUser.isAnonymous && !didUserDataFetch) {
            bootWithUser()
        } else if (!currentUser.isAnonymous && didUserDataFetch) {
            boot({
                name: user ? user.name : "Ukjent",
                email: user ? user.email : null,
                ...getIntercomOptions()
            })
        } else {
            boot(getIntercomOptions())
        }
    }, [])

    useEffect(() => {
        if (!currentUser.isAnonymous && !didUserDataFetch) {
            updateWithUser()
        } else if (!currentUser.isAnonymous && didUserDataFetch) {
            update({
                name: user ? user.name : "Ukjent",
                email: user ? user.email : null,
                ...getIntercomOptions()
            })
        } else {
            update(getIntercomOptions())
        }
    }, [location])

    return <Container>{children}</Container>
}

const Container = styled.div`
    display: flex;
    background: ${(props) => props.theme.backgroundPrimary};
    min-height: 100vh;
    align-items: flex-start;
`

export default AppContainer
