import { createContext, useReducer, useContext } from "react"

const FirstSignerContext = createContext()

function firstSignerReducer(state, action) {
    switch (action.type) {
        case "setIndex": {
            return { ...state, firstSignerIndex: action.firstSignerIndex }
        }
        case "unsetIndex": {
            if (state.firstSignerIndex === action.firstSignerIndex) {
                return { ...state, firstSignerIndex: null }
            }
            return
        }
        case "setUuid": {
            return { ...state, firstSignerUuid: action.firstSignerUuid }
        }
        case "unsetUuid": {
            return { ...state, firstSignerUuid: null }
        }
        case "setUrl": {
            return { ...state, firstSignerUrl: action.firstSignerUrl }
        }
        case "unsetUrl": {
            return { ...state, firstSignerUrl: null }
        }
        case "clear": {
            return {
                firstSignerIndex: null,
                firstSignerUuid: null,
                firstSignerUrl: null
            }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function FirstSignerProvider({ children }) {
    const [state, dispatch] = useReducer(firstSignerReducer, {
        firstSignerIndex: null,
        firstSignerUuid: null,
        firstSignerUrl: null
    })
    const value = { state, dispatch }
    return (
        <FirstSignerContext.Provider value={value}>
            {children}
        </FirstSignerContext.Provider>
    )
}

function useFirstSigner() {
    const context = useContext(FirstSignerContext)
    if (context === undefined) {
        throw new Error(
            "useFirstSigner must be used within a FirstSignerProvider"
        )
    }
    return context
}

export { FirstSignerProvider, useFirstSigner }
