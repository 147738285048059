import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import "./index.css"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

import AuthProvider from "./providers/AuthProvider"
import { IntercomProvider } from "react-use-intercom"

import TagManager from "react-gtm-module"

// bootstrap@5.3.0-alpha3
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const INTERCOM_APP_ID = "v5r5gj8c"

Sentry.init({
    dsn: "https://40e1305e0a8e4df389c84f2411ead320@o889770.ingest.sentry.io/5838893",
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: [
                "localhost",
                "app.lawyered.no",
                "stage.app.lawyered.no",
                /^\//
            ]
        })
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.8,
    release: "lawyered-webapp@1.0.0"
})

TagManager.initialize({
    gtmId: "GTM-WC82MBB"
})

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <IntercomProvider appId={INTERCOM_APP_ID}>
                <Router>
                    <App />
                </Router>
            </IntercomProvider>
        </AuthProvider>
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
