import React from "react"

const Search = ({ fill }) => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.0917 15.9083L14 12.8417C15.2001 11.3453 15.7813 9.44608 15.6241 7.53442C15.4668 5.62275 14.5831 3.84399 13.1546 2.56387C11.7262 1.28376 9.86155 0.599592 7.94414 0.652059C6.02674 0.704526 4.20231 1.48964 2.84599 2.84595C1.48968 4.20227 0.704568 6.0267 0.652101 7.9441C0.599634 9.86151 1.2838 11.7261 2.56391 13.1546C3.84403 14.5831 5.62279 15.4668 7.53446 15.624C9.44612 15.7813 11.3454 15.2001 12.8417 14L15.9084 17.0667C15.9858 17.1448 16.078 17.2068 16.1796 17.2491C16.2811 17.2914 16.39 17.3132 16.5 17.3132C16.6101 17.3132 16.719 17.2914 16.8205 17.2491C16.9221 17.2068 17.0142 17.1448 17.0917 17.0667C17.2419 16.9113 17.3259 16.7036 17.3259 16.4875C17.3259 16.2714 17.2419 16.0637 17.0917 15.9083ZM8.16671 14C7.01298 14 5.88517 13.6579 4.92588 13.0169C3.9666 12.3759 3.21892 11.4649 2.77741 10.399C2.3359 9.33308 2.22038 8.1602 2.44546 7.02864C2.67054 5.89708 3.22611 4.85768 4.04192 4.04188C4.85773 3.22607 5.89713 2.6705 7.02868 2.44542C8.16024 2.22034 9.33313 2.33586 10.399 2.77737C11.4649 3.21888 12.376 3.96655 13.0169 4.92584C13.6579 5.88513 14 7.01294 14 8.16667C14 9.71376 13.3855 11.1975 12.2915 12.2915C11.1975 13.3854 9.71381 14 8.16671 14Z"
                fill="#9CA3AF"
            />
        </svg>
    )
}

export default Search
