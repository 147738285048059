import * as React from "react"
import { useTheme } from "styled-components"

function Info({ fill, size = 20 }) {
    const theme = useTheme()
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 15c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-5c0 .6-.4 1-1 1s-1-.4-1-1V6c0-.6.4-1 1-1s1 .4 1 1v4z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default Info
