import React from "react"

const Ball = ({ fill }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0.333374C5.88516 0.334514 4.7884 0.615217 3.81008 1.1498C2.83177 1.68438 2.00313 2.45576 1.4 3.39337C0.551713 4.72374 0.202766 6.31186 0.415219 7.8753C0.627671 9.43874 1.38775 10.8762 2.56029 11.9319C3.73282 12.9877 5.24181 13.5934 6.81889 13.6412C8.39597 13.6891 9.93892 13.1761 11.1733 12.1934C12.2477 11.3286 13.0274 10.1519 13.4051 8.82548C13.7827 7.49904 13.7397 6.08812 13.2819 4.78715C12.8241 3.48619 11.9741 2.35923 10.849 1.56156C9.72393 0.763891 8.37916 0.334785 7 0.333374ZM8.33334 1.84004C9.25456 2.0785 10.0952 2.55909 10.7681 3.23197C11.441 3.90484 11.9215 4.74548 12.16 5.66671C11.0453 5.6981 9.95733 6.01486 9 6.58671C8.51928 6.04136 7.98306 5.54759 7.4 5.11337C7.98743 4.12068 8.30889 2.99326 8.33334 1.84004ZM7 1.66671C6.99781 2.62339 6.73507 3.56141 6.24 4.38004C6.14 4.32671 6.04667 4.26671 5.94667 4.22004C5.08825 3.7671 4.17036 3.4372 3.22 3.24004C3.71537 2.74172 4.30432 2.3462 4.95301 2.07619C5.60171 1.80619 6.29736 1.66703 7 1.66671ZM2.33334 4.44004C3.37895 4.58529 4.39161 4.91007 5.32667 5.40004L5.41334 5.45337C4.92128 5.94497 4.33705 6.33464 3.69412 6.60005C3.0512 6.86545 2.36222 7.00138 1.66667 7.00004C1.67038 6.10426 1.89965 5.22385 2.33334 4.44004ZM5.66667 12.16C4.74545 11.9216 3.9048 11.441 3.23193 10.7681C2.55906 10.0952 2.07846 9.2546 1.84 8.33337C2.73044 8.3136 3.60766 8.11381 4.41884 7.74603C5.23001 7.37825 5.95836 6.8501 6.56 6.19337C7.04194 6.54964 7.48845 6.95149 7.89334 7.39337C7.21825 7.9953 6.67271 8.7283 6.28991 9.54777C5.90711 10.3672 5.69506 11.256 5.66667 12.16ZM7 12.3334C7.00144 11.601 7.15372 10.8767 7.44735 10.2057C7.74097 9.53473 8.16965 8.93142 8.70667 8.43337C8.74667 8.48671 8.78667 8.53337 8.82 8.58671C9.37867 9.44219 9.79307 10.3836 10.0467 11.3734C9.1544 11.9999 8.09025 12.3352 7 12.3334ZM11.14 10.3334C10.8505 9.45137 10.4471 8.61094 9.94 7.83337L9.8 7.66671C10.5751 7.23548 11.4463 7.00622 12.3333 7.00004C12.3267 8.21462 11.9057 9.39056 11.14 10.3334Z" fill="#374151" />
        </svg>
    )
}

export default Ball
