import styled, { useTheme } from "styled-components"
import Colors from "../../assets/colors"
import Icon from "../icon"
import { P_Small_M } from "../text"

const MenuItem = ({
    count,
    icon,
    iconRight,
    iconWidth,
    iconHeight,
    onClick = () => { },
    selected,
    text
}) => {
    const theme = useTheme()
    return (
        <Container onClick={onClick} iconRight={iconRight} selected={selected}>
            <div>
                {icon && (
                    <Icon
                        icon={icon}
                        fill={theme.foregroundPrimary}
                        width={iconWidth}
                        height={iconHeight}
                    />
                )}
                <Title>{text}</Title>
            </div>
            {count && <Count>{count}</Count>}
        </Container>
    )
}

const Container = styled.button`
    width: 44px;
    height: 44px;
    justify-content: center;
    @media screen and (min-width: 768px) {
        width: 162px;
        height: auto;
        justify-content: space-between;
    }
    @media screen and (min-width: 1024px) {
        width: 256px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 8px;
    cursor: pointer;
    background-color: ${(props) => props.selected ? props.theme.inputForegroundSecondary : Colors.white};
    border: none;
    transition: ease-in-out 0.4s;

    div {
        display: flex;
        align-items: center;
    }

    svg {
        margin: ${(props) => (props.iconRight ? "0 0 0 12px" : "0 0 0 0")};
    }

    @media screen and (min-width: 768px) {
        svg {
            margin: ${(props) =>
        props.iconRight ? "0 0 0 12px" : "0 12px 0 0"};
        }
    }

    &:hover {
        background-color: ${(props) => props.selected ? props.theme.inputForegroundSecondary : props.theme.backgroundSecondary};
    }
`

const Count = styled.div`
    padding: 2px 12px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: ${(props) => props.theme.foregroundPrimary};
    background-color: ${(props) => props.theme.backgroundPrimary};
`

const Title = styled(P_Small_M)`
    color: ${(props) => props.theme.foregroundPrimary};
    display: none;
    @media screen and (min-width: 768px) {
        display: block;
    }
`

export default MenuItem
