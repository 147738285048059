import React, { useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { H5, P, P_Medium } from "../../components/text"

import A from "../../components/a"
import List from "../../components/list"
import SearchResults from "../../components/searchResults"
import Step from "../../components/step"
import TextInput from "../../components/textInput"
import { search } from "../../utils/api-brreg"

const FindCompany = ({
    onSubmit = (item) => { },
    onStepPressed = (stepIndex) => { }
}) => {
    const [result, setResult] = useState([])

    const onChange = async (event) => {
        const value = event.target.value

        if (value.length > 3) {
            const results = await search(value)
            if (results) {
                const cleanedUnits = results.map((unit) => {
                    return {
                        title: unit.navn,
                        subtitle:
                            (unit.forretningsadresse?.postnummer || "") +
                            " " +
                            (unit.forretningsadresse?.poststed || ""),
                        orgNo: unit.organisasjonsnummer
                    }
                })
                setResult(cleanedUnits)
            } else {
                console.log(`not`)
                setResult([])
            }
        }
    }

    const renderResult = () => {
        return (
            <List>
                {result.map((item) => (
                    <SearchResults
                        key={`unit-${item.orgNo}`}
                        {...item}
                        onClick={() => onSubmit(item)}
                    />
                ))}
            </List>
        )
    }

    return (
        <Container>
            <UpperContainer>
                <InnerContainer>
                    <Title>{"Finn bedriften din"}</Title>
                    <Message>
                        {"Søk etter bedriftsnavn eller organisasjonsnummer"}
                    </Message>
                    <TextInput
                        icon={"Search"}
                        onChange={onChange}
                        placeholder={"Søk"}
                    />
                    <ResultContainer>
                        {result.length > 0 && renderResult()}
                    </ResultContainer>
                    <LinkMessage>
                        <A href={""}>{"Jeg finner ikke bedriften min"}</A>
                    </LinkMessage>
                </InnerContainer>
            </UpperContainer>
            <BottomContainer>
                <Step
                    stepPrefix="STEG"
                    count={1}
                    description={"Velg konto"}
                    filled={true}
                    onClick={() => onStepPressed(1)}
                />
                <Step
                    stepPrefix="STEG"
                    count={2}
                    description={"Finn bedriften din"}
                    filled={true}
                    onClick={() => onStepPressed(2)}
                />
                <Step
                    stepPrefix="STEG"
                    count={3}
                    description={"Bekreft opplysningene"}
                    filled={false}
                    onClick={() => onStepPressed(3)}
                />
            </BottomContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

const UpperContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const BottomContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 36px 0;
    background-color: ${Colors.white};

    > * {
        width: calc(33% - 16px);
        margin: 0 8px;
    }

    @media screen and (min-width: 768px) {
        > * {
            width: 168px;
            margin: 0 16px;
        }
    }
`

const InnerContainer = styled.div`
    position: relative;
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin: 12px;

    > *:nth-child(3) {
        width: 100%;
    }

    > *:nth-child(3) > *:not(:last-child) {
        margin-bottom: 4px;
    }

    > *:not(:last-child) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 768px) {
        margin: 0;
        > *:nth-child(3) {
            width: 400px;
        }
    }
`

const ResultContainer = styled.div`
    position: absolute;
    left: 24px;
    top: 190px;
    width: calc(100% - 48px);
    border: 1px solid ${Colors.gray[300]};
    border-radius: 8px;

    > :first-child > *:first-child {
        border: 1px solid transparent;
    }

    > :first-child > * {
        border-top: 1px solid ${Colors.gray[300]};
        border-bottom: 1px solid ${Colors.gray[300]};
    }

    > :first-child > *:last-child {
        border: 1px solid transparent;
    }

    @media screen and (min-width: 768px) {
        left: 24px;
        top: 160px;
        width: calc(100% - 48px);
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-align: center;
`

const Message = styled(P)`
    color: ${Colors.gray[500]};
    text-align: center;
`

const LinkMessage = styled(P_Medium)`
    text-align: center;
`

export default FindCompany
