import React, { createRef, useState } from "react"
import styled from "styled-components"

import { Popover } from "react-tiny-popover"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import nb from "date-fns/locale/nb"
registerLocale("nb", nb)

import Colors from "../../assets/colors"
import Icon from "../icon"
import danger from "../../assets/icons/svg/Danger.svg"
import Notice from "../notice"
import { AttachedButton } from "../button"

const DateInputContract = ({
    icon,
    onChange = () => { },
    placeholder,
    helpText,
    name,
    id,
    label,
    prefix,
    suffix,
    allowPastDate = true,
    allowFutureDate = true,
    shouldDisplayValidationErrors,
    value = undefined,
    required = true
}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [startDate, setStartDate] = useState(value ? new Date(value) : null)

    const calendarRef = createRef()

    const handleDateChange = (inputDate) => {
        setStartDate(inputDate)

        if (!inputDate) {
            return
        }
        const year = inputDate.getFullYear()
        let month = inputDate.getMonth() + 1
        if (month < 10) {
            month = "0" + month
        }
        const date = inputDate.getDate()
        const formattedDate = `${year}-${month}-${date}`

        onChange(formattedDate)
    }

    return (
        <Wrapper hasLabel={!!label}>
            {label && <label htmlFor={id || name}>{label}</label>}
            {prefix && <span className="fieldPrefix">{prefix}</span>}
            <Container>
                {icon && <Icon icon={icon} fill={Colors.gray[400]} />}
                <InputWithAttachedButton>
                    <DatePicker
                        calendarStartDay={1}
                        dateFormatCalendar="MMMM"
                        dropdownMode="select"
                        showYearDropdown
                        locale="nb"
                        required={required}
                        name={name}
                        id={id || name}
                        selected={startDate}
                        placeholderText={placeholder}
                        dateFormat="dd.MM.yyyy"
                        onChange={(date) => handleDateChange(date)}
                        className={
                            shouldDisplayValidationErrors ? "invalid" : ""
                        }
                        minDate={allowPastDate ? null : new Date()}
                        maxDate={allowFutureDate ? null : new Date()}
                        todayButton={"I dag"}
                        preventOpenOnFocus={true}
                        ref={calendarRef}
                    />
                    <button
                        className="click_mask"
                        onClick={(e) => {
                            e.preventDefault()
                            calendarRef.current.setOpen(true)
                        }}
                    ></button>
                    <AttachedButton
                        icon="Calendar"
                        width="34"
                        height="31"
                        className="attached_button"
                        onClick={(e) => {
                            e.preventDefault()
                            calendarRef.current.setOpen(true)
                        }}
                    />
                </InputWithAttachedButton>

                {helpText && (
                    <Popover
                        isOpen={isPopoverOpen}
                        positions={["top", "bottom", "right", "left"]} // preferred positions by priority
                        content={<Notice info text={helpText} />}
                        containerStyle={{ zIndex: 2048 }}
                        onClickOutside={() => setIsPopoverOpen(false)}
                        containerClassName="popoverOuter"
                    >
                        <PopoverWrapper
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <Icon icon="Info" />
                        </PopoverWrapper>
                    </Popover>
                )}
            </Container>
            {suffix && <span className="fieldSuffix">{suffix}</span>}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex: ${(props) => (props.hasLabel ? "1 0 100%" : "0")};
    flex-direction: ${(props) =>
        props.hasLabel ? "column" : "row"}; /* example: 'samarbeidsavtale' */
    align-items: baseline;
    margin-bottom: 16px;
    margin-right: 4px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
    label {
        display: flex;
        margin-bottom: 8px;
        @media screen and (min-width: 768px) {
            flex-basis: 40%;
            justify-content: flex-end;
        }
        padding-right: 16px;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
    }
    input {
        padding: 2px 8px 2px 8px;
        margin: -2px 8px 0 2px;
        height: 26px;
        background-color: ${Colors.gray[50]};
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        border: 1px solid ${Colors.gray[200]};
        opacity: ${(props) => (props.disabled ? 0.4 : 1)};
        font-size: 16px;
        display: flex;
        cursor: pointer;
        color: ${(props) => props.theme.inputForeground};
        width: 7em;

        &:focus {
            outline: none;
        }
        &.invalid:invalid {
            border: 1px solid ${Colors.red[600]};
            background-image: url(${danger});
            background-repeat: no-repeat;
            background-position: calc(100% - 6px) 7px;
            background-size: 18px 15px;
        }
    }
`

const InputWithAttachedButton = styled.div`
    display: flex;
    margin-right: 8px;
    position: relative;

    input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    button.attached_button {
        margin-left: -11px;
        margin-top: -2px;
    }
    button.click_mask {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
`

const Container = styled.div`
    display: flex;
    @media screen and (min-width: 768px) {
        flex-basis: 60%;
    }

    /* react-datepicker.css overrides: */
    .react-datepicker-popper {
        z-index: 3192;
    }
    .react-datepicker {
        font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
            "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
        border-radius: 8px;
        box-shadow: 0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%);
    }
    .react-datepicker__header {
        background-color: ${Colors.gray[100]};
    }
    .react-datepicker__year-select {
        display: flex;
        flex-basis: 60%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 2px 20px 2px 4px;
        margin: 0 8px 0 2px;
        height: 30px;
        font-size: 16px;
        cursor: pointer;
        color: ${(props) => props.theme.inputForeground};
        background-color: ${Colors.gray[50]};
        border: 1px solid ${Colors.gray[300]};
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
    }
    .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
        background-color: ${(props) => props.theme.inputForeground};
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        margin-bottom: 8px;
        font-weight: 500;
    }
    .react-datepicker__today-button {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        border-radius: 8px;
        margin: 14px auto;
        border: none;
        height: 28px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 0;
        display: flex;
        width: 80px;
        background-color: ${(props) => props.theme.backgroundSecondary};
        color: ${(props) => props.theme.foregroundSecondary};
    }
    .react-datepicker__navigation-icon::before,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow,
    .react-datepicker__month-year-read-view--down-arrow {
        border-color: ${(props) => props.theme.foregroundSecondary};
    }
`

const PopoverWrapper = styled.div`
    padding: 2px;
    margin: 0 4px 0 0;
    z-index: 1024;
    display: flex;
    align-items: center;
`

export default DateInputContract
