import React, { useEffect, useState } from 'react'
import InputGroup from '../inputGroup';
import { toast } from 'react-toastify';
import { addOrderForBulkContractUsingPromocode, applyPromoCode } from '../../utils/api';
import Button from '../button';
import styled, { useTheme } from "styled-components"
import SpaceBetweenBox from "../box/SpaceBetweenBox";
import owlImg from "../../assets/images/hånd_over_mobil.svg"
import {
    H5,
    H6,
    P,
    P_Large_M,
    H2,
    P_Small_M,
    P_Mini,
    H6_Medium,
    H4,
    P_Mini_M
} from "../text"
import TagIcon from "../../assets/icons/TagIcon"

export default function BulkContractPromocodeModal(props) {
    const token = localStorage.getItem("lawyered.user.token")
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponValue, setCouponValue] = useState("");
    const [discountPrice, setDiscountPrice] = useState(0);
    const [couponId, setCouponId] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    const handleCouponApply = async () => {
        setShowLoader(true)
        const token = localStorage.getItem("lawyered.user.token")
        let response = await applyPromoCode(couponValue, props?.packagePrice?.id, token);
        if (response.status === 200) {
            toast.success("Rabatten er lagt inn!", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000 //3 seconds
            });
            setDiscountPrice(props?.packagePrice?.data?.contract_price * (parseInt(response.percentage) / 100))
            setCouponApplied(couponValue);
            setCouponValue('');
            setCouponId(response.couponId);
            localStorage.setItem('lawyered.bulk.contract.coupon', response.couponId);
            // props.setAppliedCouponId(response.couponId);
            // setTemplatePrice(templatePrice - (templatePrice * (parseInt(response.percentage) / 100)))
        } else {
            toast.error(response?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000 //3 seconds
            });
        }
        setShowLoader(false)
    }




    useEffect(() => {
        if (props.isOpen === true) {
            document.getElementById("openBulkContractPromocodeModal").click();
        }
    }, [props])

    const toggleModal = () => {
        setCouponApplied(false);
        setCouponValue('');
        setDiscountPrice(0);
        setCouponId('')
        props.setIsOpen(false);
    }

    const redeemCodeHandler = () => {
        addOrderForBulkContractUsingPromocode(props.bulkContractId, couponId, token)
            .then((response) => {
                console.log(`addOrderForBulkContractUsingPromocode :: response`, response)

            })
            .catch((error) => {
                console.error("addOrderForBulkContractUsingPromocode :: error", error)
            })

        toast.success("Kontraktene ligger i Arkiv", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 30000 //30 seconds
        });

        document.getElementById("closeBulkContractPromocodeModal").click();
    }

    const betalingHandlerButton = () => {
        if ((parseInt(props?.packagePrice?.data?.contract_price) - parseInt((couponApplied ? parseInt(discountPrice) : 0))) > 0) {
            console.log('if')
            props.setFinalPriceAfterDiscount(parseInt(props?.packagePrice?.data?.contract_price - parseInt(discountPrice)))
            props.buyContractsButtonHandler()
        } else {
            props.setFinalPriceAfterDiscount(props?.packagePrice?.data?.contract_price)
            redeemCodeHandler();
            console.log('else');
        }
        console.error('get here proces package value', props?.packagePrice?.data?.contract_price)
        console.error('get here the discount', parseInt((couponApplied ? parseInt(discountPrice): 0)))
    }

    return (

        <>
            <button type="button" class="btn btn-primary d-none" id="openBulkContractPromocodeModal" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>

            <div class="modal fade" id="exampleModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content rounded-0 pb-2">
                        <div class="modal-header border-0">
                            {/* <h1 class="modal-title fs-5" id="exampleModalLabel">Rabattkode</h1> */}
                            <button type="button" class="btn-close" id="closeBulkContractPromocodeModal" data-bs-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}></button>
                        </div>
                        <div class="modal-body">
                            {/* <InputGroup title="Skriv inn rabattkoden" value={redeemCode} maxlength={10} onChange={(e) => setRedeemCode(e.target.value)} /> */}
                            <CouponWrapper>
                                <CouponBox>
                                    <H5>Rabattkode</H5>
                                    <P style={{ fontWeight: '500' }}>
                                        Har du en rabattkode?
                                    </P>
                                    <>
                                        <CouponFormWrapper>
                                            <SpaceBetweenBox>
                                                <InputGroup value={couponValue} onChange={(e) => setCouponValue(e.target.value.toUpperCase())} placeholder="Rabattkode" />
                                            </SpaceBetweenBox>
                                            <SpaceBetweenBox style={{ width: '60%', marginLeft: '8px' }}>
                                                <Button
                                                    text="Bruk kode"
                                                    onClick={() => handleCouponApply()}
                                                    secondary
                                                    small
                                                    disabled={!couponValue || discountPrice || showLoader}
                                                />
                                            </SpaceBetweenBox>
                                            <StyledImage src={owlImg} alt="Owl Image" />

                                        </CouponFormWrapper>
                                        {couponApplied &&
                                            <><SpaceBetweenBox marginTop='8px'>
                                                <P style={{ fontWeight: '500' }}>
                                                    Aktivert:
                                                </P>
                                            </SpaceBetweenBox>
                                                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                    <AppliedCouponWrapper>
                                                        <span style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}>
                                                            <TagIcon width="10%" />
                                                            <P_Mini_M style={{ marginLeft: '6px' }}>{couponApplied.length > 12 ? `${couponApplied.substring(0, 12)}...` : couponApplied}</P_Mini_M>
                                                        </span>
                                                    </AppliedCouponWrapper>
                                                    <P onClick={() => {
                                                        setCouponValue('');
                                                        setCouponApplied(false);
                                                        setDiscountPrice(0);
                                                        toast.success("Rabatten er tatt bort.", {
                                                            position: toast.POSITION.TOP_RIGHT,
                                                            autoClose: 3000 //3 seconds
                                                        })
                                                    }}
                                                        style={{ cursor: 'pointer', marginLeft: '10px', color: '#5B4379', textDecoration: 'underline' }}>
                                                        Fjern kode
                                                    </P>
                                                </div>
                                            </>
                                        }
                                    </>

                                </CouponBox>
                                <hr style={{ border: '1px solid #D9D9D9' }} />
                                <CouponBox>
                                    <H5>Oppsummering</H5>
                                    <SpaceBetweenBox marginTop='8px'>
                                        <P style={{ fontWeight: '500' }}>
                                            Kontraktspakker
                                        </P>
                                        {console.log(props?.packagePrice.data)}
                                        {props?.packagePrice?.data?.contract_price
                                            > 0 ? <P style={{ fontWeight: '500' }}>{`+${props?.packagePrice?.data?.contract_price
                                                } kr.`}</P> : <P style={{ fontWeight: '500' }}>+0 kr.</P>}
                                    </SpaceBetweenBox>
                                    {couponApplied && <>
                                        <hr style={{ border: '1px solid #D9D9D9' }} />
                                        <SpaceBetweenBox style={{ margin: 0 }}>
                                            <span style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}>
                                                <P_Mini_M style={{ fontWeight: '400', }}>
                                                    Subtotal
                                                </P_Mini_M>
                                            </span>
                                            <P_Mini_M style={{ fontWeight: '500' }}>
                                                {props?.packagePrice?.data?.contract_price} kr.
                                            </P_Mini_M>
                                        </SpaceBetweenBox>
                                        <SpaceBetweenBox marginTop='10px'>
                                            <span style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}>

                                                <TagIcon width="20px" />
                                                <P_Small_M style={{ fontWeight: '500', marginLeft: '10px' }}>
                                                    {couponApplied}
                                                </P_Small_M>
                                            </span>
                                            <span>
                                                <P_Small_M style={{ fontWeight: '500' }}>
                                                    -{`${discountPrice} kr.`}
                                                </P_Small_M>
                                            </span>
                                        </SpaceBetweenBox>
                                    </>
                                    }
                                    <hr style={{ border: '1px solid #D9D9D9' }} />
                                    <SpaceBetweenBox marginTop='3rem'>
                                        <H4>
                                            Totalt å betale
                                        </H4>
                                        <H4 style={{ display: 'flex', justifyContent: 'end' }}>
                                            {parseInt(props?.packagePrice?.data?.contract_price) > 0 ? parseInt(props?.packagePrice?.data?.contract_price) - parseInt(couponApplied ? parseInt(discountPrice) ?? 0 : 0) : 0}
                                            {/* {signatureType ===
                                                    "electronic"
                                                    ? (signers?.length * PRICE_PER_SIGNING.no) + parseInt(templatePrice ?? 0)
                                                    : (signers?.length * PRICE_PER_SIGNING.manual) + parseInt(templatePrice ?? 0) - parseInt(couponApplied ? parseInt(discountPrice) ?? 0 : 0)}{' '} */}
                                            {' '}  kr.
                                        </H4>
                                    </SpaceBetweenBox>
                                    <SpaceBetweenBox style={{ width: " min-content", position: "absolute", bottom: "8px", right: "8px" }}>
                                        <Button
                                            text="Gå til betaling"
                                            onClick={() => {
                                                betalingHandlerButton()
                                                toggleModal()
                                            }}
                                            primary
                                        />
                                    </SpaceBetweenBox>

                                </CouponBox>

                            </CouponWrapper>
                        </div>
                        {/* <div class="modal-footer">
                            <div>
                                <Button
                                    text="Bruk"
                                    onClick={() => redeemCodeHandler()}
                                    primary
                                    disabled={redeemCode ? false : true}
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>

    )
}

const CouponWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 0;
  box-shadow: none;
  @media (max-width: 1024px) {
    flex-direction: column;
    overflow: auto;
  }
`;

const CouponBox = styled.div`
  width: 100%;
  margin: 0;
  height: 24rem;
  box-shadow: none;
  padding: 0 20px;
  position: relative;
`;

const CouponFormWrapper = styled.div`
  margin-right: 170px;
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppliedCouponWrapper = styled.div`
  width: 44%;
  padding: 12px 20px 12px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  color: #5B4379;
  margin-top: 4px;
  background: #DCB9FF;
`;

const StyledImage = styled.img`
  width: 175px;
  margin-left: 60px;
  margin-top: 70px;
  position: absolute;
  top: 75px;
  display: block;
  
  @media (max-width: 1024px) {
      display: none;
  }
`;