import { useState } from "react"
import styled, { useTheme } from "styled-components"
import Icon from "../components/icon"
import { P_Large_M, P_Small } from "../components/text"

import Colors from "../assets/colors"
import List from "../components/list"
import RadioButtonLabelDesc from "../components/radioButtonLabelDescription"
import { PRICE_PER_SIGNING } from "../utils/constants"

const InfoBoxSignatureMethodSelection = ({ onSelectChange = () => {} }) => {
    const theme = useTheme()
    const [selected, setSelected] = useState("")
    const onChange = (type) => {
        setSelected(type)
        onSelectChange(type)
    }
    return (
        <Box>
            <HeadingWithIcon>
                <P_Large_M>
                    <Icon
                        icon={"Document"}
                        fill={theme.foregroundSecondary}
                        width="16"
                    />
                    Velg signeringsmetode
                </P_Large_M>
            </HeadingWithIcon>
            <P_Small className="info">
                Begge signeringsløsninger er like juridisk bindende, men
                BankID-signering gir en ekstra trygghet ved at partene må
                identifiseres, samt at det kan dokumenteres at dokumentet ikke
                er endret etter at det er signert.
            </P_Small>
            <List>
                <RadioButtonLabelDesc
                    title={"Elektronisk signering"}
                    description={`Verifisert med BankID (kr. ${PRICE_PER_SIGNING.no},- pr. signatur.)`}
                    onChange={() => onChange("electronic")}
                    selected={selected === "electronic"}
                />
                <RadioButtonLabelDesc
                    title={"Manuell signering"}
                    description={"Håndskrevet signatur (gratis)"}
                    onChange={() => onChange("manual")}
                    selected={selected === "manual"}
                />
            </List>
        </Box>
    )
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: ${(props) => (props.big ? "48px" : "16px")};
    margin-top: 24px;
    @media screen and (min-width: 768px) {
        margin-right: 24px;
    }
    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    > * {
        margin-bottom: 12px;
    }
    p.info {
        padding: 0 0 8px 0;
    }
`

const HeadingWithIcon = styled.div`
    p {
        display: flex;
        align-items: center;
    }
    p svg {
        padding-right: 8px;
    }
`

export default InfoBoxSignatureMethodSelection
