import { useEffect, useState, useContext, useRef } from "react"
import styled, { useTheme } from "styled-components"
import { Link, useParams, useLocation, useHistory } from "react-router-dom"
import parse from "html-react-parser"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import * as Sentry from "@sentry/react"

import { FirebaseAuth } from "../providers/AuthProvider"
import { useTemplate } from "../hooks/useTemplates"

import Button from "../components/button"
import Progress from "../components/progress"
import FileUploader from "../components/fileUploader"
import Editor from "../components/editor"
import UploadedFile from "../components/uploadedFile"
import ContractSigner from "./ContractSigner"
import { ContractLoading } from "./Loading"
import InfoBoxSigning from "./InfoBoxSigning"
import InfoBox from "./InfoBox"
import InfoBoxPrice from "./InfoBoxPrice"
import InfoBoxSignatureMethodSelection from "./InfoBoxSignatureMethodSelection"
import SpaceBetweenBox from "../components/box"
import { applyPromoCode } from "../utils/api"
import owlImg from "../assets/images/hånd_over_mobil.svg"

import {
    H5,
    H6,
    P,
    P_Large_M,
    H2,
    P_Small_M,
    P_Mini,
    H6_Medium,
    H4,
    P_Mini_M
} from "../components/text"
import CheckoutForm from "./CheckoutForm"
import Icon from "../components/icon"
import Spinner from "../components/Spinner"
import Colors from "../assets/colors"

import {
    buildContract,
    buildContractPreview,
    generatePdf,
    uploadAttachmentsToContract,
    addSigners,
    sendToSigning,
    getContract,
    createSigningShortcodes,
    saveContractTargetEmail,
    uploadUserPdf,
    buildContractForUploadedPdf,
    getSigningShortcodes,
    getSingleContractOrderStatus
} from "../utils/api"
import getManualSigningUrlQR from "../utils/qr"
import {
    handleLocalStorageDataOnCompletion,
    clearCurrentContractMetaData,
    deleteDraft
} from "../utils/localStorage"

import { STEP, STRIPE, PRICE_PER_SIGNING } from "../utils/constants"
import { useFirstSigner } from "../providers/FirstSignerProvider"
import Notice from "../components/notice"
import {
    isObjectEmpty,
    buildQueryStringFromObject
} from "../utils/objectHelpers"
import SendSigningRequestNotification from "./SendSigningRequestNotification"
import { Logo } from "../assets/icons"
import TextInput from "../components/textInput"
import { useContracts } from "../hooks/useContracts"
import { useContractSigningStatus } from "../hooks/useContractSigningStatus"
import { usePaymentOrders } from "../hooks/usePaymentOrders"
import { toast } from "react-toastify"
import InputGroup from "../components/inputGroup"
import TagIcon from "../assets/icons/TagIcon"
import ContractIcon from "../assets/icons/ContractIcon"

const getStripeKey = () => {
    const l = window.location.hostname
    if (l === "app.lawyered.no" || l === "lawyered-no.web.app") {
        return STRIPE.PK_PROD
    }
    return STRIPE.PK_TEST
}

const stripe = loadStripe(getStripeKey())

/*  
    Steps overview:
    0. STEP.UPLOAD_FILE
    1. STEP.FORM
    2. STEP.SIGNING_DETAILS
    3. STEP.SUMMARY
    4. STEP.PAYMENT
    5. STEP.SIGNING
    6. STEP.COMPLETE
*/

const CreateContract = ({
    widgetClientId = null,
    widgetExternalReference = null,
    widgetClientImages = null,
    upload = false,
    isClientAuthorized = false
}) => {
    const history = useHistory();
    const theme = useTheme()

    const token = localStorage.getItem("lawyered.user.token")

    useEffect(() => {
        clearCurrentContractMetaData()
    }, [])

    const isWidget = !!widgetClientId

    const location = useLocation()
    const { currentUser } = useContext(FirebaseAuth)

    let { template_name: templateName } = useParams()
    const { template, isLoading, isError } = useTemplate(templateName || null);
    const [templatePrice, setTemplatePrice] = useState(0)
    const { contracts } = useContracts()
    const { orders } = usePaymentOrders(template?.id)

    const [contractData, setContractData] = useState(null)

    const [generatedQueryString, setGeneratedQueryString] = useState("")

    const [contractPreviewContent, setContractPreviewContent] = useState(null)
    const [formData, setFormData] = useState({})
    const [form, setForm] = useState(null)
    const [isFormValid, setIsFormValid] = useState(false)

    const [prefilledData, setPrefilledData] = useState({})
    const [isUsingDraftData, setIsUsingDraftData] = useState(false)

    const [attachments, setAttachments] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [shouldDisplayValidationErrors, setShouldDisplayValidationErrors] =
        useState(false)
    const [
        shouldDisplayValidationErrorNotice,
        setShouldDisplayValidationErrorNotice
    ] = useState(false)

    const [uploadedFiles, setUploadedFiles] = useState([])

    const [signatureType, setSignatureType] = useState("manual")
    const [signingShortcodes, setSigningShortcodes] = useState(null)
    const [isSigningShortcodesLoading, setIsSigningShortcodesLoading] =
        useState(false)

    const [phoneNumberForCheckout, setPhoneNumberForCheckout] = useState(null)

    const [currentStatusText, setCurrentStatusText] = useState(null)
    const [currentStatusSubText, setCurrentStatusSubText] = useState(null)

    const [didTimeOut, setDidTimeOut] = useState(false)

    // Using index in keys makes refreshing unstable, so bump this on each update to ensure a re-render
    const [signersSetIndex, setSignersSetIndex] = useState(0)
    const [signers, setSigners] = useState([])
    const signersForm = useRef(null)

    const [hasContractPriceUpdated, setHasContractPriceUpdated] = useState(false)
    const [couponApplied, setCouponApplied] = useState(false);
    const [couponValue, setCouponValue] = useState("");
    const [discountPrice, setDiscountPrice] = useState(0);
    const [couponId, setCouponId] = useState("");

    useEffect(() => {
        if ((currentUser.email === null && currentUser.phoneNumber === null)) {
            history.push("/onboarding")
        }
    }, [currentUser.emailVerified, templateName])

    useEffect(() => {
        if (location.state?.updatedTemplatePrice) {
            setTemplatePrice(location.state?.updatedTemplatePrice)
        } else {
            setTemplatePrice(template?.template_price);
        }
    }, [location.state?.updatedTemplatePrice])
    console.log(template, "template")

    // useEffect(() => {
    //     if(template?.id){
    //         //check for contract order details for the current contract
    //         getSingleContractOrderStatus(template?.id, token).then((response)=>{
    //             if(response?.length > 0){
    //                 setTemplatePrice(0);
    //             }else{
    //                 setTemplatePrice(template?.template_price);
    //             }
    //         })
    //     }
    // }, [template])






    useEffect(() => {
        if (template) {
            const draftData = localStorage.getItem("lawyered.drafts")
            if (draftData) {
                const drafts = JSON.parse(draftData)
                if (drafts[template.id]) {
                    if (!isObjectEmpty(drafts[template.id].data)) {
                        // TODO check if the data is empty
                        setPrefilledData(drafts[template.id])
                        setIsUsingDraftData(true)
                        console.info("Found and used form data from draft")
                    }
                }
            }

            // Query string handling
            const query = location.search
            const params = new URLSearchParams(query)

            const validParams = {}

            params.forEach(function (value, key) {
                if (key.includes("[]")) {
                    if (validParams[key]) {
                        validParams[key].push(value)
                    } else {
                        validParams[key] = [value]
                    }
                } else {
                    validParams[key] = value
                }
            })

            if (!isObjectEmpty(validParams)) {
                console.log(`Using data from query parameters`) //, validParams)
                setPrefilledData({
                    data: validParams,
                    templateId: template.id,
                    updatedAt: new Date()
                })
                setIsUsingDraftData(false)
            }
        }
    }, [template])



    const {
        dispatch,
        state: firstSignerState //{ firstSignerIndex, firstSignerUuid, firstSignerUrl }
    } = useFirstSigner()

    const [currentStep, setCurrentStep] = useState(
        upload ? STEP.UPLOAD : STEP.FORM
    )
    const stepIs = (stepOrSteps) => {
        if (Array.isArray(stepOrSteps)) {
            return stepOrSteps.includes(currentStep)
        }
        return currentStep === stepOrSteps
    }


    // useEffect(() => {

    //     if(currentStep === "3_SIGNING_DETAILS"){

    //     // Skip the useEffect if setTemplatePrice has been called before
    //     if (hasContractPriceUpdated) {
    //         return;
    //     }

    //     if (orders?.length > 0) {
    //         console.log("already bought in bulk")
    //         const hasSucceeded = orders.some(obj => obj.data.status === 'succeeded');
    //         if (hasSucceeded) {
    //             console.log("exist in the bulk contract with succedded status")
    //             //set template price zero if this exist in the bulk contract and it's bought
    //             setTemplatePrice(0);
    //             setHasContractPriceUpdated(true);
    //         } else {
    //             console.log("exist in the bulk")
    //             setTemplatePrice(0);
    //             setHasContractPriceUpdated(true);
    //         }

    //     } else if (template?.id) {
    //         console.log("check if single template bought")
    //         //check for contract order details for the current contract
    //         getSingleContractOrderStatus(template?.id, token).then((response) => {
    //             if (response?.length > 0) {
    //                 setTemplatePrice(0);
    //                 setHasContractPriceUpdated(true);
    //                 return;
    //             } else {
    //                 setTemplatePrice(template?.template_price);
    //             }
    //         })
    //     } else {
    //         console.log("Template is not bought", template?.template_price)
    //         setTemplatePrice(template?.template_price);
    //         setHasContractPriceUpdated(true);
    //     }

    // }
    // }, [orders, template, currentStep])

    /**
     * If the user is anonymous, we'll keep a list of contract IDs with a key
     * to transfer these contracts to the user if they log in afterwards.
     */
    const addContractToAnonymousTransferList = (
        contractId,
        anonymousTransferKey
    ) => {
        const anonContracts = localStorage.getItem(
            "lawyered.anonymous_contracts"
        )
        let data
        if (!anonContracts) {
            data = JSON.stringify([{ contractId, anonymousTransferKey }])
        } else {
            data = JSON.parse(anonContracts)
            data.push({ contractId, anonymousTransferKey })
            data = JSON.stringify(data)
        }
        localStorage.setItem("lawyered.anonymous_contracts", data)
    }

    const requestPreview = async () => {
        if (!form.checkValidity()) {
            setShouldDisplayValidationErrors(true)
            setShouldDisplayValidationErrorNotice(true)
        } else {
            setShowLoader(true)
            const buildContractPreviewResponse = await buildContractPreview(
                template.id,
                formData, // formData || template.testData
                token
            )

            if (buildContractPreviewResponse.success) {
                setContractPreviewContent(
                    buildContractPreviewResponse.contractHtml
                )
            } else {
                if (
                    buildContractPreviewResponse.missingFields &&
                    !!buildContractPreviewResponse.missingFields.length
                ) {
                    alert(
                        "Manglende felter: " +
                        buildContractPreviewResponse.missingFields.join(
                            ", "
                        )
                    )
                } else {
                    console.error(buildContractPreviewResponse)
                }
            }
            setShowLoader(false)
        }
    }

    const updateDraftCache = (data) => {
        const current = localStorage.getItem("lawyered.drafts")
        let worker = current ? JSON.parse(current) : {}
        const content = {
            updatedAt: new Date(),
            templateId: template.id,
            data
        }

        worker[template.id] = content
        localStorage.setItem("lawyered.drafts", JSON.stringify(worker))
    }

    const handleClearDraftData = () => {
        setIsUsingDraftData(false)
        deleteDraft(template.id)
    }

    const checkFormValidity = (form) => {
        if (form && form.checkValidity()) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }

    const updateFormData = (data, form) => {
        checkFormValidity(form)
        setShouldDisplayValidationErrorNotice(false)
        setFormData(data)
        setForm(form)
        updateDraftCache(data)
        setGeneratedQueryString(buildQueryStringFromObject(data))
    }

    const deleteSigner = (index) => {
        const currentSigners = [...signers]
        currentSigners.splice(index, 1)
        setSigners([...currentSigners])
        setSignersSetIndex(signersSetIndex + 1)
    }

    const onSignerUpdate = (data, index) => {
        const currentSigners = [...signers]
        currentSigners[index] = data
        setSigners([...currentSigners])
        if (signersForm.current.checkValidity()) {
            setShouldDisplayValidationErrors(false)
            setShouldDisplayValidationErrorNotice(false)
        }
    }

    const [completedFlows, setCompletedFlows] = useState({
        generateContract: false,
        uploadAttachments: false,
        generatePdf: false,
        addSigners: false,
        payment: false,
        signing: false
    })

    const setCompleted = (flow) => {
        setCompletedFlows((oldFlows) => {
            return { ...oldFlows, [flow]: true }
        })
    }

    const nextStep = () => {
        // Verbose, ya think? Objects are (per spec) unordered, so that's why
        switch (currentStep) {
            case STEP.UPLOAD:
                setCurrentStep(STEP.SIGNING_DETAILS)
                break
            case STEP.FORM:
                setCurrentStep(STEP.SIGNING_DETAILS)
                if (template?.hasPhysicalSign) {
                    toast.warn(template?.physicalSignMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 120000 //120 seconds
                    });
                }
                break
            case STEP.SIGNING_DETAILS:
                localStorage.setItem("lawyered.current.current_contract_id", template.id)
                setCurrentStep(STEP.SUMMARY)
                break
            case STEP.SUMMARY:
                setCurrentStep(STEP.CHECKOUT)
                break
            case STEP.CHECKOUT:
                setCurrentStep(STEP.PAYMENT)
                break
            case STEP.PAYMENT:
                setCurrentStep(STEP.SIGNING)
                break
            case STEP.SIGNING:
                setCurrentStep(STEP.COMPLETE)
                break
            default:
                setCurrentStep(STEP.SIGNING_DETAILS)
        }
    }

    const prevStep = () => {
        switch (currentStep) {
            case STEP.SIGNING_DETAILS:
                setCurrentStep(STEP.FORM)
                break
            case STEP.CHECKOUT:
                setCurrentStep(STEP.SUMMARY)
                break
            case STEP.SUMMARY:
            default:
                setCurrentStep(STEP.SIGNING_DETAILS)
        }
    }

    const toStep = (step) => {
        setCurrentStep(step)
    }

    const toSummary = () => {
        if (!signersForm.current.checkValidity()) {
            setShouldDisplayValidationErrors(true)
            setShouldDisplayValidationErrorNotice(true)
        } else {
            setShouldDisplayValidationErrors(false)
            setShouldDisplayValidationErrorNotice(false)
            nextStep()
            requestPreview(formData)
        }
    }

    const toSigningDetails = () => {
        if (!upload) {
            if (!form.checkValidity()) {
                setShouldDisplayValidationErrors(true)
                setShouldDisplayValidationErrorNotice(true)
            } else {
                // Check if there are any fields in the template with "is_signer",
                // and add them as signers if there are.
                const templateSigners = []
                // TODO -- this should instead use template.template, and support nesting
                template.fields.forEach((field) => {
                    if (field.is_signer) {
                        if (field.repeatable) {
                            formData[field.name].forEach((signer) => {
                                templateSigners.push({
                                    type: field.is_company
                                        ? "bedrift"
                                        : "privat",
                                    name: field.is_company ? "" : signer,
                                    companyName: field.is_company ? signer : "",
                                    email: ""
                                })
                            })
                        } else {
                            const signerName = formData[field.name]
                            templateSigners.push({
                                type: field.is_company ? "bedrift" : "privat",
                                name: field.is_company ? "" : signerName,
                                companyName: field.is_company ? signerName : "",
                                email: ""
                            })
                        }
                    }
                })
                if (!templateSigners.length) {
                    templateSigners.push({
                        type: "privat",
                        name: "",
                        companyName: "",
                        email: ""
                    })
                }
                setShouldDisplayValidationErrors(false)
                setShouldDisplayValidationErrorNotice(false)
                setSignersSetIndex(signersSetIndex + 1)
                setSigners([...templateSigners])
                nextStep()
            }
        } else {
            setShouldDisplayValidationErrors(false)
            setShouldDisplayValidationErrorNotice(false)
            setSigners([
                {
                    type: "privat",
                    name: "",
                    companyName: "",
                    email: ""
                }
            ])
            nextStep()
        }
    }

    const handleElectronicSigningCompletedClick = (event) => {
        event.preventDefault()
        handleLocalStorageDataOnCompletion(
            currentUser.isAnonymous,
            !upload ? template.id : null
        )
        window.location.href = "/archive"
    }

    const renderEditor = () => {
        return (
            <EditorBox big>
                <Editor
                    template={template}
                    prefilledData={prefilledData}
                    attachments={attachments}
                    requestPreview={requestPreview}
                    cancelPreview={() => setContractPreviewContent(null)}
                    contractPreviewContent={contractPreviewContent}
                    updateFormData={updateFormData}
                    currentStep={currentStep}
                    isLoading={showLoader}
                    shouldDisplayValidationErrors={
                        shouldDisplayValidationErrors
                    }
                    isFormValid={isFormValid}
                    isUsingDraftData={isUsingDraftData && !stepIs(STEP.SUMMARY)}
                    didClearDraftData={handleClearDraftData}
                    checkFormValidity={checkFormValidity}
                    generatedQueryString={generatedQueryString}
                />
                {shouldDisplayValidationErrorNotice && (
                    <Notice
                        warning
                        text="Kontrakten har noen mangler/feil som må rettes opp."
                    />
                )}
            </EditorBox>
        )
    }

    /* Contract attachments */
    const refreshAttachmentList = (files) => {
        setAttachments([...files])
    }

    const removeAttachment = (attachmentIndex) => {
        const attachmentsWorker = [...attachments]
        attachmentsWorker.splice(attachmentIndex, 1)
        refreshAttachmentList(attachmentsWorker)
    }

    const addAttachment = (file) => {
        setAttachments((oldFiles) => [...oldFiles, file])
    }

    const renderAttachmentsUploader = () => {
        return (
            <Box bg={theme.backgroundTertiary}>
                <HeadingWithIcon>
                    <P_Large_M>
                        <Icon icon="PaperClip" />
                        Vedlegg
                    </P_Large_M>
                </HeadingWithIcon>

                <FileUploader add={addAttachment} />
                {template.attachments.info && (
                    <Notice info text={template.attachments.info} />
                )}
                {attachments.map((file, index) => {
                    return (
                        <UploadedFile
                            key={`uploaded-file-${file.name}`}
                            title={file.name}
                            size={file.size}
                            onClick={() => removeAttachment(index)}
                        />
                    )
                })}
            </Box>
        )
    }

    /* User uploaded files */
    const addUploadedFile = (uploadedFile) => {
        setUploadedFiles([uploadedFile])
    }

    const removeUploadedFile = () => {
        setUploadedFiles([])
    }

    const renderFileUploader = () => {
        return (
            <>
                <FileUploader
                    add={addUploadedFile}
                    containerStyles={{ marginTop: 16, marginBottom: 16 }}
                    description="Maks 10 MB; kun PDF tillatt"
                    acceptedMimeTypes="application/pdf"
                    maxFilesAccepted={1}
                />
                {uploadedFiles.map((file, index) => {
                    return (
                        <UploadedFile
                            key={`uploaded-file-${file.name}`}
                            title={file.name}
                            size={file.size}
                            bg={theme.backgroundSecondary}
                            onClick={() => removeUploadedFile()}
                        />
                    )
                })}
            </>
        )
    }

    const renderSignersBox = () => {
        return (
            <Box>
                <HeadingWithIcon>
                    <P_Large_M>
                        <Icon icon="PersonCheckmark" />
                        Parter
                    </P_Large_M>
                </HeadingWithIcon>
                <form ref={signersForm}>
                    {signers.map((signer, index) => {
                        return (
                            <ContractSigner
                                firstSignerEnabled={isWidget ? false : true}
                                key={`signer-${index}-${signersSetIndex}`}
                                index={index}
                                signer={signer}
                                onDelete={
                                    signers.length > 1
                                        ? (index) => deleteSigner(index)
                                        : null
                                }
                                shouldDisplayValidationErrors={
                                    shouldDisplayValidationErrors
                                }
                                onUpdate={(data) => {
                                    onSignerUpdate(data, index)
                                }}
                            />
                        )
                    })}
                </form>
                <Button
                    primary
                    text="Legg til ny part"
                    onClick={() => {
                        setSigners([
                            ...signers,
                            {
                                type: "privat",
                                name: "",
                                companyName: "",
                                email: ""
                            }
                        ])
                    }}
                    icon="Plus"
                    disabled={template?.hasPhysicalSign ? true : false}
                />
                {shouldDisplayValidationErrorNotice && (
                    <Notice
                        warning
                        text="Vi mangler noe data for minst én av partene."
                    />
                )}
            </Box>
        )
    }

    const renderTemplateCreator = () => {
        if (isError) {
            return <P>{isError.toString()}</P>
        }
        return (
            <>
                <CreatorWrapper visible={stepIs([STEP.UPLOAD])}>
                    <ColumnWide visible>{renderFileUploader()}</ColumnWide>
                </CreatorWrapper>

                <CreatorWrapper visible={stepIs([STEP.FORM, STEP.SUMMARY])}>
                    {!upload && (
                        <>
                            <ColumnWide
                                visible={stepIs([STEP.FORM, STEP.SUMMARY])}
                            >
                                <ContentBox wide>{renderEditor()}</ContentBox>
                            </ColumnWide>

                            <ColumnNarrow visible={stepIs(STEP.FORM)}>
                                <ContentBox>
                                    {!upload &&
                                        template.attachments &&
                                        template.attachments.enabled &&
                                        renderAttachmentsUploader()}
                                    {!upload &&
                                        (template.info ||
                                            template.descriptionExtended ||
                                            template.description) && (
                                            <InfoBox
                                                description={
                                                    template.description
                                                }
                                                descriptionExtended={
                                                    template.descriptionExtended
                                                }
                                                info={template.info}
                                            />
                                        )}
                                </ContentBox>
                            </ColumnNarrow>
                        </>
                    )}

                    <ColumnNarrow visible={stepIs(STEP.SUMMARY)}>
                        {templatePrice &&
                            <InfoBoxPrice
                                signers={signers}
                                isLoading={showLoader}
                                signatureType={signatureType}
                                onStartButtonClick={() => {
                                    setShouldDisplayValidationErrors(false)
                                    setShouldDisplayValidationErrorNotice(false)
                                    nextStep()
                                }}
                                templatePrice={templatePrice}
                            />
                        }
                    </ColumnNarrow>
                </CreatorWrapper>
                <CreatorWrapper
                    visible={stepIs(STEP.SIGNING_DETAILS)}
                    asWidget={isWidget}
                >
                    <ColumnNarrowPlus
                        visible={stepIs(STEP.SIGNING_DETAILS)}
                        asWidget={isWidget}
                    >
                        <ContentBox>{renderSignersBox()}</ContentBox>
                    </ColumnNarrowPlus>

                    <ColumnNarrow
                        vertical
                        visible={stepIs(STEP.SIGNING_DETAILS)}
                    >
                        <ContentBox vertical>
                            {/*
                            <InfoBoxSignatureMethodSelection
                                onSelectChange={(signatureType) =>
                                    setSignatureType(signatureType)
                                }
                            />
                            */}
                            <InfoBoxPrice
                                signatureType={signatureType}
                                signers={signers}
                                templatePrice={templatePrice}
                            />
                            <InfoBoxSigning />
                        </ContentBox>
                    </ColumnNarrow>
                </CreatorWrapper>
            </>
        )
    }

    const renderQrCodeFrame = (
        qr,
        shortcode,
        contractReadingKey = null,
        index
    ) => {
        return (
            <QRFrame key={`qr-${shortcode.code}`} target="_blank">
                <a
                    href={`/s/${shortcode.code}${contractReadingKey
                        ? `?contract_reading_key=${contractReadingKey}`
                        : ""
                        }`}
                    target="_blank"
                >
                    <QR>
                        {qr}
                        <code>{shortcode.code}</code>
                    </QR>
                    <QRMeta>
                        <P_Mini>Part {index + 1}</P_Mini>
                        <P>
                            {shortcode.signerInfo.firstName}{" "}
                            {shortcode.signerInfo.lastName}
                            {shortcode.signerInfo.organizationInfo &&
                                shortcode.signerInfo.organizationInfo
                                    .companyName}
                        </P>
                    </QRMeta>
                </a>
                <SendSigningRequestNotification
                    shortcode={shortcode}
                    inputLabel="E-post-adresse"
                    inputPlaceholder="E-post"
                    submitButtonText="Send"
                    asModal={true}
                    openModalButtonText={"Send på e-post"}
                />
            </QRFrame>
        )
    }

    const renderQrCodes = (shortcodes, contractReadingKey = null) => {
        const qrs = []
        shortcodes.forEach((shortcode, index) => {
            const qr = getManualSigningUrlQR(shortcode.code, contractReadingKey)
            const el = parse(qr)
            const fr = renderQrCodeFrame(
                el,
                shortcode,
                contractReadingKey,
                index
            )
            qrs.push(fr)
        })
        return qrs
    }

    const startBuilding = async () => {
        setShowLoader(true)

        const token = localStorage.getItem("lawyered.user.token")
        let contractId = localStorage.getItem("lawyered.current.contract_id")

        // 1. Generate contract
        if (!contractId) {
            console.log("Generating contract..")
            setCurrentStatusText("Bygger kontrakten...")
            const buildContractResponse = await buildContract(
                template.id,
                formData, // formData || template.testData
                signatureType,
                token,
                widgetClientId,
                widgetExternalReference
            )

            console.log(`****** buildContractResponse`, buildContractResponse)
            contractId = buildContractResponse.generatedContractId
            localStorage.setItem("lawyered.current.contract_id", contractId)

            if (buildContractResponse.anonymousTransferKey) {
                addContractToAnonymousTransferList(
                    contractId,
                    buildContractResponse.anonymousTransferKey
                )
            }

            console.log("Generating contract content done.")
            setCompleted("generateContract")

            startSigning()
        } else {
            console.info("✅ Created contract content")
            setCompleted("generateContract")
            startSigning()
        }
    }

    let subTextTimer, subTextTimerLongRunning, subTextTimerTimedOut

    const uploadFileAndStartBuilding = async () => {
        setShowLoader(true)

        const token = localStorage.getItem("lawyered.user.token")

        let uploadUserPdfResponse = null
        setCurrentStatusText("Laster opp og konverterer PDF...")

        subTextTimer = setTimeout(() => {
            setCurrentStatusSubText("Dette kan ta litt tid...")
        }, 3000)

        subTextTimerLongRunning = setTimeout(() => {
            setCurrentStatusSubText("Arbeider fortsatt...")
        }, 10000)

        subTextTimerLongRunning = setTimeout(() => {
            setCurrentStatusSubText(
                "Store PDFer kan ta opptil 5 minutter å behandle..."
            )
        }, 30000)

        subTextTimerTimedOut = setTimeout(() => {
            setCurrentStatusSubText(
                "Huff, det ser ut til at vi fikk problemer med PDFen din. Forsøk gjerne igjen, og kontakt oss hvis du fortsatt opplever trøbbel."
            )
            setDidTimeOut(true)
            Sentry.captureException(
                "uploadFileAndStartBuilding timed out (in CreateContract.js)"
            )
        }, 300000)

        toStep(STEP.STATUS)
        console.log("uploadedFiles", uploadedFiles)
        try {
            uploadUserPdfResponse = await uploadUserPdf({
                token,
                files: uploadedFiles
            })
        } catch (error) {
            console.error("uploadUserPdf error", error)
        }

        if (uploadUserPdfResponse && uploadUserPdfResponse.success) {
            let buildContractForUploadedPdfResponse = null
            try {
                buildContractForUploadedPdfResponse =
                    await buildContractForUploadedPdf({
                        token,
                        userPdfId: uploadUserPdfResponse.uploaded.id,
                        signatureType,
                        widgetClientId,
                        widgetExternalReference
                    })
            } catch (error) {
                console.error("buildContractForUploadedPdf error", error)
            }

            if (
                buildContractForUploadedPdfResponse &&
                buildContractForUploadedPdfResponse.success
            ) {
                let contractId =
                    buildContractForUploadedPdfResponse.generatedContractId
                localStorage.setItem("lawyered.current.contract_id", contractId)

                if (buildContractForUploadedPdfResponse.anonymousTransferKey) {
                    addContractToAnonymousTransferList(
                        contractId,
                        buildContractForUploadedPdfResponse.anonymousTransferKey
                    )
                }

                setCompleted("generateContract")

                startSigning()
                clearTimeout(subTextTimer)
                clearTimeout(subTextTimerLongRunning)
                clearTimeout(subTextTimerTimedOut)
                setCurrentStatusSubText(null)
            } else {
                console.error(
                    "buildContractForUploadedPdfResponse reports unsuccessful attempt",
                    buildContractForUploadedPdfResponse
                )
            }
        } else {
            console.error(
                "uploadUserPdfResponse reports unsuccessful attempt",
                uploadUserPdfResponse
            )
        }
        clearTimeout(subTextTimer)
        clearTimeout(subTextTimerLongRunning)
        clearTimeout(subTextTimerTimedOut)
        setCurrentStatusSubText(null)
    }

    const startSigning = async () => {
        let localFirstSignerUuid = null

        const token = localStorage.getItem("lawyered.user.token")
        let contractId = localStorage.getItem("lawyered.current.contract_id")

        /*
        // 1. Generate contract - done from startBuilding()
        */

        toStep(STEP.STATUS)

        // 2. Add signers to contract
        console.log(`Adding signers to contract..`)
        setCurrentStatusText("Lagrer parter...")
        signers.forEach((signer, index) => {
            if (index === firstSignerState.firstSignerIndex) {
                signer.isFirstSigner = true
            }
        })
        try {
            const addSignersResponse = await addSigners(
                contractId,
                signers,
                templatePrice,
                token
            )

            // Check addSignersResponse.signers for an "isFirstSigner"-flag.
            // If it exists, the firstSigner.uuid reference will point to the
            // signer who will sign the contract in this current flow.
            const firstSigner = addSignersResponse.signers.find(
                (signer) => signer.isFirstSigner
            )
            if (firstSigner) {
                console.log(`firstSigner found`, firstSigner)
                setPhoneNumberForCheckout(
                    firstSigner.info.mobile
                        ? firstSigner.info.mobile.number
                        : null
                )
                dispatch({ type: "setUuid", firstSignerUuid: firstSigner.uuid }) // For later use (isn't picked up until a re-render (?))
                localFirstSignerUuid = firstSigner.uuid
            } else {
                console.log(`firstSigner not found`)
            }

            console.log(`Adding signers to contract done.`)
            console.info("✅ Added signers to contract")
            setCompleted("addSigners")
        } catch (error) {
            console.error("Add signers to contract ERROR", error)
        }

        // 3. Upload attachment(s)
        console.log(`Uploading attachments..`)
        setCurrentStatusText("Laster opp vedlegg...")
        const uploadAttachmentsToContractResponse =
            await uploadAttachmentsToContract(contractId, token, attachments)
        console.log(
            `uploadAttachmentsToContractResponse`,
            uploadAttachmentsToContractResponse
        )
        console.log(`Uploading attachments done.`)
        console.info("✅ Uploaded attachments")
        setCompleted("uploadAttachments")

        // 4. Generate PDF
        if (!upload) {
            if (
                signatureType === "electronic" &&
                !localStorage.getItem("lawyered.current.pdf")
            ) {
                console.log("Generating PDF..")
                setCurrentStatusText("Genererer PDF...")
                const generatePdfResponse = await generatePdf(contractId, token) // { ~url: ...,~ pdf: ...}
                //console.log(`generatePdfResponse`, generatePdfResponse)
                localStorage.setItem(
                    "lawyered.current.pdf",
                    generatePdfResponse.pdf
                )
                console.info("✅ Generated PDF")
                setCompleted("generatePdf")
            } else {
                setCompleted("generatePdf")
                console.info(
                    "❌ PDF not generated when signatureType is 'manual'"
                )
            }
        } else {
            setCompleted("generatePdf")
            console.info("❌ PDF already generated when uploading PDFs")
        }

        // 5. Collect payment
        // Payment intent created and payment form displayed with the call to nextStep()

        // Check the payment status first; e.g. in case of a cancelled signing etc.
        const contract = await getContract(contractId, token)
        setContractData(contract)

        if (
            (contract.paymentData && contract.paymentData.paid) ||
            contract.paymentAmount === 0
        ) {
            console.info("✅ Payment already completed or not needed")
            setCompleted("payment")
            toStep(STEP.SIGNING)
            startInFlowSigning(localFirstSignerUuid)
        } else {
            console.info("✅ Starting payment")
            //nextStep()
            setCurrentStatusText("Behandler betaling...")
            toStep(STEP.PAYMENT)
        }

        // 6. Send to signing
        // Initiated from onPaymentSucceeded() callback unless payment is already registered
    }

    const startInFlowSigning = async (lawyeredSignerId) => {
        setCurrentStatusText("Sjekker signeringsvalg...")
        if (signatureType === "electronic") {
            let subTextTimer, subTextTimerLongRunning, subTextTimerTimedOut
            // Start the signing order creation here before the timeout below
            console.log(`Sending document to signing..`)
            const token = localStorage.getItem("lawyered.user.token")
            let contractId = localStorage.getItem(
                "lawyered.current.contract_id"
            )

            subTextTimer = setTimeout(() => {
                setCurrentStatusSubText("Dette kan ta litt tid...")
            }, 3000)

            subTextTimerLongRunning = setTimeout(() => {
                setCurrentStatusSubText("Arbeider fortsatt...")
            }, 10000)

            subTextTimerLongRunning = setTimeout(() => {
                setCurrentStatusSubText(
                    "Hvis systemene våre har mye trafikk, kan dette ta opptil 5 minutter..."
                )
            }, 30000)

            subTextTimerTimedOut = setTimeout(() => {
                setCurrentStatusSubText(
                    "Huff, det ser ut til at vi fikk problemer med signeringen. Forsøk gjerne igjen, og kontakt oss hvis du fortsatt opplever trøbbel."
                )
                setDidTimeOut(true)
                Sentry.captureException(
                    "sendToSigning timed out (in CreateContract.js)"
                )
            }, 300000)

            let sendToSigningResponse
            try {
                sendToSigningResponse = await sendToSigning(contractId, token)
            } catch (error) {
                console.log("sendToSigning error", error)
                Sentry.captureException(error)
            }

            if (sendToSigningResponse.success) {
                clearTimeout(subTextTimer)
                clearTimeout(subTextTimerLongRunning)
                clearTimeout(subTextTimerTimedOut)
                setCurrentStatusSubText(null)

                console.log(`sendToSigningResponse`, sendToSigningResponse)
                console.info("✅ Sent document to signing.")

                console.info("✅ Signing links:")
                sendToSigningResponse.signingOrder.signers.forEach((signer) => {
                    console.info(
                        parse(
                            "- " +
                            signer.externalSignerId +
                            ": <a href='" +
                            signer.url +
                            "'>Go sign!</a>"
                        )
                    )
                })

                console.log(
                    `Checking response for firstSigner:`,
                    lawyeredSignerId
                )
                const confirmedFirstSigner =
                    sendToSigningResponse.signingOrder.signers.find(
                        (signer) => signer.externalSignerId === lawyeredSignerId
                    )
                if (confirmedFirstSigner) {
                    console.log(
                        `Setting firstSigner.url`,
                        confirmedFirstSigner.url
                    )
                    dispatch({
                        type: "setUrl",
                        firstSignerUrl: confirmedFirstSigner.url
                    })
                }

                setTimeout(() => {
                    if (confirmedFirstSigner) {
                        toStep(STEP.SIGNING)
                    } else {
                        if (!isWidget) {
                            handleLocalStorageDataOnCompletion(
                                currentUser.isAnonymous,
                                !upload ? template.id : null
                            )
                        }
                        setCompleted("signing")
                        toStep(STEP.COMPLETE)
                    }
                }, 1500)
            } else {
                console.error(`sendToSigning error`, sendToSigningResponse)
                Sentry.captureException(sendToSigningResponse)
                if (
                    sendToSigningResponse.error &&
                    sendToSigningResponse.error.code &&
                    sendToSigningResponse.error.code ===
                    "lawyered/pdf-not-created-in-time"
                ) {
                    alert(
                        "Det ser ut som om systemene våre er litt trege i dag. Du kan forsøke igjen."
                    )

                    // Retry
                    const token = localStorage.getItem("lawyered.user.token")
                    console.log("Generating PDF..")
                    setCurrentStatusText("Genererer PDF...")
                    const generatePdfResponse = await generatePdf(
                        contractId,
                        token
                    ) // { ~url: ...,~ pdf: ...}

                    localStorage.setItem(
                        "lawyered.current.pdf",
                        generatePdfResponse.pdf
                    )
                    console.info("✅ Retried generating PDF")
                    setCompleted("generatePdf")

                    clearTimeout(subTextTimer)
                    clearTimeout(subTextTimerLongRunning)
                    clearTimeout(subTextTimerTimedOut)
                    setCurrentStatusSubText(null)
                    startInFlowSigning(lawyeredSignerId)
                } else {
                    alert(
                        "En ukjent feil oppstod. Vi har fått beskjed, og jobber med å fikse det!"
                    )
                }
            }
        } else {
            console.log(
                `Start the manual signing flow for contract`,
                contractData
            )
            // 2.1 Get signing shortcodes for QR-code
            setIsSigningShortcodesLoading(true)
            const token = localStorage.getItem("lawyered.user.token")
            let contractId = localStorage.getItem(
                "lawyered.current.contract_id"
            )
            try {
                const shortcodeData = await createSigningShortcodes(
                    contractId,
                    token,
                    signatureType
                )
                setSigningShortcodes(shortcodeData.shortcodes)
                setIsSigningShortcodesLoading(false)
                toStep(STEP.SIGNING)
            } catch (error) {
                Sentry.captureException(error)
            }
        }
    }

    const onPaymentSucceeded = () => {
        setCompleted("payment")
        startInFlowSigning(firstSignerState.firstSignerUuid)
    }

    const onPaymentCancel = () => {
        setShowLoader(false)
        if(template?.template_price > 0){
            toStep(STEP.CHECKOUT)
        }else{
            toStep(STEP.SUMMARY)
        }
    }

    const onSigningCancel = () => {
        setShowLoader(false)
        toStep(STEP.SUMMARY)
    }

    const getStartButtonText = () => {
        const requiresPayment =
            signatureType === "electronic"
                ? signers.length * PRICE_PER_SIGNING.no + templatePrice
                : signers.length * PRICE_PER_SIGNING.manual + templatePrice

        if (requiresPayment) {
            if (firstSignerState.firstSignerIndex !== null) {
                return "Betal og start signering"
            }
            return "Betal og send til signering"
        } else {
            if (firstSignerState.firstSignerIndex !== null) {
                return "Start signering"
            }
            return "Send til signering"
        }
    }

    const [widgetTargetEmail, setWidgetTargetEmail] = useState("")
    const [isSavingWidgetTargetEmail, setIsSavingWidgetTargetEmail] =
        useState(false)
    const [
        completedSavingWidgetTargetEmail,
        setCompletedSavingWidgetTargetEmail
    ] = useState(false)

    const handleWidgetTargetEmailClick = async (e) => {
        e.preventDefault()
        setIsSavingWidgetTargetEmail(true)
        const contractId = localStorage.getItem("lawyered.current.contract_id")
        const token = localStorage.getItem("lawyered.user.token")
        const result = await saveContractTargetEmail(
            contractId,
            token,
            widgetTargetEmail
        )
        if (result && result.success) {
            setIsSavingWidgetTargetEmail(false)
            setCompletedSavingWidgetTargetEmail(true)
            setTimeout(() => {
                startSigning()
            }, 2000)
        } else {
            setIsSavingWidgetTargetEmail(false)
            console.error("Saving target e-mail failed", result)
        }
    }

    const handleCouponApply = async () => {
        setShowLoader(true)
        const token = localStorage.getItem("lawyered.user.token")
        let response = await applyPromoCode(couponValue, template?.id, token);
        if (response.status === 200) {
            toast.success("Coupon is applied successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000 //3 seconds
            });
            setDiscountPrice((templatePrice ?? 0) * (parseInt(response.percentage) / 100))
            setCouponApplied(couponValue);
            setCouponValue('');
            setCouponId(response.couponId);
            // setTemplatePrice(templatePrice - (templatePrice * (parseInt(response.percentage) / 100)))
        } else {
            toast.error(response?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000 //3 seconds
            });
        }
        setShowLoader(false)
    }
    const renderWidgetTargetEmail = () => {
        return (
            <WidgetTargetEmailWrapper>
                <WidgetTargetEmailLabel>
                    Hvilken e-post-adresse vil du ha den ferdige signerte
                    kontrakten tilsendt til?
                </WidgetTargetEmailLabel>
                <TextInput
                    placeholder="E-post-adresse"
                    value={widgetTargetEmail}
                    type="email"
                    onChange={(event) => {
                        setWidgetTargetEmail(event.target.value)
                    }}
                />
                <Button
                    onClick={(e) => handleWidgetTargetEmailClick(e)}
                    small
                    primary
                    disabled={
                        isSavingWidgetTargetEmail ||
                        !widgetTargetEmail ||
                        completedSavingWidgetTargetEmail
                    }
                    icon={completedSavingWidgetTargetEmail ? "Checkmark" : null}
                    text={
                        completedSavingWidgetTargetEmail ? (
                            "Lagret"
                        ) : isSavingWidgetTargetEmail ? (
                            <Spinner size="24" />
                        ) : (
                            "Lagre"
                        )
                    }
                />
                {currentStatusText && completedSavingWidgetTargetEmail && (
                    <Spinner size="24" text={currentStatusText} />
                )}
            </WidgetTargetEmailWrapper>
        )
    }

    const handleEndWidget = (event) => {
        event.preventDefault()
        const contractId = localStorage.getItem("lawyered.current.contract_id")
        console.log(`postMessage contractId`, contractId)
        window.parent.postMessage(
            { source: "lawyered", status: "COMPLETED", contractId },
            "*"
        )
    }

    const handleCancelClick = (event) => {
        event.preventDefault()
        if (!isWidget) {
            window.location.href = "/create"
        } else {
            window.parent.postMessage(
                { source: "lawyered", status: "CANCELLED" },
                "*"
            )
        }
    }

    const handleUploadRestart = () => {
        setDidTimeOut(false)
        clearTimeout(subTextTimer)
        clearTimeout(subTextTimerLongRunning)
        clearTimeout(subTextTimerTimedOut)
        setCurrentStatusSubText(null)
        uploadFileAndStartBuilding()
    }

    // Listen to Signicat's iframe postMessage;
    /*
        {
            payload: {
                documentId: ...,
                eid: ...,
                jwt: ...
            },
            type: sign_success
        }
    */
    useEffect(() => {
        const handler = async (event) => {
            // TODO maybe use this instead of try/catching everything like below?
            // if (event.origin !== "http://example.org:8080")
            // return
            try {
                const data = JSON.parse(event.data)
                if (data.type === "sign_success") {
                    // TODO validate the JWT against the Signicat API
                    console.log(`Completed signing in-flow`)
                    toStep(STEP.COMPLETE)
                    setCompleted("signing")
                    if (!isWidget) {
                        handleLocalStorageDataOnCompletion(
                            currentUser.isAnonymous,
                            template.id
                        )
                    }
                } else {
                    // console.log(`postMessage data`, data)
                }
            } catch (e) {
                // Fail silently, lots of postMessages coming through here
                // console.error("Caught an error parsing event", event)
            }
        }

        window.addEventListener("message", handler)

        // clean up
        return () => window.removeEventListener("message", handler)
    }, [])

    const getPhoneNumberForCheckout = () => {
        return currentUser.phoneNumber || phoneNumberForCheckout || ""
    }

    useEffect(() => {
        console.log("(2022.04.05_2312) currentStep", currentStep)
    }, [currentStep])
    // RENDER BASE //
    const getContent = () => {
        return (
            <>
                {!stepIs([
                    STEP.PAYMENT,
                    STEP.SIGNING,
                    STEP.COMPLETE,
                    STEP.STATUS,
                ]) && (
                        <>
                            <TopContainer asWidget={isWidget}>
                                {isWidget &&
                                    widgetClientImages &&
                                    widgetClientImages.mark && (
                                        <TopRow>
                                            {!upload && (
                                                <Title>
                                                    {template && template.title}
                                                </Title>
                                            )}
                                            {upload && (
                                                <Title>
                                                    Last opp din egen kontrakt
                                                </Title>
                                            )}
                                            <WidgetMark
                                                src={
                                                    widgetClientImages.mark
                                                        .dataSource
                                                }
                                                style={{
                                                    ...widgetClientImages.mark
                                                        .attributes
                                                }}
                                            />
                                        </TopRow>
                                    )}
                                {isWidget &&
                                    (!widgetClientImages ||
                                        !widgetClientImages.mark) && (
                                        <TopRow>
                                            {!upload && (
                                                <Title>
                                                    {template && template.title}
                                                </Title>
                                            )}
                                            {upload && (
                                                <Title>
                                                    Last opp din egen kontrakt
                                                </Title>
                                            )}
                                            <WidgetSubtitle>
                                                <span>En kontraktsmal fra</span>
                                                <Logo />
                                            </WidgetSubtitle>
                                        </TopRow>
                                    )}
                                {!isWidget && !upload && (
                                    <Title>{template && template.title}</Title>
                                )}
                                {!isWidget && upload && (
                                    <Title>Last opp din egen kontrakt</Title>
                                )}
                            </TopContainer>
                            {
                                currentStep != STEP.CHECKOUT &&
                                <TemplateContainer asWidget={isWidget}>
                                    {renderTemplateCreator()}
                                </TemplateContainer>
                            }
                        </>
                    )}
                {!stepIs([
                    STEP.PAYMENT,
                    STEP.SIGNING,
                    STEP.COMPLETE,
                    STEP.STATUS
                ]) && (
                        <Statusbar asWidget={isWidget}>
                            <Progress
                                steps={[
                                    {
                                        no: 1,
                                        description: !upload
                                            ? "Fyll ut kontrakt"
                                            : "Last opp kontrakt",
                                        active: stepIs([STEP.FORM, STEP.UPLOAD]),
                                        completed: currentStep == STEP.CHECKOUT ? true : stepIs([
                                            STEP.SUMMARY,
                                            STEP.SIGNING_DETAILS,
                                        ])
                                    },
                                    {
                                        no: 2,
                                        description: "Velg parter",
                                        active: stepIs(STEP.SIGNING_DETAILS),
                                        completed: currentStep == STEP.CHECKOUT ? true : stepIs([
                                            STEP.SUMMARY,
                                            STEP.SIGNING,
                                            STEP.PAYMENT,
                                            STEP.COMPLETE
                                        ])
                                    },
                                    {
                                        no: 3,
                                        description: !upload
                                            ? "Se igjennom"
                                            : "Send til signering",
                                        active: stepIs(STEP.SUMMARY),
                                        completed: currentStep === STEP.CHECKOUT ? true : false
                                    },
                                    {
                                        no: 4,
                                        description: !upload
                                            ? 'Sjekk ut'
                                            : "Send til signering",
                                        active: stepIs(STEP.CHECKOUT),
                                        completed: stepIs([
                                            STEP.SIGNING,
                                            STEP.PAYMENT,
                                            STEP.COMPLETE
                                        ])
                                    }
                                ]}
                            ></Progress>
                            <Buttons>
                                {/* BACK BUTTONS */}
                                {stepIs(STEP.FORM) && (
                                    <Button
                                        text={"Avbryt"}
                                        onClick={handleCancelClick}
                                        secondary
                                    />
                                )}
                                {stepIs([STEP.SUMMARY, STEP.SIGNING_DETAILS]) && (
                                    <Button
                                        text={"Tilbake"}
                                        secondary
                                        onClick={() => {
                                            prevStep()
                                            if (stepIs(STEP.SUMMARY)) {
                                                setContractPreviewContent(null)
                                            }
                                        }}
                                    />
                                )}
                                {stepIs([STEP.CHECKOUT]) && (
                                    <Button
                                        text={"Tilbake"}
                                        secondary
                                        onClick={() => {
                                            prevStep()
                                        }}
                                    />
                                )}

                                {/* NEXT BUTTONS */}
                                {stepIs([STEP.FORM, STEP.UPLOAD]) && (
                                    <Button
                                        text={
                                            showLoader ? (
                                                <Spinner size="18" inline />
                                            ) : (
                                                "Velg parter"
                                            )
                                        }
                                        disabled={
                                            stepIs(STEP.UPLOAD) &&
                                            !uploadedFiles.length
                                        }
                                        onClick={() => toSigningDetails()}
                                        primary
                                    />
                                )}
                                {stepIs(STEP.SIGNING_DETAILS) && (
                                    <Button
                                        text={
                                            showLoader ? (
                                                <Spinner size="18" inline />
                                            ) : !upload ? (
                                                "Se igjennom"
                                            ) : (
                                                getStartButtonText()
                                            )
                                        }
                                        onClick={() => {
                                            !upload
                                                ? toSummary()
                                                : uploadFileAndStartBuilding()
                                        }}
                                        disabled={!signatureType || showLoader}
                                        primary
                                    />
                                )}
                                {/* {stepIs(STEP.SUMMARY) && (
                                    <Button
                                        text={
                                            showLoader ? (
                                                <Spinner size="18" inline />
                                            ) : (
                                                getStartButtonText()
                                            )
                                        }
                                        disabled={showLoader}
                                        onClick={startBuilding}
                                        primary
                                    />
                                )} */}
                                {stepIs(STEP.SUMMARY) && (
                                    <Button
                                        text={
                                            showLoader ? (
                                                <Spinner size="18" inline />
                                            ) : (
                                                templatePrice > 0 ? "Sjekk ut": getStartButtonText()
                                            )
                                        }
                                        disabled={showLoader}
                                        onClick={() => {
                                            if(templatePrice > 0){
                                                setShouldDisplayValidationErrors(false)
                                                setShouldDisplayValidationErrorNotice(false)
                                                nextStep()
                                            }else{
                                                startBuilding()
                                            }
                                        }}
                                        primary
                                    />
                                )}
                                {stepIs(STEP.CHECKOUT) && (
                                    <Button
                                        text={
                                            showLoader ? (
                                                <Spinner size="18" inline />
                                            ) : (
                                                getStartButtonText()
                                            )
                                        }
                                        disabled={showLoader}
                                        onClick={() => { startBuilding() }}
                                        primary
                                    />
                                )}
                            </Buttons>
                        </Statusbar>
                    )}
                {/* FULL SCREEN LAYOUTS */}
                {stepIs([
                    STEP.PAYMENT,
                    STEP.SIGNING,
                    STEP.COMPLETE,
                    STEP.STATUS,
                    STEP.CHECKOUT,
                ]) && (
                        <Fullscreen isWidget={isWidget} style={{ display: currentStep == STEP.PAYMENT ? 'flex' : 'block', justifyContent: currentStep == STEP.PAYMENT && 'center', alignItems: currentStep == STEP.PAYMENT && 'center' }}>
                            <FullscreenInner
                                wide={stepIs(STEP.SIGNING)}
                                matchSignicatBg={
                                    stepIs(STEP.SIGNING) &&
                                    signatureType === "electronic"
                                }
                                style={{ width: currentStep !== STEP.PAYMENT ? '96%' : '40%' }}
                            >
                                {stepIs(STEP.STATUS) && (
                                    <FullScreenNotification>
                                        <H5>Behandler...</H5>
                                        <>
                                            {currentStatusText && !didTimeOut && (
                                                <Spinner
                                                    size="24"
                                                    text={currentStatusText}
                                                />
                                            )}
                                            {currentStatusSubText && (
                                                <P_Small_M
                                                    style={{ textAlign: "center" }}
                                                >
                                                    {currentStatusSubText}
                                                </P_Small_M>
                                            )}
                                            {didTimeOut && (
                                                <Button
                                                    text="Prøv på nytt"
                                                    onClick={() => {
                                                        handleUploadRestart()
                                                    }}
                                                />
                                            )}
                                        </>
                                    </FullScreenNotification>
                                )}
                                {stepIs(STEP.CHECKOUT) && (
                                    <CouponWrapper>
                                        <CouponBox>
                                            <H5>Rabattkode</H5>
                                            <P style={{ fontWeight: '500' }}>
                                                Har du en rabattkode?
                                            </P>
                                            <>
                                                <CouponFormWrapper>
                                                    <SpaceBetweenBox>
                                                        <InputGroup value={couponValue} onChange={(e) => setCouponValue(e.target.value.toUpperCase())} placeholder="Rabattkode" />
                                                    </SpaceBetweenBox>
                                                    <SpaceBetweenBox style={{ width: '60%', marginLeft: '8px' }}>
                                                        <Button
                                                            text="Bruk kode"
                                                            onClick={() => handleCouponApply()}
                                                            secondary
                                                            small
                                                            disabled={!couponValue || discountPrice || showLoader}
                                                        />
                                                    </SpaceBetweenBox>
                                                    <StyledImage src={owlImg} alt="Owl Image" />

                                                </CouponFormWrapper>
                                                {couponApplied &&
                                                    <><SpaceBetweenBox marginTop='8px'>
                                                        <P style={{ fontWeight: '500' }}>
                                                            Aktivert:
                                                        </P>
                                                    </SpaceBetweenBox>
                                                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                                                            <AppliedCouponWrapper>
                                                                <span style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                }}>
                                                                    <TagIcon width="10%" />
                                                                    <P_Mini_M style={{ marginLeft: '6px' }}>{couponApplied.length > 12 ? `${couponApplied.substring(0, 12)}...` : couponApplied}</P_Mini_M>
                                                                </span>
                                                            </AppliedCouponWrapper>
                                                            <P onClick={() => {
                                                                setCouponValue('');
                                                                setCouponApplied(false);
                                                                setDiscountPrice(0);
                                                                toast.success("Coupon is removed successfully", {
                                                                    position: toast.POSITION.TOP_RIGHT,
                                                                    autoClose: 3000 //3 seconds
                                                                })
                                                            }}
                                                                style={{ cursor: 'pointer', marginLeft: '10px', color: '#5B4379', textDecoration: 'underline' }}>
                                                                Fjern kode
                                                            </P>
                                                        </div>
                                                    </>
                                                }
                                            </>

                                        </CouponBox>
                                        <hr style={{ border: '1px solid #D9D9D9' }} />
                                        <CouponBox style={{ width: '75%', marginLeft: '10px' }}>
                                            <H5>Oppsummering</H5>
                                            <SpaceBetweenBox marginTop='15px'>
                                                <P style={{ fontWeight: '500' }}>Signatur
                                                    {`${signers?.length > 1
                                                        ? "er"
                                                        : ""
                                                        }`}
                                                    {' '}({signers?.length})
                                                </P>
                                                <P style={{ fontWeight: '500' }}>
                                                    {` ${signatureType === "electronic"
                                                        ? signers.length * PRICE_PER_SIGNING.no
                                                        : signers.length * PRICE_PER_SIGNING.manual} kr.`}
                                                </P>
                                            </SpaceBetweenBox>
                                            <SpaceBetweenBox marginTop='10px'>
                                                <P style={{ fontWeight: '500' }}>
                                                    Kontrakt
                                                </P>
                                                {templatePrice > 0 ? <P style={{ fontWeight: '500' }}>{`+${templatePrice} kr.`}</P> : <P style={{ fontWeight: '500' }}>+0 kr.</P>}
                                            </SpaceBetweenBox>
                                            {couponApplied && <>
                                                <hr style={{ border: '1px solid #D9D9D9' }} />
                                                <SpaceBetweenBox style={{ margin: 0 }}>
                                                    <span style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}>
                                                        <P_Mini_M style={{ fontWeight: '400', }}>
                                                            Subtotal
                                                        </P_Mini_M>
                                                    </span>
                                                    <P_Mini_M style={{ fontWeight: '500' }}>
                                                        {signatureType ===
                                                            "electronic"
                                                            ? (signers?.length * PRICE_PER_SIGNING.no) + parseInt(templatePrice ?? 0)
                                                            : (signers?.length * PRICE_PER_SIGNING.manual) + parseInt(templatePrice ?? 0)} kr.
                                                    </P_Mini_M>
                                                </SpaceBetweenBox>
                                                <SpaceBetweenBox marginTop='10px'>
                                                    <span style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                    }}>

                                                        <TagIcon width="20px" />
                                                        <P_Small_M style={{ fontWeight: '500', marginLeft: '10px' }}>
                                                            {couponApplied}
                                                        </P_Small_M>
                                                    </span>
                                                    <span>
                                                        <P_Small_M style={{ fontWeight: '500' }}>
                                                            -{`${discountPrice} kr.`}
                                                        </P_Small_M>
                                                    </span>
                                                </SpaceBetweenBox>
                                            </>
                                            }
                                            <hr style={{ border: '1px solid #D9D9D9', marginTop: '30px' }} />
                                            <SpaceBetweenBox marginTop='20px'>
                                                <H4>
                                                    Totalt å betale
                                                </H4>
                                                <H4 style={{ display: 'flex', justifyContent: 'end' }}>
                                                    {signatureType ===
                                                        "electronic"
                                                        ? (signers?.length * PRICE_PER_SIGNING.no) + parseInt(templatePrice ?? 0)
                                                        : (signers?.length * PRICE_PER_SIGNING.manual) + parseInt(templatePrice ?? 0) - parseInt(couponApplied ? parseInt(discountPrice) ?? 0 : 0)}{' '}
                                                    kr.
                                                </H4>

                                            </SpaceBetweenBox>
                                            <SpaceBetweenBox style={{ width: " min-content", position: "absolute", bottom: "8px", right: "8px" }}>
                                                <Button
                                                    text="Gå til betaling"
                                                    onClick={() => { startBuilding() }}
                                                    primary
                                                />
                                            </SpaceBetweenBox>

                                        </CouponBox>

                                    </CouponWrapper>
                                )}
                                {stepIs(STEP.PAYMENT) && (
                                    <>
                                        <FullscreenInnerHeader>
                                            <H5>Betaling</H5>
                                            {!completedFlows.payment && (
                                                <KeyFigure>
                                                    <H2>
                                                        {signatureType ===
                                                            "electronic"
                                                            ? (signers?.length * PRICE_PER_SIGNING.no) + parseInt(templatePrice ?? 0)
                                                            : (signers?.length * PRICE_PER_SIGNING.manual) + parseInt(templatePrice > 0 ? templatePrice - parseInt(discountPrice) : 0 ?? 0)}{" "}
                                                        kr
                                                    </H2>
                                                    <P>
                                                        {signers?.length} signatur
                                                        {`${signers?.length > 1
                                                            ? "er"
                                                            : ""
                                                            }`}
                                                        {` ${signatureType === "electronic"
                                                            ? signers.length * PRICE_PER_SIGNING.no
                                                            : signers.length * PRICE_PER_SIGNING.manual} kr. ${templatePrice > 0 ? ` og 1 kontrakt ${templatePrice - parseInt(discountPrice)} kr.` : ""}`}
                                                    </P>
                                                </KeyFigure>
                                            )}
                                            {completedFlows.payment && (
                                                <BigIconWithText>
                                                    <Icon
                                                        icon="Checkmark"
                                                        fill={Colors.green[500]}
                                                        width="122"
                                                    />
                                                    <P_Large_M>
                                                        Betaling fullført
                                                    </P_Large_M>

                                                    <Spinner
                                                        size="32"
                                                        text="Sjekker signeringsvalg og lagrer fil..."
                                                    />
                                                    {currentStatusSubText && (
                                                        <P_Small_M
                                                            style={{
                                                                textAlign: "center"
                                                            }}
                                                        >
                                                            {currentStatusSubText}
                                                        </P_Small_M>
                                                    )}
                                                </BigIconWithText>
                                            )}
                                        </FullscreenInnerHeader>
                                        {completedFlows.generateContract &&
                                            completedFlows.uploadAttachments &&
                                            completedFlows.generatePdf &&
                                            completedFlows.addSigners &&
                                            !completedFlows.payment && (
                                                <>
                                                    <Elements stripe={stripe}>
                                                        <CheckoutForm
                                                            email={
                                                                currentUser.email
                                                            }
                                                            phone={getPhoneNumberForCheckout()}
                                                            onSucceeded={
                                                                onPaymentSucceeded
                                                            }
                                                            onCancel={
                                                                onPaymentCancel
                                                            }
                                                            couponId={couponId}
                                                        />
                                                    </Elements>
                                                </>
                                            )}
                                    </>
                                )}
                                {stepIs(STEP.SIGNING) &&
                                    signatureType === "electronic" && (
                                        <FullScreenSigning>
                                            <FullscreenInnerHeader>
                                                <H6_Medium>
                                                    Fullfør signering
                                                </H6_Medium>
                                            </FullscreenInnerHeader>
                                            <SigningInFrame
                                                src={
                                                    firstSignerState.firstSignerUrl
                                                }
                                                width="100%"
                                                height="600"
                                            ></SigningInFrame>
                                            <Button
                                                onClick={onSigningCancel}
                                                secondary
                                                small
                                                text="Avbry"
                                            />
                                        </FullScreenSigning>
                                    )}
                                {stepIs(STEP.SIGNING) &&
                                    signatureType === "manual" && (
                                        <FullScreenSigning manual>
                                            <FullscreenInnerHeader>
                                                <H5 color={theme.foregroundPrimary}>
                                                    Gjennomfør signering
                                                </H5>
                                                <P_Small_M
                                                    color={theme.foregroundPrimary}
                                                >
                                                    Skann QR-koden med mobilen, og
                                                    følg instruksjonene &mdash;
                                                    eller trykk på QR-koden og
                                                    signér direkte. Signeringen
                                                    åpnes i eget vindu.
                                                </P_Small_M>
                                                <P_Small_M
                                                    color={theme.foregroundPrimary}
                                                >
                                                    Hvis du ikke kan skanne
                                                    QR-koden, kan du gå til{" "}
                                                    <strong>
                                                        app.lawyered.no/s
                                                    </strong>{" "}
                                                    og taste inn koden vist under.
                                                </P_Small_M>
                                                <P_Small_M
                                                    color={theme.foregroundPrimary}
                                                >
                                                    Hvis noen av partene ikke er
                                                    tilstede, kan du sende dem en
                                                    signeringslenke ved å trykke{" "}
                                                    <strong>Send på e-post</strong>{" "}
                                                    under.
                                                </P_Small_M>
                                            </FullscreenInnerHeader>
                                            <Centered>
                                                <QRFrames>
                                                    {isSigningShortcodesLoading && (
                                                        <Spinner
                                                            size="32"
                                                            text="Laster QR-koder..."
                                                        />
                                                    )}
                                                    {!isSigningShortcodesLoading &&
                                                        signingShortcodes &&
                                                        signingShortcodes.length &&
                                                        renderQrCodes(
                                                            signingShortcodes,
                                                            contractData.contractReadingKey
                                                        )}
                                                    {!isSigningShortcodesLoading &&
                                                        (!signingShortcodes ||
                                                            !signingShortcodes.length) && (
                                                            <Notice warning>
                                                                Det oppstod en feil.
                                                            </Notice>
                                                        )}
                                                </QRFrames>
                                            </Centered>

                                            {isWidget && (
                                                <>
                                                    <P_Small_M
                                                        color={
                                                            theme.foregroundPrimary
                                                        }
                                                    >
                                                        Når alle parter har signert,
                                                        får du kontrakten tilsendt
                                                        til e-post-adressen du oppga
                                                        tidligere.
                                                        <br />
                                                        <br />
                                                    </P_Small_M>
                                                    <Button
                                                        text="Avslutt"
                                                        onClick={(event) =>
                                                            handleEndWidget(event)
                                                        }
                                                    />
                                                </>
                                            )}
                                            {!isWidget && (
                                                <Link to="/archive">
                                                    <Button text="Ferdig" />
                                                </Link>
                                            )}
                                        </FullScreenSigning>
                                    )}
                                {stepIs(STEP.COMPLETE) && completedFlows.signing && (
                                    <FullScreenNotification>
                                        <H5>Ferdig!</H5>
                                        {isWidget && (
                                            <>
                                                <P>
                                                    Parten(e) har nå fått tilsendt
                                                    lenker for signering. Når alle
                                                    har signert, får du kontrakten
                                                    tilsendt i e-posten du oppga i
                                                    forrige steg.
                                                </P>
                                                <Button
                                                    text="Avslutt"
                                                    onClick={(event) =>
                                                        handleEndWidget(event)
                                                    }
                                                />
                                            </>
                                        )}
                                        {!isWidget && (
                                            <>
                                                <P>
                                                    Partene som ikke har signert,
                                                    har nå fått tilsendt en lenke de
                                                    kan benytte for å signere. Du
                                                    kan følge status i{" "}
                                                    <Link to="/archive">
                                                        arkivet
                                                    </Link>
                                                    .
                                                </P>
                                                <Button
                                                    onClick={(event) =>
                                                        handleElectronicSigningCompletedClick(
                                                            event
                                                        )
                                                    }
                                                    secondary
                                                    icon="Dictionary"
                                                    text="Til arkivet"
                                                />
                                            </>
                                        )}
                                    </FullScreenNotification>
                                )}
                            </FullscreenInner>
                        </Fullscreen>
                    )}
            </>
        )
    }

    return (
        <WithStatusbar>
            {isLoading && <ContractLoading asWidget={isWidget} />}
            {!isLoading && getContent()}
        </WithStatusbar>
    )
}

const Title = styled(H5)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 16px;
`

const SigningInFrame = styled.iframe`
    width: calc(100% + 32px);
    height: 500px;
    max-height: calc(100vh - 80px - 48px - 32px);
    margin-left: -16px;
    margin-right: -16px;
    border: 0;
    padding-bottom: 16px;
`

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 16px 16px 0;

    @media screen and (min-width: 768px) {
        align-items: ${(props) => (props.asWidget ? "flex-end" : "flex-start")};
        flex-direction: ${(props) => (props.asWidget ? "row" : "column")};
        padding: ${(props) =>
        props.asWidget ? "32px 46px 0 36px" : "100px 64px 0 48px"};
    }

    h5 {
        line-height: 1;
        order: 2;
        @media screen and (min-width: 768px) {
            order: 1;
        }
    }

    @media screen and (min-width: 1330px) {
        max-width: 1225px;
    }
`

const TopRow = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 16px;
    align-self: stretch;
    h5 {
        line-height: 32px;
    }
`

const WidgetMark = styled.img`
    order: 1;
    @media screen and (min-width: 768px) {
        order: 2;
    }
`

const WidgetSubtitle = styled.p`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin: 8px 0 16px 0;
    order: 1;
    svg {
        width: 110px;
        height: 18px;
    }
    span {
        margin-bottom: 2px;
    }
    @media screen and (min-width: 768px) {
        margin: 0 24px 0;
        order: 2;
        font-size: 14px;
        svg {
            width: 128px;
            height: 20px;
        }
    }
`

const TemplateContainer = styled.div`
    padding: 0 16px 84px;
    @media screen and (min-width: 768px) {
        padding: ${(props) =>
        props.asWidget ? "0 16px 116px 36px" : "0 24px 116px 48px"};
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: 16px;
    margin-top: 24px;
    @media screen and (min-width: 768px) {
        margin-right: 24px;
        padding: 16px;
    }
    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    > *:not(:last-child) {
        margin-bottom: 12px;
    }
`

const EditorBox = styled(Box)`
    padding: 24px;
    background-color: ${(props) => props.theme.backgroundTertiary};
    @media screen and (min-width: 768px) {
        padding: 24px 48px 48px;
    }
`

const HeadingWithIcon = styled.div`
    p {
        display: flex;
        align-items: center;
    }
    p svg {
        padding-right: 8px;
    }
`

const Fullscreen = styled.div`
    background-color: ${(props) => props.theme.backgroundPrimary};
    opacity: 1;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    padding-top: 16px;
    bottom: 0;
    min-height: calc(100vh - 76px - 16px);
    padding-bottom: 16px;
    margin: ${(props) => (props.isWidget ? "0" : "0 48px")};
    width: ${(props) => (props.isWidget ? "calc(100%)" : "calc(100% - 48px)")};
    @media screen and (min-width: 768px) {
        height: calc(100vh - 32px);
    }
`

const FullscreenInner = styled(Box)`
    min-width: 308px;
    // width: 96% !important;
    @media screen and (min-width: 768px) {
        width: ${(props) =>
        props.wide ? "calc(480px - 32px)" : "calc(360px - 32px)"};
    }
    transition: width 225ms ease-out;
    margin: auto 0;
    @media screen and (min-width: 768px) {
        margin: 0 !important;
    }
    background-color: ${(props) =>
        props.matchSignicatBg
            ? "#fafafa"
            : props.bg
                ? props.bg
                : props.theme.backgroundTertiary};
`

const FullScreenNotification = styled.div`
    padding: 24px 24px 16px 24px;
    h5 {
        margin-bottom: 16px;
        text-align: center;
    }
    > p {
        margin-bottom: 16px;
    }
`

const FullScreenSigning = styled.div`
    padding: 0 8px;
    @media screen and (min-width: 768px) {
        padding: 24px 24px 16px 24px;
    }

    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
`

const BigIconWithText = styled.div`
    svg {
        margin: 24px 0;
    }
`

const FullscreenInnerHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: 24px 0;
    align-items: center;
    text-align: center;
    > p {
        margin-top: 8px;
    }
`

const KeyFigure = styled.div`
    text-align: center;
    h2 {
        margin-bottom: 8px;
    }
    p {
        color: ${Colors.gray[500]};
    }
`

const CreatorWrapper = styled.div`
    display: ${(props) => (props.visible ? "flex" : "none")};
    flex: 1;
    flex-direction: ${(props) => (props.horizontalOnMobile ? "row" : "column")};
    @media screen and (min-width: 768px) {
        flex-direction: ${(props) => (props.asWidget ? "row" : "column")};
    }
    @media screen and (min-width: 1330px) {
        flex-direction: row;
    }
`

const Column = styled.div`
    flex: 1 1 auto;
    flex-direction: column;
    display: ${(props) => (props.visible ? "flex" : "none")};
`

const ColumnWide = styled(Column)`
    max-width: 100%;
    @media screen and (min-width: 1330px) {
        width: 62%;
        max-width: 905px;
        min-width: 580px;
    }
`

const ColumnNarrow = styled(Column)`
    max-width: ${(props) => (props.vertical ? "350px" : "none")};
    @media screen and (min-width: 1330px) {
        max-width: 350px;
        min-width: 350px;
    }
`

const ColumnNarrowPlus = styled(Column)`
    min-width: 100%;
    @media screen and (min-width: 768px) {
        max-width: ${(props) => (props.asWidget ? "390px" : "none")};
        min-width: ${(props) => (props.asWidget ? "390px" : "100%")};
    }
    @media screen and (min-width: 1330px) {
        max-width: 390px;
        min-width: 390px;
    }
`

const ContentBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media screen and (min-width: 768px) {
        flex-direction: ${(props) => (props.vertical ? "column" : "row")};
    }
    @media screen and (min-width: 1330px) {
        flex-direction: column;
        justify-content: flex-start;
    }
    > * {
        flex-basis: ${(props) => (props.narrow ? "calc(50% - 48px)" : "100%")};
    }
`

const WithStatusbar = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
`

const statusbarHPadding = 48

const Statusbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;

    padding: 0px ${statusbarHPadding / 2}px;
    @media screen and (min-width: 768px) {
        padding: 16px ${statusbarHPadding}px;
    }
    @media screen and (min-width: 900px) {
        padding: 16px ${statusbarHPadding}px;
    }
    @media screen and (min-width: 1024px) {
        padding: 16px ${statusbarHPadding}px;
    }

    flex: 0;
    background-color: ${(props) => props.theme.backgroundSecondary};
    position: fixed;
    height: 68px;
    bottom: 0;
    right: 0;

    width: calc(100% - ${statusbarHPadding}px);
    @media screen and (min-width: 768px) {
        width: ${(props) =>
        props.asWidget
            ? `calc(100% - ${statusbarHPadding * 2}px)`
            : `calc(100% - ${statusbarHPadding * 2}px - 194px)`};
    }
    @media screen and (min-width: 900px) {
        width: ${(props) =>
        props.asWidget
            ? `calc(100% - ${statusbarHPadding * 2}px)`
            : `calc(100% - ${statusbarHPadding * 2}px - 194px)`};
    }
    @media screen and (min-width: 1024px) {
        width: ${(props) =>
        props.asWidget
            ? `calc(100% - ${statusbarHPadding * 2}px)`
            : `calc(100% - ${statusbarHPadding * 2}px - 288px)`};
    }

    ul {
        display: none;
    }
    @media screen and (min-width: 768px) {
        ul {
            display: flex;
        }
    }
`

const Buttons = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
        flex: 0;
    }
    > * {
        margin-right: 16px;
    }
`

const Centered = styled.div`
    display: flex;
    justify-content: center;
`

const QRFrames = styled.div`
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 32px;
    flex-wrap: wrap;
    width: 100%;
    background-color: ${(props) => props.theme.backgroundPrimary};
    border-radius: 8px;
`

const QRFrame = styled.div`
    display: flex;
    flex-direction: column;
    margin: 16px;
    flex: 0;
    width: 128px;
    text-decoration: none;
    border-radius: 8px;
    a {
        border-radius: 8px;
    }
    a:hover {
        background-color: ${Colors.white};
    }
`

const QR = styled.div`
    width: 128px;
    border-radius: 8px;
    overflow: hidden;
    /*background-color: ${Colors.gray[50]};*/
    code {
        font-family: inherit;
        text-align: center;
        display: block;
        padding-bottom: 16px;
        font-size: 16px;
        font-weight: 500;
        color: ${(props) => props.theme.foregroundPrimary};
    }
`

const QRMeta = styled.div`
    padding: 8px 14px;
    text-align: center;
`

const WidgetTargetEmailLabel = styled(P_Small_M)`
    margin-bottom: 4px;
`

const WidgetTargetEmailWrapper = styled.div`
    margin: 16px 0 0;
    input {
        font-size: 0.9rem;
        margin-bottom: 8px;
    }
    button {
        margin-bottom: 8px;
    }
`
const CouponWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin: 0;
  box-shadow: none;
  @media (max-width: 1024px) {
    flex-direction: column;
    // overflow: scroll;
    max-height: auto;
  }
`;

const CouponBox = styled.div`
  margin: 0;
  height: 24rem;
  box-shadow: none;
  padding: 10px;
  position: relative;
  @media (max-width: 1024px) {
    height: 16rem;
    width: 90% !important;
    margin: 0px !important;
    padding: 5px;
}
`;

const CouponFormWrapper = styled.div`
  margin-right: 60px;
  margin-top: 8px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  @media (max-width: 1024px) {
    flex-direction: column;
    overflow: auto;
    div{
        margin-left: 0px !important;
        width: 100% !important;
    }
    button{
        margin-top: 20px;
        font-size: 12px !important;
    }
  }
`;
const AppliedCouponWrapper = styled.div`
  width: 44%;
  padding: 12px 20px 12px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  color: #5B4379;
  margin-top: 4px;
  background: #DCB9FF;
`;

const StyledImage = styled.img`
  width: 175px;
  margin-left: 60px;
  margin-top: 70px;
  position: absolute;
  top: 75px;
  display: block;
  
  @media (max-width: 1024px) {
      display: none;
  }
`;


export default CreateContract
