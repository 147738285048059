import { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import Notice from "../notice"
import Spinner from "../Spinner"
import { P_Medium, P_Mini } from "../text"

const FileUploader = ({
    actionText = "Dra og slipp en fil her, eller...",
    add = () => { },
    containerStyles = {},
    description = "Maks 10 MB; JPG eller PNG",
    acceptedMimeTypes = "image/jpeg, image/png",
    maxFilesAccepted = 10
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            add(file)
        })
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        fileRejections
    } = useDropzone({
        onDrop,
        accept: acceptedMimeTypes,
        maxFiles: maxFilesAccepted,
        maxSize: 10485760,
        multiple: maxFilesAccepted > 1 ? true : false
    })

    const [uploading, setUploading] = useState(false)

    let errorIndex = 0
    const fileRejectionItems = fileRejections.map(({ file, errors }) => {
        const errorsArray = errors.map((e) => e.message)
        return (
            <Notice
                key={`file-error-${errorIndex++}`}
                error
                title={`${file.path}`}
                text={errorsArray.join("; ")}
                fillWidth
            />
        )
    })

    if (uploading) {
        return (
            <Container style={containerStyles}>
                <SpinnerContainer>
                    <Spinner size="24" />
                </SpinnerContainer>
                <Title>{"Laster opp..."}</Title>
            </Container>
        )
    } else {
        return (
            <Container
                style={containerStyles}
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
                <Input type="file" name="file" id="file" {...getInputProps()} />
                <Icon icon={"Document"} fill={Colors.gray[400]} />
                <Title>{actionText}</Title>
                <Label htmlFor="file">{"Velg en fil"}</Label>
                <Description>{description}</Description>
                <>{fileRejectionItems}</>
            </Container>
        )
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border: 2px dashed ${Colors.gray[300]};
    box-sizing: border-box;
    border-radius: 8px;

    &:hover {
        border: 2px dashed ${(props) => props.theme.foregroundHover};
    }
`

const SpinnerContainer = styled.div`
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`

const Title = styled(P_Medium)`
    color: ${Colors.black};
    margin: 12px 0 8px 0;
`

const Description = styled(P_Mini)`
    color: ${Colors.gray[400]};
    margin: 8px 0 0 0;
`

const Input = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`

const Label = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    background-color: ${(props) => props.theme.backgroundSecondary};
    border-radius: 8px;
    border: none;
    color: ${(props) => props.theme.foregroundSecondary};
    font-weight: 500;
`

export default FileUploader
