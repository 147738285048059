import React from "react";

const Mail = ({ fill }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8333 0.333313H3.16663C2.50358 0.333313 1.8677 0.596705 1.39886 1.06555C0.930018 1.53439 0.666626 2.17027 0.666626 2.83331V11.1666C0.666626 11.8297 0.930018 12.4656 1.39886 12.9344C1.8677 13.4033 2.50358 13.6666 3.16663 13.6666H14.8333C15.4963 13.6666 16.1322 13.4033 16.6011 12.9344C17.0699 12.4656 17.3333 11.8297 17.3333 11.1666V2.83331C17.3333 2.17027 17.0699 1.53439 16.6011 1.06555C16.1322 0.596705 15.4963 0.333313 14.8333 0.333313ZM14.4916 1.99998L9.59163 6.89998C9.51416 6.97809 9.42199 7.04008 9.32044 7.08239C9.21889 7.1247 9.10997 7.14648 8.99996 7.14648C8.88995 7.14648 8.78103 7.1247 8.67948 7.08239C8.57793 7.04008 8.48576 6.97809 8.40829 6.89998L3.50829 1.99998H14.4916ZM15.6666 11.1666C15.6666 11.3877 15.5788 11.5996 15.4225 11.7559C15.2663 11.9122 15.0543 12 14.8333 12H3.16663C2.94561 12 2.73365 11.9122 2.57737 11.7559C2.42109 11.5996 2.33329 11.3877 2.33329 11.1666V3.17498L7.23329 8.07498C7.70204 8.54315 8.33746 8.80611 8.99996 8.80611C9.66246 8.80611 10.2979 8.54315 10.7666 8.07498L15.6666 3.17498V11.1666Z"
        fill={fill}
      />
    </svg>
  );
};

export default Mail;
