import { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { FirebaseAuth } from "../providers/AuthProvider"
import { getAuthUserData } from "../utils/api"

import Colors from "../assets/colors"

import { H5, P_Medium, P_Small_M, Overline } from "../components/text"
import ActionCard from "../components/actionCard"
import Button from "../components/button"
import Contract from "../components/contract"
import WithSidebarMainContent from "../components/withSidebarMainContent"

import { DashBoardLoading } from "./Loading"

import { useContracts } from "../hooks/useContracts"
import { buildSignersString } from "../utils/stringHelpers"
import { renderError } from "../utils/errors"
import Notice from "../components/notice"

const DashboardLanding = ({ path, onItemPressed = (itemKey) => {} }) => {
    const { contracts, isLoading, isError } = useContracts()
    const { currentUser, token } = useContext(FirebaseAuth)

    const [userData, setUserData] = useState(null)
    const [user, setUser] = useState({})

    const [hasAnonymousContracts, setHasAnonymousContracts] = useState(false)

    useEffect(() => {
        async function getUser() {
            const userData = await getAuthUserData(token)
            const dbData = userData?.userData?.dbData || setUserData(userData)
            setUser({
                name: dbData?.name || null,
                email: dbData?.email || currentUser.email || null,
                phoneNumber:
                    dbData?.phoneNumber || currentUser.phoneNumber || null
            })
        }

        const anonContracts = localStorage.getItem(
            "lawyered.anonymous_contracts"
        )

        if (anonContracts && currentUser.isAnonymous) {
            setHasAnonymousContracts(true)
        }

        if (token) {
            getUser()
        }
    }, [])

    const getTitle = () => {
        const date = new Date()
        let prefix = ""
        if (date.getHours() < 7) {
            prefix = "God natt"
        } else if (date.getHours() < 10) {
            prefix = "God morgen"
        } else if (date.getHours() < 20) {
            prefix = "God dag"
        } else {
            prefix = "God kveld"
        }
        return prefix + (user.name ? `, ${user.name.split(" ")[0]}` : "") + "!"
    }

    const renderCreateButton = () => {
        return (
            <Link to="/create" className="hidden-sm">
                <Button text={"Opprett ny kontrakt"} small secondary />
            </Link>
        )
    }

    const renderContracts = () => {
        return (
            <div>
                <ContractsTitle>SIST BRUKTE KONTRAKTER</ContractsTitle>
                <Row>
                    {contracts.slice(0, 3).map((item, index) => {
                        const signers = buildSignersString(item.signers)
                        const created = new Date(item.created)
                        return (
                            <Link
                                key={`contract-${index}`}
                                to={`/archive/${item.id}`}
                            >
                                <Contract
                                    date={created.toLocaleString("nb-NO", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    })}
                                    description={signers}
                                    status={
                                        item.signing?.status?.documentStatus
                                    }
                                    title={item.title}
                                />
                            </Link>
                        )
                    })}
                </Row>
            </div>
        )
    }

    const getContent = () => {
        return (
            <>
                <TopContainer>
                    <Header>
                        <Title>{getTitle()}</Title>
                        {hasAnonymousContracts && (
                            <Notice
                                warning
                                title={"Du har kontrakter som ikke er lagret"}
                                text={
                                    "Det ser ut til at du har laget kontrakter når du ikke var innlogget. Lag en konto eller logg inn for å sikre at ikke disse går tapt."
                                }
                            />
                        )}
                        {isError &&
                            isError.code !== 404 &&
                            renderError(isError)}
                        {(!contracts || contracts.length === 0) && (
                            <Subtitle>Du har ingen kontrakter enda.</Subtitle>
                        )}
                        {(!contracts || contracts.length === 0) &&
                            renderCreateButton()}
                        {contracts && contracts.length > 0 && renderContracts()}
                    </Header>
                    <Link to="/create">
                        <Action>
                            <NewContract>Ny kontrakt</NewContract>
                            <Button className="fixed_size" icon={"Plus"} />
                        </Action>
                    </Link>
                </TopContainer>

                <BottomContainer>
                    <SecondTitle>Kom i gang med Lawyered</SecondTitle>

                    <Row>
                        {/*
                        <ActionCard
                            key="action-card-1"
                            icon={"Presentation"}
                            title={"Se demo"}
                            description={"Beregnet tid: 30 sekunder"}
                            onClick={() => onItemPressed("Demo")}
                        />
                        */}
                        <Link to="/create">
                            <ActionCard
                                key="action-card-2"
                                icon={"Plus"}
                                title={"Opprett en kontrakt"}
                                description={"Beregnet tid: 1 minutt"}
                            />
                        </Link>
                        <Link to="/upload">
                            <ActionCard
                                key="action-card-3"
                                icon={"Upload"}
                                title={"Last opp kontrakt"}
                                description={"Last opp egen PDF"}
                            />
                        </Link>
                        {/*
                        <ActionCard
                            key="action-card-3"
                            icon={"Mobile"}
                            title={"Last ned mobilappen"}
                            description={"Beregnet tid: 1 minutt"}
                            onClick={() => onItemPressed("MobileApp")}
                        />
                        <ActionCard
                            key="action-card-4"
                            icon={"Star"}
                            title={"Bli betatester"}
                            description={"Beregnet tid: 1 minutt"}
                            onClick={() => onItemPressed("Beta")}
                        />
                        <ActionCard
                            key="action-card-5"
                            icon={"Newspaper"}
                            title={"Les bloggen vår"}
                            description={"Beregnet tid: 1 minutt"}
                            onClick={() => onItemPressed("Blog")}
                        />
                        */}
                    </Row>
                </BottomContainer>
            </>
        )
    }

    return (
        <WithSidebarMainContent>
            {isLoading && <DashBoardLoading />}
            {!isLoading && getContent()}
        </WithSidebarMainContent>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }

    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }

    a button:not(.fixed_size) {
        width: auto;
    }

    .hidden-sm {
        display: none;
        @media screen and (min-width: 768px) {
            display: block;
        }
    }
`

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 32px 48px 48px;
    }
    background-color: ${(props) => props.theme.backgroundSecondary};
`

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;

    > * {
        margin-bottom: 16px;
    }

    > *:not(:last-child) {
        margin-right: 16px;
    }

    > * {
        margin-bottom: 16px;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: column;
    }
    h5 {
        max-width: 85%;
    }
`

const Action = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    align-items: center;
    grid-gap: 16px;
    p {
        white-space: nowrap;
    }
`

const Title = styled(H5)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 8px;
`

const Subtitle = styled(P_Medium)`
    color: ${(props) => props.theme.foregroundSecondary};
    line-height: 32px;
    margin-bottom: 16px;
`

const SecondTitle = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    line-height: 32px;
    margin-bottom: 16px;
`

const NewContract = styled(P_Small_M)`
    color: ${(props) => props.theme.foregroundPrimary};
`

const ContractsTitle = styled(Overline)`
    color: ${Colors.black};
    margin: 24px 0 8px;
`

export default DashboardLanding
