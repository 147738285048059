import { isObjectEmpty } from "./objectHelpers"

export const buildQueryStringForClientAuth = () => {
    const query = location.search
    const params = new URLSearchParams(query)

    let relevantParams = {}
    if (params.has("client_id")) {
        relevantParams.client_id = params.get("client_id")
    }
    if (params.has("api_key")) {
        relevantParams.api_key = params.get("api_key")
    }
    if (params.has("external_reference")) {
        relevantParams.external_reference = params.get("external_reference")
    }

    if (!isObjectEmpty(relevantParams)) {
        return "?" + new URLSearchParams(relevantParams).toString()
    }
    return ""
}
