import useSWR from "swr"
import { apiUrl, fetcher } from "./fetcher"

function useTemplates() {
    const { data, error } = useSWR(`${apiUrl}/templates`, fetcher)

    return {
        templates: data,
        isLoading: !error && !data,
        isError: error
    }
}

function useTemplate(template_name) {
    if (template_name) {
        const { data, error } = useSWR(
            `${apiUrl}/templates?name=${template_name}`,
            fetcher
        )

        return {
            template: data,
            isLoading: !error && !data,
            isError: error
        }
    }

    return {
        template: null,
        isLoading: false,
        isError: null
    }
}

export { useTemplates, useTemplate }
