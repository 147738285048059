import React from "react";

const Presentation = ({ fill }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 24H36V4H38C38.5304 4 39.0391 3.78929 39.4142 3.41421C39.7893 3.03914 40 2.53043 40 2C40 1.46957 39.7893 0.960859 39.4142 0.585786C39.0391 0.210714 38.5304 0 38 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2C0 2.53043 0.210714 3.03914 0.585786 3.41421C0.960859 3.78929 1.46957 4 2 4H4V24H2C1.46957 24 0.960859 24.2107 0.585786 24.5858C0.210714 24.9609 0 25.4696 0 26C0 26.5304 0.210714 27.0391 0.585786 27.4142C0.960859 27.7893 1.46957 28 2 28H18V30.3L8.9 36.3C8.52667 36.5311 8.23976 36.8786 8.08354 37.289C7.92732 37.6993 7.91047 38.1496 8.03559 38.5705C8.16071 38.9914 8.42085 39.3594 8.77587 39.6177C9.13089 39.8761 9.56105 40.0104 10 40C10.3932 40.0045 10.7779 39.8856 11.1 39.66L18 35.1V38C18 38.5304 18.2107 39.0391 18.5858 39.4142C18.9609 39.7893 19.4696 40 20 40C20.5304 40 21.0391 39.7893 21.4142 39.4142C21.7893 39.0391 22 38.5304 22 38V35.1L28.9 39.66C29.2221 39.8856 29.6068 40.0045 30 40C30.4273 39.9967 30.8423 39.8566 31.1842 39.6003C31.5261 39.344 31.7769 38.9849 31.8999 38.5757C32.0229 38.1665 32.0116 37.7286 31.8677 37.3263C31.7237 36.924 31.4547 36.5783 31.1 36.34L22 30.34V28H38C38.5304 28 39.0391 27.7893 39.4142 27.4142C39.7893 27.0391 40 26.5304 40 26C40 25.4696 39.7893 24.9609 39.4142 24.5858C39.0391 24.2107 38.5304 24 38 24ZM32 24H8V4H32V24ZM15.22 20.52C15.7531 20.8349 16.3609 21.0011 16.98 21.0011C17.5991 21.0011 18.2069 20.8349 18.74 20.52L24.74 17.04C25.2529 16.7252 25.6766 16.2841 25.9705 15.7589C26.2644 15.2337 26.4187 14.6419 26.4187 14.04C26.4187 13.4381 26.2644 12.8463 25.9705 12.3211C25.6766 11.7959 25.2529 11.3548 24.74 11.04L18.74 7.56C18.2069 7.24506 17.5991 7.07893 16.98 7.07893C16.3609 7.07893 15.7531 7.24506 15.22 7.56C14.6829 7.8628 14.2376 8.30518 13.9313 8.84033C13.625 9.37548 13.469 9.98349 13.48 10.6V17.56C13.4833 18.163 13.646 18.7544 13.9516 19.2742C14.2572 19.794 14.6948 20.2238 15.22 20.52ZM17.48 11.36L22 14L17.44 16.64L17.48 11.36Z" fill={fill || "#4C1D95"} />
        </svg>
    );
};

export default Presentation;
