import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import { P_Small_M } from "../text"

const Tab = ({ onClick = () => { }, selected, title, icon }) => {
    return (
        <Container onClick={onClick} selected={selected}>
            <Title selected={selected}>
                {icon && <Icon icon={icon} width="12" />}
                {icon && " "}
                {title}
            </Title>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    background-color: ${(props) =>
        props.selected ? props.theme.backgroundSecondary : "transparent"};
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
`

const Title = styled(P_Small_M)`
    color: ${(props) =>
        props.selected ? props.theme.foregroundSecondary : Colors.gray[500]};
    margin: 0;
    white-space: nowrap;
`

export default Tab
