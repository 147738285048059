import React from "react"

const Checkmark = ({ fill, width = 10 }) => {
    const height = width * (8 / 10)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 10 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.46659 0.800006C9.19992 0.533339 8.79992 0.533339 8.53325 0.800006L3.53325 5.80001L1.46659 3.73334C1.19992 3.46667 0.799919 3.46667 0.533252 3.73334C0.266585 4.00001 0.266585 4.40001 0.533252 4.66667L3.06659 7.20001C3.19992 7.33334 3.33325 7.40001 3.53325 7.40001C3.73325 7.40001 3.86659 7.33334 3.99992 7.20001L9.46659 1.73334C9.73325 1.46667 9.73325 1.06667 9.46659 0.800006Z"
                fill={fill || "white"}
            />
        </svg>
    )
}

export default Checkmark
