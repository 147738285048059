import React from "react"

const House = ({ fill }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4402 5.83326L7.44016 0.499929C7.3185 0.39305 7.1621 0.334106 7.00016 0.334106C6.83822 0.334106 6.68182 0.39305 6.56016 0.499929L0.560161 5.83326C0.458185 5.92347 0.386273 6.04274 0.354094 6.17503C0.321914 6.30732 0.331011 6.44629 0.380161 6.57326C0.428506 6.69855 0.513564 6.80632 0.624193 6.88245C0.734823 6.95859 0.865867 6.99953 1.00016 6.99993H1.66683V12.9999C1.66683 13.1767 1.73707 13.3463 1.86209 13.4713C1.98711 13.5964 2.15668 13.6666 2.33349 13.6666H11.6668C11.8436 13.6666 12.0132 13.5964 12.1382 13.4713C12.2633 13.3463 12.3335 13.1767 12.3335 12.9999V6.99993H13.0002C13.1345 6.99953 13.2655 6.95859 13.3761 6.88245C13.4868 6.80632 13.5718 6.69855 13.6202 6.57326C13.6693 6.44629 13.6784 6.30732 13.6462 6.17503C13.614 6.04274 13.5421 5.92347 13.4402 5.83326ZM7.66683 12.3333H6.33349V10.3333C6.33349 10.1565 6.40373 9.98688 6.52876 9.86186C6.65378 9.73683 6.82335 9.6666 7.00016 9.6666C7.17697 9.6666 7.34654 9.73683 7.47157 9.86186C7.59659 9.98688 7.66683 10.1565 7.66683 10.3333V12.3333ZM11.0002 12.3333H9.00016V10.3333C9.00016 9.80283 8.78945 9.29412 8.41438 8.91905C8.0393 8.54398 7.53059 8.33326 7.00016 8.33326C6.46973 8.33326 5.96102 8.54398 5.58595 8.91905C5.21087 9.29412 5.00016 9.80283 5.00016 10.3333V12.3333H3.00016V6.99993H11.0002V12.3333ZM2.75349 5.6666L7.00016 1.89326L11.2468 5.6666H2.75349Z" fill="#374151" />
        </svg>
    )
}

export default House
