import styled from "styled-components"
import parse from "html-react-parser"
import DOMPurify from "dompurify"

import { P_Large_M, P } from "../components/text"
import Icon from "../components/icon"
import Colors from "../assets/colors"

const InfoBox = ({
    title = "Info",
    icon = "Info",
    info = null,
    description = null,
    descriptionExtended = null
}) => {
    const getText = (text) => {
        if (/<\/?[a-z][\s\S]*>/i.test(text)) {
            return parse(DOMPurify.sanitize(text))
        }
        return <P>{text}</P>
    }
    return (
        <Box>
            <HeadingWithIcon>
                <P_Large_M>
                    {icon && <Icon icon={icon} />}
                    {title}
                </P_Large_M>
            </HeadingWithIcon>
            <Content>
                {descriptionExtended && getText(descriptionExtended)}
                {info && getText(info)}
                {!info && !descriptionExtended && getText(description)}
            </Content>
        </Box>
    )
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: ${(props) => (props.big ? "48px" : "16px")};
    margin-top: 24px;
    @media screen and (min-width: 768px) {
        margin-right: 24px;
    }
    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    > * {
        margin-bottom: 12px;
    }
    p {
        margin-top: 0;
    }
    ul {
        margin-left: 24px;
        margin-top: 0;
        padding: 0 12px 0 0;
        li {
            line-height: 28px;
        }
    }
`

const Content = styled.div`
    line-height: 24px;
`

const HeadingWithIcon = styled.div`
    p {
        display: flex;
        align-items: center;
    }
    p svg {
        padding-right: 8px;
    }
`

export default InfoBox
