import React from "react"
import Colors from "../colors"

const Heart = ({ fill }) => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4401 1.3334C11.7334 0.624875 10.7966 0.19243 9.799 0.114183C8.80135 0.0359362 7.80861 0.317047 7.0001 0.906736C6.15187 0.275828 5.0961 -0.0102562 4.04538 0.106094C2.99467 0.222445 2.02706 0.732588 1.33742 1.53379C0.647773 2.335 0.287317 3.36775 0.328636 4.42408C0.369956 5.48041 0.809981 6.48185 1.5601 7.22674L5.7001 11.3734C6.04678 11.7146 6.51369 11.9058 7.0001 11.9058C7.48651 11.9058 7.95342 11.7146 8.3001 11.3734L12.4401 7.22674C13.2185 6.44358 13.6554 5.38425 13.6554 4.28007C13.6554 3.17589 13.2185 2.11656 12.4401 1.3334ZM11.5001 6.30674L7.3601 10.4467C7.31299 10.4943 7.25692 10.5321 7.19513 10.5578C7.13334 10.5836 7.06705 10.5969 7.0001 10.5969C6.93315 10.5969 6.86687 10.5836 6.80508 10.5578C6.74328 10.5321 6.68721 10.4943 6.6401 10.4467L2.5001 6.28674C1.97727 5.7523 1.68451 5.03438 1.68451 4.28674C1.68451 3.53909 1.97727 2.82117 2.5001 2.28674C3.03287 1.76073 3.75142 1.46578 4.5001 1.46578C5.24879 1.46578 5.96733 1.76073 6.5001 2.28674C6.56208 2.34922 6.63581 2.39882 6.71705 2.43266C6.79829 2.46651 6.88543 2.48394 6.97344 2.48394C7.06144 2.48394 7.14858 2.46651 7.22982 2.43266C7.31106 2.39882 7.38479 2.34922 7.44677 2.28674C7.97954 1.76073 8.69808 1.46578 9.44677 1.46578C10.1955 1.46578 10.914 1.76073 11.4468 2.28674C11.9768 2.81417 12.2792 3.5282 12.2891 4.27586C12.2991 5.02352 12.0159 5.74536 11.5001 6.28674V6.30674Z" fill={fill || Colors.gray[700]} />
        </svg>
    )
}

export default Heart
