import { useState } from "react"
import styled, { useTheme } from "styled-components"

import Colors from "../../assets/colors"
import { H5, P, P_Large_M } from "../../components/text"

import Button from "../../components/button"
import Icon from "../../components/icon"
import InputGroup from "../../components/inputGroup"
import Step from "../../components/step"
import Feedback from "../../components/feedback"
import { Link } from "react-router-dom"

const Summary = ({
    onSubmit = () => { },
    onStepPressed = (stepIndex) => { },
    name,
    organizationNumber,
    phoneNumber,
    email,
    isCompanyDuplicate = false,
    children
}) => {
    const theme = useTheme()
    const [companyInfo, setCompanyInfo] = useState({
        name,
        orgNo: organizationNumber,
        phoneNumber,
        email
    })

    const handleFieldChange = (event) => {
        setCompanyInfo({
            ...companyInfo,
            [event.target.name]: event.target.value
        })
    }

    const handleOnSubmit = () => {
        onSubmit(companyInfo)
    }

    return (
        <Container>
            <UpperContainer>
                <InnerContainer>
                    <Title>{"Bekreft opplysningene"}</Title>
                    <Message>{"Stemmer opplysningene vi fant?"}</Message>
                    <Row>
                        <Icon icon={"Building"} fill={theme.foregroundPrimary} />
                        <Subtitle>{"Bedriftsinfo"}</Subtitle>
                    </Row>
                    <InputGroup
                        title={"Navn på bedriften"}
                        value={companyInfo.name}
                        name="name"
                        onChange={handleFieldChange}
                    />
                    <InputGroup
                        title={"Organisasjonsnummer"}
                        value={companyInfo.orgNo}
                        name="orgNo"
                        onChange={handleFieldChange}
                        readonly={!!organizationNumber}
                    />

                    <Row>
                        <Icon icon={"Call"} fill={theme.foregroundPrimary} />
                        <Subtitle>{"Bedriftsinfo"}</Subtitle>
                    </Row>
                    <InputGroup
                        title={"Telefonnummer"}
                        value={companyInfo.phoneNumber}
                        name="phoneNumber"
                        onChange={handleFieldChange}
                    />
                    <InputGroup
                        title={"E-post"}
                        value={companyInfo.email}
                        name="email"
                        onChange={handleFieldChange}
                    />
                    <Button
                        text={"Fullfør"}
                        onClick={handleOnSubmit}
                        disabled={isCompanyDuplicate}
                    />
                    {children}
                    {isCompanyDuplicate && (
                        <>
                            <Feedback
                                type="warning"
                                description="Denne bedriften er allerede registrert hos Lawyered. Dersom dette er feil, ta gjerne kontakt med oss
                                slik at vi kan finne ut av det."
                            />
                            <Link to={"/dashboard"}>
                                <Button text="Gå til din personlige brukerkonto" />
                            </Link>
                        </>
                    )}
                </InnerContainer>
            </UpperContainer>
            <BottomContainer>
                <Step
                    count={1}
                    description={"Velg konto"}
                    filled={true}
                    onClick={() => onStepPressed(1)}
                />
                <Step
                    count={2}
                    description={"Finn bedriften din"}
                    filled={true}
                    onClick={() => onStepPressed(2)}
                />
                <Step
                    count={3}
                    description={"Bekreft opplysningene"}
                    filled={true}
                    onClick={() => onStepPressed(3)}
                />
            </BottomContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

const UpperContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const BottomContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 36px 0;
    background-color: ${Colors.white};

    > * {
        width: calc(33% - 16px);
        margin: 0 8px;
    }

    @media screen and (min-width: 768px) {
        > * {
            width: 168px;
            margin: 0 16px;
        }
    }
`

const InnerContainer = styled.div`
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    margin: 12px;

    > * {
        width: 100%;
    }

    > :nth-child(2),
    > :nth-child(5),
    > :nth-child(8) {
        margin-bottom: 24px;
    }

    > *:not(:nth-child(2)):not(:nth-child(5)):not(:nth-child(8)):not(:last-child) {
        margin-bottom: 8px;
    }

    @media screen and (min-width: 768px) {
        margin: 0;
        > *:nth-child(3) {
            width: 400px;
        }
    }
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > *:nth-child(1) {
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundSecondary};
    text-align: center;
`

const Message = styled(P)`
    color: ${Colors.gray[500]};
    text-align: center;
`

const Subtitle = styled(P_Large_M)`
    color: ${(props) => props.theme.foregroundPrimary};
`

export default Summary
