import firebase from "firebase"
import jwt_decode from "jwt-decode"
import { identifyAuthTypeForUser } from "../utils/api"

export const authMethods = {
    signupPhone: (
        phoneNumber,
        setConfirmationResultRef,
        setIsOtpSent,
        setIsLoading,
        setErrors
    ) => {
        setIsLoading(true)
        var appVerifier = window.recaptchaVerifier
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
                console.log("SMS with OTP sent to " + phoneNumber)
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResultRef(confirmationResult)
                setIsOtpSent(true)
                setIsLoading(false)
                localStorage.setItem("lawyered.user.phoneNumber", phoneNumber)
            })
            .catch(function (error) {
                console.error(error)
                setIsLoading(false)
                setErrors((prev) => [
                    ...prev,
                    { message: error.message, code: error.code }
                ])
            })
    },
    sendOtpForLinking: (
        phoneNumber,
        setConfirmationResultRef,
        setIsOtpSent,
        setIsLoading,
        setErrors
    ) => {
        setIsLoading(true)
        var appVerifier = window.recaptchaVerifier
        firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(function (confirmationResult) {
                console.log("SMS with OTP sent to " + phoneNumber)
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResultRef(confirmationResult)
                setIsOtpSent(true)
                setIsLoading(false)
                localStorage.setItem("lawyered.user.phoneNumber", phoneNumber)
            })
            .catch(function (error) {
                console.error(error)
                setIsLoading(false)
                setErrors((prev) => [
                    ...prev,
                    { message: error.message, code: error.code }
                ])
            })
    },
    confirmOtpForLinking: async (
        otp,
        confirmationResultRef,
        currentUser,
        setIsLoading,
        setLinkingCompleted,
        setErrors
    ) => {
        setIsLoading(true)
        const credential = firebase.auth.PhoneAuthProvider.credential(
            confirmationResultRef.verificationId,
            otp
        )
        currentUser
            .linkWithCredential(credential)
            .then((userCredential) => {
                console.log("Linking completed!", userCredential)
                setIsLoading(false)
                setLinkingCompleted(true)
            })
            .catch((error) => {
                console.error(`Linking failed`, error)
                setIsLoading(false)
                setErrors((prev) => [
                    ...prev,
                    { message: error.message, code: error.code }
                ])
            })
    },
    confirmOtp: async (
        otp,
        phoneNumber,
        token,
        confirmationResultRef,
        currentUser,
        setApproved,
        setIsAnonymous,
        setToken,
        setIsLoading,
        setErrors
    ) => {
        setIsLoading(true)
        const preAuthCheck = await identifyAuthTypeForUser(token, phoneNumber)
        if (preAuthCheck.hasVerifiedPhoneNumber) {
            // The user exists with this phone number, we do a "normal" login
            console.log(`confirmOtp::preAuthCheck::user exists, logging in...`)
            confirmationResultRef
                .confirm(otp)
                .then(function (result) {
                    // User signed in successfully.
                    var user = result.user
                    console.log("Signed in successfully", user)
                    user.getIdToken().then((idToken) => {
                        localStorage.setItem("lawyered.user.token", idToken)
                        localStorage.setItem("lawyered.user.isAnonymous", false)
                        setToken(idToken)
                        setApproved(true)
                        setIsAnonymous(false)
                        setIsLoading(false)
                    })
                })
                .catch(function (error) {
                    setIsLoading(false)
                    setErrors((prev) => [
                        ...prev,
                        { message: error.message, code: error.code }
                    ])
                    console.error(
                        "Error while checking the verification code",
                        error
                    )
                })
        } else {
            // Couldn't find the user (or it's not a verified one) - go ahead with registration/linking
            console.log(
                `confirmOtp::preAuthCheck::user does not exist, linking with anon account...`
            )
            const credential = firebase.auth.PhoneAuthProvider.credential(
                confirmationResultRef.verificationId,
                otp
            )
            const userCred = await currentUser.linkWithCredential(credential)
            const user = userCred.user
            console.log("Anonymous account successfully upgraded", user)
            user.getIdToken()
                .then((idToken) => {
                    if(idToken){
                        localStorage.setItem("lawyered.user.token", idToken)
                    }
                    localStorage.setItem("lawyered.user.isAnonymous", false)
                    setToken(idToken)
                    setApproved(true)
                    setIsAnonymous(false)
                    setIsLoading(false)
                })
                .catch(function (error) {
                    setIsLoading(false)
                    setErrors((prev) => [
                        ...prev,
                        { message: error.message, code: error.code }
                    ])
                    console.error("Error *** ", error)
                })
        }
    },
    signupEmail: (email, setIsLoading, setErrors, setIsSignupEmailSent) => {
        const redirectTo =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
        setIsLoading(true)
        firebase
            .auth()
            .sendSignInLinkToEmail(email, {
                url: `${redirectTo}/magic-login?email=true`,
                handleCodeInApp: true
            })
            .then((res) => {
                setIsLoading(false)
                setIsSignupEmailSent(true)
                console.log("Sent e-mail")
                localStorage.setItem("lawyered.user.email", email)
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setErrors((prev) => [
                    ...prev,
                    { message: error.message, code: error.code }
                ])
            })
    },
    sendLinkEmail: (email, setIsLoading, setErrors, setIsLinkEmailSent) => {
        const redirectTo =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
        setIsLoading(true)
        firebase
            .auth()
            .sendSignInLinkToEmail(email, {
                url: `${redirectTo}/magic-linking?email=true`,
                handleCodeInApp: true
            })
            .then((res) => {
                setIsLoading(false)
                setIsLinkEmailSent(true)
                console.log("Sent e-mail")
                localStorage.setItem("lawyered.user.email", email)
            })
            .catch((error) => {
                setIsLoading(false)
                console.error(error)
                setErrors((prev) => [
                    ...prev,
                    { message: error.message, code: error.code }
                ])
            })
    },
    signin: async (
        url,
        token,
        currentUser,
        setApproved,
        setIsAnonymous,
        setToken,
        setIsLoading,
        setErrors
    ) => {
        let email = localStorage.getItem("lawyered.user.email")
        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(url)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt(
                    "Please provide your email for confirmation"
                )
            }
            setIsLoading(true)

            const preAuthCheck = await identifyAuthTypeForUser(token, email)

            if (preAuthCheck.hasVerifiedEmail) {
                firebase
                    .auth()
                    .signInWithEmailLink(email, url)
                    .then(async (result) => {
                        const token = await Object.entries(result?.user)[5][1]?.b
                        if(token){
                            localStorage.setItem("lawyered.user.token", token)
                        }
                        localStorage.setItem("lawyered.user.isAnonymous", false)
                        localStorage.setItem("lawyered.user.email", email)
                        setToken(token)
                        setApproved(true)
                        setIsAnonymous(false)
                        setIsLoading(false)
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        console.error(error)
                        setErrors((prev) => [
                            ...prev,
                            { message: error.message, code: error.code }
                        ])
                    })
            } else {
                console.log(
                    `signin::preAuthCheck::user does not exist, linking with anon account...`
                )
                const credential =
                    firebase.auth.EmailAuthProvider.credentialWithLink(
                        email,
                        url
                    )
                const userCred = await currentUser.linkWithCredential(
                    credential
                )
                const user = userCred.user
                console.log("Anonymous account successfully upgraded", user)
                user.getIdToken()
                    .then((idToken) => {
                        localStorage.setItem("lawyered.user.email", email)
                        if(idToken){
                            localStorage.setItem("lawyered.user.token", idToken)
                        }
                        localStorage.setItem("lawyered.user.isAnonymous", false)
                        setToken(idToken)
                        setApproved(true)
                        setIsAnonymous(false)
                        setIsLoading(false)
                    })
                    .catch(function (error) {
                        setIsLoading(false)
                        setErrors((prev) => [
                            ...prev,
                            { message: error.message, code: error.code }
                        ])
                        console.error("Error *** ", error)
                    })
            }
        }
    },
    link: async (
        url,
        currentUser,
        setIsLoading,
        setLinkingCompleted,
        setErrors
    ) => {
        let email = localStorage.getItem("lawyered.user.email")
        // Confirm the link is a sign-in with email link.
        if (firebase.auth().isSignInWithEmailLink(url)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt(
                    "Please provide your email for confirmation"
                )
            }
            setIsLoading(true)

            const credential =
                firebase.auth.EmailAuthProvider.credentialWithLink(email, url)
            currentUser
                .linkWithCredential(credential)
                .then((userCredential) => {
                    console.log("Linking completed!", userCredential)
                    setIsLoading(false)
                    setLinkingCompleted(true)
                })
                .catch((error) => {
                    console.error(`Linking failed`, error)
                    setIsLoading(false)
                    setErrors((prev) => [
                        ...prev,
                        { message: error.message, code: error.code }
                    ])
                })
        }
    },
    signout: (
        setApproved,
        setIsAnonymous,
        setToken,
        setIsLoading,
        setErrors
    ) => {
        setIsLoading(true)
        firebase
            .auth()
            .signOut()
            .then((res) => {
                localStorage.removeItem("lawyered.user.token")
                localStorage.removeItem("lawyered.user.email")
                localStorage.removeItem("lawyered.user.phoneNumber")
                localStorage.removeItem("lawyered.user.isAnonymous")
                setApproved(false)
                setIsAnonymous(true)
                setToken(null)
                setIsLoading(false)
            })
            .catch((err) => {
                setErrors((prev) => [...prev, err.message])
                localStorage.removeItem("lawyered.user.token")
                localStorage.removeItem("lawyered.user.email")
                localStorage.removeItem("lawyered.user.phoneNumber")
                localStorage.removeItem("lawyered.user.isAnonymous")
                console.error("signout() :: error", err)
                setApproved(false)
                setIsAnonymous(true)
                setToken(null)
                setIsLoading(false)
            })
    },
    refreshToken: (setToken, setRefreshTokenError) => {
        const currentUser = firebase.auth().currentUser
        if (currentUser) {
            const token = localStorage.getItem("lawyered.user.token")
            let shouldRefresh = false
            if (token) {
                const decodedToken = jwt_decode(token)

                /*
                console.log(`decodedToken.exp`, decodedToken.exp)
                console.log(
                    `Token age`,
                    Math.floor(Date.now() / 1000) - decodedToken.iat
                )
                */

                if (Math.floor(Date.now() / 1000) < decodedToken.exp) {
                    // console.log(`Not refreshing token`)
                    setRefreshTokenError(null)
                } else {
                    console.log(`Refreshing token`)
                    shouldRefresh = true
                }
            } else {
                shouldRefresh = true
            }
            if (shouldRefresh) {
                currentUser
                    .getIdToken(true)
                    .then(function (idToken) {
                        setToken(idToken)
                        console.log("Refreshed token.")
                        setRefreshTokenError(null)
                        localStorage.setItem("lawyered.user.token", idToken)
                        localStorage.setItem(
                            "lawyered.user.isAnonymous",
                            currentUser.isAnonymous
                        )
                    })
                    .catch(function (error) {
                        console.error("refreshToken() :: error", error)
                        // The refresh token is likely expired
                        try {
                            const firebaseError = JSON.parse(error.message)
                            console.error(
                                `refreshToken() :: firebaseError`,
                                firebaseError
                            )
                            setRefreshTokenError(firebaseError)
                        } catch (error) {
                            console.error(
                                "Failed parsing possible Firebase error JSON"
                            )
                        }
                    })
            } else {
                // console.log("Should not refresh")
            }
        }
    },
    anonymousSignin: (
        setToken,
        setApproved,
        setIsAnonymous,
        setIsLoading,
        setErrors
    ) => {
        const currentUser = firebase.auth().currentUser
        console.log("anonymousSignin::currentUser", currentUser)
        if (!currentUser) {
            firebase
                .auth()
                .signInAnonymously()
                .then(async (result) => {
                    const token = await Object.entries(result.user)[5][1]?.b
                    if(token){
                        localStorage.setItem("lawyered.user.token", token)
                    }
                    localStorage.setItem("lawyered.user.isAnonymous", true)
                    setToken(token)
                    setApproved(true)
                    setIsAnonymous(true)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                    setErrors((prev) => [
                        ...prev,
                        { message: error.message, code: error.code }
                    ])
                })
        } else {
            console.log(
                "anonymousSignin called, but currentUser is not null",
                currentUser
            )
        }
    }
}

/* Ref.: https://dev.to/itnext/user-auth-with-firebase-and-react-1725 */
