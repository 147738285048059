import { useState } from "react"
import styled from "styled-components"
import Colors from "../../assets/colors"
import RadioButtonLabel from "../radioButtonLabel"
import { Overline } from "../text"

const RadioButtonLabelGroup = ({
    groupName,
    onSelectionChange,
    title,
    titleColor = null,
    selected = null,
    options = []
}) => {
    const [currentlySelected, setCurrentlySelected] = useState(selected)

    const handleOnChange = (value) => {
        setCurrentlySelected(value)
        onSelectionChange(groupName, value)
    }
    return (
        <StyledRadioButtonGroup>
            {title && (
                <Overline color={titleColor ? titleColor : Colors.black}>
                    {title.toUpperCase()}
                </Overline>
            )}
            <Options>
                {options.map((option) => {
                    return (
                        <RadioButtonLabel
                            key={`radio-${groupName}-${option.value}`}
                            title={option.title}
                            selected={currentlySelected === option.value}
                            onChange={() => {
                                handleOnChange(option.value)
                            }}
                        />
                    )
                })}
            </Options>
        </StyledRadioButtonGroup>
    )
}

const StyledRadioButtonGroup = styled.div`
    > p {
        margin-bottom: 16px;
    }
`

const Options = styled.div`
    display: flex;
    > div {
        margin-right: 16px;
    }
`

export default RadioButtonLabelGroup
