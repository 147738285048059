const Colors = {
    black: "#000000",
    gray: {
        900: "#111827",
        800: "#1F2937",
        700: "#374151",
        600: "#4B5563",
        500: "#6B7280",
        400: "#9CA3AF",
        300: "#D1D5DB",
        200: "#E5E7EB",
        100: "#F3F4F6",
        50: "#F9FAFB"
    },
    green: {
        900: "#064E3B",
        800: "#065F46",
        700: "#047857",
        600: "#059669",
        500: "#10B981",
        400: "#34D399",
        300: "#6EE7B7",
        200: "#A7F3D0",
        100: "#D1FAE5",
        50: "#ECFDF5"
    },
    red: {
        900: "#7F1D1D",
        800: "#991B1B",
        700: "#991B1B",
        600: "#DC2626",
        500: "#EF4444",
        400: "#F87171",
        300: "#FCA5A5",
        200: "#FECACA",
        100: "#FEE2E2",
        50: "#FEF2F2"
    },
    yellow: {
        900: "#78350F",
        800: "#92400E",
        700: "#B45309",
        600: "#D97706",
        500: "#F59E0B",
        400: "#FBBF24",
        300: "#FCD34D",
        200: "#FDE68A",
        100: "#FEF3C7",
        50: "#FFFBEB"
    },
    white: "#FFFFFF"
}

export default Colors
