import styled from "styled-components"

import Icon from "../components/icon"
import { P, P_Large_M } from "../components/text"
import Colors from "../assets/colors"

const InfoBoxSigning = ({ title = "Info", icon = "Info", content = null }) => {
    return (
        <Box>
            <HeadingWithIcon>
                <P_Large_M>
                    {icon && <Icon icon={icon} />}
                    {title}
                </P_Large_M>
            </HeadingWithIcon>
            {!content && (
                <>
                    <P>
                        Legg til partene i avtalen (de som skal signere). Du
                        betaler pr. signatur.
                    </P>
                    <P>
                        Etter betaling sendes du, hvis du har krysset av for
                        det, direkte til signering. De andre partene får link
                        til signering tilsendt på e-post eller SMS.
                    </P>
                    <P>
                        Når alle partene har gjennomført signering, blir
                        kontrakten tilgjengelig for nedlasting.
                    </P>
                </>
            )}
            {content && content}
        </Box>
    )
}

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: ${(props) => (props.big ? "48px" : "16px")};
    margin-top: 24px;
    @media screen and (min-width: 768px) {
        margin-right: 24px;
    }
    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    > * {
        margin-bottom: 12px;
    }
`

const HeadingWithIcon = styled.div`
    p {
        display: flex;
        align-items: center;
    }
    p svg {
        padding-right: 8px;
    }
`

export default InfoBoxSigning
