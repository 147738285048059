import * as React from "react"

function VippsMark({ width = 48 }) {
    const iconBaseWidth = 48
    const iconBaseHeight = 32
    const height = width * (iconBaseHeight / iconBaseWidth)
    return (
        <svg
            fill="none"
            height={height}
            width={width}
            viewBox="0 0 48 32"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect fill="#ff5b24" height={32} rx={3.5} width={48} />
            <path
                clipRule="evenodd"
                d="M27.864 12.262c1.377 0 2.557-1.045 2.557-2.548 0-1.502-1.18-2.548-2.557-2.548s-2.557 1.046-2.557 2.548c0 1.503 1.18 2.548 2.557 2.548zm3.344 4.117c-1.705 2.221-3.508 3.757-6.688 3.757-3.244 0-5.769-1.96-7.736-4.835-.787-1.176-2-1.437-2.885-.817-.82.588-1.015 1.83-.262 2.908 2.72 4.15 6.49 6.566 10.883 6.566 4.032 0 7.18-1.96 9.638-5.227.917-1.208.885-2.45 0-3.136-.82-.654-2.033-.424-2.95.784z"
                fill="#fff"
                fillRule="evenodd"
            />
        </svg>
    )
}

export default VippsMark
