import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"

const RadioButton = ({ onChange = () => { }, selected = false }) => {
    const [isSelected, setIsSelected] = useState(selected)
    const onClick = () => {
        setIsSelected(true)
        onChange(true)
    }
    useEffect(() => {
        if (!selected) {
            setIsSelected(false)
        }
    }, [selected])

    return (
        <Container selected={selected || isSelected} onClick={onClick}>
            {(selected || isSelected) && <Circle />}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border: 1px solid ${Colors.gray[300]};
    border-radius: 8px;
    background-color: ${(props) =>
        props.selected ? props.theme.inputForeground : Colors.white};
`

const Circle = styled.div`
    height: 6px;
    width: 6px;
    border-radius: 3px;
    background-color: ${Colors.white};
`

export default RadioButton
