import React from "react"
import styled from "styled-components"
import Spinner from "./Spinner"
import { P_Small } from "./text"

const SpinnerScreen = ({ spinnerSize = 48, text = null }) => {
    return (
        <StyledSpinnerScreen>
            <Spinner size={spinnerSize} />
            {text && <P_Small>{text}</P_Small>}
        </StyledSpinnerScreen>
    )
}

const StyledSpinnerScreen = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
        margin-top: 16px;
    }
`

export default SpinnerScreen
