import * as React from "react"
import { useTheme } from "styled-components"

function PersonCheckmark({ fill, width = 21 }) {
    const theme = useTheme()
    const iconBaseWidth = 21
    const iconBaseHeight = 18
    const height = width * (iconBaseHeight / iconBaseWidth)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 21 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.3 9.22A4.92 4.92 0 0013 5.5a5 5 0 00-10 0 4.92 4.92 0 001.7 3.72A8 8 0 000 16.5a1 1 0 102 0 6 6 0 1112 0 1 1 0 002 0 8 8 0 00-4.7-7.28zM8 8.5a3 3 0 110-6 3 3 0 010 6zm11.71-2.37a1 1 0 00-1.42 0l-2 2-.62-.63a1 1 0 10-1.42 1.41l1.34 1.34a1 1 0 001.41 0l2.67-2.67a1 1 0 00.04-1.45z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default PersonCheckmark
