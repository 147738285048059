import ContractForm from "./ContractForm"

const Editor = ({
    template,
    prefilledData,
    attachments,
    requestPreview,
    cancelPreview,
    contractPreviewContent,
    updateFormData,
    currentStep,
    isLoading,
    isFormValid,
    isUsingDraftData,
    didClearDraftData,
    checkFormValidity,
    shouldDisplayValidationErrors,
    generatedQueryString
}) => {
    return (
        <ContractForm
            template={template}
            prefilledData={prefilledData}
            attachments={attachments}
            requestPreview={requestPreview}
            cancelPreview={cancelPreview}
            contractPreviewContent={contractPreviewContent}
            updateFormData={updateFormData}
            currentStep={currentStep}
            isLoading={isLoading}
            isFormValid={isFormValid}
            isUsingDraftData={isUsingDraftData}
            didClearDraftData={didClearDraftData}
            checkFormValidity={checkFormValidity}
            shouldDisplayValidationErrors={shouldDisplayValidationErrors}
            generatedQueryString={generatedQueryString}
        />
    )
}

export default Editor
