import Notice from "../components/notice"

/* https://github.com/bjyoungblood/es6-error */
class ExtendableError extends Error {
    constructor(message) {
        super(message)

        // extending Error is weird and does not propagate `message`
        Object.defineProperty(this, "message", {
            configurable: true,
            enumerable: false,
            value: message,
            writable: true
        })

        Object.defineProperty(this, "name", {
            configurable: true,
            enumerable: false,
            value: this.constructor.name,
            writable: true
        })

        if (Error.hasOwnProperty("captureStackTrace")) {
            Error.captureStackTrace(this, this.constructor)
            return
        }

        Object.defineProperty(this, "stack", {
            configurable: true,
            enumerable: false,
            value: new Error(message).stack,
            writable: true
        })
    }
}

class NetworkError extends ExtendableError {
    constructor(message, data) {
        super()
        this.name = this.constructor.name
        this.message = message
        this.stack = new Error(message).stack
        this.data = data
        return this
    }
}

class NotFoundError extends ExtendableError {
    constructor(message, data) {
        super()
        this.name = this.constructor.name
        this.message = message
        this.stack = new Error(message).stack
        this.data = data
        return this
    }
}

const renderError = (error) => {
    if (error instanceof NetworkError) {
        return <Notice error text={error.data.message} />
    }
    if (error instanceof NotFoundError) {
        return <Notice error text={error.data.message} />
    }
    return <Notice error text={"// Get error text //"} />
}

const __error = (errorCode) => {
    switch (errorCode) {
        case "lawyered/not-implemented":
            return "Funksjonen er ikke implementert."
        case "lawyered/shortcode-not-found":
            return "Signaturkoden er ugyldig, eller allerede brukt."
        case "lawyered/contract-not-found":
            return "Fant ikke kontrakten."
        case "lawyered/signer-uuid-mismatch":
            return "Inkompatible data."
        case "lawyered/unable-to-save-signature":
            return "Klarte ikke å lagre signaturen."
        case "lawyered/file-type-not-accepted":
            return "Denne filtypen er ikke tillatt."
        default:
            return errorCode
    }
}

export { NetworkError, NotFoundError, renderError, __error }
