import * as React from "react"
import { useTheme } from "styled-components"

function Profile({ fill, width = 24 }) {
    const theme = useTheme()
    const height = width
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.71 12.71a6 6 0 10-7.42 0 10 10 0 00-6.22 8.18 1.006 1.006 0 102 .22 8 8 0 0115.9 0 1 1 0 001 .89h.11a1 1 0 00.88-1.1 10 10 0 00-6.25-8.19zM12 12a4 4 0 110-8 4 4 0 010 8z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default Profile
