import { useContext } from "react"
import useSWR from "swr"
import { apiUrl, fetchWithToken } from "./fetcher"

import { FirebaseAuth } from "../providers/AuthProvider"

function useContracts() {
    const { token } = useContext(FirebaseAuth)
    const { data, error } = useSWR(
        [`${apiUrl}/contracts`, token],
        fetchWithToken
    )

    return {
        contracts: data,
        isLoading: !error && !data,
        isError: error
    }
}

function useContract(contractId) {
    const { token } = useContext(FirebaseAuth)
    const { data, error } = useSWR(
        [`${apiUrl}/contracts?contract_id=${contractId}`, token],
        fetchWithToken
    )

    return {
        contract: data,
        isLoading: !error && !data,
        isError: error
    }
}

export { useContracts, useContract }
