import React, { useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"

const Select = ({ list, onChange = (event) => { }, text }) => {
    const [isFocused, setIsFocused] = useState(false)
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)

    const renderList = () => {
        return list.map((item, index) => {
            return (
                <option value={item} key={index}>
                    {item}
                </option>
            )
        })
    }

    return (
        <Container
            isFocused={isFocused}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue=""
        >
            <option value="" disabled hidden>
                {text}
            </option>
            {renderList()}
        </Container>
    )
}

const Container = styled.select`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px;
    cursor: pointer;
    color: ${Colors.gray[500]};
    background-color: ${Colors.white};
    border: ${(props) =>
        props.isFocused
            ? "1px solid " + props.theme.selectForeground
            : "1px solid " + Colors.gray[300]};
    border-radius: 8px;
`

export default Select
