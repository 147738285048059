import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import Countries from "../../utils/Countries"
import Flag from "./Flag"
import Icon from "../icon"
import Colors from "../../assets/colors"
import { P_Medium } from "../../components/text"

const FlagSelect = ({
    defaultValue = "",
    disabled = false,
    onChange = () => {}
}) => {
    const SupportedCountries = {
        NO: Countries.NO,
        SE: Countries.SE,
        DK: Countries.DK,
        FI: Countries.FI
    }

    const [selectedCountryCode, setSelectedCountryCode] = useState(defaultValue)
    const onSelectChange = (event) => {
        setSelectedCountryCode(event.target.value)
        onChange(event)
    }

    const renderList = () => {
        const list = []
        for (const key in SupportedCountries) {
            const Country = Countries[key]
            list.push(
                <option value={key} key={key}>
                    {Country.name}
                </option>
            )
        }

        return (
            <Select
                defaultValue={defaultValue}
                onChange={onSelectChange}
                disabled={disabled}
            >
                {list}
            </Select>
        )
    }

    return (
        <Container>
            <SelectContainer>{renderList()}</SelectContainer>
            <InnerContainer>
                <Flag country={selectedCountryCode} />
                <Icon icon="ArrowDown" />
                <CountryCode>
                    {"+" + SupportedCountries[selectedCountryCode].landCode}
                </CountryCode>
                <HorizontalLine />
            </InnerContainer>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    width: 150px;
`

const SelectContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
`

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
    pointer-events: none;
    width: auto;
`

const Select = styled.select`
    opacity: 0;
    width: 100%;
`

const CountryCode = styled(P_Medium)`
    color: ${Colors.gray[500]};
    margin-left: 8px;
`

const HorizontalLine = styled.div`
    height: 16px;
    width: 1px;
    background-color: ${Colors.gray[200]};
    margin-left: 8px;
`

export default FlagSelect
