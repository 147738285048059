import { useContext } from "react"
import { Route, useRouteMatch } from "react-router-dom"
import { FirebaseAuth } from "../providers/AuthProvider"
import styled from "styled-components"

import ArchiveLanding from "../containers/ArchiveLanding"
import DisplayContract from "../containers/DisplayContract"
import WithSidebar from "../components/withSidebar"

const Archive = () => {
    let { path } = useRouteMatch()
    const { currentUser, token } = useContext(FirebaseAuth)

    return (
        <WithSidebar currentUser={currentUser} token={token} path={path}>
            <Container>
                <Route exact path={path}>
                    <ArchiveLanding />
                </Route>
                <Route exact path={`${path}/:contract_id`}>
                    <DisplayContract />
                </Route>
            </Container>
        </WithSidebar>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export default Archive
