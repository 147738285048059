export function isEmpty(obj) {
    for (const i in obj) return false
    return true
}

export function isObjectEmpty(obj) {
    for (const i in obj) {
        if (typeof obj[i] == "object" && obj[i] !== null) {
            isObjectEmpty(obj[i])
        } else {
            if (obj[i] !== "") {
                return false
            }
        }
    }
    return true
}

export const buildQueryStringFromObject = (data) => {
    let queries = []
    for (const i in data) {
        if (i.includes("[]")) {
            data[i].forEach((part) => {
                queries.push(`${i}=${part}`)
            })
        } else {
            queries.push(`${i}=${data[i]}`)
        }
    }
    const queryString = queries.join("&")
    /*
    console.info(
        `Query: %c${queryString}`,
        "color: blue; font-size: 10px;"
    )
    */
    return queryString
}
