/* Build a string for use in contract listings */
export const buildSignersString = (signers) => {
    const signersArray = []
    signers.forEach((signer) => {
        if (!signer.info) {
            signersArray.push("(mangler info)")
            return
        }
        if (signer.info.organizationInfo) {
            signersArray.push(signer.info.organizationInfo.companyName)
        } else {
            signersArray.push(
                (signer.info.firstName || "") +
                    (signer.info.lastName ? " " + signer.info.lastName : "")
            )
        }
    })
    return signersArray.join(", ")
}
