import { useContext, useState, useEffect } from "react"
import { Switch, Route, Redirect, useLocation } from "react-router-dom"
import { ThemeProvider } from "styled-components"
import * as Sentry from "@sentry/react"

import { FirebaseAuth } from "./providers/AuthProvider"
import { AuthProvider } from "react-oidc-context"

import AppContainer from "./containers/AppContainer"

import Components from "./components"
import Archive from "./pages/Archive"
import Dashboard from "./pages/Dashboard"
import Create from "./pages/Create"
import MagicLogin from "./pages/MagicLogin"
import MagicLinking from "./pages/MagicLinking"
import Onboarding from "./pages/Onboarding"
import Settings from "./pages/Settings"
import Sign from "./pages/Sign"

import * as themes from "./assets/themes"
import useInterval from "./hooks/useInterval"

import FullscreenError from "./containers/errors/FullscreenError"
import Documentation from "./pages/Documentation"
import SpinnerScreen from "./components/SpinnerScreen"
import Upload from "./pages/Upload"
import BulkContracts from "./pages/BulkContracts"

import { toast } from 'react-toastify'

// for toastify
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

function App() {
    window.dataLayer.push({
        event: "pageview"
    })

    const { refreshToken, refreshTokenError } = useContext(FirebaseAuth)

    let location = useLocation()
    const [error, setError] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)

    useEffect(() => {
        if (location.search) {
            const l = new URLSearchParams(location.search)
            const error = l.get("error")
            const errorDescription = l.get("error_description")
            if (error) {
                setError(error)
            }
            if (errorDescription) {
                setErrorDescription(errorDescription)
            }
        }
    }, [location])

    let baseUrl = "https://app.lawyered.no"
    let oidcConfigSelection = "PRODUCTION"

    if (window.location.hostname == "localhost") {
        baseUrl = "https://localhost:3000"
        oidcConfigSelection = "TEST"
    } else if (window.location.hostname == "stage.app.lawyered.no") {
        baseUrl = "https://stage.app.lawyered.no"
        oidcConfigSelection = "TEST"
    }

    const oidcConfig = {
        authority: `${
            oidcConfigSelection === "PRODUCTION"
                ? "https://api.vipps.no/access-management-1.0/access/"
                : "https://apitest.vipps.no/access-management-1.0/access/"
        }`,
        client_id: `${
            oidcConfigSelection === "PRODUCTION"
                ? "946c3afd-c8b4-454a-a5d6-73c8d4049f63"
                : "abe904dc-84ac-46e0-b457-f9f995873207"
        }`,
        redirect_uri: baseUrl + "/onboarding/vipps-redirect",
        scope: "openid name email phoneNumber",
        client_authentication: "client_secret_basic",
        response_type: "code",
        skipSigninCallback: true
    }

    useInterval(() => {
        refreshToken()
    }, 5000)

    if (refreshTokenError) {
        Sentry.captureException(refreshTokenError)
    }

    const renderFallback = ({ error, componentStack, resetError }) => {
        return (
            <FullscreenError
                error={error}
                componentStack={componentStack}
                resetError={resetError}
            />
        )
    }

    const [currentTheme, setCurrentTheme] = useState(themes.lawyered)

    const setTheme = (themeKey = null) => {
        if (themeKey && themes[themeKey]) {
            setCurrentTheme(themes[themeKey])
        } else {
            setCurrentTheme(themes.lawyered)
        }
    }

    return (
        <ThemeProvider theme={currentTheme}>
            <AuthProvider {...oidcConfig}>
                <Sentry.ErrorBoundary fallback={renderFallback}>
                    <AppContainer>
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/dashboard" />
                            </Route>

                            <Route exact path="/dashboard/create">
                                <Redirect to="/create" />
                            </Route>

                            <Route exact path="/onboarding">
                                <Onboarding />
                            </Route>

                            <Route exact path="/onboarding/vipps-redirect">
                                <Onboarding vipps />
                            </Route>

                            <Route exact path="/magic-login">
                                <MagicLogin />
                            </Route>

                            <Route exact path="/magic-linking">
                                <MagicLinking />
                            </Route>

                            <Route path="/dashboard">
                                <Dashboard />
                            </Route>

                            <Route path="/create">
                                <Create setTheme={setTheme} />
                            </Route>

                            <Route path="/upload">
                                <Upload setTheme={setTheme} />
                            </Route>

                            <Route path="/s">
                                <Sign />
                            </Route>

                            <Route path="/payment-pending">
                                {/* ?contract_id=${contract_id} */}
                                <SpinnerScreen text={"Behandler betaling..."} />
                            </Route>

                            <Route path="/archive" component={Archive} />

                            <Route path="/settings" component={Settings} />

                            <Route path="/components">
                                <Components />
                            </Route>

                            <Route path="/docs">
                                <Documentation />
                            </Route>

                            <Route path="/bulk-contracts" component={BulkContracts} />

                            <Route path="/error">
                                {renderFallback({
                                    error: "Error",
                                    componentStack: "stack",
                                    resetError: () => {}
                                })}
                            </Route>
                        </Switch>
                    </AppContainer>
                </Sentry.ErrorBoundary>
            </AuthProvider>
        </ThemeProvider>
    )
}

export default App
