import styled, { useTheme } from "styled-components"
import filesize from "filesize"

import Colors from "../../assets/colors"
import Icon from "../icon"

import { P_Small_M, P_Mini } from "../text"

const UploadedFile = ({
    onClick = () => { },
    title,
    size,
    bg,
    dismissable = true
}) => {
    const theme = useTheme()
    return (
        <Container bg={bg || theme.backgroundSecondary}>
            <Icon icon={"ApproveFile"} />
            <TextWrapper>
                <Title>{title}</Title>
                <Subtitle>{filesize(size)}</Subtitle>
            </TextWrapper>
            {dismissable && (
                <IconContainer onClick={onClick}>
                    <Icon icon={"Cancel"} />
                </IconContainer>
            )}
        </Container>
    )
}

const Container = styled.div`
    width: 272px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    background-color: ${(props) => props.bg};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 8px 0 0;

    /*
    &:hover {
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
    */

    svg {
        display: flex;
        flex: 0 0 auto;
    }
`

const TextWrapper = styled.div`
    flex: 0 1 calc(100% - 32px);
    justify-content: space-between;
    align-items: center;
    max-width: calc(100% - 32px);
`

const Title = styled(P_Small_M)`
    flex: 1;
    color: ${Colors.black};
    margin: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
`

const Subtitle = styled(P_Mini)`
    color: ${Colors.gray[400]};
    margin: 0 8px 0 12px;
`

const IconContainer = styled.div`
    display: flex;
`

export default UploadedFile
