import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import FlagSelect from "../flag/FlagSelect"

const PhoneNumberInput = ({
    onChange = () => {},
    onCountryCodeChange = () => {},
    placeholder,
    value,
    readonly,
    maxlength,
    required,
    shouldDisplayValidationErrors,
    name,
    id,
    defaultCountry = "NO",
    isCountryChangeable = true
}) => {
    return (
        <Container
            readOnly={readonly}
            className={shouldDisplayValidationErrors ? "invalid" : ""}
        >
            <FlagSelect
                defaultValue={defaultCountry}
                onChange={onCountryCodeChange}
                disabled={!!isCountryChangeable}
            />
            <Input
                className={shouldDisplayValidationErrors ? "invalid" : ""}
                value={value}
                type="tel"
                placeholder={placeholder}
                onChange={onChange}
                readOnly={readonly}
                maxLength={maxlength}
                name={name}
                id={id}
                required={required}
            />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 100%;
    cursor: pointer;
    color: ${Colors.gray[500]};
    background-color: ${(props) =>
        props.readOnly ? Colors.gray[100] : Colors.white};
    border: 1px solid ${Colors.gray[300]};
    border-radius: 8px;

    svg {
        margin: 0 0 0 12px;
    }
`

const Input = styled.input`
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    padding: 0 0 0 8px;
    color: ${Colors.gray[900]};
    background-color: ${(props) =>
        props.readOnly ? Colors.gray[100] : Colors.white};
    &:focus {
        outline: none;
    }
`

export default PhoneNumberInput
