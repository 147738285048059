import { useState } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

import { __error } from "../utils/errors"

import Colors from "../assets/colors"

import { H4, P, P_Medium } from "../components/text"
import TextInput from "../components/textInput"
import Icon from "../components/icon"
import Spinner from "../components/Spinner"
import { getShortcodeMeta } from "../utils/api"

const SignLanding = () => {
    const history = useHistory()
    const [code, setCode] = useState("")
    const [loading, setLoading] = useState(null)
    const [error, setError] = useState(null)

    const handleOnChange = async (event) => {
        const shortcode = event.target.value
        setCode(shortcode)
        if (shortcode.length === 6) {
            setLoading("Henter info...")
            const result = await getShortcodeMeta(shortcode)
            if (result.success) {
                history.push(`/s/${shortcode}`)
            } else {
                setLoading(null)
                setError(__error(result.error?.code))
                setCode("")
            }
        }
    }

    return (
        <TopContainer>
            <Title>Signér dokument</Title>
            <P>Skriv inn koden du har fått oppgitt.</P>
            <TextInput
                placeholder="Skriv inn kode (6 tegn)"
                onChange={handleOnChange}
                value={code}
                disabled={code.length >= 6}
            />
            {loading && !error && (
                <Feedback>
                    <Spinner />
                    <P_Medium>{loading}</P_Medium>
                </Feedback>
            )}
            {error && !loading && (
                <Feedback>
                    <Circle bg={Colors.red[400]}>
                        <Icon icon="Cancel" width="21" fill={Colors.white} />
                    </Circle>
                    <P_Medium>{error}</P_Medium>
                </Feedback>
            )}
        </TopContainer>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px;
    width: 275px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }
    input {
        margin: 16px 0;
    }
`

const Title = styled(H4)`
    color: ${(props) => props.theme.foregroundPrimary};
    line-height: 32px;
    margin-bottom: 16px;
`

const Circle = styled.span`
    display: flex;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.bg};
    border-radius: 50%;
    margin-bottom: 16px;
`

const Feedback = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
`

export default SignLanding
