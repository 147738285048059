import * as React from "react"
import { useTheme } from "styled-components"

function SvgUpload({ fill, width = 20 }) {
    const theme = useTheme()
    const height = width
    return (
        <svg
            width={width}
            height={height}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path
                d="M6.71 5.71L9 3.41V13a1 1 0 102 0V3.41l2.29 2.3a1 1 0 001.639-.325 1 1 0 00-.219-1.095l-4-4a1 1 0 00-.33-.21 1 1 0 00-.76 0 1 1 0 00-.33.21l-4 4a1.004 1.004 0 101.42 1.42zM19 12a1 1 0 00-1 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 10-2 0v4a3 3 0 003 3h14a3 3 0 003-3v-4a1 1 0 00-1-1z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default SvgUpload
