import React from "react"
import styled from "styled-components/macro"


const SpaceBetweenBox = ({style, marginTop, children }) => {
    return <SpaceBetweenBoxWrapper style={{marginTop: marginTop? marginTop: 0, ...style}}>{children}</SpaceBetweenBoxWrapper>
}


const SpaceBetweenBoxWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 1024px) {
        h4 {
            font-size: 20px;
        }
      }
`

export default SpaceBetweenBox
