import styled from "styled-components"
import Colors from "../../assets/colors"

const Loader = ({ color = Colors.white }) => {
    return (
        <Spinner color={color}>
            <span className="bounce1"></span>
            <span className="bounce2"></span>
            <span className="bounce3"></span>
        </Spinner>
    )
}

const Spinner = styled.span`
    display: block;
    margin: 8px auto 0;
    width: 70px;
    text-align: center;

    > span {
        width: 16px;
        height: 16px;
        background-color: ${(props) => props.color};

        border-radius: 100%;
        display: inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
        }
    }

    @keyframes sk-bouncedelay {
        0%,
        80%,
        100% {
            -webkit-transform: scale(0);
            transform: scale(0);
        }
        40% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
    }
`

export default Loader
