import React from "react"

const QR_iOS = () => {
    return (
        <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.5616 0.405518H21.8589V3.10822H24.5616V0.405518Z"
                fill="black"
            />
            <path
                d="M29.9669 0.405518H27.2642V3.10822H29.9669V0.405518Z"
                fill="black"
            />
            <path
                d="M32.67 0.405518H29.9673V3.10822H32.67V0.405518Z"
                fill="black"
            />
            <path
                d="M35.3721 0.405518H32.6694V3.10822H35.3721V0.405518Z"
                fill="black"
            />
            <path
                d="M38.0753 0.405518H35.3726V3.10822H38.0753V0.405518Z"
                fill="black"
            />
            <path
                d="M40.7779 0.405518H38.0752V3.10822H40.7779V0.405518Z"
                fill="black"
            />
            <path
                d="M46.1832 0.405518H43.4805V3.10822H46.1832V0.405518Z"
                fill="black"
            />
            <path
                d="M48.8858 0.405518H46.1831V3.10822H48.8858V0.405518Z"
                fill="black"
            />
            <path
                d="M51.5889 0.405518H48.8862V3.10822H51.5889V0.405518Z"
                fill="black"
            />
            <path
                d="M59.6968 0.405518H56.9941V3.10822H59.6968V0.405518Z"
                fill="black"
            />
            <path
                d="M62.3995 0.405518H59.6968V3.10822H62.3995V0.405518Z"
                fill="black"
            />
            <path
                d="M24.5616 3.10815H21.8589V5.81086H24.5616V3.10815Z"
                fill="black"
            />
            <path
                d="M38.0753 3.10815H35.3726V5.81086H38.0753V3.10815Z"
                fill="black"
            />
            <path
                d="M40.7779 3.10815H38.0752V5.81086H40.7779V3.10815Z"
                fill="black"
            />
            <path
                d="M51.5889 3.10815H48.8862V5.81086H51.5889V3.10815Z"
                fill="black"
            />
            <path
                d="M54.2916 3.10815H51.5889V5.81086H54.2916V3.10815Z"
                fill="black"
            />
            <path
                d="M62.3995 3.10815H59.6968V5.81086H62.3995V3.10815Z"
                fill="black"
            />
            <path
                d="M67.8048 3.10815H65.1021V5.81086H67.8048V3.10815Z"
                fill="black"
            />
            <path
                d="M24.5616 5.81079H21.8589V8.51349H24.5616V5.81079Z"
                fill="black"
            />
            <path
                d="M29.9669 5.81079H27.2642V8.51349H29.9669V5.81079Z"
                fill="black"
            />
            <path
                d="M38.0753 5.81079H35.3726V8.51349H38.0753V5.81079Z"
                fill="black"
            />
            <path
                d="M40.7779 5.81079H38.0752V8.51349H40.7779V5.81079Z"
                fill="black"
            />
            <path
                d="M43.4805 5.81079H40.7778V8.51349H43.4805V5.81079Z"
                fill="black"
            />
            <path
                d="M46.1832 5.81079H43.4805V8.51349H46.1832V5.81079Z"
                fill="black"
            />
            <path
                d="M56.9942 5.81079H54.2915V8.51349H56.9942V5.81079Z"
                fill="black"
            />
            <path
                d="M59.6968 5.81079H56.9941V8.51349H59.6968V5.81079Z"
                fill="black"
            />
            <path
                d="M62.3995 5.81079H59.6968V8.51349H62.3995V5.81079Z"
                fill="black"
            />
            <path
                d="M65.1021 5.81079H62.3994V8.51349H65.1021V5.81079Z"
                fill="black"
            />
            <path
                d="M27.2647 8.51343H24.562V11.2161H27.2647V8.51343Z"
                fill="black"
            />
            <path
                d="M29.9669 8.51343H27.2642V11.2161H29.9669V8.51343Z"
                fill="black"
            />
            <path
                d="M32.67 8.51343H29.9673V11.2161H32.67V8.51343Z"
                fill="black"
            />
            <path
                d="M40.7779 8.51343H38.0752V11.2161H40.7779V8.51343Z"
                fill="black"
            />
            <path
                d="M46.1832 8.51343H43.4805V11.2161H46.1832V8.51343Z"
                fill="black"
            />
            <path
                d="M51.5889 8.51343H48.8862V11.2161H51.5889V8.51343Z"
                fill="black"
            />
            <path
                d="M54.2916 8.51343H51.5889V11.2161H54.2916V8.51343Z"
                fill="black"
            />
            <path
                d="M56.9942 8.51343H54.2915V11.2161H56.9942V8.51343Z"
                fill="black"
            />
            <path
                d="M59.6968 8.51343H56.9941V11.2161H59.6968V8.51343Z"
                fill="black"
            />
            <path
                d="M62.3995 8.51343H59.6968V11.2161H62.3995V8.51343Z"
                fill="black"
            />
            <path
                d="M65.1021 8.51343H62.3994V11.2161H65.1021V8.51343Z"
                fill="black"
            />
            <path
                d="M24.5616 11.2161H21.8589V13.9188H24.5616V11.2161Z"
                fill="black"
            />
            <path
                d="M38.0753 11.2161H35.3726V13.9188H38.0753V11.2161Z"
                fill="black"
            />
            <path
                d="M43.4805 11.2161H40.7778V13.9188H43.4805V11.2161Z"
                fill="black"
            />
            <path
                d="M46.1832 11.2161H43.4805V13.9188H46.1832V11.2161Z"
                fill="black"
            />
            <path
                d="M59.6968 11.2161H56.9941V13.9188H59.6968V11.2161Z"
                fill="black"
            />
            <path
                d="M62.3995 11.2161H59.6968V13.9188H62.3995V11.2161Z"
                fill="black"
            />
            <path
                d="M67.8048 11.2161H65.1021V13.9188H67.8048V11.2161Z"
                fill="black"
            />
            <path
                d="M27.2647 13.9189H24.562V16.6216H27.2647V13.9189Z"
                fill="black"
            />
            <path
                d="M38.0753 13.9189H35.3726V16.6216H38.0753V13.9189Z"
                fill="black"
            />
            <path
                d="M40.7779 13.9189H38.0752V16.6216H40.7779V13.9189Z"
                fill="black"
            />
            <path
                d="M43.4805 13.9189H40.7778V16.6216H43.4805V13.9189Z"
                fill="black"
            />
            <path
                d="M46.1832 13.9189H43.4805V16.6216H46.1832V13.9189Z"
                fill="black"
            />
            <path
                d="M48.8858 13.9189H46.1831V16.6216H48.8858V13.9189Z"
                fill="black"
            />
            <path
                d="M54.2916 13.9189H51.5889V16.6216H54.2916V13.9189Z"
                fill="black"
            />
            <path
                d="M62.3995 13.9189H59.6968V16.6216H62.3995V13.9189Z"
                fill="black"
            />
            <path
                d="M65.1021 13.9189H62.3994V16.6216H65.1021V13.9189Z"
                fill="black"
            />
            <path
                d="M24.5616 16.6216H21.8589V19.3243H24.5616V16.6216Z"
                fill="black"
            />
            <path
                d="M29.9669 16.6216H27.2642V19.3243H29.9669V16.6216Z"
                fill="black"
            />
            <path
                d="M35.3721 16.6216H32.6694V19.3243H35.3721V16.6216Z"
                fill="black"
            />
            <path
                d="M40.7779 16.6216H38.0752V19.3243H40.7779V16.6216Z"
                fill="black"
            />
            <path
                d="M46.1832 16.6216H43.4805V19.3243H46.1832V16.6216Z"
                fill="black"
            />
            <path
                d="M51.5889 16.6216H48.8862V19.3243H51.5889V16.6216Z"
                fill="black"
            />
            <path
                d="M56.9942 16.6216H54.2915V19.3243H56.9942V16.6216Z"
                fill="black"
            />
            <path
                d="M62.3995 16.6216H59.6968V19.3243H62.3995V16.6216Z"
                fill="black"
            />
            <path
                d="M67.8048 16.6216H65.1021V19.3243H67.8048V16.6216Z"
                fill="black"
            />
            <path
                d="M29.9669 19.3245H27.2642V22.0272H29.9669V19.3245Z"
                fill="black"
            />
            <path
                d="M35.3721 19.3245H32.6694V22.0272H35.3721V19.3245Z"
                fill="black"
            />
            <path
                d="M38.0753 19.3245H35.3726V22.0272H38.0753V19.3245Z"
                fill="black"
            />
            <path
                d="M43.4805 19.3245H40.7778V22.0272H43.4805V19.3245Z"
                fill="black"
            />
            <path
                d="M46.1832 19.3245H43.4805V22.0272H46.1832V19.3245Z"
                fill="black"
            />
            <path
                d="M62.3995 19.3245H59.6968V22.0272H62.3995V19.3245Z"
                fill="black"
            />
            <path
                d="M65.1021 19.3245H62.3994V22.0272H65.1021V19.3245Z"
                fill="black"
            />
            <path
                d="M67.8048 19.3245H65.1021V22.0272H67.8048V19.3245Z"
                fill="black"
            />
            <path
                d="M2.94001 22.0271H0.237305V24.7298H2.94001V22.0271Z"
                fill="black"
            />
            <path
                d="M11.0479 22.0271H8.34521V24.7298H11.0479V22.0271Z"
                fill="black"
            />
            <path
                d="M13.751 22.0271H11.0483V24.7298H13.751V22.0271Z"
                fill="black"
            />
            <path
                d="M16.4537 22.0271H13.751V24.7298H16.4537V22.0271Z"
                fill="black"
            />
            <path
                d="M19.1563 22.0271H16.4536V24.7298H19.1563V22.0271Z"
                fill="black"
            />
            <path
                d="M21.859 22.0271H19.1562V24.7298H21.859V22.0271Z"
                fill="black"
            />
            <path
                d="M24.5616 22.0271H21.8589V24.7298H24.5616V22.0271Z"
                fill="black"
            />
            <path
                d="M40.7779 22.0271H38.0752V24.7298H40.7779V22.0271Z"
                fill="black"
            />
            <path
                d="M48.8858 22.0271H46.1831V24.7298H48.8858V22.0271Z"
                fill="black"
            />
            <path
                d="M51.5889 22.0271H48.8862V24.7298H51.5889V22.0271Z"
                fill="black"
            />
            <path
                d="M67.8048 22.0271H65.1021V24.7298H67.8048V22.0271Z"
                fill="black"
            />
            <path
                d="M70.5079 22.0271H67.8052V24.7298H70.5079V22.0271Z"
                fill="black"
            />
            <path
                d="M78.6158 22.0271H75.9131V24.7298H78.6158V22.0271Z"
                fill="black"
            />
            <path
                d="M84.0211 22.0271H81.3184V24.7298H84.0211V22.0271Z"
                fill="black"
            />
            <path
                d="M86.7237 22.0271H84.021V24.7298H86.7237V22.0271Z"
                fill="black"
            />
            <path
                d="M89.4268 22.0271H86.7241V24.7298H89.4268V22.0271Z"
                fill="black"
            />
            <path
                d="M5.64265 24.7297H2.93994V27.4324H5.64265V24.7297Z"
                fill="black"
            />
            <path
                d="M8.34528 24.7297H5.64258V27.4324H8.34528V24.7297Z"
                fill="black"
            />
            <path
                d="M29.9669 24.7297H27.2642V27.4324H29.9669V24.7297Z"
                fill="black"
            />
            <path
                d="M35.3721 24.7297H32.6694V27.4324H35.3721V24.7297Z"
                fill="black"
            />
            <path
                d="M38.0753 24.7297H35.3726V27.4324H38.0753V24.7297Z"
                fill="black"
            />
            <path
                d="M40.7779 24.7297H38.0752V27.4324H40.7779V24.7297Z"
                fill="black"
            />
            <path
                d="M43.4805 24.7297H40.7778V27.4324H43.4805V24.7297Z"
                fill="black"
            />
            <path
                d="M48.8858 24.7297H46.1831V27.4324H48.8858V24.7297Z"
                fill="black"
            />
            <path
                d="M51.5889 24.7297H48.8862V27.4324H51.5889V24.7297Z"
                fill="black"
            />
            <path
                d="M54.2916 24.7297H51.5889V27.4324H54.2916V24.7297Z"
                fill="black"
            />
            <path
                d="M65.1021 24.7297H62.3994V27.4324H65.1021V24.7297Z"
                fill="black"
            />
            <path
                d="M67.8048 24.7297H65.1021V27.4324H67.8048V24.7297Z"
                fill="black"
            />
            <path
                d="M78.6158 24.7297H75.9131V27.4324H78.6158V24.7297Z"
                fill="black"
            />
            <path
                d="M81.3184 24.7297H78.6157V27.4324H81.3184V24.7297Z"
                fill="black"
            />
            <path
                d="M84.0211 24.7297H81.3184V27.4324H84.0211V24.7297Z"
                fill="black"
            />
            <path
                d="M86.7237 24.7297H84.021V27.4324H86.7237V24.7297Z"
                fill="black"
            />
            <path
                d="M5.64265 27.4324H2.93994V30.1351H5.64265V27.4324Z"
                fill="black"
            />
            <path
                d="M8.34528 27.4324H5.64258V30.1351H8.34528V27.4324Z"
                fill="black"
            />
            <path
                d="M13.751 27.4324H11.0483V30.1351H13.751V27.4324Z"
                fill="black"
            />
            <path
                d="M16.4537 27.4324H13.751V30.1351H16.4537V27.4324Z"
                fill="black"
            />
            <path
                d="M19.1563 27.4324H16.4536V30.1351H19.1563V27.4324Z"
                fill="black"
            />
            <path
                d="M24.5616 27.4324H21.8589V30.1351H24.5616V27.4324Z"
                fill="black"
            />
            <path
                d="M27.2647 27.4324H24.562V30.1351H27.2647V27.4324Z"
                fill="black"
            />
            <path
                d="M35.3721 27.4324H32.6694V30.1351H35.3721V27.4324Z"
                fill="black"
            />
            <path
                d="M38.0753 27.4324H35.3726V30.1351H38.0753V27.4324Z"
                fill="black"
            />
            <path
                d="M43.4805 27.4324H40.7778V30.1351H43.4805V27.4324Z"
                fill="black"
            />
            <path
                d="M59.6968 27.4324H56.9941V30.1351H59.6968V27.4324Z"
                fill="black"
            />
            <path
                d="M65.1021 27.4324H62.3994V30.1351H65.1021V27.4324Z"
                fill="black"
            />
            <path
                d="M70.5079 27.4324H67.8052V30.1351H70.5079V27.4324Z"
                fill="black"
            />
            <path
                d="M78.6158 27.4324H75.9131V30.1351H78.6158V27.4324Z"
                fill="black"
            />
            <path
                d="M81.3184 27.4324H78.6157V30.1351H81.3184V27.4324Z"
                fill="black"
            />
            <path
                d="M84.0211 27.4324H81.3184V30.1351H84.0211V27.4324Z"
                fill="black"
            />
            <path
                d="M86.7237 27.4324H84.021V30.1351H86.7237V27.4324Z"
                fill="black"
            />
            <path
                d="M89.4268 27.4324H86.7241V30.1351H89.4268V27.4324Z"
                fill="black"
            />
            <path
                d="M8.34528 30.135H5.64258V32.8377H8.34528V30.135Z"
                fill="black"
            />
            <path
                d="M16.4537 30.135H13.751V32.8377H16.4537V30.135Z"
                fill="black"
            />
            <path
                d="M27.2647 30.135H24.562V32.8377H27.2647V30.135Z"
                fill="black"
            />
            <path
                d="M32.67 30.135H29.9673V32.8377H32.67V30.135Z"
                fill="black"
            />
            <path
                d="M38.0753 30.135H35.3726V32.8377H38.0753V30.135Z"
                fill="black"
            />
            <path
                d="M46.1832 30.135H43.4805V32.8377H46.1832V30.135Z"
                fill="black"
            />
            <path
                d="M51.5889 30.135H48.8862V32.8377H51.5889V30.135Z"
                fill="black"
            />
            <path
                d="M54.2916 30.135H51.5889V32.8377H54.2916V30.135Z"
                fill="black"
            />
            <path
                d="M67.8048 30.135H65.1021V32.8377H67.8048V30.135Z"
                fill="black"
            />
            <path
                d="M70.5079 30.135H67.8052V32.8377H70.5079V30.135Z"
                fill="black"
            />
            <path
                d="M73.21 30.135H70.5073V32.8377H73.21V30.135Z"
                fill="black"
            />
            <path
                d="M75.9132 30.135H73.2104V32.8377H75.9132V30.135Z"
                fill="black"
            />
            <path
                d="M84.0211 30.135H81.3184V32.8377H84.0211V30.135Z"
                fill="black"
            />
            <path
                d="M86.7237 30.135H84.021V32.8377H86.7237V30.135Z"
                fill="black"
            />
            <path
                d="M89.4268 30.135H86.7241V32.8377H89.4268V30.135Z"
                fill="black"
            />
            <path
                d="M16.4537 32.8379H13.751V35.5406H16.4537V32.8379Z"
                fill="black"
            />
            <path
                d="M19.1563 32.8379H16.4536V35.5406H19.1563V32.8379Z"
                fill="black"
            />
            <path
                d="M32.67 32.8379H29.9673V35.5406H32.67V32.8379Z"
                fill="black"
            />
            <path
                d="M38.0753 32.8379H35.3726V35.5406H38.0753V32.8379Z"
                fill="black"
            />
            <path
                d="M43.4805 32.8379H40.7778V35.5406H43.4805V32.8379Z"
                fill="black"
            />
            <path
                d="M48.8858 32.8379H46.1831V35.5406H48.8858V32.8379Z"
                fill="black"
            />
            <path
                d="M54.2916 32.8379H51.5889V35.5406H54.2916V32.8379Z"
                fill="black"
            />
            <path
                d="M56.9942 32.8379H54.2915V35.5406H56.9942V32.8379Z"
                fill="black"
            />
            <path
                d="M62.3995 32.8379H59.6968V35.5406H62.3995V32.8379Z"
                fill="black"
            />
            <path
                d="M65.1021 32.8379H62.3994V35.5406H65.1021V32.8379Z"
                fill="black"
            />
            <path
                d="M73.21 32.8379H70.5073V35.5406H73.21V32.8379Z"
                fill="black"
            />
            <path
                d="M75.9132 32.8379H73.2104V35.5406H75.9132V32.8379Z"
                fill="black"
            />
            <path
                d="M89.4268 32.8379H86.7241V35.5406H89.4268V32.8379Z"
                fill="black"
            />
            <path
                d="M5.64265 35.5405H2.93994V38.2432H5.64265V35.5405Z"
                fill="black"
            />
            <path
                d="M8.34528 35.5405H5.64258V38.2432H8.34528V35.5405Z"
                fill="black"
            />
            <path
                d="M24.5616 35.5405H21.8589V38.2432H24.5616V35.5405Z"
                fill="black"
            />
            <path
                d="M27.2647 35.5405H24.562V38.2432H27.2647V35.5405Z"
                fill="black"
            />
            <path
                d="M29.9669 35.5405H27.2642V38.2432H29.9669V35.5405Z"
                fill="black"
            />
            <path
                d="M32.67 35.5405H29.9673V38.2432H32.67V35.5405Z"
                fill="black"
            />
            <path
                d="M35.3721 35.5405H32.6694V38.2432H35.3721V35.5405Z"
                fill="black"
            />
            <path
                d="M40.7779 35.5405H38.0752V38.2432H40.7779V35.5405Z"
                fill="black"
            />
            <path
                d="M48.8858 35.5405H46.1831V38.2432H48.8858V35.5405Z"
                fill="black"
            />
            <path
                d="M51.5889 35.5405H48.8862V38.2432H51.5889V35.5405Z"
                fill="black"
            />
            <path
                d="M54.2916 35.5405H51.5889V38.2432H54.2916V35.5405Z"
                fill="black"
            />
            <path
                d="M56.9942 35.5405H54.2915V38.2432H56.9942V35.5405Z"
                fill="black"
            />
            <path
                d="M62.3995 35.5405H59.6968V38.2432H62.3995V35.5405Z"
                fill="black"
            />
            <path
                d="M65.1021 35.5405H62.3994V38.2432H65.1021V35.5405Z"
                fill="black"
            />
            <path
                d="M67.8048 35.5405H65.1021V38.2432H67.8048V35.5405Z"
                fill="black"
            />
            <path
                d="M70.5079 35.5405H67.8052V38.2432H70.5079V35.5405Z"
                fill="black"
            />
            <path
                d="M2.94001 38.2432H0.237305V40.9459H2.94001V38.2432Z"
                fill="black"
            />
            <path
                d="M5.64265 38.2432H2.93994V40.9459H5.64265V38.2432Z"
                fill="black"
            />
            <path
                d="M19.1563 38.2432H16.4536V40.9459H19.1563V38.2432Z"
                fill="black"
            />
            <path
                d="M40.7779 38.2432H38.0752V40.9459H40.7779V38.2432Z"
                fill="black"
            />
            <path
                d="M43.4805 38.2432H40.7778V40.9459H43.4805V38.2432Z"
                fill="black"
            />
            <path
                d="M48.8858 38.2432H46.1831V40.9459H48.8858V38.2432Z"
                fill="black"
            />
            <path
                d="M54.2916 38.2432H51.5889V40.9459H54.2916V38.2432Z"
                fill="black"
            />
            <path
                d="M59.6968 38.2432H56.9941V40.9459H59.6968V38.2432Z"
                fill="black"
            />
            <path
                d="M62.3995 38.2432H59.6968V40.9459H62.3995V38.2432Z"
                fill="black"
            />
            <path
                d="M73.21 38.2432H70.5073V40.9459H73.21V38.2432Z"
                fill="black"
            />
            <path
                d="M75.9132 38.2432H73.2104V40.9459H75.9132V38.2432Z"
                fill="black"
            />
            <path
                d="M78.6158 38.2432H75.9131V40.9459H78.6158V38.2432Z"
                fill="black"
            />
            <path
                d="M81.3184 38.2432H78.6157V40.9459H81.3184V38.2432Z"
                fill="black"
            />
            <path
                d="M84.0211 38.2432H81.3184V40.9459H84.0211V38.2432Z"
                fill="black"
            />
            <path
                d="M2.94001 40.946H0.237305V43.6487H2.94001V40.946Z"
                fill="black"
            />
            <path
                d="M5.64265 40.946H2.93994V43.6487H5.64265V40.946Z"
                fill="black"
            />
            <path
                d="M8.34528 40.946H5.64258V43.6487H8.34528V40.946Z"
                fill="black"
            />
            <path
                d="M11.0479 40.946H8.34521V43.6487H11.0479V40.946Z"
                fill="black"
            />
            <path
                d="M13.751 40.946H11.0483V43.6487H13.751V40.946Z"
                fill="black"
            />
            <path
                d="M24.5616 40.946H21.8589V43.6487H24.5616V40.946Z"
                fill="black"
            />
            <path
                d="M27.2647 40.946H24.562V43.6487H27.2647V40.946Z"
                fill="black"
            />
            <path
                d="M32.67 40.946H29.9673V43.6487H32.67V40.946Z"
                fill="black"
            />
            <path
                d="M38.0753 40.946H35.3726V43.6487H38.0753V40.946Z"
                fill="black"
            />
            <path
                d="M46.1832 40.946H43.4805V43.6487H46.1832V40.946Z"
                fill="black"
            />
            <path
                d="M56.9942 40.946H54.2915V43.6487H56.9942V40.946Z"
                fill="black"
            />
            <path
                d="M62.3995 40.946H59.6968V43.6487H62.3995V40.946Z"
                fill="black"
            />
            <path
                d="M70.5079 40.946H67.8052V43.6487H70.5079V40.946Z"
                fill="black"
            />
            <path
                d="M75.9132 40.946H73.2104V43.6487H75.9132V40.946Z"
                fill="black"
            />
            <path
                d="M78.6158 40.946H75.9131V43.6487H78.6158V40.946Z"
                fill="black"
            />
            <path
                d="M81.3184 40.946H78.6157V43.6487H81.3184V40.946Z"
                fill="black"
            />
            <path
                d="M84.0211 40.946H81.3184V43.6487H84.0211V40.946Z"
                fill="black"
            />
            <path
                d="M89.4268 40.946H86.7241V43.6487H89.4268V40.946Z"
                fill="black"
            />
            <path
                d="M2.94001 43.6487H0.237305V46.3514H2.94001V43.6487Z"
                fill="black"
            />
            <path
                d="M5.64265 43.6487H2.93994V46.3514H5.64265V43.6487Z"
                fill="black"
            />
            <path
                d="M8.34528 43.6487H5.64258V46.3514H8.34528V43.6487Z"
                fill="black"
            />
            <path
                d="M13.751 43.6487H11.0483V46.3514H13.751V43.6487Z"
                fill="black"
            />
            <path
                d="M16.4537 43.6487H13.751V46.3514H16.4537V43.6487Z"
                fill="black"
            />
            <path
                d="M19.1563 43.6487H16.4536V46.3514H19.1563V43.6487Z"
                fill="black"
            />
            <path
                d="M21.859 43.6487H19.1562V46.3514H21.859V43.6487Z"
                fill="black"
            />
            <path
                d="M32.67 43.6487H29.9673V46.3514H32.67V43.6487Z"
                fill="black"
            />
            <path
                d="M35.3721 43.6487H32.6694V46.3514H35.3721V43.6487Z"
                fill="black"
            />
            <path
                d="M40.7779 43.6487H38.0752V46.3514H40.7779V43.6487Z"
                fill="black"
            />
            <path
                d="M43.4805 43.6487H40.7778V46.3514H43.4805V43.6487Z"
                fill="black"
            />
            <path
                d="M46.1832 43.6487H43.4805V46.3514H46.1832V43.6487Z"
                fill="black"
            />
            <path
                d="M54.2916 43.6487H51.5889V46.3514H54.2916V43.6487Z"
                fill="black"
            />
            <path
                d="M56.9942 43.6487H54.2915V46.3514H56.9942V43.6487Z"
                fill="black"
            />
            <path
                d="M62.3995 43.6487H59.6968V46.3514H62.3995V43.6487Z"
                fill="black"
            />
            <path
                d="M65.1021 43.6487H62.3994V46.3514H65.1021V43.6487Z"
                fill="black"
            />
            <path
                d="M70.5079 43.6487H67.8052V46.3514H70.5079V43.6487Z"
                fill="black"
            />
            <path
                d="M73.21 43.6487H70.5073V46.3514H73.21V43.6487Z"
                fill="black"
            />
            <path
                d="M75.9132 43.6487H73.2104V46.3514H75.9132V43.6487Z"
                fill="black"
            />
            <path
                d="M78.6158 43.6487H75.9131V46.3514H78.6158V43.6487Z"
                fill="black"
            />
            <path
                d="M81.3184 43.6487H78.6157V46.3514H81.3184V43.6487Z"
                fill="black"
            />
            <path
                d="M2.94001 46.3513H0.237305V49.054H2.94001V46.3513Z"
                fill="black"
            />
            <path
                d="M5.64265 46.3513H2.93994V49.054H5.64265V46.3513Z"
                fill="black"
            />
            <path
                d="M8.34528 46.3513H5.64258V49.054H8.34528V46.3513Z"
                fill="black"
            />
            <path
                d="M13.751 46.3513H11.0483V49.054H13.751V46.3513Z"
                fill="black"
            />
            <path
                d="M21.859 46.3513H19.1562V49.054H21.859V46.3513Z"
                fill="black"
            />
            <path
                d="M29.9669 46.3513H27.2642V49.054H29.9669V46.3513Z"
                fill="black"
            />
            <path
                d="M32.67 46.3513H29.9673V49.054H32.67V46.3513Z"
                fill="black"
            />
            <path
                d="M38.0753 46.3513H35.3726V49.054H38.0753V46.3513Z"
                fill="black"
            />
            <path
                d="M43.4805 46.3513H40.7778V49.054H43.4805V46.3513Z"
                fill="black"
            />
            <path
                d="M48.8858 46.3513H46.1831V49.054H48.8858V46.3513Z"
                fill="black"
            />
            <path
                d="M51.5889 46.3513H48.8862V49.054H51.5889V46.3513Z"
                fill="black"
            />
            <path
                d="M54.2916 46.3513H51.5889V49.054H54.2916V46.3513Z"
                fill="black"
            />
            <path
                d="M56.9942 46.3513H54.2915V49.054H56.9942V46.3513Z"
                fill="black"
            />
            <path
                d="M73.21 46.3513H70.5073V49.054H73.21V46.3513Z"
                fill="black"
            />
            <path
                d="M78.6158 46.3513H75.9131V49.054H78.6158V46.3513Z"
                fill="black"
            />
            <path
                d="M84.0211 46.3513H81.3184V49.054H84.0211V46.3513Z"
                fill="black"
            />
            <path
                d="M86.7237 46.3513H84.021V49.054H86.7237V46.3513Z"
                fill="black"
            />
            <path
                d="M89.4268 46.3513H86.7241V49.054H89.4268V46.3513Z"
                fill="black"
            />
            <path
                d="M2.94001 49.054H0.237305V51.7567H2.94001V49.054Z"
                fill="black"
            />
            <path
                d="M8.34528 49.054H5.64258V51.7567H8.34528V49.054Z"
                fill="black"
            />
            <path
                d="M13.751 49.054H11.0483V51.7567H13.751V49.054Z"
                fill="black"
            />
            <path
                d="M19.1563 49.054H16.4536V51.7567H19.1563V49.054Z"
                fill="black"
            />
            <path
                d="M21.859 49.054H19.1562V51.7567H21.859V49.054Z"
                fill="black"
            />
            <path
                d="M38.0753 49.054H35.3726V51.7567H38.0753V49.054Z"
                fill="black"
            />
            <path
                d="M40.7779 49.054H38.0752V51.7567H40.7779V49.054Z"
                fill="black"
            />
            <path
                d="M43.4805 49.054H40.7778V51.7567H43.4805V49.054Z"
                fill="black"
            />
            <path
                d="M48.8858 49.054H46.1831V51.7567H48.8858V49.054Z"
                fill="black"
            />
            <path
                d="M51.5889 49.054H48.8862V51.7567H51.5889V49.054Z"
                fill="black"
            />
            <path
                d="M54.2916 49.054H51.5889V51.7567H54.2916V49.054Z"
                fill="black"
            />
            <path
                d="M56.9942 49.054H54.2915V51.7567H56.9942V49.054Z"
                fill="black"
            />
            <path
                d="M59.6968 49.054H56.9941V51.7567H59.6968V49.054Z"
                fill="black"
            />
            <path
                d="M65.1021 49.054H62.3994V51.7567H65.1021V49.054Z"
                fill="black"
            />
            <path
                d="M67.8048 49.054H65.1021V51.7567H67.8048V49.054Z"
                fill="black"
            />
            <path
                d="M70.5079 49.054H67.8052V51.7567H70.5079V49.054Z"
                fill="black"
            />
            <path
                d="M75.9132 49.054H73.2104V51.7567H75.9132V49.054Z"
                fill="black"
            />
            <path
                d="M81.3184 49.054H78.6157V51.7567H81.3184V49.054Z"
                fill="black"
            />
            <path
                d="M84.0211 49.054H81.3184V51.7567H84.0211V49.054Z"
                fill="black"
            />
            <path
                d="M89.4268 49.054H86.7241V51.7567H89.4268V49.054Z"
                fill="black"
            />
            <path
                d="M2.94001 51.7568H0.237305V54.4595H2.94001V51.7568Z"
                fill="black"
            />
            <path
                d="M13.751 51.7568H11.0483V54.4595H13.751V51.7568Z"
                fill="black"
            />
            <path
                d="M16.4537 51.7568H13.751V54.4595H16.4537V51.7568Z"
                fill="black"
            />
            <path
                d="M24.5616 51.7568H21.8589V54.4595H24.5616V51.7568Z"
                fill="black"
            />
            <path
                d="M27.2647 51.7568H24.562V54.4595H27.2647V51.7568Z"
                fill="black"
            />
            <path
                d="M40.7779 51.7568H38.0752V54.4595H40.7779V51.7568Z"
                fill="black"
            />
            <path
                d="M67.8048 51.7568H65.1021V54.4595H67.8048V51.7568Z"
                fill="black"
            />
            <path
                d="M73.21 51.7568H70.5073V54.4595H73.21V51.7568Z"
                fill="black"
            />
            <path
                d="M78.6158 51.7568H75.9131V54.4595H78.6158V51.7568Z"
                fill="black"
            />
            <path
                d="M81.3184 51.7568H78.6157V54.4595H81.3184V51.7568Z"
                fill="black"
            />
            <path
                d="M84.0211 51.7568H81.3184V54.4595H84.0211V51.7568Z"
                fill="black"
            />
            <path
                d="M89.4268 51.7568H86.7241V54.4595H89.4268V51.7568Z"
                fill="black"
            />
            <path
                d="M2.94001 54.4595H0.237305V57.1622H2.94001V54.4595Z"
                fill="black"
            />
            <path
                d="M5.64265 54.4595H2.93994V57.1622H5.64265V54.4595Z"
                fill="black"
            />
            <path
                d="M11.0479 54.4595H8.34521V57.1622H11.0479V54.4595Z"
                fill="black"
            />
            <path
                d="M16.4537 54.4595H13.751V57.1622H16.4537V54.4595Z"
                fill="black"
            />
            <path
                d="M19.1563 54.4595H16.4536V57.1622H19.1563V54.4595Z"
                fill="black"
            />
            <path
                d="M21.859 54.4595H19.1562V57.1622H21.859V54.4595Z"
                fill="black"
            />
            <path
                d="M24.5616 54.4595H21.8589V57.1622H24.5616V54.4595Z"
                fill="black"
            />
            <path
                d="M29.9669 54.4595H27.2642V57.1622H29.9669V54.4595Z"
                fill="black"
            />
            <path
                d="M43.4805 54.4595H40.7778V57.1622H43.4805V54.4595Z"
                fill="black"
            />
            <path
                d="M46.1832 54.4595H43.4805V57.1622H46.1832V54.4595Z"
                fill="black"
            />
            <path
                d="M51.5889 54.4595H48.8862V57.1622H51.5889V54.4595Z"
                fill="black"
            />
            <path
                d="M56.9942 54.4595H54.2915V57.1622H56.9942V54.4595Z"
                fill="black"
            />
            <path
                d="M62.3995 54.4595H59.6968V57.1622H62.3995V54.4595Z"
                fill="black"
            />
            <path
                d="M75.9132 54.4595H73.2104V57.1622H75.9132V54.4595Z"
                fill="black"
            />
            <path
                d="M81.3184 54.4595H78.6157V57.1622H81.3184V54.4595Z"
                fill="black"
            />
            <path
                d="M86.7237 54.4595H84.021V57.1622H86.7237V54.4595Z"
                fill="black"
            />
            <path
                d="M2.94001 57.1621H0.237305V59.8648H2.94001V57.1621Z"
                fill="black"
            />
            <path
                d="M5.64265 57.1621H2.93994V59.8648H5.64265V57.1621Z"
                fill="black"
            />
            <path
                d="M21.859 57.1621H19.1562V59.8648H21.859V57.1621Z"
                fill="black"
            />
            <path
                d="M27.2647 57.1621H24.562V59.8648H27.2647V57.1621Z"
                fill="black"
            />
            <path
                d="M35.3721 57.1621H32.6694V59.8648H35.3721V57.1621Z"
                fill="black"
            />
            <path
                d="M48.8858 57.1621H46.1831V59.8648H48.8858V57.1621Z"
                fill="black"
            />
            <path
                d="M54.2916 57.1621H51.5889V59.8648H54.2916V57.1621Z"
                fill="black"
            />
            <path
                d="M56.9942 57.1621H54.2915V59.8648H56.9942V57.1621Z"
                fill="black"
            />
            <path
                d="M65.1021 57.1621H62.3994V59.8648H65.1021V57.1621Z"
                fill="black"
            />
            <path
                d="M67.8048 57.1621H65.1021V59.8648H67.8048V57.1621Z"
                fill="black"
            />
            <path
                d="M75.9132 57.1621H73.2104V59.8648H75.9132V57.1621Z"
                fill="black"
            />
            <path
                d="M78.6158 57.1621H75.9131V59.8648H78.6158V57.1621Z"
                fill="black"
            />
            <path
                d="M81.3184 57.1621H78.6157V59.8648H81.3184V57.1621Z"
                fill="black"
            />
            <path
                d="M84.0211 57.1621H81.3184V59.8648H84.0211V57.1621Z"
                fill="black"
            />
            <path
                d="M2.94001 59.865H0.237305V62.5677H2.94001V59.865Z"
                fill="black"
            />
            <path
                d="M8.34528 59.865H5.64258V62.5677H8.34528V59.865Z"
                fill="black"
            />
            <path
                d="M11.0479 59.865H8.34521V62.5677H11.0479V59.865Z"
                fill="black"
            />
            <path
                d="M19.1563 59.865H16.4536V62.5677H19.1563V59.865Z"
                fill="black"
            />
            <path
                d="M21.859 59.865H19.1562V62.5677H21.859V59.865Z"
                fill="black"
            />
            <path
                d="M29.9669 59.865H27.2642V62.5677H29.9669V59.865Z"
                fill="black"
            />
            <path
                d="M38.0753 59.865H35.3726V62.5677H38.0753V59.865Z"
                fill="black"
            />
            <path
                d="M40.7779 59.865H38.0752V62.5677H40.7779V59.865Z"
                fill="black"
            />
            <path
                d="M59.6968 59.865H56.9941V62.5677H59.6968V59.865Z"
                fill="black"
            />
            <path
                d="M65.1021 59.865H62.3994V62.5677H65.1021V59.865Z"
                fill="black"
            />
            <path
                d="M73.21 59.865H70.5073V62.5677H73.21V59.865Z"
                fill="black"
            />
            <path
                d="M84.0211 59.865H81.3184V62.5677H84.0211V59.865Z"
                fill="black"
            />
            <path
                d="M86.7237 59.865H84.021V62.5677H86.7237V59.865Z"
                fill="black"
            />
            <path
                d="M89.4268 59.865H86.7241V62.5677H89.4268V59.865Z"
                fill="black"
            />
            <path
                d="M2.94001 62.5676H0.237305V65.2703H2.94001V62.5676Z"
                fill="black"
            />
            <path
                d="M11.0479 62.5676H8.34521V65.2703H11.0479V62.5676Z"
                fill="black"
            />
            <path
                d="M16.4537 62.5676H13.751V65.2703H16.4537V62.5676Z"
                fill="black"
            />
            <path
                d="M24.5616 62.5676H21.8589V65.2703H24.5616V62.5676Z"
                fill="black"
            />
            <path
                d="M27.2647 62.5676H24.562V65.2703H27.2647V62.5676Z"
                fill="black"
            />
            <path
                d="M32.67 62.5676H29.9673V65.2703H32.67V62.5676Z"
                fill="black"
            />
            <path
                d="M38.0753 62.5676H35.3726V65.2703H38.0753V62.5676Z"
                fill="black"
            />
            <path
                d="M40.7779 62.5676H38.0752V65.2703H40.7779V62.5676Z"
                fill="black"
            />
            <path
                d="M43.4805 62.5676H40.7778V65.2703H43.4805V62.5676Z"
                fill="black"
            />
            <path
                d="M48.8858 62.5676H46.1831V65.2703H48.8858V62.5676Z"
                fill="black"
            />
            <path
                d="M51.5889 62.5676H48.8862V65.2703H51.5889V62.5676Z"
                fill="black"
            />
            <path
                d="M62.3995 62.5676H59.6968V65.2703H62.3995V62.5676Z"
                fill="black"
            />
            <path
                d="M65.1021 62.5676H62.3994V65.2703H65.1021V62.5676Z"
                fill="black"
            />
            <path
                d="M67.8048 62.5676H65.1021V65.2703H67.8048V62.5676Z"
                fill="black"
            />
            <path
                d="M70.5079 62.5676H67.8052V65.2703H70.5079V62.5676Z"
                fill="black"
            />
            <path
                d="M75.9132 62.5676H73.2104V65.2703H75.9132V62.5676Z"
                fill="black"
            />
            <path
                d="M78.6158 62.5676H75.9131V65.2703H78.6158V62.5676Z"
                fill="black"
            />
            <path
                d="M84.0211 62.5676H81.3184V65.2703H84.0211V62.5676Z"
                fill="black"
            />
            <path
                d="M86.7237 62.5676H84.021V65.2703H86.7237V62.5676Z"
                fill="black"
            />
            <path
                d="M89.4268 62.5676H86.7241V65.2703H89.4268V62.5676Z"
                fill="black"
            />
            <path
                d="M2.94001 65.2703H0.237305V67.973H2.94001V65.2703Z"
                fill="black"
            />
            <path
                d="M5.64265 65.2703H2.93994V67.973H5.64265V65.2703Z"
                fill="black"
            />
            <path
                d="M13.751 65.2703H11.0483V67.973H13.751V65.2703Z"
                fill="black"
            />
            <path
                d="M16.4537 65.2703H13.751V67.973H16.4537V65.2703Z"
                fill="black"
            />
            <path
                d="M19.1563 65.2703H16.4536V67.973H19.1563V65.2703Z"
                fill="black"
            />
            <path
                d="M27.2647 65.2703H24.562V67.973H27.2647V65.2703Z"
                fill="black"
            />
            <path
                d="M29.9669 65.2703H27.2642V67.973H29.9669V65.2703Z"
                fill="black"
            />
            <path
                d="M38.0753 65.2703H35.3726V67.973H38.0753V65.2703Z"
                fill="black"
            />
            <path
                d="M40.7779 65.2703H38.0752V67.973H40.7779V65.2703Z"
                fill="black"
            />
            <path
                d="M46.1832 65.2703H43.4805V67.973H46.1832V65.2703Z"
                fill="black"
            />
            <path
                d="M56.9942 65.2703H54.2915V67.973H56.9942V65.2703Z"
                fill="black"
            />
            <path
                d="M65.1021 65.2703H62.3994V67.973H65.1021V65.2703Z"
                fill="black"
            />
            <path
                d="M67.8048 65.2703H65.1021V67.973H67.8048V65.2703Z"
                fill="black"
            />
            <path
                d="M70.5079 65.2703H67.8052V67.973H70.5079V65.2703Z"
                fill="black"
            />
            <path
                d="M73.21 65.2703H70.5073V67.973H73.21V65.2703Z"
                fill="black"
            />
            <path
                d="M75.9132 65.2703H73.2104V67.973H75.9132V65.2703Z"
                fill="black"
            />
            <path
                d="M78.6158 65.2703H75.9131V67.973H78.6158V65.2703Z"
                fill="black"
            />
            <path
                d="M86.7237 65.2703H84.021V67.973H86.7237V65.2703Z"
                fill="black"
            />
            <path
                d="M89.4268 65.2703H86.7241V67.973H89.4268V65.2703Z"
                fill="black"
            />
            <path
                d="M24.5616 67.9729H21.8589V70.6756H24.5616V67.9729Z"
                fill="black"
            />
            <path
                d="M27.2647 67.9729H24.562V70.6756H27.2647V67.9729Z"
                fill="black"
            />
            <path
                d="M38.0753 67.9729H35.3726V70.6756H38.0753V67.9729Z"
                fill="black"
            />
            <path
                d="M40.7779 67.9729H38.0752V70.6756H40.7779V67.9729Z"
                fill="black"
            />
            <path
                d="M43.4805 67.9729H40.7778V70.6756H43.4805V67.9729Z"
                fill="black"
            />
            <path
                d="M46.1832 67.9729H43.4805V70.6756H46.1832V67.9729Z"
                fill="black"
            />
            <path
                d="M48.8858 67.9729H46.1831V70.6756H48.8858V67.9729Z"
                fill="black"
            />
            <path
                d="M51.5889 67.9729H48.8862V70.6756H51.5889V67.9729Z"
                fill="black"
            />
            <path
                d="M56.9942 67.9729H54.2915V70.6756H56.9942V67.9729Z"
                fill="black"
            />
            <path
                d="M65.1021 67.9729H62.3994V70.6756H65.1021V67.9729Z"
                fill="black"
            />
            <path
                d="M67.8048 67.9729H65.1021V70.6756H67.8048V67.9729Z"
                fill="black"
            />
            <path
                d="M78.6158 67.9729H75.9131V70.6756H78.6158V67.9729Z"
                fill="black"
            />
            <path
                d="M84.0211 67.9729H81.3184V70.6756H84.0211V67.9729Z"
                fill="black"
            />
            <path
                d="M86.7237 67.9729H84.021V70.6756H86.7237V67.9729Z"
                fill="black"
            />
            <path
                d="M24.5616 70.6755H21.8589V73.3782H24.5616V70.6755Z"
                fill="black"
            />
            <path
                d="M29.9669 70.6755H27.2642V73.3782H29.9669V70.6755Z"
                fill="black"
            />
            <path
                d="M43.4805 70.6755H40.7778V73.3782H43.4805V70.6755Z"
                fill="black"
            />
            <path
                d="M48.8858 70.6755H46.1831V73.3782H48.8858V70.6755Z"
                fill="black"
            />
            <path
                d="M54.2916 70.6755H51.5889V73.3782H54.2916V70.6755Z"
                fill="black"
            />
            <path
                d="M56.9942 70.6755H54.2915V73.3782H56.9942V70.6755Z"
                fill="black"
            />
            <path
                d="M59.6968 70.6755H56.9941V73.3782H59.6968V70.6755Z"
                fill="black"
            />
            <path
                d="M62.3995 70.6755H59.6968V73.3782H62.3995V70.6755Z"
                fill="black"
            />
            <path
                d="M65.1021 70.6755H62.3994V73.3782H65.1021V70.6755Z"
                fill="black"
            />
            <path
                d="M67.8048 70.6755H65.1021V73.3782H67.8048V70.6755Z"
                fill="black"
            />
            <path
                d="M73.21 70.6755H70.5073V73.3782H73.21V70.6755Z"
                fill="black"
            />
            <path
                d="M78.6158 70.6755H75.9131V73.3782H78.6158V70.6755Z"
                fill="black"
            />
            <path
                d="M84.0211 70.6755H81.3184V73.3782H84.0211V70.6755Z"
                fill="black"
            />
            <path
                d="M24.5616 73.3784H21.8589V76.0811H24.5616V73.3784Z"
                fill="black"
            />
            <path
                d="M29.9669 73.3784H27.2642V76.0811H29.9669V73.3784Z"
                fill="black"
            />
            <path
                d="M32.67 73.3784H29.9673V76.0811H32.67V73.3784Z"
                fill="black"
            />
            <path
                d="M35.3721 73.3784H32.6694V76.0811H35.3721V73.3784Z"
                fill="black"
            />
            <path
                d="M40.7779 73.3784H38.0752V76.0811H40.7779V73.3784Z"
                fill="black"
            />
            <path
                d="M43.4805 73.3784H40.7778V76.0811H43.4805V73.3784Z"
                fill="black"
            />
            <path
                d="M48.8858 73.3784H46.1831V76.0811H48.8858V73.3784Z"
                fill="black"
            />
            <path
                d="M51.5889 73.3784H48.8862V76.0811H51.5889V73.3784Z"
                fill="black"
            />
            <path
                d="M62.3995 73.3784H59.6968V76.0811H62.3995V73.3784Z"
                fill="black"
            />
            <path
                d="M65.1021 73.3784H62.3994V76.0811H65.1021V73.3784Z"
                fill="black"
            />
            <path
                d="M67.8048 73.3784H65.1021V76.0811H67.8048V73.3784Z"
                fill="black"
            />
            <path
                d="M78.6158 73.3784H75.9131V76.0811H78.6158V73.3784Z"
                fill="black"
            />
            <path
                d="M81.3184 73.3784H78.6157V76.0811H81.3184V73.3784Z"
                fill="black"
            />
            <path
                d="M84.0211 73.3784H81.3184V76.0811H84.0211V73.3784Z"
                fill="black"
            />
            <path
                d="M89.4268 73.3784H86.7241V76.0811H89.4268V73.3784Z"
                fill="black"
            />
            <path
                d="M24.5616 76.0811H21.8589V78.7838H24.5616V76.0811Z"
                fill="black"
            />
            <path
                d="M32.67 76.0811H29.9673V78.7838H32.67V76.0811Z"
                fill="black"
            />
            <path
                d="M35.3721 76.0811H32.6694V78.7838H35.3721V76.0811Z"
                fill="black"
            />
            <path
                d="M38.0753 76.0811H35.3726V78.7838H38.0753V76.0811Z"
                fill="black"
            />
            <path
                d="M40.7779 76.0811H38.0752V78.7838H40.7779V76.0811Z"
                fill="black"
            />
            <path
                d="M46.1832 76.0811H43.4805V78.7838H46.1832V76.0811Z"
                fill="black"
            />
            <path
                d="M48.8858 76.0811H46.1831V78.7838H48.8858V76.0811Z"
                fill="black"
            />
            <path
                d="M65.1021 76.0811H62.3994V78.7838H65.1021V76.0811Z"
                fill="black"
            />
            <path
                d="M67.8048 76.0811H65.1021V78.7838H67.8048V76.0811Z"
                fill="black"
            />
            <path
                d="M70.5079 76.0811H67.8052V78.7838H70.5079V76.0811Z"
                fill="black"
            />
            <path
                d="M73.21 76.0811H70.5073V78.7838H73.21V76.0811Z"
                fill="black"
            />
            <path
                d="M75.9132 76.0811H73.2104V78.7838H75.9132V76.0811Z"
                fill="black"
            />
            <path
                d="M78.6158 76.0811H75.9131V78.7838H78.6158V76.0811Z"
                fill="black"
            />
            <path
                d="M86.7237 76.0811H84.021V78.7838H86.7237V76.0811Z"
                fill="black"
            />
            <path
                d="M24.5616 78.7837H21.8589V81.4864H24.5616V78.7837Z"
                fill="black"
            />
            <path
                d="M27.2647 78.7837H24.562V81.4864H27.2647V78.7837Z"
                fill="black"
            />
            <path
                d="M32.67 78.7837H29.9673V81.4864H32.67V78.7837Z"
                fill="black"
            />
            <path
                d="M35.3721 78.7837H32.6694V81.4864H35.3721V78.7837Z"
                fill="black"
            />
            <path
                d="M40.7779 78.7837H38.0752V81.4864H40.7779V78.7837Z"
                fill="black"
            />
            <path
                d="M46.1832 78.7837H43.4805V81.4864H46.1832V78.7837Z"
                fill="black"
            />
            <path
                d="M51.5889 78.7837H48.8862V81.4864H51.5889V78.7837Z"
                fill="black"
            />
            <path
                d="M54.2916 78.7837H51.5889V81.4864H54.2916V78.7837Z"
                fill="black"
            />
            <path
                d="M59.6968 78.7837H56.9941V81.4864H59.6968V78.7837Z"
                fill="black"
            />
            <path
                d="M65.1021 78.7837H62.3994V81.4864H65.1021V78.7837Z"
                fill="black"
            />
            <path
                d="M67.8048 78.7837H65.1021V81.4864H67.8048V78.7837Z"
                fill="black"
            />
            <path
                d="M70.5079 78.7837H67.8052V81.4864H70.5079V78.7837Z"
                fill="black"
            />
            <path
                d="M75.9132 78.7837H73.2104V81.4864H75.9132V78.7837Z"
                fill="black"
            />
            <path
                d="M81.3184 78.7837H78.6157V81.4864H81.3184V78.7837Z"
                fill="black"
            />
            <path
                d="M84.0211 78.7837H81.3184V81.4864H84.0211V78.7837Z"
                fill="black"
            />
            <path
                d="M86.7237 78.7837H84.021V81.4864H86.7237V78.7837Z"
                fill="black"
            />
            <path
                d="M89.4268 78.7837H86.7241V81.4864H89.4268V78.7837Z"
                fill="black"
            />
            <path
                d="M27.2647 81.4866H24.562V84.1893H27.2647V81.4866Z"
                fill="black"
            />
            <path
                d="M35.3721 81.4866H32.6694V84.1893H35.3721V81.4866Z"
                fill="black"
            />
            <path
                d="M43.4805 81.4866H40.7778V84.1893H43.4805V81.4866Z"
                fill="black"
            />
            <path
                d="M54.2916 81.4866H51.5889V84.1893H54.2916V81.4866Z"
                fill="black"
            />
            <path
                d="M59.6968 81.4866H56.9941V84.1893H59.6968V81.4866Z"
                fill="black"
            />
            <path
                d="M65.1021 81.4866H62.3994V84.1893H65.1021V81.4866Z"
                fill="black"
            />
            <path
                d="M67.8048 81.4866H65.1021V84.1893H67.8048V81.4866Z"
                fill="black"
            />
            <path
                d="M75.9132 81.4866H73.2104V84.1893H75.9132V81.4866Z"
                fill="black"
            />
            <path
                d="M78.6158 81.4866H75.9131V84.1893H78.6158V81.4866Z"
                fill="black"
            />
            <path
                d="M81.3184 81.4866H78.6157V84.1893H81.3184V81.4866Z"
                fill="black"
            />
            <path
                d="M86.7237 81.4866H84.021V84.1893H86.7237V81.4866Z"
                fill="black"
            />
            <path
                d="M89.4268 81.4866H86.7241V84.1893H89.4268V81.4866Z"
                fill="black"
            />
            <path
                d="M40.7779 84.1892H38.0752V86.8919H40.7779V84.1892Z"
                fill="black"
            />
            <path
                d="M54.2916 84.1892H51.5889V86.8919H54.2916V84.1892Z"
                fill="black"
            />
            <path
                d="M56.9942 84.1892H54.2915V86.8919H56.9942V84.1892Z"
                fill="black"
            />
            <path
                d="M65.1021 84.1892H62.3994V86.8919H65.1021V84.1892Z"
                fill="black"
            />
            <path
                d="M73.21 84.1892H70.5073V86.8919H73.21V84.1892Z"
                fill="black"
            />
            <path
                d="M75.9132 84.1892H73.2104V86.8919H75.9132V84.1892Z"
                fill="black"
            />
            <path
                d="M78.6158 84.1892H75.9131V86.8919H78.6158V84.1892Z"
                fill="black"
            />
            <path
                d="M81.3184 84.1892H78.6157V86.8919H81.3184V84.1892Z"
                fill="black"
            />
            <path
                d="M84.0211 84.1892H81.3184V86.8919H84.0211V84.1892Z"
                fill="black"
            />
            <path
                d="M86.7237 84.1892H84.021V86.8919H86.7237V84.1892Z"
                fill="black"
            />
            <path
                d="M89.4268 84.1892H86.7241V86.8919H89.4268V84.1892Z"
                fill="black"
            />
            <path
                d="M24.5616 86.8918H21.8589V89.5946H24.5616V86.8918Z"
                fill="black"
            />
            <path
                d="M29.9669 86.8918H27.2642V89.5946H29.9669V86.8918Z"
                fill="black"
            />
            <path
                d="M32.67 86.8918H29.9673V89.5946H32.67V86.8918Z"
                fill="black"
            />
            <path
                d="M35.3721 86.8918H32.6694V89.5946H35.3721V86.8918Z"
                fill="black"
            />
            <path
                d="M38.0753 86.8918H35.3726V89.5946H38.0753V86.8918Z"
                fill="black"
            />
            <path
                d="M43.4805 86.8918H40.7778V89.5946H43.4805V86.8918Z"
                fill="black"
            />
            <path
                d="M46.1832 86.8918H43.4805V89.5946H46.1832V86.8918Z"
                fill="black"
            />
            <path
                d="M48.8858 86.8918H46.1831V89.5946H48.8858V86.8918Z"
                fill="black"
            />
            <path
                d="M51.5889 86.8918H48.8862V89.5946H51.5889V86.8918Z"
                fill="black"
            />
            <path
                d="M54.2916 86.8918H51.5889V89.5946H54.2916V86.8918Z"
                fill="black"
            />
            <path
                d="M67.8048 86.8918H65.1021V89.5946H67.8048V86.8918Z"
                fill="black"
            />
            <path
                d="M70.5079 86.8918H67.8052V89.5946H70.5079V86.8918Z"
                fill="black"
            />
            <path
                d="M75.9132 86.8918H73.2104V89.5946H75.9132V86.8918Z"
                fill="black"
            />
            <path
                d="M78.6158 86.8918H75.9131V89.5946H78.6158V86.8918Z"
                fill="black"
            />
            <path
                d="M16.3194 0.405518H3.07612H0.238281V3.24336V16.4866V19.3244H3.07612H16.3194H19.1572V16.4866V3.24336V0.405518H16.3194ZM16.3194 16.4866H3.07612V3.24336H16.3194V16.4866Z"
                fill="black"
            />
            <path
                d="M86.5884 0.405518H73.3452H70.5073V3.24336V16.4866V19.3244H73.3452H86.5884H89.4262V16.4866V3.24336V0.405518H86.5884ZM86.5884 16.4866H73.3452V3.24336H86.5884V16.4866Z"
                fill="black"
            />
            <path
                d="M16.3184 70.6755H3.07514H0.237305V73.5134V86.7566V89.5945H3.07514H16.3184H19.1562V86.7566V73.5134V70.6755H16.3184ZM16.3184 86.7566H3.07514V73.5134H16.3184V86.7566Z"
                fill="black"
            />
            <path
                d="M13.7507 5.81079H5.64258V13.9189H13.7507V5.81079Z"
                fill="black"
            />
            <path
                d="M84.0212 5.81079H75.9131V13.9189H84.0212V5.81079Z"
                fill="black"
            />
            <path
                d="M13.7507 76.0811H5.64258V84.1892H13.7507V76.0811Z"
                fill="black"
            />
        </svg>
    )
}

export default QR_iOS
