import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"

const A = ({ children, href, target, rel, onClick }) => {
    return (
        <Container
            href={href}
            target={target}
            rel={rel}
            onClick={onClick}
        >
            {children}
        </Container>
    )
}

const Container = styled.a`
    color: ${(props) => props.theme.inputForeground};
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`

export default A
