import { useTheme } from "styled-components"

function SvgEraser({ fill, width = 18 }) {
    const theme = useTheme()
    const height = width * (18 / 20)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.414 7.414L2.83 12l4 4h2.343l3.414-3.414-5.172-5.172zm11.172 2L10 18H6l-4.586-4.586a2 2 0 010-2.828l9.172-9.172a2 2 0 012.828 0l5.172 5.172a2 2 0 010 2.828z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default SvgEraser
