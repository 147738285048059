import * as React from "react"
import Colors from "../colors"

function LogoIcon({ fill = Colors.black, size = 70 }) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 70 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M47.5 25H40v32.5h7.5V25zM65 25h-7.5v32.5H65V25zM70 15L35 0 0 15v5h70v-5zM30 25h-7.5v32.5H30V25zM70 62.5H0V70h70v-7.5zM12.5 25H5v32.5h7.5V25z"
                fill={fill}
            />
        </svg>
    )
}

export default LogoIcon
