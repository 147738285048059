import React from "react"
import { useTheme } from "styled-components"

const Dictionary = ({ fill, width = 20 }) => {
    const theme = useTheme()
    const height = width
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 3.50002H10.72L10.4 2.50002C10.1926 1.91325 9.80775 1.40553 9.2989 1.04718C8.79005 0.688841 8.18236 0.497623 7.56 0.500022H3C2.20435 0.500022 1.44129 0.816093 0.87868 1.3787C0.316071 1.94131 0 2.70437 0 3.50002V16.5C0 17.2957 0.316071 18.0587 0.87868 18.6213C1.44129 19.184 2.20435 19.5 3 19.5H17C17.7956 19.5 18.5587 19.184 19.1213 18.6213C19.6839 18.0587 20 17.2957 20 16.5V6.50002C20 5.70437 19.6839 4.94131 19.1213 4.3787C18.5587 3.81609 17.7956 3.50002 17 3.50002ZM18 16.5C18 16.7652 17.8946 17.0196 17.7071 17.2071C17.5196 17.3947 17.2652 17.5 17 17.5H3C2.73478 17.5 2.48043 17.3947 2.29289 17.2071C2.10536 17.0196 2 16.7652 2 16.5V3.50002C2 3.23481 2.10536 2.98045 2.29289 2.79292C2.48043 2.60538 2.73478 2.50002 3 2.50002H7.56C7.76964 2.49948 7.97416 2.56484 8.14463 2.68686C8.3151 2.80889 8.44291 2.9814 8.51 3.18002L9.05 4.82002C9.11709 5.01864 9.2449 5.19116 9.41537 5.31318C9.58584 5.4352 9.79036 5.50056 10 5.50002H17C17.2652 5.50002 17.5196 5.60538 17.7071 5.79292C17.8946 5.98045 18 6.23481 18 6.50002V16.5Z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default Dictionary
