import { createContext, useState, useEffect } from "react"
import { authMethods } from "../firebase/auth"
import firebase from "firebase"
import {
    grantAccessToContract,
    transferAnonymouslyCreatedContractsToUser
} from "../utils/api"

const AuthProvider = (props) => {
    // Email auth related
    const [email, setEmail] = useState("")
    const [isSignupEmailSent, setIsSignupEmailSent] = useState(false)
    const [isLinkEmailSent, setIsLinkEmailSent] = useState(false)
    const [isLinkingCompleted, setLinkingCompleted] = useState(false)

    // Phone auth related
    const [phoneNumber, setPhoneNumber] = useState("")
    const [confirmationResultRef, setConfirmationResultRef] = useState(null)
    const [isOtpSent, setIsOtpSent] = useState(false)
    const [otp, setOtp] = useState("")

    // General
    const [errors, setErrors] = useState([])
    const [refreshTokenError, setRefreshTokenError] = useState(null)
    const [token, setToken] = useState(
        localStorage.getItem("lawyered.user.token")
    )
    const [approved, setApproved] = useState(false)
    const [isAnonymous, setIsAnonymous] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [currentUser, setCurrentUser] = useState({})

    const getFriendlyErrorMessage = (error) => {
        if (error.code === "auth/invalid-phone-number") {
            return "Ugyldig telefonnummer"
        } else if (error.code === "auth/invalid-verification-id") {
            return "Inntastet kode er feil eller ugyldig"
        } else if (error.code === "auth/invalid-verification-code") {
            return "Inntastet kode er feil eller ugyldig"
        } else if (error.code === "auth/credential-already-in-use") {
            return "Innloggingen eksisterer allerede"
        } else if (error.code === "auth/provider-already-linked") {
            return "Vi har allerede dette i våre systemer."
        }
        return `${error.message} (${error.code})`
    }

    const handleSignupEmail = (emailFallback = null) => {
        return authMethods.signupEmail(
            emailFallback ? emailFallback : email,
            setIsLoading,
            setErrors,
            setIsSignupEmailSent
        )
    }

    const handleSignin = (url) => {
        return authMethods.signin(
            url,
            token,
            currentUser,
            setApproved,
            setIsAnonymous,
            setToken,
            setIsLoading,
            setErrors
        )
    }

    const handleLinking = (url) => {
        return authMethods.link(
            url,
            currentUser,
            setIsLoading,
            setLinkingCompleted,
            setErrors
        )
    }

    const handleSignout = () => {
        return authMethods.signout(
            setApproved,
            setIsAnonymous,
            setToken,
            setIsLoading,
            setErrors
        )
    }

    const anonymousSignin = () => {
        return authMethods.anonymousSignin(
            setToken,
            setApproved,
            setIsAnonymous,
            setIsLoading,
            setErrors
        )
    }

    const refreshToken = () => {
        return authMethods.refreshToken(setToken, setRefreshTokenError)
    }

    const transferAnonymousContractsToUser = async (user) => {
        const anonContracts = localStorage.getItem(
            "lawyered.anonymous_contracts"
        )
        if (anonContracts && token) {
            const data = JSON.parse(anonContracts)
            const transferResult =
                await transferAnonymouslyCreatedContractsToUser({
                    token,
                    contracts: data
                })
            console.log("transferResult", transferResult)
            localStorage.removeItem("lawyered.anonymous_contracts")
        }
    }

    const addContractsToAccessList = async (user) => {
        const accessToContracts = localStorage.getItem(
            "lawyered.access_to_contracts"
        )
        if (accessToContracts && token) {
            const data = JSON.parse(accessToContracts)
            const accessResult = await grantAccessToContract({
                token,
                contracts: data
            })
            console.log("accessResult", accessResult)
            localStorage.removeItem("lawyered.access_to_contracts")
        }
    }

    const [authStateChangedWithUserReady, setAuthStateChangedWithUserReady] =
        useState(false)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            console.log(`onAuthStateChanged`, user)
            if (user) {
                console.log(`Setting current user`, user)
                setCurrentUser(user)
                setAuthStateChangedWithUserReady(true)
                if (!user.isAnonymous) {
                    // Transfer all contracts created when logged out to the logged in account
                    if(user.Aa){
                        localStorage.setItem("lawyered.user.token", user.Aa)
                    }
                    transferAnonymousContractsToUser(user)
                    addContractsToAccessList(user)
                }
            } else {
                console.log(`Signing in anonymously`)
                anonymousSignin()
            }
        })
    }, [])

    return (
        <FirebaseAuth.Provider
            value={{
                handleSignupEmail,
                handleSignin,
                handleSignout,
                handleLinking,
                anonymousSignin,
                refreshToken,
                refreshTokenError,
                currentUser,
                authStateChangedWithUserReady,
                email,
                phoneNumber,
                approved,
                token,
                otp,
                setEmail,
                setPhoneNumber,
                setApproved,
                setToken,
                setOtp,
                isAnonymous,
                isOtpSent,
                isSignupEmailSent,
                isLinkEmailSent,
                isLinkingCompleted,
                isLoading,
                setIsLoading,
                errors,
                getFriendlyErrorMessage
            }}
        >
            {props.children}
        </FirebaseAuth.Provider>
    )
}

export const FirebaseAuth = createContext()

export default AuthProvider

/* Ref.: https://dev.to/itnext/user-auth-with-firebase-and-react-1725 */
