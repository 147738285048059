const theme = {
    backgroundPrimary: "#F5F3FF", //purple[50]
    backgroundSecondary: "#EDE9FE", //purple[100]
    backgroundSecondaryHover: "#DDD6FE", //purple[200]

    backgroundTertiary: "#FFF", // Needed for e.g. Box-backgrounds, which are white for Lawyered

    foregroundPrimary: "#4C1D95", //purple[900]
    foregroundHover: "#A78BFA", //purple[400]
    foregroundSecondary: "#6D28D9", //purple[700]

    inputForeground: "#7C3AED", //purple[600]
    inputForegroundHover: "#6D28D9", //purple[700]
    inputForegroundSecondary: "#DDD6FE" //purple[200]
}

export default theme
