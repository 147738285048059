import { useTheme } from "styled-components"

function RotateDevice({ width = 46, stroke }) {
    const theme = useTheme()
    const height = width * (40 / 46)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 46 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.465 15.69a.75.75 0 01-.247-.157l-2.25-2.25a.753.753 0 011.064-1.066l.968.976V6a.75.75 0 00-.75-.75h-3a.75.75 0 110-1.5h3A2.25 2.25 0 0132.5 6v7.193l.968-.976a.75.75 0 011.229.245.751.751 0 01-.165.82l-2.25 2.25a.75.75 0 01-.247.158.75.75 0 01-.57 0z"
                fill={stroke || theme.inputForeground}
            />
            <rect
                x={0.625}
                y={0.625}
                width={19.75}
                height={38.75}
                rx={3.375}
                fill="#fff"
                stroke={stroke || theme.inputForeground}
                strokeWidth={1.25}
            />
            <rect
                x={45.375}
                y={19.625}
                width={19.75}
                height={38.75}
                rx={3.375}
                transform="rotate(90 45.375 19.625)"
                fill="#fff"
                stroke={stroke || theme.inputForeground}
                strokeWidth={1.25}
            />
        </svg>
    )
}

export default RotateDevice
