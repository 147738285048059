import { Link, NavLink } from "react-router-dom"
import Colors from "../../assets/colors"
import styled, { useTheme } from "styled-components"

import { useContext, useEffect, useState } from "react"
import { useIntercom } from "react-use-intercom"
import { FirebaseAuth } from "../../providers/AuthProvider"
import { getAuthUserData } from "../../utils/api"

import MenuItem from "../menuItem"
import { Logo, LogoIcon } from "../../assets/icons"

const WithSidebar = ({ path, children }) => {
    const theme = useTheme()
    const { currentUser, token, handleSignout } = useContext(FirebaseAuth)
    const [userData, setUserData] = useState(null)
    const [user, setUser] = useState({})

    const { shutdown } = useIntercom()

    useEffect(() => {
        async function getUser() {
            const userData = await getAuthUserData(token)
            const dbData = userData?.userData?.dbData || setUserData(userData)
            setUser({
                name: dbData?.name || null,
                email: dbData?.email || currentUser.email || null,
                phoneNumber:
                    dbData?.phoneNumber || currentUser.phoneNumber || null
            })
        }
        if (token) {
            getUser()
        }
    }, [])

    const signout = () => {
        shutdown()
        handleSignout()
        window.location.href = "/dashboard"
    }

    return (
        <PageWrapper>
            <Sidebar>
                <Primary>
                    <LogoSection>
                        <LogoIconWrapper>
                            <LogoIcon size="24" fill={theme.foregroundPrimary} />
                        </LogoIconWrapper>
                        <LogoWrapper>
                            <Logo width="150" fill={theme.foregroundPrimary} />
                        </LogoWrapper>
                    </LogoSection>
                    <Navigation>
                        <NavLink to="/dashboard">
                            <MenuItem
                                text={"Dashboard"}
                                icon={"Home"}
                                iconWidth={24}
                                iconHeight={24}
                                selected={path === "/dashboard"}
                            />
                        </NavLink>
                        <NavLink to="/create">
                            <MenuItem
                                text={"Ny kontrakt"}
                                icon={"Plus"}
                                iconWidth={18}
                                iconHeight={18}
                                selected={path === "/create"}
                            />
                        </NavLink>
                        <NavLink to="/upload">
                            <MenuItem
                                text={"Last opp kontrakt"}
                                icon={"Upload"}
                                iconWidth={18}
                                iconHeight={18}
                                selected={path === "/upload"}
                            />
                        </NavLink>
                        <NavLink to="/archive">
                            <MenuItem
                                text={"Arkiv"}
                                icon={"Dictionary"}
                                iconWidth={20}
                                iconHeight={20}
                                selected={path === "/archive"}
                            />
                        </NavLink>
                        <NavLink to="/settings">
                            <MenuItem
                                text={"Innstillinger"}
                                icon={"Settings"}
                                iconWidth={20}
                                iconHeight={20}
                                selected={path === "/settings"}
                            />
                        </NavLink>
                        <NavLink to="/bulk-contracts">
                            <MenuItem
                                text={"Kontraktspakker"}
                                icon={"Document"}
                                iconWidth={20}
                                iconHeight={20}
                                selected={path === "/bulk-contracts"}
                            />
                        </NavLink>
                    </Navigation>
                </Primary>
                <Secondary>
                    {token && (user.name || user.email || user.phoneNumber) && (
                        <MenuItem
                            onClick={() => signout()}
                            text={`
                                ${user.name || user.email || user.phoneNumber
                                } (Logg ut)`}
                            icon={"Building"}
                        />
                    )}
                    {(!token || currentUser.isAnonymous) && (
                        <Link to="/onboarding">
                            <MenuItem
                                text={"Logg inn eller registrer deg"}
                                icon={"Profile"}
                            />
                        </Link>
                    )}
                </Secondary>
            </Sidebar>
            <Main>{children}</Main>
        </PageWrapper>
    )
}

const PageWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`

const Main = styled.div`
    display: flex;
    flex: 1;
    background-color: ${(props) => props.theme.backgroundPrimary};
    align-items: flex-start;
    padding-top: 76px;
    position: relative;
    @media screen and (min-width: 768px) {
        padding-top: 0;
    }
`

const Sidebar = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    height: 44px;
    padding: 16px;
    width: calc(100% - 32px);
    position: fixed;
    @media screen and (min-width: 768px) {
        position: sticky;
        width: auto;
        flex-direction: column;
        height: calc(100vh - 16px - 32px);
        padding: 32px 16px 16px;
    }
    background-color: ${(props) => (props.noBackground ? "" : Colors.white)};
    border: ${(props) => (props.noBackground ? `1px dashed ${props.theme.foregroundPrimary}` : "")};

    top: 0;
    z-index: 1050;
    > * {
        margin-bottom: 12px;
    }
    a {
        text-decoration: none;
    }
`

const Primary = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
        justify-content: flex-start;
        flex-direction: column;
    }
`

const Navigation = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 768px) {
        flex-direction: column;
    }
`

const Secondary = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 768px) {
        flex-direction: column;
    }
    > button > div {
        align-items: flex-start;
        text-align: left;
    }
`

const LogoSection = styled.div`
    align-items: flex-end;
    margin-top: 8px;
    @media screen and (min-width: 768px) {
        margin-bottom: 32px;
        margin-left: 8px;
    }
`

const LogoIconWrapper = styled.div`
    display: flex;
    margin-right: 12px;
    @media screen and (min-width: 768px) {
        display: none;
    }
`

const LogoWrapper = styled.div`
    display: none;
    @media screen and (min-width: 768px) {
        display: flex;
    }
`

export default WithSidebar
