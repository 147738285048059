const multiply = (field, formFields) => {
    let factors = []
    field.factors.forEach((factor) => {
        factors.push(Number(formFields[factor]))
    })
    return factors.reduce((a, b) => a * b, 1)
}

const add = (field, formFields) => {
    let addends = []
    field.addends.forEach((addend) => {
        addends.push(Number(formFields[addend]))
    })
    return addends.reduce((a, b) => a + b, 0)
}

export const calculate = (field, formFields) => {
    if (field.type === "multiply") {
        return multiply(field, formFields)
    }
    if (field.type === "addition") {
        return add(field, formFields)
    }
}
