import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import danger from "../../assets/icons/svg/Danger.svg"

const TextInput = ({
    icon,
    onChange = () => {},
    placeholder,
    value,
    type,
    readonly,
    maxlength,
    name,
    required,
    shouldDisplayValidationErrors,
    id
}) => {
    return (
        <Container readOnly={readonly}>
            {icon && <Icon icon={icon} fill={Colors.gray[400]} />}
            <Input
                className={shouldDisplayValidationErrors ? "invalid" : ""}
                value={value}
                type={type || "text"}
                placeholder={placeholder}
                onChange={onChange}
                readOnly={readonly}
                maxLength={maxlength}
                name={name}
                required={required}
                id={id}
                hasIcon={!!icon}
            />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;

    svg {
        position: absolute;
        top: 15px;
        left: 12px;
        margin: 0;
    }
`

const Input = styled.input`
    width: 100%;
    height: 42px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    padding: 0 0 0 ${(props) => (props.hasIcon ? "38px" : "8px")};
    color: ${Colors.gray[900]};
    background-color: ${(props) =>
        props.readOnly ? Colors.gray[100] : Colors.white};
    border: 1px solid ${Colors.gray[300]};
    border-radius: 8px;
    &:focus {
        outline: none;
    }
    &.invalid:invalid {
        border: 1px solid ${Colors.red[600]};
        background-image: url(${danger});
        background-repeat: no-repeat;
        background-position: ${(props) =>
                props.type === "date" ? "calc(80% - 6px)" : "calc(100% - 6px)"}
            13px;
        background-size: 18px 15px;
    }
`

export default TextInput
