import styled, { useTheme } from "styled-components"
import Colors from "../../assets/colors"
import { Cancel, Checkmark, Menu } from "../../assets/icons"

const getBgColor = (type) => {
    const theme = useTheme()
    switch (type) {
        case "success":
            return Colors.green[100]
        case "warning":
            return Colors.yellow[100]
        case "error":
            return Colors.red[100]
        case "info":
        default:
            return theme.backgroundSecondary
    }
}

const getColor = (type) => {
    const theme = useTheme()
    switch (type) {
        case "success":
            return Colors.green[600]
        case "warning":
            return Colors.yellow[600]
        case "error":
            return Colors.red[600]
        case "info":
        default:
            return theme.inputForeground
    }
}

const Feedback = ({ description, type = "info" }) => {
    return (
        <Wrapper>
            <Circle type={type}>
                {type === "info" && <Checkmark fill={getColor(type)} />}
                {type === "success" && <Checkmark fill={getColor(type)} />}
                {type === "warning" && <Menu fill={getColor(type)} />}
                {type === "error" && <Cancel fill={getColor(type)} />}
            </Circle>
            <Description type={type}>{description}</Description>
        </Wrapper>
    )
}

const Wrapper = styled.li`
    display: flex;
    align-items: center;
    padding: 12px 12px 12px 0;
`

const Circle = styled.span`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    border: 2px solid ${(props) => getBgColor(props.type)};

    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    color: ${(props) => getColor(props.type)};
    background-color: ${(props) => getBgColor(props.type)};
    font-weight: 500;
`

const Description = styled.p`
    color: ${(props) => getColor(props.type)};
    font-weight: 500;
    padding-left: 16px;
    font-size: 14px;
`

export default Feedback
