import useSWR, { mutate } from "swr";
import { apiUrl, fetchWithToken } from "./fetcher";
import { useContext } from "react";
import { FirebaseAuth } from "../providers/AuthProvider";

function useBulkContracts() {
  const { token } = useContext(FirebaseAuth);

  const { data, error } = useSWR([`${apiUrl}/bulk_contract`, token], fetchWithToken);

  const refetchData = async () => {
    // Manually trigger the refetch by mutating the cache
    await mutate([`${apiUrl}/bulk_contracts`, token]);
  };

  return {
    bulkContracts: data?.bulkContractData,
    orders: data?.ordersData,
    isLoading: !error && !data,
    isError: error,
    refetchData, // Add refetchData function to the returned object
  };
}

export { useBulkContracts };
