import styled from "styled-components"

import { Route, useRouteMatch } from "react-router-dom"

import WithSidebar from "../components/withSidebar"
import DocumentationLanding from "../containers/docs/DocumentationLanding"
import DocumentationPrefill from "../containers/docs/DocumentationPrefill"
import DocumentationWidget from "../containers/docs/DocumentationWidget"
import DocumentationIframe from "../containers/docs/DocumentationIframe"
import DocumentationUpload from "../containers/docs/DocumentationUpload"
import DocumentationTemplates from "../containers/docs/DocumentationTemplates"

const Documentation = () => {
    // (https://reactrouter.com/web/example/nesting)
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path } = useRouteMatch()

    return (
        <>
            <WithSidebar path={path}>
                <Container>
                    <Route exact path={path}>
                        <DocumentationLanding path={path} />
                    </Route>
                    <Route exact path={`${path}/templates`}>
                        <DocumentationTemplates path={path} />
                    </Route>
                    <Route exact path={`${path}/prefill-url`}>
                        <DocumentationPrefill path={path} />
                    </Route>
                    <Route exact path={`${path}/widget`}>
                        <DocumentationWidget path={path} />
                    </Route>
                    <Route exact path={`${path}/iframe`}>
                        <DocumentationIframe path={path} />
                    </Route>
                    <Route exact path={`${path}/upload`}>
                        <DocumentationUpload path={path} />
                    </Route>
                </Container>
            </WithSidebar>
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export default Documentation
