import * as React from "react";

function TagIcon({ width = 120 }) {
    const iconBaseWidth = 150;
    const iconBaseHeight = 34;
    const height = (width * iconBaseHeight) / iconBaseWidth;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <g clip-path="url(#clip0_124_114)">
                <path d="M11.1912 18.375L2.625 9.80875V11.5587C2.625 12.0225 2.80875 12.4687 3.14125 12.7925L9.9575 19.6087C10.64 20.2912 11.7513 20.2912 12.4338 19.6087L17.8675 14.175C18.55 13.4925 18.55 12.3812 17.8675 11.6987L11.1912 18.375Z" fill="#5B4379" />
                <path d="M9.9575 15.2337C10.64 15.9163 11.7513 15.9163 12.4338 15.2337L17.8675 9.8C18.55 9.1175 18.55 8.00625 17.8675 7.32375L11.0513 0.5075C10.7189 0.183139 10.2732 0.00108966 9.80875 0L4.375 0C3.4125 0 2.625 0.7875 2.625 1.75V7.18375C2.625 7.6475 2.80875 8.09375 3.14125 8.4175L9.9575 15.2337ZM6.34375 2.625C6.63383 2.625 6.91203 2.74023 7.11715 2.94535C7.32227 3.15047 7.4375 3.42867 7.4375 3.71875C7.4375 4.00883 7.32227 4.28703 7.11715 4.49215C6.91203 4.69727 6.63383 4.8125 6.34375 4.8125C6.05367 4.8125 5.77547 4.69727 5.57035 4.49215C5.36523 4.28703 5.25 4.00883 5.25 3.71875C5.25 3.42867 5.36523 3.15047 5.57035 2.94535C5.77547 2.74023 6.05367 2.625 6.34375 2.625Z" fill="#5B4379" />
            </g>
            <defs>
                <clipPath id="clip0_124_114">
                    <rect width="21" height="21" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default TagIcon;
