import React from "react"

const QR_Android = () => {
    return (
        <svg
            width="91"
            height="92"
            viewBox="0 0 91 92"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M68.999 0.878174H71.438V3.3172H68.999V0.878174Z"
                fill="black"
            />
            <path
                d="M66.56 0.878174H68.999V3.3172H66.56V0.878174Z"
                fill="black"
            />
            <path
                d="M61.6821 0.878174H64.1211V3.3172H61.6821V0.878174Z"
                fill="black"
            />
            <path
                d="M49.4868 0.878174H51.9258V3.3172H49.4868V0.878174Z"
                fill="black"
            />
            <path
                d="M47.0478 0.878174H49.4868V3.3172H47.0478V0.878174Z"
                fill="black"
            />
            <path
                d="M42.1699 0.878174H44.6089V3.3172H42.1699V0.878174Z"
                fill="black"
            />
            <path
                d="M39.7304 0.878174H42.1694V3.3172H39.7304V0.878174Z"
                fill="black"
            />
            <path
                d="M37.292 0.878174H39.731V3.3172H37.292V0.878174Z"
                fill="black"
            />
            <path
                d="M34.8525 0.878174H37.2915V3.3172H34.8525V0.878174Z"
                fill="black"
            />
            <path
                d="M32.4135 0.878174H34.8525V3.3172H32.4135V0.878174Z"
                fill="black"
            />
            <path
                d="M25.0966 0.878174H27.5356V3.3172H25.0966V0.878174Z"
                fill="black"
            />
            <path
                d="M22.6572 0.878174H25.0962V3.3172H22.6572V0.878174Z"
                fill="black"
            />
            <path
                d="M68.999 3.31714H71.438V5.75616H68.999V3.31714Z"
                fill="black"
            />
            <path
                d="M64.121 3.31714H66.5601V5.75616H64.121V3.31714Z"
                fill="black"
            />
            <path
                d="M56.8037 3.31714H59.2427V5.75616H56.8037V3.31714Z"
                fill="black"
            />
            <path
                d="M54.3652 3.31714H56.8042V5.75616H54.3652V3.31714Z"
                fill="black"
            />
            <path
                d="M47.0478 3.31714H49.4868V5.75616H47.0478V3.31714Z"
                fill="black"
            />
            <path
                d="M44.6088 3.31714H47.0479V5.75616H44.6088V3.31714Z"
                fill="black"
            />
            <path
                d="M42.1699 3.31714H44.6089V5.75616H42.1699V3.31714Z"
                fill="black"
            />
            <path
                d="M37.292 3.31714H39.731V5.75616H37.292V3.31714Z"
                fill="black"
            />
            <path
                d="M32.4135 3.31714H34.8525V5.75616H32.4135V3.31714Z"
                fill="black"
            />
            <path
                d="M29.9746 3.31714H32.4136V5.75616H29.9746V3.31714Z"
                fill="black"
            />
            <path
                d="M68.999 5.7561H71.438V8.19513H68.999V5.7561Z"
                fill="black"
            />
            <path d="M66.56 5.7561H68.999V8.19513H66.56V5.7561Z" fill="black" />
            <path
                d="M64.121 5.7561H66.5601V8.19513H64.121V5.7561Z"
                fill="black"
            />
            <path
                d="M61.6821 5.7561H64.1211V8.19513H61.6821V5.7561Z"
                fill="black"
            />
            <path
                d="M56.8037 5.7561H59.2427V8.19513H56.8037V5.7561Z"
                fill="black"
            />
            <path
                d="M51.9257 5.7561H54.3647V8.19513H51.9257V5.7561Z"
                fill="black"
            />
            <path
                d="M42.1699 5.7561H44.6089V8.19513H42.1699V5.7561Z"
                fill="black"
            />
            <path
                d="M37.292 5.7561H39.731V8.19513H37.292V5.7561Z"
                fill="black"
            />
            <path
                d="M32.4135 5.7561H34.8525V8.19513H32.4135V5.7561Z"
                fill="black"
            />
            <path
                d="M27.5356 5.7561H29.9746V8.19513H27.5356V5.7561Z"
                fill="black"
            />
            <path
                d="M25.0966 5.7561H27.5356V8.19513H25.0966V5.7561Z"
                fill="black"
            />
            <path
                d="M66.56 8.19507H68.999V10.6341H66.56V8.19507Z"
                fill="black"
            />
            <path
                d="M59.2431 8.19507H61.6821V10.6341H59.2431V8.19507Z"
                fill="black"
            />
            <path
                d="M56.8037 8.19507H59.2427V10.6341H56.8037V8.19507Z"
                fill="black"
            />
            <path
                d="M51.9257 8.19507H54.3647V10.6341H51.9257V8.19507Z"
                fill="black"
            />
            <path
                d="M47.0478 8.19507H49.4868V10.6341H47.0478V8.19507Z"
                fill="black"
            />
            <path
                d="M44.6088 8.19507H47.0479V10.6341H44.6088V8.19507Z"
                fill="black"
            />
            <path
                d="M37.292 8.19507H39.731V10.6341H37.292V8.19507Z"
                fill="black"
            />
            <path
                d="M32.4135 8.19507H34.8525V10.6341H32.4135V8.19507Z"
                fill="black"
            />
            <path
                d="M27.5356 8.19507H29.9746V10.6341H27.5356V8.19507Z"
                fill="black"
            />
            <path
                d="M22.6572 8.19507H25.0962V10.6341H22.6572V8.19507Z"
                fill="black"
            />
            <path
                d="M20.2187 8.19507H22.6577V10.6341H20.2187V8.19507Z"
                fill="black"
            />
            <path
                d="M68.999 10.634H71.438V13.0731H68.999V10.634Z"
                fill="black"
            />
            <path d="M66.56 10.634H68.999V13.0731H66.56V10.634Z" fill="black" />
            <path
                d="M64.121 10.634H66.5601V13.0731H64.121V10.634Z"
                fill="black"
            />
            <path
                d="M61.6821 10.634H64.1211V13.0731H61.6821V10.634Z"
                fill="black"
            />
            <path
                d="M59.2431 10.634H61.6821V13.0731H59.2431V10.634Z"
                fill="black"
            />
            <path
                d="M54.3652 10.634H56.8042V13.0731H54.3652V10.634Z"
                fill="black"
            />
            <path
                d="M51.9257 10.634H54.3647V13.0731H51.9257V10.634Z"
                fill="black"
            />
            <path
                d="M44.6088 10.634H47.0479V13.0731H44.6088V10.634Z"
                fill="black"
            />
            <path
                d="M42.1699 10.634H44.6089V13.0731H42.1699V10.634Z"
                fill="black"
            />
            <path
                d="M37.292 10.634H39.731V13.0731H37.292V10.634Z"
                fill="black"
            />
            <path
                d="M34.8525 10.634H37.2915V13.0731H34.8525V10.634Z"
                fill="black"
            />
            <path
                d="M27.5356 10.634H29.9746V13.0731H27.5356V10.634Z"
                fill="black"
            />
            <path
                d="M25.0966 10.634H27.5356V13.0731H25.0966V10.634Z"
                fill="black"
            />
            <path
                d="M20.2187 10.634H22.6577V13.0731H20.2187V10.634Z"
                fill="black"
            />
            <path
                d="M56.8037 13.0732H59.2427V15.5123H56.8037V13.0732Z"
                fill="black"
            />
            <path
                d="M51.9257 13.0732H54.3647V15.5123H51.9257V13.0732Z"
                fill="black"
            />
            <path
                d="M34.8525 13.0732H37.2915V15.5123H34.8525V13.0732Z"
                fill="black"
            />
            <path
                d="M32.4135 13.0732H34.8525V15.5123H32.4135V13.0732Z"
                fill="black"
            />
            <path
                d="M25.0966 13.0732H27.5356V15.5123H25.0966V13.0732Z"
                fill="black"
            />
            <path
                d="M68.999 15.5122H71.438V17.9512H68.999V15.5122Z"
                fill="black"
            />
            <path
                d="M64.121 15.5122H66.5601V17.9512H64.121V15.5122Z"
                fill="black"
            />
            <path
                d="M59.2431 15.5122H61.6821V17.9512H59.2431V15.5122Z"
                fill="black"
            />
            <path
                d="M54.3652 15.5122H56.8042V17.9512H54.3652V15.5122Z"
                fill="black"
            />
            <path
                d="M49.4868 15.5122H51.9258V17.9512H49.4868V15.5122Z"
                fill="black"
            />
            <path
                d="M44.6088 15.5122H47.0479V17.9512H44.6088V15.5122Z"
                fill="black"
            />
            <path
                d="M39.7304 15.5122H42.1694V17.9512H39.7304V15.5122Z"
                fill="black"
            />
            <path
                d="M34.8525 15.5122H37.2915V17.9512H34.8525V15.5122Z"
                fill="black"
            />
            <path
                d="M29.9746 15.5122H32.4136V17.9512H29.9746V15.5122Z"
                fill="black"
            />
            <path
                d="M25.0966 15.5122H27.5356V17.9512H25.0966V15.5122Z"
                fill="black"
            />
            <path
                d="M20.2187 15.5122H22.6577V17.9512H20.2187V15.5122Z"
                fill="black"
            />
            <path
                d="M61.6821 17.9512H64.1211V20.3902H61.6821V17.9512Z"
                fill="black"
            />
            <path
                d="M56.8037 17.9512H59.2427V20.3902H56.8037V17.9512Z"
                fill="black"
            />
            <path
                d="M54.3652 17.9512H56.8042V20.3902H54.3652V17.9512Z"
                fill="black"
            />
            <path
                d="M51.9257 17.9512H54.3647V20.3902H51.9257V17.9512Z"
                fill="black"
            />
            <path
                d="M47.0478 17.9512H49.4868V20.3902H47.0478V17.9512Z"
                fill="black"
            />
            <path
                d="M44.6088 17.9512H47.0479V20.3902H44.6088V17.9512Z"
                fill="black"
            />
            <path
                d="M42.1699 17.9512H44.6089V20.3902H42.1699V17.9512Z"
                fill="black"
            />
            <path
                d="M39.7304 17.9512H42.1694V20.3902H39.7304V17.9512Z"
                fill="black"
            />
            <path
                d="M34.8525 17.9512H37.2915V20.3902H34.8525V17.9512Z"
                fill="black"
            />
            <path
                d="M32.4135 17.9512H34.8525V20.3902H32.4135V17.9512Z"
                fill="black"
            />
            <path
                d="M27.5356 17.9512H29.9746V20.3902H27.5356V17.9512Z"
                fill="black"
            />
            <path
                d="M25.0966 17.9512H27.5356V20.3902H25.0966V17.9512Z"
                fill="black"
            />
            <path
                d="M22.6572 17.9512H25.0962V20.3902H22.6572V17.9512Z"
                fill="black"
            />
            <path
                d="M20.2187 17.9512H22.6577V20.3902H20.2187V17.9512Z"
                fill="black"
            />
            <path
                d="M88.5112 20.3904H90.9502V22.8294H88.5112V20.3904Z"
                fill="black"
            />
            <path
                d="M81.1943 20.3904H83.6333V22.8294H81.1943V20.3904Z"
                fill="black"
            />
            <path
                d="M78.7548 20.3904H81.1938V22.8294H78.7548V20.3904Z"
                fill="black"
            />
            <path
                d="M76.3163 20.3904H78.7554V22.8294H76.3163V20.3904Z"
                fill="black"
            />
            <path
                d="M73.8769 20.3904H76.3159V22.8294H73.8769V20.3904Z"
                fill="black"
            />
            <path
                d="M71.4379 20.3904H73.877V22.8294H71.4379V20.3904Z"
                fill="black"
            />
            <path
                d="M68.999 20.3904H71.438V22.8294H68.999V20.3904Z"
                fill="black"
            />
            <path
                d="M66.56 20.3904H68.999V22.8294H66.56V20.3904Z"
                fill="black"
            />
            <path
                d="M64.121 20.3904H66.5601V22.8294H64.121V20.3904Z"
                fill="black"
            />
            <path
                d="M61.6821 20.3904H64.1211V22.8294H61.6821V20.3904Z"
                fill="black"
            />
            <path
                d="M59.2431 20.3904H61.6821V22.8294H59.2431V20.3904Z"
                fill="black"
            />
            <path
                d="M51.9257 20.3904H54.3647V22.8294H51.9257V20.3904Z"
                fill="black"
            />
            <path
                d="M42.1699 20.3904H44.6089V22.8294H42.1699V20.3904Z"
                fill="black"
            />
            <path
                d="M37.292 20.3904H39.731V22.8294H37.292V20.3904Z"
                fill="black"
            />
            <path
                d="M34.8525 20.3904H37.2915V22.8294H34.8525V20.3904Z"
                fill="black"
            />
            <path
                d="M32.4135 20.3904H34.8525V22.8294H32.4135V20.3904Z"
                fill="black"
            />
            <path
                d="M29.9746 20.3904H32.4136V22.8294H29.9746V20.3904Z"
                fill="black"
            />
            <path
                d="M25.0966 20.3904H27.5356V22.8294H25.0966V20.3904Z"
                fill="black"
            />
            <path
                d="M20.2187 20.3904H22.6577V22.8294H20.2187V20.3904Z"
                fill="black"
            />
            <path
                d="M17.7792 20.3904H20.2183V22.8294H17.7792V20.3904Z"
                fill="black"
            />
            <path
                d="M10.4623 20.3904H12.9014V22.8294H10.4623V20.3904Z"
                fill="black"
            />
            <path
                d="M5.58443 20.3904H8.02344V22.8294H5.58443V20.3904Z"
                fill="black"
            />
            <path
                d="M3.14545 20.3904H5.58447V22.8294H3.14545V20.3904Z"
                fill="black"
            />
            <path
                d="M0.706014 20.3904H3.14502V22.8294H0.706014V20.3904Z"
                fill="black"
            />
            <path
                d="M86.0722 22.8293H88.5112V25.2684H86.0722V22.8293Z"
                fill="black"
            />
            <path
                d="M76.3163 22.8293H78.7554V25.2684H76.3163V22.8293Z"
                fill="black"
            />
            <path
                d="M61.6821 22.8293H64.1211V25.2684H61.6821V22.8293Z"
                fill="black"
            />
            <path
                d="M59.2431 22.8293H61.6821V25.2684H59.2431V22.8293Z"
                fill="black"
            />
            <path
                d="M56.8037 22.8293H59.2427V25.2684H56.8037V22.8293Z"
                fill="black"
            />
            <path
                d="M49.4868 22.8293H51.9258V25.2684H49.4868V22.8293Z"
                fill="black"
            />
            <path
                d="M47.0478 22.8293H49.4868V25.2684H47.0478V22.8293Z"
                fill="black"
            />
            <path
                d="M44.6088 22.8293H47.0479V25.2684H44.6088V22.8293Z"
                fill="black"
            />
            <path
                d="M29.9746 22.8293H32.4136V25.2684H29.9746V22.8293Z"
                fill="black"
            />
            <path
                d="M25.0966 22.8293H27.5356V25.2684H25.0966V22.8293Z"
                fill="black"
            />
            <path
                d="M22.6572 22.8293H25.0962V25.2684H22.6572V22.8293Z"
                fill="black"
            />
            <path
                d="M20.2187 22.8293H22.6577V25.2684H20.2187V22.8293Z"
                fill="black"
            />
            <path
                d="M17.7792 22.8293H20.2183V25.2684H17.7792V22.8293Z"
                fill="black"
            />
            <path
                d="M12.9013 22.8293H15.3403V25.2684H12.9013V22.8293Z"
                fill="black"
            />
            <path
                d="M10.4623 22.8293H12.9014V25.2684H10.4623V22.8293Z"
                fill="black"
            />
            <path
                d="M8.02338 22.8293H10.4624V25.2684H8.02338V22.8293Z"
                fill="black"
            />
            <path
                d="M5.58443 22.8293H8.02344V25.2684H5.58443V22.8293Z"
                fill="black"
            />
            <path
                d="M3.14545 22.8293H5.58447V25.2684H3.14545V22.8293Z"
                fill="black"
            />
            <path
                d="M86.0722 25.2683H88.5112V27.7073H86.0722V25.2683Z"
                fill="black"
            />
            <path
                d="M83.6332 25.2683H86.0723V27.7073H83.6332V25.2683Z"
                fill="black"
            />
            <path
                d="M78.7548 25.2683H81.1938V27.7073H78.7548V25.2683Z"
                fill="black"
            />
            <path
                d="M73.8769 25.2683H76.3159V27.7073H73.8769V25.2683Z"
                fill="black"
            />
            <path
                d="M71.4379 25.2683H73.877V27.7073H71.4379V25.2683Z"
                fill="black"
            />
            <path
                d="M68.999 25.2683H71.438V27.7073H68.999V25.2683Z"
                fill="black"
            />
            <path
                d="M66.56 25.2683H68.999V27.7073H66.56V25.2683Z"
                fill="black"
            />
            <path
                d="M64.121 25.2683H66.5601V27.7073H64.121V25.2683Z"
                fill="black"
            />
            <path
                d="M51.9257 25.2683H54.3647V27.7073H51.9257V25.2683Z"
                fill="black"
            />
            <path
                d="M49.4868 25.2683H51.9258V27.7073H49.4868V25.2683Z"
                fill="black"
            />
            <path
                d="M42.1699 25.2683H44.6089V27.7073H42.1699V25.2683Z"
                fill="black"
            />
            <path
                d="M39.7304 25.2683H42.1694V27.7073H39.7304V25.2683Z"
                fill="black"
            />
            <path
                d="M37.292 25.2683H39.731V27.7073H37.292V25.2683Z"
                fill="black"
            />
            <path
                d="M34.8525 25.2683H37.2915V27.7073H34.8525V25.2683Z"
                fill="black"
            />
            <path
                d="M32.4135 25.2683H34.8525V27.7073H32.4135V25.2683Z"
                fill="black"
            />
            <path
                d="M29.9746 25.2683H32.4136V27.7073H29.9746V25.2683Z"
                fill="black"
            />
            <path
                d="M27.5356 25.2683H29.9746V27.7073H27.5356V25.2683Z"
                fill="black"
            />
            <path
                d="M22.6572 25.2683H25.0962V27.7073H22.6572V25.2683Z"
                fill="black"
            />
            <path
                d="M17.7792 25.2683H20.2183V27.7073H17.7792V25.2683Z"
                fill="black"
            />
            <path
                d="M15.3403 25.2683H17.7793V27.7073H15.3403V25.2683Z"
                fill="black"
            />
            <path
                d="M12.9013 25.2683H15.3403V27.7073H12.9013V25.2683Z"
                fill="black"
            />
            <path
                d="M10.4623 25.2683H12.9014V27.7073H10.4623V25.2683Z"
                fill="black"
            />
            <path
                d="M8.02338 25.2683H10.4624V27.7073H8.02338V25.2683Z"
                fill="black"
            />
            <path
                d="M0.706014 25.2683H3.14502V27.7073H0.706014V25.2683Z"
                fill="black"
            />
            <path
                d="M81.1943 27.7073H83.6333V30.1463H81.1943V27.7073Z"
                fill="black"
            />
            <path
                d="M78.7548 27.7073H81.1938V30.1463H78.7548V27.7073Z"
                fill="black"
            />
            <path
                d="M76.3163 27.7073H78.7554V30.1463H76.3163V27.7073Z"
                fill="black"
            />
            <path
                d="M66.56 27.7073H68.999V30.1463H66.56V27.7073Z"
                fill="black"
            />
            <path
                d="M64.121 27.7073H66.5601V30.1463H64.121V27.7073Z"
                fill="black"
            />
            <path
                d="M59.2431 27.7073H61.6821V30.1463H59.2431V27.7073Z"
                fill="black"
            />
            <path
                d="M54.3652 27.7073H56.8042V30.1463H54.3652V27.7073Z"
                fill="black"
            />
            <path
                d="M49.4868 27.7073H51.9258V30.1463H49.4868V27.7073Z"
                fill="black"
            />
            <path
                d="M47.0478 27.7073H49.4868V30.1463H47.0478V27.7073Z"
                fill="black"
            />
            <path
                d="M42.1699 27.7073H44.6089V30.1463H42.1699V27.7073Z"
                fill="black"
            />
            <path
                d="M39.7304 27.7073H42.1694V30.1463H39.7304V27.7073Z"
                fill="black"
            />
            <path
                d="M34.8525 27.7073H37.2915V30.1463H34.8525V27.7073Z"
                fill="black"
            />
            <path
                d="M32.4135 27.7073H34.8525V30.1463H32.4135V27.7073Z"
                fill="black"
            />
            <path
                d="M25.0966 27.7073H27.5356V30.1463H25.0966V27.7073Z"
                fill="black"
            />
            <path
                d="M17.7792 27.7073H20.2183V30.1463H17.7792V27.7073Z"
                fill="black"
            />
            <path
                d="M15.3403 27.7073H17.7793V30.1463H15.3403V27.7073Z"
                fill="black"
            />
            <path
                d="M5.58443 27.7073H8.02344V30.1463H5.58443V27.7073Z"
                fill="black"
            />
            <path
                d="M3.14545 27.7073H5.58447V30.1463H3.14545V27.7073Z"
                fill="black"
            />
            <path
                d="M0.706014 27.7073H3.14502V30.1463H0.706014V27.7073Z"
                fill="black"
            />
            <path
                d="M86.0722 30.1462H88.5112V32.5853H86.0722V30.1462Z"
                fill="black"
            />
            <path
                d="M78.7548 30.1462H81.1938V32.5853H78.7548V30.1462Z"
                fill="black"
            />
            <path
                d="M73.8769 30.1462H76.3159V32.5853H73.8769V30.1462Z"
                fill="black"
            />
            <path
                d="M68.999 30.1462H71.438V32.5853H68.999V30.1462Z"
                fill="black"
            />
            <path
                d="M64.121 30.1462H66.5601V32.5853H64.121V30.1462Z"
                fill="black"
            />
            <path
                d="M59.2431 30.1462H61.6821V32.5853H59.2431V30.1462Z"
                fill="black"
            />
            <path
                d="M54.3652 30.1462H56.8042V32.5853H54.3652V30.1462Z"
                fill="black"
            />
            <path
                d="M51.9257 30.1462H54.3647V32.5853H51.9257V30.1462Z"
                fill="black"
            />
            <path
                d="M32.4135 30.1462H34.8525V32.5853H32.4135V30.1462Z"
                fill="black"
            />
            <path
                d="M29.9746 30.1462H32.4136V32.5853H29.9746V30.1462Z"
                fill="black"
            />
            <path
                d="M22.6572 30.1462H25.0962V32.5853H22.6572V30.1462Z"
                fill="black"
            />
            <path
                d="M20.2187 30.1462H22.6577V32.5853H20.2187V30.1462Z"
                fill="black"
            />
            <path
                d="M15.3403 30.1462H17.7793V32.5853H15.3403V30.1462Z"
                fill="black"
            />
            <path
                d="M0.706014 30.1462H3.14502V32.5853H0.706014V30.1462Z"
                fill="black"
            />
            <path
                d="M88.5112 32.5854H90.9502V35.0245H88.5112V32.5854Z"
                fill="black"
            />
            <path
                d="M78.7548 32.5854H81.1938V35.0245H78.7548V32.5854Z"
                fill="black"
            />
            <path
                d="M76.3163 32.5854H78.7554V35.0245H76.3163V32.5854Z"
                fill="black"
            />
            <path
                d="M66.56 32.5854H68.999V35.0245H66.56V32.5854Z"
                fill="black"
            />
            <path
                d="M59.2431 32.5854H61.6821V35.0245H59.2431V32.5854Z"
                fill="black"
            />
            <path
                d="M51.9257 32.5854H54.3647V35.0245H51.9257V32.5854Z"
                fill="black"
            />
            <path
                d="M49.4868 32.5854H51.9258V35.0245H49.4868V32.5854Z"
                fill="black"
            />
            <path
                d="M44.6088 32.5854H47.0479V35.0245H44.6088V32.5854Z"
                fill="black"
            />
            <path
                d="M37.292 32.5854H39.731V35.0245H37.292V32.5854Z"
                fill="black"
            />
            <path
                d="M32.4135 32.5854H34.8525V35.0245H32.4135V32.5854Z"
                fill="black"
            />
            <path
                d="M29.9746 32.5854H32.4136V35.0245H29.9746V32.5854Z"
                fill="black"
            />
            <path
                d="M27.5356 32.5854H29.9746V35.0245H27.5356V32.5854Z"
                fill="black"
            />
            <path
                d="M25.0966 32.5854H27.5356V35.0245H25.0966V32.5854Z"
                fill="black"
            />
            <path
                d="M22.6572 32.5854H25.0962V35.0245H22.6572V32.5854Z"
                fill="black"
            />
            <path
                d="M20.2187 32.5854H22.6577V35.0245H20.2187V32.5854Z"
                fill="black"
            />
            <path
                d="M10.4623 32.5854H12.9014V35.0245H10.4623V32.5854Z"
                fill="black"
            />
            <path
                d="M8.02338 32.5854H10.4624V35.0245H8.02338V32.5854Z"
                fill="black"
            />
            <path
                d="M88.5112 35.0244H90.9502V37.4634H88.5112V35.0244Z"
                fill="black"
            />
            <path
                d="M86.0722 35.0244H88.5112V37.4634H86.0722V35.0244Z"
                fill="black"
            />
            <path
                d="M83.6332 35.0244H86.0723V37.4634H83.6332V35.0244Z"
                fill="black"
            />
            <path
                d="M78.7548 35.0244H81.1938V37.4634H78.7548V35.0244Z"
                fill="black"
            />
            <path
                d="M76.3163 35.0244H78.7554V37.4634H76.3163V35.0244Z"
                fill="black"
            />
            <path
                d="M73.8769 35.0244H76.3159V37.4634H73.8769V35.0244Z"
                fill="black"
            />
            <path
                d="M66.56 35.0244H68.999V37.4634H66.56V35.0244Z"
                fill="black"
            />
            <path
                d="M64.121 35.0244H66.5601V37.4634H64.121V35.0244Z"
                fill="black"
            />
            <path
                d="M56.8037 35.0244H59.2427V37.4634H56.8037V35.0244Z"
                fill="black"
            />
            <path
                d="M51.9257 35.0244H54.3647V37.4634H51.9257V35.0244Z"
                fill="black"
            />
            <path
                d="M49.4868 35.0244H51.9258V37.4634H49.4868V35.0244Z"
                fill="black"
            />
            <path
                d="M44.6088 35.0244H47.0479V37.4634H44.6088V35.0244Z"
                fill="black"
            />
            <path
                d="M42.1699 35.0244H44.6089V37.4634H42.1699V35.0244Z"
                fill="black"
            />
            <path
                d="M27.5356 35.0244H29.9746V37.4634H27.5356V35.0244Z"
                fill="black"
            />
            <path
                d="M22.6572 35.0244H25.0962V37.4634H22.6572V35.0244Z"
                fill="black"
            />
            <path
                d="M17.7792 35.0244H20.2183V37.4634H17.7792V35.0244Z"
                fill="black"
            />
            <path
                d="M15.3403 35.0244H17.7793V37.4634H15.3403V35.0244Z"
                fill="black"
            />
            <path
                d="M10.4623 35.0244H12.9014V37.4634H10.4623V35.0244Z"
                fill="black"
            />
            <path
                d="M8.02338 35.0244H10.4624V37.4634H8.02338V35.0244Z"
                fill="black"
            />
            <path
                d="M5.58443 35.0244H8.02344V37.4634H5.58443V35.0244Z"
                fill="black"
            />
            <path
                d="M3.14545 35.0244H5.58447V37.4634H3.14545V35.0244Z"
                fill="black"
            />
            <path
                d="M0.706014 35.0244H3.14502V37.4634H0.706014V35.0244Z"
                fill="black"
            />
            <path
                d="M83.6332 37.4636H86.0723V39.9026H83.6332V37.4636Z"
                fill="black"
            />
            <path
                d="M81.1943 37.4636H83.6333V39.9026H81.1943V37.4636Z"
                fill="black"
            />
            <path
                d="M78.7548 37.4636H81.1938V39.9026H78.7548V37.4636Z"
                fill="black"
            />
            <path
                d="M66.56 37.4636H68.999V39.9026H66.56V37.4636Z"
                fill="black"
            />
            <path
                d="M64.121 37.4636H66.5601V39.9026H64.121V37.4636Z"
                fill="black"
            />
            <path
                d="M61.6821 37.4636H64.1211V39.9026H61.6821V37.4636Z"
                fill="black"
            />
            <path
                d="M59.2431 37.4636H61.6821V39.9026H59.2431V37.4636Z"
                fill="black"
            />
            <path
                d="M56.8037 37.4636H59.2427V39.9026H56.8037V37.4636Z"
                fill="black"
            />
            <path
                d="M54.3652 37.4636H56.8042V39.9026H54.3652V37.4636Z"
                fill="black"
            />
            <path
                d="M39.7304 37.4636H42.1694V39.9026H39.7304V37.4636Z"
                fill="black"
            />
            <path
                d="M37.292 37.4636H39.731V39.9026H37.292V37.4636Z"
                fill="black"
            />
            <path
                d="M34.8525 37.4636H37.2915V39.9026H34.8525V37.4636Z"
                fill="black"
            />
            <path
                d="M22.6572 37.4636H25.0962V39.9026H22.6572V37.4636Z"
                fill="black"
            />
            <path
                d="M17.7792 37.4636H20.2183V39.9026H17.7792V37.4636Z"
                fill="black"
            />
            <path
                d="M12.9013 37.4636H15.3403V39.9026H12.9013V37.4636Z"
                fill="black"
            />
            <path
                d="M8.02338 37.4636H10.4624V39.9026H8.02338V37.4636Z"
                fill="black"
            />
            <path
                d="M5.58443 37.4636H8.02344V39.9026H5.58443V37.4636Z"
                fill="black"
            />
            <path
                d="M3.14545 37.4636H5.58447V39.9026H3.14545V37.4636Z"
                fill="black"
            />
            <path
                d="M0.706014 37.4636H3.14502V39.9026H0.706014V37.4636Z"
                fill="black"
            />
            <path
                d="M88.5112 39.9026H90.9502V42.3416H88.5112V39.9026Z"
                fill="black"
            />
            <path
                d="M86.0722 39.9026H88.5112V42.3416H86.0722V39.9026Z"
                fill="black"
            />
            <path
                d="M78.7548 39.9026H81.1938V42.3416H78.7548V39.9026Z"
                fill="black"
            />
            <path
                d="M73.8769 39.9026H76.3159V42.3416H73.8769V39.9026Z"
                fill="black"
            />
            <path
                d="M71.4379 39.9026H73.877V42.3416H71.4379V39.9026Z"
                fill="black"
            />
            <path
                d="M66.56 39.9026H68.999V42.3416H66.56V39.9026Z"
                fill="black"
            />
            <path
                d="M61.6821 39.9026H64.1211V42.3416H61.6821V39.9026Z"
                fill="black"
            />
            <path
                d="M56.8037 39.9026H59.2427V42.3416H56.8037V39.9026Z"
                fill="black"
            />
            <path
                d="M47.0478 39.9026H49.4868V42.3416H47.0478V39.9026Z"
                fill="black"
            />
            <path
                d="M44.6088 39.9026H47.0479V42.3416H44.6088V39.9026Z"
                fill="black"
            />
            <path
                d="M37.292 39.9026H39.731V42.3416H37.292V39.9026Z"
                fill="black"
            />
            <path
                d="M34.8525 39.9026H37.2915V42.3416H34.8525V39.9026Z"
                fill="black"
            />
            <path
                d="M29.9746 39.9026H32.4136V42.3416H29.9746V39.9026Z"
                fill="black"
            />
            <path
                d="M27.5356 39.9026H29.9746V42.3416H27.5356V39.9026Z"
                fill="black"
            />
            <path
                d="M22.6572 39.9026H25.0962V42.3416H22.6572V39.9026Z"
                fill="black"
            />
            <path
                d="M15.3403 39.9026H17.7793V42.3416H15.3403V39.9026Z"
                fill="black"
            />
            <path
                d="M5.58443 39.9026H8.02344V42.3416H5.58443V39.9026Z"
                fill="black"
            />
            <path
                d="M3.14545 39.9026H5.58447V42.3416H3.14545V39.9026Z"
                fill="black"
            />
            <path
                d="M88.5112 42.3416H90.9502V44.7806H88.5112V42.3416Z"
                fill="black"
            />
            <path
                d="M83.6332 42.3416H86.0723V44.7806H83.6332V42.3416Z"
                fill="black"
            />
            <path
                d="M78.7548 42.3416H81.1938V44.7806H78.7548V42.3416Z"
                fill="black"
            />
            <path
                d="M68.999 42.3416H71.438V44.7806H68.999V42.3416Z"
                fill="black"
            />
            <path
                d="M59.2431 42.3416H61.6821V44.7806H59.2431V42.3416Z"
                fill="black"
            />
            <path
                d="M56.8037 42.3416H59.2427V44.7806H56.8037V42.3416Z"
                fill="black"
            />
            <path
                d="M47.0478 42.3416H49.4868V44.7806H47.0478V42.3416Z"
                fill="black"
            />
            <path
                d="M37.292 42.3416H39.731V44.7806H37.292V42.3416Z"
                fill="black"
            />
            <path
                d="M34.8525 42.3416H37.2915V44.7806H34.8525V42.3416Z"
                fill="black"
            />
            <path
                d="M27.5356 42.3416H29.9746V44.7806H27.5356V42.3416Z"
                fill="black"
            />
            <path
                d="M25.0966 42.3416H27.5356V44.7806H25.0966V42.3416Z"
                fill="black"
            />
            <path
                d="M22.6572 42.3416H25.0962V44.7806H22.6572V42.3416Z"
                fill="black"
            />
            <path
                d="M12.9013 42.3416H15.3403V44.7806H12.9013V42.3416Z"
                fill="black"
            />
            <path
                d="M8.02338 42.3416H10.4624V44.7806H8.02338V42.3416Z"
                fill="black"
            />
            <path
                d="M88.5112 44.7805H90.9502V47.2195H88.5112V44.7805Z"
                fill="black"
            />
            <path
                d="M86.0722 44.7805H88.5112V47.2195H86.0722V44.7805Z"
                fill="black"
            />
            <path
                d="M78.7548 44.7805H81.1938V47.2195H78.7548V44.7805Z"
                fill="black"
            />
            <path
                d="M76.3163 44.7805H78.7554V47.2195H76.3163V44.7805Z"
                fill="black"
            />
            <path
                d="M73.8769 44.7805H76.3159V47.2195H73.8769V44.7805Z"
                fill="black"
            />
            <path
                d="M68.999 44.7805H71.438V47.2195H68.999V44.7805Z"
                fill="black"
            />
            <path
                d="M61.6821 44.7805H64.1211V47.2195H61.6821V44.7805Z"
                fill="black"
            />
            <path
                d="M56.8037 44.7805H59.2427V47.2195H56.8037V44.7805Z"
                fill="black"
            />
            <path
                d="M49.4868 44.7805H51.9258V47.2195H49.4868V44.7805Z"
                fill="black"
            />
            <path
                d="M47.0478 44.7805H49.4868V47.2195H47.0478V44.7805Z"
                fill="black"
            />
            <path
                d="M42.1699 44.7805H44.6089V47.2195H42.1699V44.7805Z"
                fill="black"
            />
            <path
                d="M32.4135 44.7805H34.8525V47.2195H32.4135V44.7805Z"
                fill="black"
            />
            <path
                d="M27.5356 44.7805H29.9746V47.2195H27.5356V44.7805Z"
                fill="black"
            />
            <path
                d="M22.6572 44.7805H25.0962V47.2195H22.6572V44.7805Z"
                fill="black"
            />
            <path
                d="M12.9013 44.7805H15.3403V47.2195H12.9013V44.7805Z"
                fill="black"
            />
            <path
                d="M10.4623 44.7805H12.9014V47.2195H10.4623V44.7805Z"
                fill="black"
            />
            <path
                d="M5.58443 44.7805H8.02344V47.2195H5.58443V44.7805Z"
                fill="black"
            />
            <path
                d="M0.706014 44.7805H3.14502V47.2195H0.706014V44.7805Z"
                fill="black"
            />
            <path
                d="M83.6332 47.2195H86.0723V49.6585H83.6332V47.2195Z"
                fill="black"
            />
            <path
                d="M81.1943 47.2195H83.6333V49.6585H81.1943V47.2195Z"
                fill="black"
            />
            <path
                d="M78.7548 47.2195H81.1938V49.6585H78.7548V47.2195Z"
                fill="black"
            />
            <path
                d="M68.999 47.2195H71.438V49.6585H68.999V47.2195Z"
                fill="black"
            />
            <path
                d="M61.6821 47.2195H64.1211V49.6585H61.6821V47.2195Z"
                fill="black"
            />
            <path
                d="M56.8037 47.2195H59.2427V49.6585H56.8037V47.2195Z"
                fill="black"
            />
            <path
                d="M54.3652 47.2195H56.8042V49.6585H54.3652V47.2195Z"
                fill="black"
            />
            <path
                d="M51.9257 47.2195H54.3647V49.6585H51.9257V47.2195Z"
                fill="black"
            />
            <path
                d="M49.4868 47.2195H51.9258V49.6585H49.4868V47.2195Z"
                fill="black"
            />
            <path
                d="M44.6088 47.2195H47.0479V49.6585H44.6088V47.2195Z"
                fill="black"
            />
            <path
                d="M42.1699 47.2195H44.6089V49.6585H42.1699V47.2195Z"
                fill="black"
            />
            <path
                d="M39.7304 47.2195H42.1694V49.6585H39.7304V47.2195Z"
                fill="black"
            />
            <path
                d="M34.8525 47.2195H37.2915V49.6585H34.8525V47.2195Z"
                fill="black"
            />
            <path
                d="M27.5356 47.2195H29.9746V49.6585H27.5356V47.2195Z"
                fill="black"
            />
            <path
                d="M25.0966 47.2195H27.5356V49.6585H25.0966V47.2195Z"
                fill="black"
            />
            <path
                d="M20.2187 47.2195H22.6577V49.6585H20.2187V47.2195Z"
                fill="black"
            />
            <path
                d="M15.3403 47.2195H17.7793V49.6585H15.3403V47.2195Z"
                fill="black"
            />
            <path
                d="M8.02338 47.2195H10.4624V49.6585H8.02338V47.2195Z"
                fill="black"
            />
            <path
                d="M0.706014 47.2195H3.14502V49.6585H0.706014V47.2195Z"
                fill="black"
            />
            <path
                d="M86.0722 49.6584H88.5112V52.0975H86.0722V49.6584Z"
                fill="black"
            />
            <path
                d="M83.6332 49.6584H86.0723V52.0975H83.6332V49.6584Z"
                fill="black"
            />
            <path
                d="M76.3163 49.6584H78.7554V52.0975H76.3163V49.6584Z"
                fill="black"
            />
            <path
                d="M73.8769 49.6584H76.3159V52.0975H73.8769V49.6584Z"
                fill="black"
            />
            <path
                d="M71.4379 49.6584H73.877V52.0975H71.4379V49.6584Z"
                fill="black"
            />
            <path
                d="M66.56 49.6584H68.999V52.0975H66.56V49.6584Z"
                fill="black"
            />
            <path
                d="M64.121 49.6584H66.5601V52.0975H64.121V49.6584Z"
                fill="black"
            />
            <path
                d="M49.4868 49.6584H51.9258V52.0975H49.4868V49.6584Z"
                fill="black"
            />
            <path
                d="M47.0478 49.6584H49.4868V52.0975H47.0478V49.6584Z"
                fill="black"
            />
            <path
                d="M42.1699 49.6584H44.6089V52.0975H42.1699V49.6584Z"
                fill="black"
            />
            <path
                d="M39.7304 49.6584H42.1694V52.0975H39.7304V49.6584Z"
                fill="black"
            />
            <path
                d="M29.9746 49.6584H32.4136V52.0975H29.9746V49.6584Z"
                fill="black"
            />
            <path
                d="M25.0966 49.6584H27.5356V52.0975H25.0966V49.6584Z"
                fill="black"
            />
            <path
                d="M22.6572 49.6584H25.0962V52.0975H22.6572V49.6584Z"
                fill="black"
            />
            <path
                d="M20.2187 49.6584H22.6577V52.0975H20.2187V49.6584Z"
                fill="black"
            />
            <path
                d="M17.7792 49.6584H20.2183V52.0975H17.7792V49.6584Z"
                fill="black"
            />
            <path
                d="M15.3403 49.6584H17.7793V52.0975H15.3403V49.6584Z"
                fill="black"
            />
            <path
                d="M12.9013 49.6584H15.3403V52.0975H12.9013V49.6584Z"
                fill="black"
            />
            <path
                d="M10.4623 49.6584H12.9014V52.0975H10.4623V49.6584Z"
                fill="black"
            />
            <path
                d="M8.02338 49.6584H10.4624V52.0975H8.02338V49.6584Z"
                fill="black"
            />
            <path
                d="M88.5112 52.0977H90.9502V54.5367H88.5112V52.0977Z"
                fill="black"
            />
            <path
                d="M83.6332 52.0977H86.0723V54.5367H83.6332V52.0977Z"
                fill="black"
            />
            <path
                d="M76.3163 52.0977H78.7554V54.5367H76.3163V52.0977Z"
                fill="black"
            />
            <path
                d="M64.121 52.0977H66.5601V54.5367H64.121V52.0977Z"
                fill="black"
            />
            <path
                d="M61.6821 52.0977H64.1211V54.5367H61.6821V52.0977Z"
                fill="black"
            />
            <path
                d="M56.8037 52.0977H59.2427V54.5367H56.8037V52.0977Z"
                fill="black"
            />
            <path
                d="M51.9257 52.0977H54.3647V54.5367H51.9257V52.0977Z"
                fill="black"
            />
            <path
                d="M47.0478 52.0977H49.4868V54.5367H47.0478V52.0977Z"
                fill="black"
            />
            <path
                d="M44.6088 52.0977H47.0479V54.5367H44.6088V52.0977Z"
                fill="black"
            />
            <path
                d="M42.1699 52.0977H44.6089V54.5367H42.1699V52.0977Z"
                fill="black"
            />
            <path
                d="M32.4135 52.0977H34.8525V54.5367H32.4135V52.0977Z"
                fill="black"
            />
            <path
                d="M25.0966 52.0977H27.5356V54.5367H25.0966V52.0977Z"
                fill="black"
            />
            <path
                d="M22.6572 52.0977H25.0962V54.5367H22.6572V52.0977Z"
                fill="black"
            />
            <path
                d="M10.4623 52.0977H12.9014V54.5367H10.4623V52.0977Z"
                fill="black"
            />
            <path
                d="M5.58443 52.0977H8.02344V54.5367H5.58443V52.0977Z"
                fill="black"
            />
            <path
                d="M88.5112 54.5366H90.9502V56.9756H88.5112V54.5366Z"
                fill="black"
            />
            <path
                d="M81.1943 54.5366H83.6333V56.9756H81.1943V54.5366Z"
                fill="black"
            />
            <path
                d="M76.3163 54.5366H78.7554V56.9756H76.3163V54.5366Z"
                fill="black"
            />
            <path
                d="M73.8769 54.5366H76.3159V56.9756H73.8769V54.5366Z"
                fill="black"
            />
            <path
                d="M68.999 54.5366H71.438V56.9756H68.999V54.5366Z"
                fill="black"
            />
            <path
                d="M66.56 54.5366H68.999V56.9756H66.56V54.5366Z"
                fill="black"
            />
            <path
                d="M51.9257 54.5366H54.3647V56.9756H51.9257V54.5366Z"
                fill="black"
            />
            <path
                d="M49.4868 54.5366H51.9258V56.9756H49.4868V54.5366Z"
                fill="black"
            />
            <path
                d="M37.292 54.5366H39.731V56.9756H37.292V54.5366Z"
                fill="black"
            />
            <path
                d="M32.4135 54.5366H34.8525V56.9756H32.4135V54.5366Z"
                fill="black"
            />
            <path
                d="M27.5356 54.5366H29.9746V56.9756H27.5356V54.5366Z"
                fill="black"
            />
            <path
                d="M22.6572 54.5366H25.0962V56.9756H22.6572V54.5366Z"
                fill="black"
            />
            <path
                d="M17.7792 54.5366H20.2183V56.9756H17.7792V54.5366Z"
                fill="black"
            />
            <path
                d="M12.9013 54.5366H15.3403V56.9756H12.9013V54.5366Z"
                fill="black"
            />
            <path
                d="M8.02338 54.5366H10.4624V56.9756H8.02338V54.5366Z"
                fill="black"
            />
            <path
                d="M0.706014 54.5366H3.14502V56.9756H0.706014V54.5366Z"
                fill="black"
            />
            <path
                d="M86.0722 56.9756H88.5112V59.4146H86.0722V56.9756Z"
                fill="black"
            />
            <path
                d="M81.1943 56.9756H83.6333V59.4146H81.1943V56.9756Z"
                fill="black"
            />
            <path
                d="M76.3163 56.9756H78.7554V59.4146H76.3163V56.9756Z"
                fill="black"
            />
            <path
                d="M66.56 56.9756H68.999V59.4146H66.56V56.9756Z"
                fill="black"
            />
            <path
                d="M64.121 56.9756H66.5601V59.4146H64.121V56.9756Z"
                fill="black"
            />
            <path
                d="M59.2431 56.9756H61.6821V59.4146H59.2431V56.9756Z"
                fill="black"
            />
            <path
                d="M54.3652 56.9756H56.8042V59.4146H54.3652V56.9756Z"
                fill="black"
            />
            <path
                d="M49.4868 56.9756H51.9258V59.4146H49.4868V56.9756Z"
                fill="black"
            />
            <path
                d="M44.6088 56.9756H47.0479V59.4146H44.6088V56.9756Z"
                fill="black"
            />
            <path
                d="M25.0966 56.9756H27.5356V59.4146H25.0966V56.9756Z"
                fill="black"
            />
            <path
                d="M22.6572 56.9756H25.0962V59.4146H22.6572V56.9756Z"
                fill="black"
            />
            <path
                d="M15.3403 56.9756H17.7793V59.4146H15.3403V56.9756Z"
                fill="black"
            />
            <path
                d="M12.9013 56.9756H15.3403V59.4146H12.9013V56.9756Z"
                fill="black"
            />
            <path
                d="M10.4623 56.9756H12.9014V59.4146H10.4623V56.9756Z"
                fill="black"
            />
            <path
                d="M8.02338 56.9756H10.4624V59.4146H8.02338V56.9756Z"
                fill="black"
            />
            <path
                d="M5.58443 56.9756H8.02344V59.4146H5.58443V56.9756Z"
                fill="black"
            />
            <path
                d="M83.6332 59.4148H86.0723V61.8538H83.6332V59.4148Z"
                fill="black"
            />
            <path
                d="M73.8769 59.4148H76.3159V61.8538H73.8769V59.4148Z"
                fill="black"
            />
            <path
                d="M71.4379 59.4148H73.877V61.8538H71.4379V59.4148Z"
                fill="black"
            />
            <path
                d="M66.56 59.4148H68.999V61.8538H66.56V59.4148Z"
                fill="black"
            />
            <path
                d="M64.121 59.4148H66.5601V61.8538H64.121V59.4148Z"
                fill="black"
            />
            <path
                d="M61.6821 59.4148H64.1211V61.8538H61.6821V59.4148Z"
                fill="black"
            />
            <path
                d="M47.0478 59.4148H49.4868V61.8538H47.0478V59.4148Z"
                fill="black"
            />
            <path
                d="M42.1699 59.4148H44.6089V61.8538H42.1699V59.4148Z"
                fill="black"
            />
            <path
                d="M39.7304 59.4148H42.1694V61.8538H39.7304V59.4148Z"
                fill="black"
            />
            <path
                d="M37.292 59.4148H39.731V61.8538H37.292V59.4148Z"
                fill="black"
            />
            <path
                d="M34.8525 59.4148H37.2915V61.8538H34.8525V59.4148Z"
                fill="black"
            />
            <path
                d="M29.9746 59.4148H32.4136V61.8538H29.9746V59.4148Z"
                fill="black"
            />
            <path
                d="M20.2187 59.4148H22.6577V61.8538H20.2187V59.4148Z"
                fill="black"
            />
            <path
                d="M15.3403 59.4148H17.7793V61.8538H15.3403V59.4148Z"
                fill="black"
            />
            <path
                d="M12.9013 59.4148H15.3403V61.8538H12.9013V59.4148Z"
                fill="black"
            />
            <path
                d="M88.5112 61.8538H90.9502V64.2928H88.5112V61.8538Z"
                fill="black"
            />
            <path
                d="M86.0722 61.8538H88.5112V64.2928H86.0722V61.8538Z"
                fill="black"
            />
            <path
                d="M66.56 61.8538H68.999V64.2928H66.56V61.8538Z"
                fill="black"
            />
            <path
                d="M56.8037 61.8538H59.2427V64.2928H56.8037V61.8538Z"
                fill="black"
            />
            <path
                d="M44.6088 61.8538H47.0479V64.2928H44.6088V61.8538Z"
                fill="black"
            />
            <path
                d="M42.1699 61.8538H44.6089V64.2928H42.1699V61.8538Z"
                fill="black"
            />
            <path
                d="M34.8525 61.8538H37.2915V64.2928H34.8525V61.8538Z"
                fill="black"
            />
            <path
                d="M32.4135 61.8538H34.8525V64.2928H32.4135V61.8538Z"
                fill="black"
            />
            <path
                d="M27.5356 61.8538H29.9746V64.2928H27.5356V61.8538Z"
                fill="black"
            />
            <path
                d="M22.6572 61.8538H25.0962V64.2928H22.6572V61.8538Z"
                fill="black"
            />
            <path
                d="M20.2187 61.8538H22.6577V64.2928H20.2187V61.8538Z"
                fill="black"
            />
            <path
                d="M10.4623 61.8538H12.9014V64.2928H10.4623V61.8538Z"
                fill="black"
            />
            <path
                d="M8.02338 61.8538H10.4624V64.2928H8.02338V61.8538Z"
                fill="black"
            />
            <path
                d="M5.58443 61.8538H8.02344V64.2928H5.58443V61.8538Z"
                fill="black"
            />
            <path
                d="M88.5112 64.2927H90.9502V66.7318H88.5112V64.2927Z"
                fill="black"
            />
            <path
                d="M86.0722 64.2927H88.5112V66.7318H86.0722V64.2927Z"
                fill="black"
            />
            <path
                d="M81.1943 64.2927H83.6333V66.7318H81.1943V64.2927Z"
                fill="black"
            />
            <path
                d="M78.7548 64.2927H81.1938V66.7318H78.7548V64.2927Z"
                fill="black"
            />
            <path
                d="M76.3163 64.2927H78.7554V66.7318H76.3163V64.2927Z"
                fill="black"
            />
            <path
                d="M73.8769 64.2927H76.3159V66.7318H73.8769V64.2927Z"
                fill="black"
            />
            <path
                d="M68.999 64.2927H71.438V66.7318H68.999V64.2927Z"
                fill="black"
            />
            <path
                d="M66.56 64.2927H68.999V66.7318H66.56V64.2927Z"
                fill="black"
            />
            <path
                d="M61.6821 64.2927H64.1211V66.7318H61.6821V64.2927Z"
                fill="black"
            />
            <path
                d="M59.2431 64.2927H61.6821V66.7318H59.2431V64.2927Z"
                fill="black"
            />
            <path
                d="M51.9257 64.2927H54.3647V66.7318H51.9257V64.2927Z"
                fill="black"
            />
            <path
                d="M34.8525 64.2927H37.2915V66.7318H34.8525V64.2927Z"
                fill="black"
            />
            <path
                d="M29.9746 64.2927H32.4136V66.7318H29.9746V64.2927Z"
                fill="black"
            />
            <path
                d="M27.5356 64.2927H29.9746V66.7318H27.5356V64.2927Z"
                fill="black"
            />
            <path
                d="M25.0966 64.2927H27.5356V66.7318H25.0966V64.2927Z"
                fill="black"
            />
            <path
                d="M22.6572 64.2927H25.0962V66.7318H22.6572V64.2927Z"
                fill="black"
            />
            <path
                d="M20.2187 64.2927H22.6577V66.7318H20.2187V64.2927Z"
                fill="black"
            />
            <path
                d="M15.3403 64.2927H17.7793V66.7318H15.3403V64.2927Z"
                fill="black"
            />
            <path
                d="M12.9013 64.2927H15.3403V66.7318H12.9013V64.2927Z"
                fill="black"
            />
            <path
                d="M10.4623 64.2927H12.9014V66.7318H10.4623V64.2927Z"
                fill="black"
            />
            <path
                d="M8.02338 64.2927H10.4624V66.7318H8.02338V64.2927Z"
                fill="black"
            />
            <path
                d="M3.14545 64.2927H5.58447V66.7318H3.14545V64.2927Z"
                fill="black"
            />
            <path
                d="M0.706014 64.2927H3.14502V66.7318H0.706014V64.2927Z"
                fill="black"
            />
            <path
                d="M88.5112 66.7317H90.9502V69.1707H88.5112V66.7317Z"
                fill="black"
            />
            <path
                d="M71.4379 66.7317H73.877V69.1707H71.4379V66.7317Z"
                fill="black"
            />
            <path
                d="M68.999 66.7317H71.438V69.1707H68.999V66.7317Z"
                fill="black"
            />
            <path
                d="M66.56 66.7317H68.999V69.1707H66.56V66.7317Z"
                fill="black"
            />
            <path
                d="M59.2431 66.7317H61.6821V69.1707H59.2431V66.7317Z"
                fill="black"
            />
            <path
                d="M56.8037 66.7317H59.2427V69.1707H56.8037V66.7317Z"
                fill="black"
            />
            <path
                d="M54.3652 66.7317H56.8042V69.1707H54.3652V66.7317Z"
                fill="black"
            />
            <path
                d="M49.4868 66.7317H51.9258V69.1707H49.4868V66.7317Z"
                fill="black"
            />
            <path
                d="M42.1699 66.7317H44.6089V69.1707H42.1699V66.7317Z"
                fill="black"
            />
            <path
                d="M34.8525 66.7317H37.2915V69.1707H34.8525V66.7317Z"
                fill="black"
            />
            <path
                d="M32.4135 66.7317H34.8525V69.1707H32.4135V66.7317Z"
                fill="black"
            />
            <path
                d="M29.9746 66.7317H32.4136V69.1707H29.9746V66.7317Z"
                fill="black"
            />
            <path
                d="M22.6572 66.7317H25.0962V69.1707H22.6572V66.7317Z"
                fill="black"
            />
            <path
                d="M20.2187 66.7317H22.6577V69.1707H20.2187V66.7317Z"
                fill="black"
            />
            <path
                d="M17.7792 66.7317H20.2183V69.1707H17.7792V66.7317Z"
                fill="black"
            />
            <path
                d="M15.3403 66.7317H17.7793V69.1707H15.3403V66.7317Z"
                fill="black"
            />
            <path
                d="M12.9013 66.7317H15.3403V69.1707H12.9013V66.7317Z"
                fill="black"
            />
            <path
                d="M8.02338 66.7317H10.4624V69.1707H8.02338V66.7317Z"
                fill="black"
            />
            <path
                d="M5.58443 66.7317H8.02344V69.1707H5.58443V66.7317Z"
                fill="black"
            />
            <path
                d="M88.5112 69.1709H90.9502V71.6099H88.5112V69.1709Z"
                fill="black"
            />
            <path
                d="M76.3163 69.1709H78.7554V71.6099H76.3163V69.1709Z"
                fill="black"
            />
            <path
                d="M73.8769 69.1709H76.3159V71.6099H73.8769V69.1709Z"
                fill="black"
            />
            <path
                d="M56.8037 69.1709H59.2427V71.6099H56.8037V69.1709Z"
                fill="black"
            />
            <path
                d="M54.3652 69.1709H56.8042V71.6099H54.3652V69.1709Z"
                fill="black"
            />
            <path
                d="M51.9257 69.1709H54.3647V71.6099H51.9257V69.1709Z"
                fill="black"
            />
            <path
                d="M42.1699 69.1709H44.6089V71.6099H42.1699V69.1709Z"
                fill="black"
            />
            <path
                d="M39.7304 69.1709H42.1694V71.6099H39.7304V69.1709Z"
                fill="black"
            />
            <path
                d="M34.8525 69.1709H37.2915V71.6099H34.8525V69.1709Z"
                fill="black"
            />
            <path
                d="M32.4135 69.1709H34.8525V71.6099H32.4135V69.1709Z"
                fill="black"
            />
            <path
                d="M29.9746 69.1709H32.4136V71.6099H29.9746V69.1709Z"
                fill="black"
            />
            <path
                d="M27.5356 69.1709H29.9746V71.6099H27.5356V69.1709Z"
                fill="black"
            />
            <path
                d="M25.0966 69.1709H27.5356V71.6099H25.0966V69.1709Z"
                fill="black"
            />
            <path
                d="M22.6572 69.1709H25.0962V71.6099H22.6572V69.1709Z"
                fill="black"
            />
            <path
                d="M20.2187 69.1709H22.6577V71.6099H20.2187V69.1709Z"
                fill="black"
            />
            <path
                d="M17.7792 69.1709H20.2183V71.6099H17.7792V69.1709Z"
                fill="black"
            />
            <path
                d="M15.3403 69.1709H17.7793V71.6099H15.3403V69.1709Z"
                fill="black"
            />
            <path
                d="M12.9013 69.1709H15.3403V71.6099H12.9013V69.1709Z"
                fill="black"
            />
            <path
                d="M10.4623 69.1709H12.9014V71.6099H10.4623V69.1709Z"
                fill="black"
            />
            <path
                d="M8.02338 69.1709H10.4624V71.6099H8.02338V69.1709Z"
                fill="black"
            />
            <path
                d="M5.58443 69.1709H8.02344V71.6099H5.58443V69.1709Z"
                fill="black"
            />
            <path
                d="M3.14545 69.1709H5.58447V71.6099H3.14545V69.1709Z"
                fill="black"
            />
            <path
                d="M0.706014 69.1709H3.14502V71.6099H0.706014V69.1709Z"
                fill="black"
            />
            <path
                d="M68.999 71.6099H71.438V74.0489H68.999V71.6099Z"
                fill="black"
            />
            <path
                d="M66.56 71.6099H68.999V74.0489H66.56V71.6099Z"
                fill="black"
            />
            <path
                d="M56.8037 71.6099H59.2427V74.0489H56.8037V71.6099Z"
                fill="black"
            />
            <path
                d="M54.3652 71.6099H56.8042V74.0489H54.3652V71.6099Z"
                fill="black"
            />
            <path
                d="M51.9257 71.6099H54.3647V74.0489H51.9257V71.6099Z"
                fill="black"
            />
            <path
                d="M47.0478 71.6099H49.4868V74.0489H47.0478V71.6099Z"
                fill="black"
            />
            <path
                d="M42.1699 71.6099H44.6089V74.0489H42.1699V71.6099Z"
                fill="black"
            />
            <path
                d="M39.7304 71.6099H42.1694V74.0489H39.7304V71.6099Z"
                fill="black"
            />
            <path
                d="M37.292 71.6099H39.731V74.0489H37.292V71.6099Z"
                fill="black"
            />
            <path
                d="M34.8525 71.6099H37.2915V74.0489H34.8525V71.6099Z"
                fill="black"
            />
            <path
                d="M27.5356 71.6099H29.9746V74.0489H27.5356V71.6099Z"
                fill="black"
            />
            <path
                d="M20.2187 71.6099H22.6577V74.0489H20.2187V71.6099Z"
                fill="black"
            />
            <path
                d="M10.4623 71.6099H12.9014V74.0489H10.4623V71.6099Z"
                fill="black"
            />
            <path
                d="M5.58443 71.6099H8.02344V74.0489H5.58443V71.6099Z"
                fill="black"
            />
            <path
                d="M68.999 74.0488H71.438V76.4879H68.999V74.0488Z"
                fill="black"
            />
            <path
                d="M61.6821 74.0488H64.1211V76.4879H61.6821V74.0488Z"
                fill="black"
            />
            <path
                d="M54.3652 74.0488H56.8042V76.4879H54.3652V74.0488Z"
                fill="black"
            />
            <path
                d="M51.9257 74.0488H54.3647V76.4879H51.9257V74.0488Z"
                fill="black"
            />
            <path
                d="M47.0478 74.0488H49.4868V76.4879H47.0478V74.0488Z"
                fill="black"
            />
            <path
                d="M44.6088 74.0488H47.0479V76.4879H44.6088V74.0488Z"
                fill="black"
            />
            <path
                d="M42.1699 74.0488H44.6089V76.4879H42.1699V74.0488Z"
                fill="black"
            />
            <path
                d="M32.4135 74.0488H34.8525V76.4879H32.4135V74.0488Z"
                fill="black"
            />
            <path
                d="M27.5356 74.0488H29.9746V76.4879H27.5356V74.0488Z"
                fill="black"
            />
            <path
                d="M25.0966 74.0488H27.5356V76.4879H25.0966V74.0488Z"
                fill="black"
            />
            <path
                d="M20.2187 74.0488H22.6577V76.4879H20.2187V74.0488Z"
                fill="black"
            />
            <path
                d="M15.3403 74.0488H17.7793V76.4879H15.3403V74.0488Z"
                fill="black"
            />
            <path
                d="M10.4623 74.0488H12.9014V76.4879H10.4623V74.0488Z"
                fill="black"
            />
            <path
                d="M3.14545 74.0488H5.58447V76.4879H3.14545V74.0488Z"
                fill="black"
            />
            <path
                d="M0.706014 74.0488H3.14502V76.4879H0.706014V74.0488Z"
                fill="black"
            />
            <path
                d="M68.999 76.4878H71.438V78.9268H68.999V76.4878Z"
                fill="black"
            />
            <path
                d="M66.56 76.4878H68.999V78.9268H66.56V76.4878Z"
                fill="black"
            />
            <path
                d="M56.8037 76.4878H59.2427V78.9268H56.8037V76.4878Z"
                fill="black"
            />
            <path
                d="M37.292 76.4878H39.731V78.9268H37.292V76.4878Z"
                fill="black"
            />
            <path
                d="M32.4135 76.4878H34.8525V78.9268H32.4135V76.4878Z"
                fill="black"
            />
            <path
                d="M29.9746 76.4878H32.4136V78.9268H29.9746V76.4878Z"
                fill="black"
            />
            <path
                d="M27.5356 76.4878H29.9746V78.9268H27.5356V76.4878Z"
                fill="black"
            />
            <path
                d="M20.2187 76.4878H22.6577V78.9268H20.2187V76.4878Z"
                fill="black"
            />
            <path
                d="M10.4623 76.4878H12.9014V78.9268H10.4623V76.4878Z"
                fill="black"
            />
            <path
                d="M3.14545 76.4878H5.58447V78.9268H3.14545V76.4878Z"
                fill="black"
            />
            <path
                d="M68.999 78.927H71.438V81.366H68.999V78.927Z"
                fill="black"
            />
            <path
                d="M64.121 78.927H66.5601V81.366H64.121V78.927Z"
                fill="black"
            />
            <path
                d="M59.2431 78.927H61.6821V81.366H59.2431V78.927Z"
                fill="black"
            />
            <path
                d="M54.3652 78.927H56.8042V81.366H54.3652V78.927Z"
                fill="black"
            />
            <path
                d="M51.9257 78.927H54.3647V81.366H51.9257V78.927Z"
                fill="black"
            />
            <path
                d="M49.4868 78.927H51.9258V81.366H49.4868V78.927Z"
                fill="black"
            />
            <path
                d="M44.6088 78.927H47.0479V81.366H44.6088V78.927Z"
                fill="black"
            />
            <path
                d="M39.7304 78.927H42.1694V81.366H39.7304V78.927Z"
                fill="black"
            />
            <path
                d="M37.292 78.927H39.731V81.366H37.292V78.927Z"
                fill="black"
            />
            <path
                d="M25.0966 78.927H27.5356V81.366H25.0966V78.927Z"
                fill="black"
            />
            <path
                d="M22.6572 78.927H25.0962V81.366H22.6572V78.927Z"
                fill="black"
            />
            <path
                d="M20.2187 78.927H22.6577V81.366H20.2187V78.927Z"
                fill="black"
            />
            <path
                d="M17.7792 78.927H20.2183V81.366H17.7792V78.927Z"
                fill="black"
            />
            <path
                d="M15.3403 78.927H17.7793V81.366H15.3403V78.927Z"
                fill="black"
            />
            <path
                d="M12.9013 78.927H15.3403V81.366H12.9013V78.927Z"
                fill="black"
            />
            <path
                d="M10.4623 78.927H12.9014V81.366H10.4623V78.927Z"
                fill="black"
            />
            <path
                d="M0.706014 78.927H3.14502V81.366H0.706014V78.927Z"
                fill="black"
            />
            <path
                d="M68.999 81.366H71.438V83.805H68.999V81.366Z"
                fill="black"
            />
            <path d="M66.56 81.366H68.999V83.805H66.56V81.366Z" fill="black" />
            <path
                d="M56.8037 81.366H59.2427V83.805H56.8037V81.366Z"
                fill="black"
            />
            <path
                d="M39.7304 81.366H42.1694V83.805H39.7304V81.366Z"
                fill="black"
            />
            <path
                d="M34.8525 81.366H37.2915V83.805H34.8525V81.366Z"
                fill="black"
            />
            <path
                d="M32.4135 81.366H34.8525V83.805H32.4135V81.366Z"
                fill="black"
            />
            <path
                d="M27.5356 81.366H29.9746V83.805H27.5356V81.366Z"
                fill="black"
            />
            <path
                d="M25.0966 81.366H27.5356V83.805H25.0966V81.366Z"
                fill="black"
            />
            <path
                d="M22.6572 81.366H25.0962V83.805H22.6572V81.366Z"
                fill="black"
            />
            <path
                d="M20.2187 81.366H22.6577V83.805H20.2187V81.366Z"
                fill="black"
            />
            <path
                d="M17.7792 81.366H20.2183V83.805H17.7792V81.366Z"
                fill="black"
            />
            <path
                d="M15.3403 81.366H17.7793V83.805H15.3403V81.366Z"
                fill="black"
            />
            <path
                d="M8.02338 81.366H10.4624V83.805H8.02338V81.366Z"
                fill="black"
            />
            <path
                d="M0.706014 81.366H3.14502V83.805H0.706014V81.366Z"
                fill="black"
            />
            <path
                d="M64.121 83.8049H66.5601V86.2439H64.121V83.8049Z"
                fill="black"
            />
            <path
                d="M61.6821 83.8049H64.1211V86.2439H61.6821V83.8049Z"
                fill="black"
            />
            <path
                d="M56.8037 83.8049H59.2427V86.2439H56.8037V83.8049Z"
                fill="black"
            />
            <path
                d="M47.0478 83.8049H49.4868V86.2439H47.0478V83.8049Z"
                fill="black"
            />
            <path
                d="M37.292 83.8049H39.731V86.2439H37.292V83.8049Z"
                fill="black"
            />
            <path
                d="M34.8525 83.8049H37.2915V86.2439H34.8525V83.8049Z"
                fill="black"
            />
            <path
                d="M32.4135 83.8049H34.8525V86.2439H32.4135V83.8049Z"
                fill="black"
            />
            <path
                d="M27.5356 83.8049H29.9746V86.2439H27.5356V83.8049Z"
                fill="black"
            />
            <path
                d="M22.6572 83.8049H25.0962V86.2439H22.6572V83.8049Z"
                fill="black"
            />
            <path
                d="M20.2187 83.8049H22.6577V86.2439H20.2187V83.8049Z"
                fill="black"
            />
            <path
                d="M10.4623 83.8049H12.9014V86.2439H10.4623V83.8049Z"
                fill="black"
            />
            <path
                d="M5.58443 83.8049H8.02344V86.2439H5.58443V83.8049Z"
                fill="black"
            />
            <path
                d="M0.706014 83.8049H3.14502V86.2439H0.706014V83.8049Z"
                fill="black"
            />
            <path
                d="M64.121 86.2439H66.5601V88.6829H64.121V86.2439Z"
                fill="black"
            />
            <path
                d="M61.6821 86.2439H64.1211V88.6829H61.6821V86.2439Z"
                fill="black"
            />
            <path
                d="M56.8037 86.2439H59.2427V88.6829H56.8037V86.2439Z"
                fill="black"
            />
            <path
                d="M54.3652 86.2439H56.8042V88.6829H54.3652V86.2439Z"
                fill="black"
            />
            <path
                d="M51.9257 86.2439H54.3647V88.6829H51.9257V86.2439Z"
                fill="black"
            />
            <path
                d="M47.0478 86.2439H49.4868V88.6829H47.0478V86.2439Z"
                fill="black"
            />
            <path
                d="M44.6088 86.2439H47.0479V88.6829H44.6088V86.2439Z"
                fill="black"
            />
            <path
                d="M32.4135 86.2439H34.8525V88.6829H32.4135V86.2439Z"
                fill="black"
            />
            <path
                d="M22.6572 86.2439H25.0962V88.6829H22.6572V86.2439Z"
                fill="black"
            />
            <path
                d="M15.3403 86.2439H17.7793V88.6829H15.3403V86.2439Z"
                fill="black"
            />
            <path
                d="M10.4623 86.2439H12.9014V88.6829H10.4623V86.2439Z"
                fill="black"
            />
            <path
                d="M5.58443 86.2439H8.02344V88.6829H5.58443V86.2439Z"
                fill="black"
            />
            <path
                d="M3.14545 86.2439H5.58447V88.6829H3.14545V86.2439Z"
                fill="black"
            />
            <path
                d="M0.706014 86.2439H3.14502V88.6829H0.706014V86.2439Z"
                fill="black"
            />
            <path
                d="M68.999 88.6829H71.438V91.1219H68.999V88.6829Z"
                fill="black"
            />
            <path
                d="M61.6821 88.6829H64.1211V91.1219H61.6821V88.6829Z"
                fill="black"
            />
            <path
                d="M59.2431 88.6829H61.6821V91.1219H59.2431V88.6829Z"
                fill="black"
            />
            <path
                d="M54.3652 88.6829H56.8042V91.1219H54.3652V88.6829Z"
                fill="black"
            />
            <path
                d="M49.4868 88.6829H51.9258V91.1219H49.4868V88.6829Z"
                fill="black"
            />
            <path
                d="M42.1699 88.6829H44.6089V91.1219H42.1699V88.6829Z"
                fill="black"
            />
            <path
                d="M39.7304 88.6829H42.1694V91.1219H39.7304V88.6829Z"
                fill="black"
            />
            <path
                d="M34.8525 88.6829H37.2915V91.1219H34.8525V88.6829Z"
                fill="black"
            />
            <path
                d="M32.4135 88.6829H34.8525V91.1219H32.4135V88.6829Z"
                fill="black"
            />
            <path
                d="M29.9746 88.6829H32.4136V91.1219H29.9746V88.6829Z"
                fill="black"
            />
            <path
                d="M20.2187 88.6829H22.6577V91.1219H20.2187V88.6829Z"
                fill="black"
            />
            <path
                d="M15.3403 88.6829H17.7793V91.1219H15.3403V88.6829Z"
                fill="black"
            />
            <path
                d="M12.9013 88.6829H15.3403V91.1219H12.9013V88.6829Z"
                fill="black"
            />
            <path
                d="M8.02338 88.6829H10.4624V91.1219H8.02338V88.6829Z"
                fill="black"
            />
            <path
                d="M0.706014 88.6829H3.14502V91.1219H0.706014V88.6829Z"
                fill="black"
            />
            <path
                d="M76.438 0.878418H88.3892H90.9502V3.43939V15.3906V17.9516H88.3892H76.438H73.877V15.3906V3.43939V0.878418H76.438ZM76.438 15.3906H88.3892V3.43939H76.438V15.3906Z"
                fill="black"
            />
            <path
                d="M3.2671 0.878174H15.2183H17.7793V3.43915V15.3904V17.9513H15.2183H3.2671H0.706121V15.3904V3.43915V0.878174H3.2671ZM3.2671 15.3904H15.2183V3.43915H3.2671V15.3904Z"
                fill="black"
            />
            <path
                d="M76.438 74.0488H88.3892H90.9502V76.6098V88.561V91.122H88.3892H76.438H73.877V88.561V76.6098V74.0488H76.438ZM76.438 88.561H88.3892V76.6098H76.438V88.561Z"
                fill="black"
            />
            <path
                d="M78.7552 5.7561H86.0723V13.0732H78.7552V5.7561Z"
                fill="black"
            />
            <path
                d="M5.5843 5.7561H12.9014V13.0732H5.5843V5.7561Z"
                fill="black"
            />
            <path
                d="M78.7552 78.927H86.0723V86.2441H78.7552V78.927Z"
                fill="black"
            />
        </svg>
    )
}

export default QR_Android
