import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"

const List = ({ children }) => {
    return <Container>{children}</Container>
}

const Container = styled.div`
    background-color: ${Colors.white};
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    > *:hover {
        background-color: ${(props) => props.theme.backgroundPrimary};
    }

    > :first-child {
        border-radius: 8px 8px 0px 0px;
    }

    > :last-child {
        border-radius: 0px 0px 8px 8px;
    }
`

export default List
