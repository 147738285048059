const getApiUrl = () => {
    const l = window.location.hostname
    if (l === "localhost") {
        return "http://localhost:5001/lawyered-no-dev/europe-west1"
    }
    if (l === "192.168.1.45" || l === "10.20.46.18") {
        return "/lawyered-no-dev/europe-west1"
    }
    if (l === "app.lawyered.no" || l === "lawyered-no.web.app") {
        return "https://europe-west1-lawyered-no.cloudfunctions.net"
    }
    return "https://europe-west1-lawyered-no-dev.cloudfunctions.net"
}

// Use this if your local env doesn't cut it (e.g. Vipps can't
// call localhost webhooks, so we'll use staging for that)
const getStagingOrProdApiUrl = () => {
    const l = window.location.hostname
    if (l === "app.lawyered.no" || l === "lawyered-no.web.app") {
        return "https://europe-west1-lawyered-no.cloudfunctions.net"
    }
    return "https://europe-west1-lawyered-no-dev.cloudfunctions.net"
}

const apiUrl = getApiUrl()
const stagingOrProdApiUrl = getStagingOrProdApiUrl()

export default apiUrl
export { stagingOrProdApiUrl }
