import styled, { useTheme } from "styled-components"
import parse from "html-react-parser"

import Colors from "../../assets/colors"
import Icon from "../icon"
import { P_Small, P_Small_M } from "../text"
import Button from "../button"

const Notice = ({
    success = false,
    warning = false,
    error = false,
    info = false,
    showIcon = true,
    title,
    text,
    buttonText = null,
    buttonAction = null,
    fillWidth = false
}) => {
    const theme = useTheme()
    const getIcon = () => {
        if (success) {
            return (
                <Circle bg={Colors.green[400]}>
                    <Icon icon="Checkmark" width="8" fill={Colors.green[50]} />
                </Circle>
            )
        }
        if (warning) {
            return (
                <Circle bg={Colors.yellow[400]}>
                    <Icon icon="Info" fill={Colors.yellow[50]} />
                </Circle>
            )
        }
        if (error) {
            return (
                <Circle bg={Colors.red[400]}>
                    <Icon icon="Cancel" width="10" fill={Colors.red[50]} />
                </Circle>
            )
        }
        if (info) {
            return (
                <Circle>
                    <Icon icon="Info" width="10" fill={theme.foregroundSecondary} />
                </Circle>
            )
        }
    }

    const getContent = () => {
        if (/<\/?[a-z][\s\S]*>/i.test(text)) {
            // Check for HTML
            return (
                <Wrapper withIcon={showIcon}>
                    <>
                        <P_Small_M>
                            {showIcon && getIcon()} {"Om vedlegg"}
                        </P_Small_M>
                        {parse(text)}
                    </>
                    {buttonText && (
                        <Button
                            block={false}
                            onClick={buttonAction}
                            text={buttonText}
                        />
                    )}
                </Wrapper>
            )
        }

        return (
            <Wrapper withIcon={showIcon} withButton={Boolean(buttonText)}>
                <TextWrapper>
                    <P_Small_M className="title">
                        {showIcon && getIcon()} {title ? title : text}
                    </P_Small_M>
                    {title && (
                        <P_Small className={showIcon && "indent"}>
                            {text}
                        </P_Small>
                    )}
                </TextWrapper>
                <>
                    {buttonText && (
                        <Button
                            small
                            block={false}
                            onClick={buttonAction}
                            text={buttonText}
                        />
                    )}
                </>
            </Wrapper>
        )
    }

    if (success) {
        return (
            <SuccessNotice fillWidth={fillWidth}>{getContent()}</SuccessNotice>
        )
    }
    if (warning) {
        return (
            <WarningNotice fillWidth={fillWidth}>{getContent()}</WarningNotice>
        )
    }
    if (error) {
        return <ErrorNotice fillWidth={fillWidth}>{getContent()}</ErrorNotice>
    }
    if (info) {
        return <InfoNotice fillWidth={fillWidth}>{getContent()}</InfoNotice>
    }
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    button {
        margin-top: 12px;
        margin-left: ${(props) => (props.withIcon ? "24px" : 0)};
        align-self: flex-start;
    }
    @media screen and (min-width: 768px) {
        flex-direction: ${(props) => (props.withButton ? "row" : "column")};
        button {
            margin-left: 12px;
            margin-top: 0;
            align-self: auto;
        }
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    p.title {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p.indent {
        margin-left: 24px;
    }
`

const Circle = styled.span`
    display: flex;
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.bg};
    border-radius: 50%;
`

const DefaultNotice = styled.div`
    border: 2px solid ${Colors.gray[100]};
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: ${(props) => (props.fillWidth ? "calc(100% - 16px)" : "auto")};
    @media screen and (min-width: 768px) {
        max-width: 500px;
        width: ${(props) => (props.fillWidth ? "calc(100% - 16px)" : "auto")};
    }
    p {
        display: flex;
        flex: 1;
        align-items: flex-start;
        font-size: 14px;
        line-height: 20px;
    }
    ul {
        margin: 0;
        padding: 0 8px 8px 16px;
    }
    li {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
    }
    span {
        margin-top: 2px;
        margin-right: 8px;
    }
`

const SuccessNotice = styled(DefaultNotice)`
    border: none;
    background-color: ${Colors.green[100]};
    p,
    li,
    span {
        color: ${Colors.green[800]};
    }
`

const WarningNotice = styled(DefaultNotice)`
    border: none;
    background-color: ${Colors.yellow[100]};
    p,
    li,
    span {
        color: ${Colors.yellow[800]};
    }
`

const ErrorNotice = styled(DefaultNotice)`
    border: none;
    background-color: ${Colors.red[100]};
    p,
    li,
    span {
        color: ${Colors.red[800]};
    }
`

const InfoNotice = styled(DefaultNotice)`
    border: none;
    background-color: ${(props) => props.theme.backgroundSecondary};
    p,
    li,
    span {
        color: ${(props) => props.theme.foregroundSecondary};
    }
`

export default Notice
