import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"

const CheckBox = ({ onChange = () => { }, isSelected }) => {
    const [selected, setSelected] = useState(isSelected)
    const onClick = () => {
        setSelected(!selected)
        onChange(!selected)
    }

    useEffect(() => {
        setSelected(isSelected)
    }, [isSelected])

    return (
        <Container selected={selected} onClick={onClick}>
            {selected && <Icon icon={"Checkmark"} />}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    height: 16px;
    width: 16px;
    border: 1px solid ${Colors.gray[300]};
    border-radius: 4px;
    background-color: ${(props) => props.selected ? props.theme.inputForeground : Colors.white};
`

export default CheckBox
