const inputType = (type) => {
    let returnType = ""
    switch (type) {
        case "date":
            returnType = "date"
            break
        case "string":
            returnType = "text"
            break
        case "number":
            returnType = "number"
            break
        default:
            returnType = "text"
    }
    return returnType
}

class HTMLString {
    static input = (field, index) => {
        return `<input key="input--${index}" type="${inputType(
            field.type
        )}" placeholder="${field.placeholder}" name="${field.name}" />`
    }

    static textarea = (field, index) => {
        return `<textarea key="input--${index}" name="${field.name}"></textarea>`
    }

    static select = (field, index) => {
        return `<select key="select--${index}" name="${
            field.name
        }">${field.options.map((option, index) => {
            return `<option key="option--${index}" value="${option}">${option}</option>`
        })}</select>`
    }

    static radiobutton = (option, name, index) => {
        return `<input type="radio" key="radio-${index}" name="${name}" id="radio-${index}" value="${option}" />`
    }

    static checkbox = (field, index) => {
        return `<input key="input--${index}" type="checkbox" name="${field.name}" />`
    }

    static checkboxMultiple = (option, name, index) => {
        return `<input key="input--${index}" type="checkbox" name="${name}" value="${option}" />`
    }

    static ref = (field, index) => {
        return `<span key="span-ref-${index}" referencingField="${field.ref}">${field.ref}</span>`
    }

    static text = (field, index) => {
        return `<span key="span-text-${index}">${field.text}</span>`
    }

    static label = (field, index) => {
        return `<label key="label-${index}" htmlFor="${field.name}">${
            field.label || field.text
        }</label>`
    }

    static linebreaks = (index) => {
        return `<div key="spacer-${index}" class="spacer-vert"></div>`
    }

    static linebreak = (index) => {
        return `<div key="spacer-${index}" class="spacer-vert-br"></div>`
    }

    static separator = (index) => {
        return `<hr key="spacer-${index}" />`
    }

    static separator_with_text = (field, index) => {
        return `<div key="spacer-with-text-${index}"><hr key="spacer-${index}" /><span>${field.text}</span></div>`
    }

    static listitem_indent = (index) => {
        return `<div key="listitem-${index}" class="spacer-horiz-listitem"></div>`
    }

    static subheader = (text, index) => {
        return `<h6 key="subheader-${index}">${text}</h6>`
    }
}

export default HTMLString
