import React from "react"

const AppStore = () => {
    return (
        <svg
            width="120"
            height="40"
            viewBox="0 0 120 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0)">
                <path
                    d="M110.135 -0.000114141H9.53468C9.16798 -0.000114141 8.80568 -0.000114141 8.43995 0.00188586C8.1338 0.00388586 7.83009 0.00969586 7.521 0.0145859C6.84951 0.0224877 6.17961 0.0815621 5.5171 0.191296C4.85552 0.303418 4.21467 0.514802 3.61622 0.818296C3.0185 1.12436 2.47235 1.52205 1.99757 1.99696C1.5203 2.47052 1.12246 3.0179 0.81935 3.61805C0.5154 4.21699 0.304641 4.85882 0.19435 5.52137C0.0830109 6.18307 0.0230984 6.85241 0.01515 7.52337C0.00587 7.82997 0.00489 8.13758 0 8.44422V31.5585C0.00489 31.869 0.00587 32.1698 0.01515 32.4804C0.0231008 33.1513 0.0830134 33.8206 0.19435 34.4823C0.304336 35.1452 0.515108 35.7874 0.81935 36.3866C1.12233 36.9848 1.52022 37.5299 1.99757 38.0009C2.47055 38.4779 3.01705 38.8759 3.61622 39.1796C4.21467 39.4839 4.85545 39.6965 5.5171 39.8104C6.17972 39.9192 6.84956 39.9783 7.521 39.9872C7.83009 39.994 8.1338 39.9979 8.43995 39.9979C8.80567 39.9999 9.168 39.9999 9.53468 39.9999H110.135C110.494 39.9999 110.859 39.9999 111.219 39.9979C111.523 39.9979 111.836 39.994 112.141 39.9872C112.811 39.9788 113.479 39.9197 114.141 39.8104C114.804 39.6957 115.448 39.4831 116.049 39.1796C116.648 38.8757 117.194 38.4777 117.666 38.0009C118.142 37.5281 118.541 36.9833 118.848 36.3866C119.15 35.787 119.358 35.1448 119.467 34.4823C119.578 33.8205 119.64 33.1514 119.652 32.4804C119.656 32.1698 119.656 31.869 119.656 31.5585C119.664 31.1952 119.664 30.8339 119.664 30.4647V9.53601C119.664 9.1698 119.664 8.80652 119.656 8.44422C119.656 8.13758 119.656 7.82997 119.652 7.52332C119.64 6.85231 119.578 6.18312 119.467 5.52133C119.358 4.85917 119.149 4.21739 118.848 3.61801C118.23 2.41508 117.252 1.43591 116.049 0.818206C115.448 0.515453 114.804 0.304126 114.141 0.191206C113.48 0.0809886 112.811 0.0218936 112.141 0.0144459C111.836 0.00956586 111.523 0.00370586 111.219 0.00175586C110.859 -0.000244141 110.494 -0.000244141 110.135 -0.000244141V-0.000114141Z"
                    fill="#A6A6A6"
                />
                <path
                    d="M8.44487 39.125C8.14019 39.125 7.84287 39.1211 7.54058 39.1143C6.91436 39.1061 6.2896 39.0516 5.67144 38.9512C5.09503 38.8519 4.53664 38.6673 4.0147 38.4033C3.49754 38.1415 3.02585 37.7983 2.6177 37.3867C2.20364 36.98 1.85891 36.5082 1.59719 35.9902C1.33258 35.4688 1.14945 34.9099 1.05419 34.333C0.951311 33.7131 0.895651 33.0863 0.887687 32.458C0.881347 32.2471 0.873047 31.5449 0.873047 31.5449V8.44434C0.873047 8.44434 0.881887 7.75293 0.887737 7.5498C0.895363 6.92248 0.950699 6.29665 1.05327 5.67773C1.14871 5.09925 1.33197 4.53875 1.59673 4.01563C1.85749 3.49794 2.2003 3.02586 2.61187 2.61768C3.02297 2.20562 3.49617 1.8606 4.01421 1.59521C4.53495 1.33209 5.09228 1.14873 5.66753 1.05127C6.28772 0.949836 6.91465 0.894996 7.54304 0.88721L8.44536 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.607 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.711 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44487 39.125Z"
                    fill="black"
                />
                <path
                    d="M24.769 20.3013C24.7797 19.4666 25.0014 18.6483 25.4135 17.9223C25.8255 17.1964 26.4145 16.5864 27.1256 16.1492C26.6738 15.5041 26.0779 14.9731 25.385 14.5986C24.6922 14.224 23.9216 14.0162 23.1344 13.9916C21.4552 13.8153 19.8272 14.9964 18.9715 14.9964C18.0992 14.9964 16.7817 14.0091 15.363 14.0383C14.4453 14.0679 13.551 14.3348 12.7671 14.8128C11.9832 15.2909 11.3365 15.9638 10.8901 16.7661C8.95607 20.1145 10.3987 25.0356 12.2513 27.7422C13.1782 29.0676 14.2615 30.548 15.6789 30.4955C17.066 30.438 17.584 29.611 19.2583 29.611C20.9171 29.611 21.4031 30.4955 22.8493 30.4621C24.3377 30.438 25.2754 29.1309 26.1698 27.793C26.8358 26.8486 27.3483 25.8049 27.6882 24.7005C26.8235 24.3347 26.0856 23.7226 25.5665 22.9403C25.0474 22.158 24.77 21.2402 24.769 20.3013Z"
                    fill="white"
                />
                <path
                    d="M22.0376 12.2113C22.8491 11.2371 23.2489 9.98494 23.1521 8.7207C21.9123 8.85092 20.767 9.44348 19.9445 10.3803C19.5424 10.838 19.2344 11.3704 19.0381 11.9472C18.8419 12.524 18.7612 13.1337 18.8008 13.7417C19.4209 13.7481 20.0344 13.6137 20.5951 13.3486C21.1558 13.0835 21.649 12.6946 22.0376 12.2113Z"
                    fill="white"
                />
                <path
                    d="M42.3022 27.1399H37.5688L36.4321 30.4964H34.4272L38.9106 18.0784H40.9936L45.477 30.4964H43.438L42.3022 27.1399ZM38.0591 25.5911H41.8111L39.9615 20.1438H39.9097L38.0591 25.5911Z"
                    fill="white"
                />
                <path
                    d="M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3814 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.928 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4423H48.4302V22.9482H48.4644C48.7553 22.4457 49.1771 22.0316 49.6847 21.7497C50.1923 21.4679 50.7669 21.3289 51.3472 21.3476C53.645 21.3476 55.1597 23.164 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3023 22.9316 50.857 22.9316C49.437 22.9316 48.482 24.1621 48.482 25.9697C48.482 27.7939 49.437 29.0156 50.857 29.0156C52.3023 29.0156 53.2495 27.8193 53.2495 25.9697Z"
                    fill="white"
                />
                <path
                    d="M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7706 30.6209 60.1981 30.4883 59.6943 30.2081C59.1905 29.928 58.7758 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4423H58.395V22.9482H58.4292C58.7201 22.4457 59.1419 22.0315 59.6495 21.7497C60.1571 21.4679 60.7317 21.3289 61.312 21.3476C63.6099 21.3476 65.1245 23.164 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9316 60.8218 22.9316C59.4019 22.9316 58.4468 24.1621 58.4468 25.9697C58.4468 27.7939 59.4019 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8193 63.2144 25.9697H63.2144Z"
                    fill="white"
                />
                <path
                    d="M71.7105 27.0364C71.8482 28.2679 73.0445 29.0764 74.6792 29.0764C76.2456 29.0764 77.3726 28.2678 77.3726 27.1575C77.3726 26.1936 76.6929 25.6165 75.0835 25.221L73.4742 24.8333C71.1939 24.2825 70.1353 23.2161 70.1353 21.4856C70.1353 19.343 72.0025 17.8713 74.6538 17.8713C77.2778 17.8713 79.0767 19.343 79.1372 21.4856H77.2612C77.1489 20.2463 76.1245 19.4983 74.6275 19.4983C73.1304 19.4983 72.106 20.2551 72.106 21.3567C72.106 22.2346 72.7603 22.7512 74.3609 23.1467L75.729 23.4826C78.2769 24.0852 79.3355 25.1086 79.3355 26.925C79.3355 29.2483 77.4849 30.7033 74.5415 30.7033C71.7876 30.7033 69.9282 29.2824 69.8081 27.0363L71.7105 27.0364Z"
                    fill="white"
                />
                <path
                    d="M83.3462 19.3V21.4426H85.0679V22.9143H83.3462V27.9055C83.3462 28.6809 83.6909 29.0422 84.4477 29.0422C84.6521 29.0387 84.8562 29.0243 85.0591 28.9992V30.4621C84.7188 30.5257 84.3729 30.5545 84.0268 30.5481C82.1938 30.5481 81.479 29.8596 81.479 28.1037V22.9143H80.1626V21.4426H81.479V19.3H83.3462Z"
                    fill="white"
                />
                <path
                    d="M86.0649 25.97C86.0649 23.1213 87.7427 21.3313 90.3589 21.3313C92.9839 21.3313 94.6538 23.1213 94.6538 25.97C94.6538 28.8264 92.9927 30.6086 90.3589 30.6086C87.726 30.6086 86.0649 28.8264 86.0649 25.97ZM92.7602 25.97C92.7602 24.0159 91.8647 22.8625 90.3589 22.8625C88.853 22.8625 87.9585 24.0247 87.9585 25.97C87.9585 27.9319 88.853 29.0764 90.3589 29.0764C91.8647 29.0764 92.7602 27.9319 92.7602 25.97H92.7602Z"
                    fill="white"
                />
                <path
                    d="M96.186 21.4425H97.9585V22.9835H98.0015C98.1214 22.5022 98.4034 22.0769 98.8 21.779C99.1965 21.4811 99.6836 21.3288 100.179 21.3478C100.393 21.347 100.607 21.3703 100.816 21.4171V23.1554C100.546 23.0728 100.264 23.0348 99.981 23.0431C99.711 23.0321 99.4418 23.0797 99.192 23.1826C98.9421 23.2855 98.7175 23.4412 98.5335 23.6391C98.3496 23.837 98.2106 24.0724 98.1262 24.3291C98.0417 24.5857 98.0139 24.8576 98.0444 25.1261V30.4962H96.186L96.186 21.4425Z"
                    fill="white"
                />
                <path
                    d="M109.384 27.8372C109.134 29.4807 107.534 30.6086 105.486 30.6086C102.852 30.6086 101.217 28.844 101.217 26.0129C101.217 23.1731 102.861 21.3313 105.408 21.3313C107.913 21.3313 109.488 23.052 109.488 25.7971V26.4338H103.093V26.5461C103.064 26.8793 103.105 27.215 103.216 27.5308C103.326 27.8467 103.502 28.1355 103.732 28.378C103.963 28.6206 104.242 28.8113 104.552 28.9376C104.861 29.0639 105.195 29.1229 105.529 29.1106C105.968 29.1517 106.409 29.05 106.785 28.8206C107.162 28.5911 107.455 28.2462 107.62 27.8371L109.384 27.8372ZM103.102 25.135H107.628C107.645 24.8354 107.6 24.5356 107.495 24.2544C107.39 23.9732 107.229 23.7166 107.02 23.5009C106.812 23.2851 106.561 23.1148 106.283 23.0006C106.006 22.8863 105.708 22.8307 105.408 22.8372C105.105 22.8354 104.805 22.8935 104.525 23.0082C104.245 23.123 103.99 23.292 103.776 23.5057C103.562 23.7193 103.392 23.9733 103.276 24.2529C103.16 24.5326 103.101 24.8324 103.102 25.135V25.135Z"
                    fill="white"
                />
                <path
                    d="M39.3926 14.698H35.6709V8.7312H36.5977V13.8459H39.3926V14.698Z"
                    fill="white"
                />
                <path
                    d="M40.3291 13.4246C40.3291 12.614 40.9326 12.1467 42.0039 12.0803L43.2236 12.01V11.6213C43.2236 11.1457 42.9092 10.8772 42.3018 10.8772C41.8057 10.8772 41.4619 11.0593 41.3633 11.3777H40.5029C40.5937 10.6042 41.3213 10.1082 42.3428 10.1082C43.4717 10.1082 44.1084 10.6702 44.1084 11.6213V14.698H43.2529V14.0652H43.1826C43.0399 14.2922 42.8395 14.4772 42.6019 14.6014C42.3643 14.7256 42.0979 14.7846 41.8301 14.7722C41.641 14.7918 41.45 14.7717 41.2692 14.713C41.0885 14.6543 40.922 14.5584 40.7806 14.4314C40.6392 14.3044 40.5259 14.1492 40.4482 13.9758C40.3704 13.8024 40.3298 13.6146 40.3291 13.4246ZM43.2236 13.0398V12.6633L42.124 12.7336C41.5039 12.7751 41.2226 12.9861 41.2226 13.383C41.2226 13.7883 41.5742 14.0242 42.0576 14.0242C42.1993 14.0385 42.3423 14.0242 42.4784 13.9821C42.6144 13.94 42.7405 13.871 42.8493 13.7792C42.9581 13.6873 43.0473 13.5745 43.1116 13.4475C43.1759 13.3205 43.214 13.1818 43.2236 13.0398Z"
                    fill="white"
                />
                <path
                    d="M47.1455 10.1082C48.1582 10.1082 48.8203 10.5793 48.9072 11.3733H48.0547C47.9727 11.0427 47.6494 10.8318 47.1455 10.8318C46.6494 10.8318 46.2725 11.0671 46.2725 11.4187C46.2725 11.6877 46.5 11.8572 46.9883 11.969L47.7363 12.1423C48.5928 12.3411 48.9941 12.7092 48.9941 13.3709C48.9941 14.2185 48.2041 14.7849 47.1289 14.7849C46.0576 14.7849 45.3594 14.301 45.2803 13.5032H46.1699C46.2466 13.6931 46.3848 13.8517 46.5624 13.9536C46.7401 14.0555 46.9468 14.0948 47.1494 14.0652C47.7031 14.0652 48.0967 13.8172 48.0967 13.4573C48.0967 13.1887 47.8857 13.0149 47.4346 12.9075L46.6494 12.7253C45.793 12.5227 45.3965 12.1384 45.3965 11.4685C45.3965 10.6702 46.1279 10.1082 47.1455 10.1082Z"
                    fill="white"
                />
                <path
                    d="M51.3613 9.07397V10.2156H52.3369V10.9641H51.3613V13.2795C51.3613 13.7512 51.5557 13.9578 51.998 13.9578C52.1113 13.9574 52.2244 13.9506 52.3369 13.9373V14.6775C52.1773 14.7061 52.0156 14.7213 51.8535 14.7229C50.8652 14.7229 50.4717 14.3752 50.4717 13.5071V10.9641H49.7568V10.2155H50.4717V9.07397H51.3613Z"
                    fill="white"
                />
                <path
                    d="M55.9522 10.1946H56.8077V10.91H56.874C56.9867 10.653 57.1767 10.4377 57.4176 10.2939C57.6584 10.15 57.9382 10.085 58.2178 10.1077C58.4369 10.0912 58.6569 10.1243 58.8615 10.2044C59.0661 10.2845 59.25 10.4096 59.3997 10.5704C59.5494 10.7313 59.661 10.9237 59.7262 11.1335C59.7914 11.3434 59.8085 11.5652 59.7764 11.7825V14.6975H58.8877V12.0057C58.8877 11.282 58.5732 10.9222 57.916 10.9222C57.7673 10.9152 57.6188 10.9406 57.4807 10.9964C57.3426 11.0522 57.2183 11.1372 57.1162 11.2456C57.014 11.354 56.9366 11.4832 56.889 11.6243C56.8415 11.7655 56.8251 11.9152 56.8408 12.0633V14.6976H55.9521L55.9522 10.1946Z"
                    fill="white"
                />
                <path
                    d="M64.9591 13.4823C64.8384 13.8938 64.5768 14.2498 64.2201 14.4879C63.8634 14.7261 63.4344 14.8312 63.008 14.785C62.7113 14.7928 62.4165 14.736 62.144 14.6185C61.8715 14.5009 61.6278 14.3255 61.4299 14.1044C61.232 13.8832 61.0846 13.6217 60.9979 13.3379C60.9112 13.054 60.8873 12.7548 60.9279 12.4608C60.8884 12.1659 60.9127 11.866 60.9992 11.5813C61.0857 11.2966 61.2323 11.0338 61.4292 10.8108C61.6261 10.5877 61.8686 10.4095 62.1403 10.2883C62.412 10.1671 62.7066 10.1057 63.0041 10.1082C64.257 10.1082 65.0133 10.9642 65.0133 12.3782V12.6883H61.8331V12.7381C61.8192 12.9034 61.84 13.0697 61.8941 13.2265C61.9482 13.3832 62.0345 13.5269 62.1474 13.6484C62.2603 13.7698 62.3974 13.8663 62.5498 13.9317C62.7022 13.9971 62.8665 14.03 63.0324 14.0281C63.2449 14.0537 63.4603 14.0154 63.6511 13.9182C63.8418 13.821 63.9994 13.6692 64.1037 13.4822L64.9591 13.4823ZM61.8331 12.0311H64.1076C64.1188 11.88 64.0982 11.7282 64.0473 11.5854C63.9963 11.4427 63.9161 11.3122 63.8117 11.2024C63.7073 11.0925 63.5811 11.0057 63.4411 10.9476C63.3012 10.8894 63.1507 10.8611 62.9992 10.8646C62.8455 10.8627 62.6929 10.8915 62.5506 10.9495C62.4082 11.0074 62.2788 11.0933 62.1702 11.202C62.0615 11.3107 61.9757 11.4401 61.9178 11.5825C61.8599 11.7248 61.8311 11.8774 61.8331 12.0311Z"
                    fill="white"
                />
                <path
                    d="M65.9829 12.4446C65.9829 11.0217 66.7144 10.1204 67.852 10.1204C68.1335 10.1074 68.4128 10.1748 68.6573 10.3147C68.9018 10.4546 69.1015 10.6612 69.2329 10.9104H69.2993V8.43726H70.188V14.698H69.3364V13.9866H69.2661C69.1245 14.2341 68.9179 14.4381 68.6686 14.5766C68.4194 14.7151 68.137 14.7827 67.852 14.7722C66.7065 14.7723 65.9829 13.8709 65.9829 12.4446ZM66.9009 12.4446C66.9009 13.3997 67.3511 13.9744 68.104 13.9744C68.853 13.9744 69.3159 13.3914 69.3159 12.4485C69.3159 11.51 68.8482 10.9187 68.104 10.9187C67.356 10.9187 66.9009 11.4973 66.9009 12.4446H66.9009Z"
                    fill="white"
                />
                <path
                    d="M74.4067 10.9637H73.6665V10.2151H74.4067V9.77664C74.4067 8.85864 74.9067 8.4783 75.8452 8.4783C76.006 8.47753 76.1665 8.49142 76.3247 8.5198V9.23078C76.195 9.20892 76.0637 9.19798 75.9321 9.19807C75.5064 9.19807 75.2827 9.39241 75.2827 9.83479V10.2152H76.2876V10.9637H75.2954V14.6976H74.4067V10.9637Z"
                    fill="white"
                />
                <path
                    d="M77.4194 10.1954H78.2749V10.8858H78.3413C78.4371 10.6461 78.6063 10.443 78.8248 10.3055C79.0432 10.168 79.2996 10.1033 79.5571 10.1207C79.6902 10.1189 79.8231 10.1313 79.9536 10.1578V11.0348C79.7905 10.9999 79.6243 10.9819 79.4575 10.9811C79.3109 10.9679 79.1632 10.9856 79.0239 11.0329C78.8845 11.0802 78.7566 11.1562 78.6484 11.2558C78.5401 11.3555 78.454 11.4768 78.3953 11.6118C78.3367 11.7468 78.307 11.8925 78.3081 12.0397V14.6984H77.4194L77.4194 10.1954Z"
                    fill="white"
                />
                <path
                    d="M80.6519 13.4246C80.6519 12.614 81.2554 12.1467 82.3267 12.0803L83.5464 12.01V11.6213C83.5464 11.1457 83.2319 10.8772 82.6245 10.8772C82.1284 10.8772 81.7847 11.0593 81.686 11.3777H80.8257C80.9165 10.6042 81.644 10.1082 82.6655 10.1082C83.7944 10.1082 84.4311 10.6702 84.4311 11.6213V14.698H83.5757V14.0652H83.5054C83.3626 14.2922 83.1623 14.4772 82.9246 14.6014C82.687 14.7256 82.4207 14.7846 82.1528 14.7722C81.9638 14.7918 81.7727 14.7717 81.592 14.713C81.4112 14.6543 81.2448 14.5584 81.1033 14.4314C80.9619 14.3044 80.8487 14.1492 80.7709 13.9758C80.6932 13.8024 80.6526 13.6146 80.6519 13.4246ZM83.5464 13.0398V12.6633L82.4468 12.7336C81.8267 12.7751 81.5454 12.9861 81.5454 13.383C81.5454 13.7883 81.897 14.0242 82.3804 14.0242C82.5221 14.0385 82.6651 14.0242 82.8011 13.9821C82.9371 13.94 83.0633 13.871 83.1721 13.7792C83.2809 13.6873 83.3701 13.5745 83.4344 13.4475C83.4987 13.3205 83.5368 13.1818 83.5464 13.0398Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="119.664" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default AppStore
