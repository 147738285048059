import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import SignaturePad from "react-signature-canvas"
import parse from "html-react-parser"
import Button from "./button"
import { P_Mini, P_Small_M } from "./text"

import Colors from "../assets/colors"

function Signature({ name, onCompleted }) {
    const [imageURL, setImageURL] = useState(null)
    const [nextEnabled, setNextEnabled] = useState(false)

    const sigCanvas = useRef({})

    const clear = () => sigCanvas.current.clear()

    const next = () => {
        const pngData = sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png")
        setImageURL(pngData)
        onCompleted(pngData)
        // setImageURL(sigCanvas.current.toDataURL("image/svg+xml"))
    }
    // const save = () => setImageURL(sigCanvas.current.toDataURL("image/svg+xml"))

    const [pointGroups, setPointGroups] = useState([])

    /* Save the current canvas drawing so we can at least restore a little bit
    in case of a resize */
    const handleOnEnd = () => {
        if (!sigCanvas.current.isEmpty()) {
            setNextEnabled(true)
        }
        setPointGroups(sigCanvas.current.toData())
    }

    const [dimensions, setDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener("resize", handleResize)

        return (_) => {
            window.removeEventListener("resize", handleResize)
        }
    })

    useEffect(() => {
        if (pointGroups.length) {
            sigCanvas.current.fromData(pointGroups)
        }
    }, [dimensions])

    return (
        <SignatureFieldWithButtons>
            <SignatureWrapper>
                <SignaturePad
                    ref={sigCanvas}
                    onEnd={handleOnEnd}
                    canvasProps={{
                        className: "signatureCanvas"
                    }}
                />
            </SignatureWrapper>

            <Bottom>
                <Nametag>
                    <Prefix>Part</Prefix>
                    <Name>{name}</Name>
                </Nametag>
                <Buttons>
                    <ButtonWrapper>
                        <Button
                            icon={"Arrow"}
                            arrowDirection="left"
                            secondary
                            onClick={close}
                        />
                        <P_Mini>Tilbake</P_Mini>
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <Button icon={"Eraser"} secondary onClick={clear} />
                        <P_Mini>Tøm</P_Mini>
                    </ButtonWrapper>
                    <ButtonWrapper>
                        <Button
                            icon={"Arrow"}
                            onClick={next}
                            disabled={!nextEnabled}
                        />
                        <P_Mini>Neste</P_Mini>
                    </ButtonWrapper>
                </Buttons>
            </Bottom>

            {/* if our we have a non-null image url we should show an image and pass our imageURL state to it*/}
            {/*
            {imageURL ? (
                <img
                    src={imageURL}
                    alt="my signature"
                    style={{
                        display: "block",
                        margin: "0 auto",
                        border: "1px solid ${Colors.black}",
                        width: "150px"
                    }}
                />
            ) : null}
            */}
        </SignatureFieldWithButtons>
    )
}

const SignatureFieldWithButtons = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 128px);
    width: calc(100vw - 48px);
    padding: 24px;
    @media screen and (min-width: 768px) {
        width: calc(768px - 48px);
    }
`

const SignatureWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: flex-end;
    .signatureCanvas {
        border-bottom: 2px solid ${Colors.gray[300]};
        width: 100%;
        height: 300px;
        @media screen and (min-width: 480px) {
            height: 170px;
        }
        background-color: ${Colors.gray[50]};
    }
`

const Bottom = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 24px 0;
    @media screen and (min-width: 480px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
`

const Nametag = styled.div`
    background-color: ${Colors.gray[100]};
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 16px;
    flex: 0;
    display: flex;
    flex-direction: row;
`

const Prefix = styled(P_Small_M)`
    color: ${Colors.gray[400]};
    padding-right: 8px;
`

const Name = styled(P_Small_M)`
    color: ${(props) => props.theme.foregroundSecondary};
    white-space: nowrap;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: row;
`

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 16px 16px;
    > p {
        padding-top: 8px;
    }
`

export default Signature
