import React, { useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { H5, P } from "../../components/text"

import Button from "../../components/button"
import List from "../../components/list"
import RadioButtonLabelDesc from "../../components/radioButtonLabelDescription"

const CustomerType = ({ onSubmit = (index) => { } }) => {
    const [indexOfSelected, setIndexOfSelected] = useState(0)
    const onClick = () => onSubmit(indexOfSelected)

    return (
        <Container>
            <InnerContainer>
                <Title>{"Velg konto"}</Title>
                <Message>
                    {"Vil du bruke Lawyered som"}
                    <br />
                    {"privatperson eller som bedriftskunde?"}
                </Message>
                <List>
                    <RadioButtonLabelDesc
                        title={"Privatperson"}
                        description={
                            "Passer for deg som skal lage\nkontrakter på egenhånd"
                        }
                        onChange={() => setIndexOfSelected(0)}
                        selected={indexOfSelected === 0}
                    />
                    <RadioButtonLabelDesc
                        title={"Bedriftskunde"}
                        description={
                            "Passer for deg som håndterer flere\nkontrakter på vegne av en bedrift"
                        }
                        onChange={() => setIndexOfSelected(1)}
                        selected={indexOfSelected === 1}
                    />
                </List>
                <Button text={"Neste"} onClick={onClick} />
            </InnerContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const InnerContainer = styled.div`
    width: 300px;
    @media screen and (min-width: 768px) {
        width: 360px;
    }
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-align: center;
`

const Message = styled(P)`
    color: ${Colors.gray[500]};
    text-align: center;
`

export default CustomerType
