import { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { FirebaseAuth } from "../providers/AuthProvider"

import Colors from "../assets/colors"

import { H5, P_Large_M, P_Medium, P_Small_M } from "../components/text"
import Contract from "../components/contract"
import Tab from "../components/tab"
import WithSidebarMainContent from "../components/withSidebarMainContent"
import Button from "../components/button"

import { ArchiveLoading } from "../containers/Loading"

import { useContracts } from "../hooks/useContracts"
import { buildSignersString } from "../utils/stringHelpers"
import { renderError } from "../utils/errors"
import Notice from "../components/notice"
import { useBulkContracts } from "../hooks/useBulkContracts"

const ArchiveLanding = () => {
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1)
    const [categories, setCategories] = useState([])

    const { contracts, isLoading, isError } = useContracts()
    const { bulkContracts, orders} = useBulkContracts()
    const { currentUser, token } = useContext(FirebaseAuth)

    const [filteredContracts, setFilteredContracts] = useState([])

    const [hasAnonymousContracts, setHasAnonymousContracts] = useState(false)

    useEffect(() => {
        if (contracts && !!contracts.length) {
            const allCategories = contracts.map(
                (contract) => contract.category.main
            )
            const frequency = {}
            for (const name of allCategories) {
                frequency[name] = frequency[name] ? frequency[name] + 1 : 1
            }
            const categories = []
            Object.entries(frequency).forEach((summary) => {
                const name = summary[0]
                const count = summary[1]
                categories.push({
                    title: name,
                    name,
                    count: count
                })
            })
            setCategories(categories)
        }

        const anonContracts = localStorage.getItem(
            "lawyered.anonymous_contracts"
        )

        if (anonContracts && currentUser.isAnonymous) {
            setHasAnonymousContracts(true)
        }
    }, [contracts])

    useEffect(() => {
        if (selectedCategoryIndex !== -1) {
            const selectedCategory = categories[selectedCategoryIndex]
            setFilteredContracts(
                contracts.filter(
                    (contract) =>
                        contract.category.main === selectedCategory.name
                )
            )
        } else {
            setFilteredContracts(...[])
        }
    }, [selectedCategoryIndex])

    const renderCategories = () => {
        if (!contracts || contracts.length === 0 || isError) {
            return null
        }
        return (
            <Row>
                <Tab
                    key={`category-tab-all`}
                    title={`Alle (${contracts.length})`}
                    selected={selectedCategoryIndex === -1}
                    onClick={() => setSelectedCategoryIndex(-1)}
                />
                {categories.map((item, index) => {
                    const title = item.title + " (" + item.count + ")"
                    const selected = index === selectedCategoryIndex
                    const category = categories[index]
                    const onClick = () => setSelectedCategoryIndex(index)
                    return (
                        <Tab
                            key={`category-tab-${item.name}`}
                            title={title}
                            selected={selected}
                            onClick={onClick}
                        />
                    )
                })}
            </Row>
        )
    }

    const renderContracts = () => {
        if (!contracts || contracts.length === 0) {
            return <Subtitle>Du har ingen kontrakter enda.</Subtitle>
        }
        const contractsToShow = filteredContracts || contracts

        return (
            <Row>
                {!!contractsToShow.length &&
                    contractsToShow.map((item) => {
                        const signers = buildSignersString(item.signers)
                        const created = new Date(item.created)
                        return (
                            <Link
                                key={`contract-${item.id}`}
                                to={`/archive/${item.id}`}
                            >
                                <Contract
                                    date={created.toLocaleString("no-NO", {
                                        day: "2-digit",
                                        month: "2-digit",
                                        year: "2-digit",
                                        hour: "2-digit",
                                        minute: "2-digit"
                                    })}
                                    description={signers}
                                    status={
                                        item.signing?.status?.documentStatus
                                    }
                                    title={item.title}
                                    highlight={item.grantedAccess}
                                />
                            </Link>
                        )
                    })}
            </Row>
        )
    }

    const renderBulkContracts = () => {

        // Get the unique bulkContractIds from ordersData for bought contracts
        const boughtBulkContractIds = orders?.filter(order => order.data.status === "succeeded")
        .map(order => order.data.bulkContractId);

        // Filter bulkContractData based on matching bulkContractIds for bought contracts
        const boughtBulkContracts = bulkContracts?.filter(contract => boughtBulkContractIds.includes(contract.id)
        );


        return (
            <Row>
                {boughtBulkContracts?.map((item, index) => (
                    <Link
                    key={`bulk-contract-${item.id}`}
                    to={{
                        pathname: '/create',
                        // state: `${item.data.title === "DNB Eiendomspakken"?"FreeAllCoontracts":"Normal"}`
                      }}
                >
                        <Contract
                            // key={index}
                            // date={created.toLocaleString("no-NO", {
                            //     day: "2-digit",
                            //     month: "2-digit",
                            //     year: "2-digit",
                            //     hour: "2-digit",
                            //     minute: "2-digit"
                            // })}
                            // description="this is description for the"
                            // status={false}
                            title={item.data.title}
                        // highlight={item.grantedAccess}
                        />
                        </Link>
                ))}
            </Row>
        )
    }

    return (
        <WithSidebarMainContent>
            <TopContainer>
                <Header>
                    <Title>Arkiv</Title>
                    <Link to={`/create`}>
                        <Action>
                            <NewContract>Ny kontrakt</NewContract>
                            <Button icon={"Plus"} />
                        </Action>
                    </Link>
                </Header>
                {isLoading && <ArchiveLoading />}
                {isError && renderError(isError)}
                {!isLoading && !isError && renderCategories()}
                {!isLoading && !isError && renderContracts()}
                {!isLoading && !isError && <div className="mb-2"><P_Large_M>Kontraktspakker</P_Large_M></div>}
                {!isLoading && !isError && renderBulkContracts()}
                {hasAnonymousContracts && (
                    <Notice
                        warning
                        title={"Du har kontrakter som ikke er lagret"}
                        text={
                            "Det ser ut til at du har laget kontrakter når du ikke var innlogget. Lag en konto eller logg inn for å sikre at ikke disse går tapt."
                        }
                    />
                )}
            </TopContainer>
        </WithSidebarMainContent>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;

    > *:not(:last-child) {
        margin-right: 16px;
    }

    > * {
        margin-bottom: 16px;
    }
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`

const Action = styled.div`
    display: flex;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    align-items: center;
    grid-gap: 16px;
    p {
        white-space: nowrap;
    }
`

const Title = styled(H5)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 8px;
`

const Subtitle = styled(P_Medium)`
    color: ${(props) => props.theme.foregroundSecondary};
    line-height: 32px;
    margin-bottom: 16px;
`

const NewContract = styled(P_Small_M)`
    color: ${(props) => props.theme.foregroundPrimary};
`

export default ArchiveLanding
