import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import Notice from '../notice'
import Icon from '../icon'
import styled from "styled-components"

export default function HelpTextComponent({helpText, dismissable = false, onDismiss = () => { } }) {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <div>
        {helpText && (
                    <Popover
                        isOpen={isPopoverOpen}
                        positions={["top", "bottom", "right", "left"]} // preferred positions by priority
                        content={<Notice info text={helpText} />}
                        containerStyle={{ zIndex: 2048 }}
                        onClickOutside={() => setIsPopoverOpen(false)}
                        containerClassName="popoverOuter"
                    >
                        <PopoverWrapper
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <Icon icon="Info" />
                        </PopoverWrapper>
                    </Popover>
                )}
                {dismissable && (
                    <IconContainer onClick={onDismiss}>
                        <Icon icon={"Cancel"} />
                    </IconContainer>
                )}
    </div>
  )
}

const IconContainer = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 0 4px 0 0;
    margin-right: 8px;
`

const PopoverWrapper = styled.div`
    padding: 2px;
    margin: 0 4px 0 0;
    z-index: 1024;
    display: flex;
    align-items: center;
`
