import styled from "styled-components"
import Colors from "../../assets/colors"

const HrWithText = ({ text }) => {
    return (
        <Element>
            <HR />
            <p>
                <span>{text}</span>
            </p>
        </Element>
    )
}

const Element = styled.div`
    display: flex;
    width: 100%;
    p {
        font-size: 16px;
        line-height: 28px;
        position: absolute;
        width: 100%;
        text-align: center;
        margin-top: -8px;
        color: ${Colors.gray[500]};
    }
    span {
        background-color: ${Colors.white};
        padding: 0 8px;
    }
`

const HR = styled.div`
    background-color: ${Colors.gray[300]};
    height: 1px;
    width: 100%;
    margin: 8px 0;
`

export default HrWithText
