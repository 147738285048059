import { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { useIntercom } from "react-use-intercom"

import { FirebaseAuth } from "../../providers/AuthProvider"
import { auth } from "../../firebase/firebaseConfig"
import { useAuth } from "react-oidc-context"
import firebase from "firebase"

import { useLocation } from "react-router-dom"

import CustomerType from "./CustomerType"
import FindCompany from "./FindCompany"
import Login from "./Login"
import MailReceived from "./MailReceived"
import Registration from "./Registration"
import Summary from "./Summary"
import Loading from "./Loading"

import {
    getAuthUserData,
    updateUserData,
    saveCompany,
    identifyWithVipps
} from "../../utils/api"

const ScreenState = {
    Loading: -1,
    Registration: 0,
    Login: 1,
    MailReceived: 2,
    CustomerType: 3,
    FindCompany: 4,
    Summary: 5
}

const Onboarding = ({ vipps }) => {
    const {
        handleSignupEmail,
        setEmail,
        errors: authErrors,
        approved,
        isSignupEmailSent,
        setIsLoading,
        isLoading,
        token,
        setToken,
        setApproved,
        setIsAnonymous,
        isAnonymous,
        email
    } = useContext(FirebaseAuth)

    let location = useLocation()

    function codeVerifierSorter(a, b) {
        var createDateOne = JSON.parse(a.value).created
        var createdDateTwo = JSON.parse(b.value).created
        if (createDateOne < createdDateTwo) {
            return 1
        } else if (createDateOne > createdDateTwo) {
            return -1
        } else {
            return 0
        }
    }

    function getLatestCodeVerifier() {
        let oidcCodeVerifier = []

        Object.entries(window.localStorage).forEach(function (valueObject) {
            if (
                valueObject instanceof Array &&
                valueObject.length > 0 &&
                valueObject[0].includes("oidc.")
            ) {
                var key = valueObject[0]
                var value = valueObject[1]
                oidcCodeVerifier.push({ key, value })
            }
        })

        var latestCodeVerifier = oidcCodeVerifier.sort(codeVerifierSorter)

        if (latestCodeVerifier.length > 0) {
            window.sessionStorage.setItem(
                "code_verifier",
                JSON.parse(latestCodeVerifier[0].value).code_verifier
            )
            return JSON.parse(latestCodeVerifier[0].value).code_verifier
        }
        return null
    }

    /**
     * Save this contract and access key to be able to give access
     * post-onboarding.
     */
    const addContractToAccessList = (contractId, linkContractKey) => {
        const accessToContracts = localStorage.getItem(
            "lawyered.access_to_contracts"
        )
        let data
        if (!accessToContracts) {
            data = JSON.stringify([{ contractId, linkContractKey }])
        } else {
            data = JSON.parse(accessToContracts)
            data.push({ contractId, linkContractKey })
            data = JSON.stringify(data)
        }
        localStorage.setItem("lawyered.access_to_contracts", data)
    }

    const doLogin = async ({
        username,
        password,
        redirectAfterLogin = true
    }) => {
        return firebase
            .auth()
            .signInWithEmailAndPassword(username, password)
            .then(async (result) => {
                const token = await Object.entries(result.user)[5][1]?.b
                if(token){
                    localStorage.setItem("lawyered.user.token", token)
                }
                localStorage.setItem("lawyered.user.isAnonymous", false)
                localStorage.setItem("lawyered.user.email", username)
                setToken(token)
                setApproved(true)
                setIsLoading(false)
                setShowLoading(false)
                if (redirectAfterLogin) {
                    window.location.href = "/dashboard"
                }
                return Promise.resolve(token)
            })
            .catch((error) => {
                setIsLoading(false)
                setShowLoading(false)
                setCurrentView({
                    screen: ScreenState.Login,
                    props: {}
                })
                console.error(error)
                setErrors((prev) => [
                    ...prev,
                    { message: error.message, code: error.code }
                ])
                return Promise.reject(error)
            })
    }

    async function handleVippsRedirect({ code, codeVerifier, scope, state }) {
        setCurrentView({
            screen: ScreenState.Loading,
            props: {}
        })
        const identity = await identifyWithVipps({
            firebaseToken: token,
            code,
            codeVerifier,
            scope,
            state
        })
        if (identity && identity.token?.success) {
            if (!identity.isNewUser) {
                console.log("Existing user, logging in...")
                // Use identity.password and identity.user.email to log user in automatically
                doLogin({
                    username: identity.vippsUser.email,
                    password: identity.password
                })
            } else {
                console.log("New user, linking...", identity)
                // This is a new user, so link the anonymous login with a new account
                const credential = firebase.auth.EmailAuthProvider.credential(
                    identity.vippsUser.email,
                    identity.password
                )

                firebase
                    .auth()
                    .currentUser.linkWithCredential(credential)
                    .then(async (userCred) => {
                        const user = userCred.user
                        console.log("linked user", user)

                        const newToken = await doLogin({
                            username: identity.vippsUser.email,
                            password: identity.password,
                            redirectAfterLogin: false
                        })

                        console.log("identity.vippsUser", identity.vippsUser)

                        const updatedUserData = {
                            vippsSub: identity.vippsUser.sub,
                            name: identity.vippsUser.name,
                            emailVerified: identity.vippsUser.email_verified,
                            email: identity.vippsUser.email,
                            name: identity.vippsUser.name,
                            phoneNumber: "+" + identity.vippsUser.phone_number
                        }

                        await updateUserData(newToken, updatedUserData)

                        setCurrentView({
                            screen: ScreenState.Registration,
                            props: updatedUserData
                        })
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        } else {
            // The code has probably expired or was re-used, so we'll restart
            console.error("identifyWithVipps error", identity)
            window.location.href = "/onboarding"
        }
    }

    useEffect(() => {
        if (location.search) {
            const l = new URLSearchParams(location.search)

            const error = l.get("error")
            const errorDescription = l.get("error_description")

            if (error) {
                setError(error)
            }
            if (errorDescription) {
                setErrorDescription(errorDescription)
            }

            const oauthCode = l.get("code")
            const oauthScope = l.get("scope")
            const oauthState = l.get("state")

            // The OIDC lib we use doesn't support skipping code challenge,
            // so we need to get this from storage before getting the tokens
            // from the backend.
            const codeVerifier = getLatestCodeVerifier()

            if (oauthCode && oauthScope && oauthState && codeVerifier) {
                handleVippsRedirect({
                    code: oauthCode,
                    codeVerifier,
                    scope: oauthScope,
                    state: oauthState
                })
            }

            const linkContractId = l.get("link_contract_id")
            const linkContractKey = l.get("link_contract_key")
            if (linkContractId && linkContractKey) {
                addContractToAccessList(linkContractId, linkContractKey)
            }
        }
    }, [location])

    const oidcAuth = useAuth()

    const { shutdown } = useIntercom()

    const [currentView, setCurrentView] = useState({
        screen: ScreenState.Login,
        props: {}
    })

    const [error, setError] = useState(null)
    const [isCompanyDuplicate, setIsCompanyDuplicate] = useState(false)

    useEffect(() => {
        async function approvedCb() {
            const authUserData = await getAuthUserData(token)
            console.log(`authUserData`, authUserData)
            if (authUserData.userData.dbData?.email) {
                shutdown()
                window.location.href = "/dashboard"
            } else {
                setCurrentView({
                    screen: ScreenState.Registration,
                    props: {
                        // E-mail logins are different sessions, so we'll
                        // need to get email from authUserData.userData.tokenData
                        email: email
                            ? email
                            : authUserData.userData.tokenData?.email
                    }
                })
            }
        }

        if (approved && !isAnonymous) {
            shutdown()
            approvedCb()
        }
    }, [approved, isAnonymous])

    const onRegistration = async (name, email, phoneNumber) => {
        const updatedUserData = await updateUserData(token, {
            name,
            email,
            phoneNumber
        })
        if (updatedUserData.success) {
            setCurrentView({
                screen: ScreenState.CustomerType,
                props: {}
            })
        }
        // TODO handle errors
    }

    const onLogin = async ({ email }) => {
        if (!email) {
            setError("Du må fylle inn en e-post-adresse.")
            return
        } else {
            setEmail(email)
            handleSignupEmail(email)
            setError(null)
        }
    }

    const onCustomerTypeSubmit = (customerType) => {
        if (customerType === 0) {
            shutdown()
            window.location.href = "/dashboard"
        } else if (customerType === 1) {
            setCurrentView({ screen: ScreenState.FindCompany, props: {} })
        }
    }

    const onFindCompanySubmit = (company) => {
        const name = company.title
        const organizationNumber = company.orgNo
        const phoneNumber = ""
        const email = ""

        setCurrentView({
            screen: ScreenState.Summary,
            props: { name, organizationNumber, phoneNumber, email }
        })
    }

    const onSummarySubmit = async (companyInfo) => {
        const savedCompany = await saveCompany(token, companyInfo)
        if (savedCompany.success) {
            shutdown()
            window.location.href = "/dashboard"
        } else {
            if (savedCompany.error.code === "lawyered/company-already-exists") {
                setIsCompanyDuplicate(true)
            }
        }
        // TODO handle errors
    }

    const onStepPressed = (stepIndex) => {
        if (stepIndex === 1) {
            setCurrentView({ screen: ScreenState.CustomerType, props: {} })
        } else if (stepIndex === 2) {
            setCurrentView({ screen: ScreenState.FindCompany, props: {} })
        }
    }

    const [showLoading, setShowLoading] = useState(false)

    if (showLoading) {
        return (
            <Container>
                <Loading />
            </Container>
        )
    }

    return (
        <Container>
            {currentView.screen === ScreenState.Loading && <Loading />}
            {currentView.screen === ScreenState.Registration && (
                <Registration
                    initialEmail={currentView.props.email}
                    initialName={currentView.props.name}
                    initialPhoneNumber={currentView.props.phoneNumber}
                    onSubmit={onRegistration}
                />
            )}
            {currentView.screen === ScreenState.Login && (
                <Login
                    {...currentView.props}
                    onSubmit={onLogin}
                    isSignupEmailSent={isSignupEmailSent}
                    isLoading={isLoading}
                    authErrors={authErrors}
                    error={error}
                    vippsSignIn={() => oidcAuth.signinRedirect()}
                />
            )}
            {currentView.screen === ScreenState.MailReceived && (
                <MailReceived {...currentView.props} />
            )}
            {currentView.screen === ScreenState.CustomerType && (
                <CustomerType
                    {...currentView.props}
                    onSubmit={onCustomerTypeSubmit}
                />
            )}
            {currentView.screen === ScreenState.FindCompany && (
                <FindCompany
                    {...currentView.props}
                    onSubmit={onFindCompanySubmit}
                    onStepPressed={onStepPressed}
                />
            )}
            {currentView.screen === ScreenState.Summary && (
                <Summary
                    {...currentView.props}
                    onSubmit={onSummarySubmit}
                    isCompanyDuplicate={isCompanyDuplicate}
                    onStepPressed={onStepPressed}
                />
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100vh;
    background-color: ${(props) => props.theme.backgroundPrimary};
`

export default Onboarding
