import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import CheckBox from "../checkBox"

const CheckBoxLabel = ({ onChange, title }) => {
    return (
        <Container>
            <CheckBox onChange={onChange} />
            <Title>{title}</Title>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
`

const Title = styled.p`
    margin: 0 0 0 8px;
    color: ${Colors.gray[700]};
    font-size: 14px;
    font-weight: 500;
`

export default CheckBoxLabel
