import React, { useEffect, useState } from 'react'
import { useContext } from "react"
import { useRouteMatch, useHistory } from "react-router-dom"
import { FirebaseAuth } from "../providers/AuthProvider"
import WithSidebar from '../components/withSidebar'
import styled from 'styled-components'
import { H5 } from '../components/text'
import Colors from '../assets/colors'
import { useBulkContracts } from '../hooks/useBulkContracts'
import { ArchiveLoading } from '../containers/Loading'
import BulkContractPaymentModal from '../components/bulkContractPaymentModal/BulkContractPaymentModal'
import { clearCurrentContractMetaData } from '../utils/localStorage'
import Button from '../components/button'
import Spinner from '../components/Spinner'
import BulkContractPromocodeModal from '../components/bulkContractPromocodeModal/BulkContractPromocodeModal'



export default function BulkContracts() {
    const history = useHistory();
    let { path } = useRouteMatch()
    const { currentUser, token } = useContext(FirebaseAuth)
    const { bulkContracts, orders, isLoading, isError } = useBulkContracts()
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false)
    const [singleBulkContractData, setSingleBulkContractData] = useState()
    const [isPromocodeModalOpen, setIsPromocodeModalOpen] = useState(false)
    const [currentSelectedBulkContract, setCurrentSelectedBulkContract] = useState("")
    const [finalPriceAfterDiscount, setFinalPriceAfterDiscount] = useState(0);
    const [appliedCouponId, setAppliedCouponId] = useState("");
    const [promocodeModal, setPromocodeModal] = useState(false);

    useEffect(() => {
        clearCurrentContractMetaData()
    }, [])

    // const getCouponIdForBulkContract = (data)=>{
    //     console.log(data, 'data')
    //     setAppliedCouponId(data)
    // }

    const buyContractsButtonHandler = () => {
        //data, bulk_contract_id    


        if ((currentUser.email === null && currentUser.phoneNumber === null)) {
            history.push("/onboarding")
        } else {
            setSingleBulkContractData(isPromocodeModalOpen)
            localStorage.removeItem("lawyered.current.contract_id")
            localStorage.setItem("lawyered.current.bulk_contract_id", isPromocodeModalOpen?.id)
            setIsPaymentModalOpen(true)
        }

    }

    const promocodeModalHandler = (item) => {
        if ((currentUser.email === null && currentUser.phoneNumber === null)) {
            history.push("/onboarding")
        } else {
            setPromocodeModal(true)
            setIsPromocodeModalOpen(item)
            setCurrentSelectedBulkContract(item?.id)
        }
    }




    return (
        <WithSidebar currentUser={currentUser} token={token} path={path}>
            <TopContainer>
                <TopRow>
                    <Title>Kontraktspakker</Title>
                </TopRow>
                {isLoading ? <ArchiveLoading />
                    :
                    <div className='row g-4'>
                        {bulkContracts?.map((item, index) => {
                            let matchingOrder = false;
                            matchingOrder = orders.find(orderItem => orderItem?.data?.bulkContractId === item?.id);

                            return (
                                <div className='col-lg-4 mb-3' key={index}>
                                    <div className="card h-100 rounded-4" style={{ width: '18rem' }}>

                                        <div className="card-body">
                                            <div className='d-flex flex-column justify-content-between h-100'>
                                                <div className="mb-3">
                                                    <div className="text-center mb-4">
                                                        <img src={item?.data?.feature_image} className="img-fluid" style={{ width: '40%' }} />
                                                    </div>
                                                    <div className="card-title mb-5 text-center">
                                                        <SecondTitle>{item?.data?.title}</SecondTitle>
                                                    </div>

                                                    <div style={{ color: "#4C1D95" }} dangerouslySetInnerHTML={{ __html: item?.data?.description }} />

                                                </div>

                                                <div className=' pb-2'>


                                                    {
                                                        matchingOrder ? <>
                                                            <Button
                                                                text="Allerede kjøpt"
                                                                secondary
                                                                disabled
                                                            />
                                                        </>
                                                            :
                                                            <>
                                                                {/* {item?.data?.title !== "Eiendomspakke for samarbeidspartnere" &&
                                                                    <p className="text-center mb-1 fw-bold" role="button" style={{ color: "#4C1D95" }} onClick={() => promocodeModalHandler(item)}>Bruk rabattkode</p>
                                                                } */}
                                                                <Button
                                                                    text={
                                                                        isLoading ? (
                                                                            <Spinner size="18" inline />
                                                                        ) : (
                                                                            `Kjøp NOK ${item?.data?.contract_price}`
                                                                        )
                                                                    }

                                                                    // onClick={() => buyContractsButtonHandler(item, item?.id)}
                                                                    onClick={() => promocodeModalHandler(item)}
                                                                    primary
                                                                />
                                                            </>
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                }

                {/* bulk contract payment modal */}
                {isPaymentModalOpen &&
                    <BulkContractPaymentModal
                        isOpen={isPaymentModalOpen}
                        setIsOpen={setIsPaymentModalOpen}
                        paymentAmount={finalPriceAfterDiscount}
                        paymentTitle={`1 ${singleBulkContractData?.data?.title} ${finalPriceAfterDiscount} kr`}
                        // appliedCouponId={appliedCouponId}
                        
                    />
                }

                {/* bulk contract promocode modal */}
                <BulkContractPromocodeModal
                    isOpen={promocodeModal}
                    setIsOpen={setPromocodeModal}
                    bulkContractId={currentSelectedBulkContract}
                    packagePrice={isPromocodeModalOpen}
                    buyContractsButtonHandler={buyContractsButtonHandler}
                    setFinalPriceAfterDiscount={setFinalPriceAfterDiscount}
                    // setAppliedCouponId={getCouponIdForBulkContract}
                />

            </TopContainer>
        </WithSidebar>
    )
}


const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }
`

const TopRow = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 16px;
    align-self: stretch;
`

const Title = styled(H5)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 16px;
`

const SecondTitle = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    line-height: 32px;
    margin-bottom: 16px;
`

