import apiUrl from "../utils/apiUrl"
import { NetworkError, NotFoundError } from "../utils/errors"

const fetchWithToken = async (url, token, method = "GET") => {
    try {
        const response = await fetch(url, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            method
        })
        if (!response.ok) {
            throw new Error(response.statusText)
        }
        return await response.json()
    } catch (error) {
        const { response } = error
        console.log(`*** fetchWithToken response `, response)
        console.log(`*** fetchWithToken error `, error.message)
        if (error.message === "Failed to fetch") {
            throw new NetworkError("NetworkError", {
                code: "lawyered/network-error",
                message:
                    "Vi opplever problemer med tjenesten - men vi er på saken! Vent litt, og forsøk igjen."
            })
        }

        if (error.message === "Not Found") {
            throw new NotFoundError("NotFoundError", {
                code: "lawyered/not-found-error",
                message:
                    "Vi fant ikke innholdet du ba om - beklager. Ta kontakt med oss, så skal vi hjelpe deg så godt vi kan."
            })
        }

        return response ? response.status : 400 // TODO I don't think this is good enough
    }
}

const fetcher = async (...args) => {
    try {
        const response = await fetch(...args)
        return await response.json()
    } catch (error) {
        const { response } = error
        console.log(`*** response `, response)
        console.log(`*** error `, error.message)
        if (error.message === "Failed to fetch") {
            throw new NetworkError("NetworkError", {
                code: "lawyered/network-error",
                message:
                    "Vi opplever problemer med tjenesten - men vi er på saken! Vent litt, og forsøk igjen."
            })
        }
        return response ? response.status : 400 // TODO I don't think this is good enough
    }
}

export { apiUrl, fetcher, fetchWithToken }
