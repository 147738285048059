import styled from "styled-components"
import { Checkmark } from "../../assets/icons"

const Step = ({ no, description, active, completed }) => {
    return (
        <StepWrapper>
            <Circle active={active} completed={completed}>
                {completed ? <Checkmark /> : no}
            </Circle>
            <Description active={active}>{description}</Description>
        </StepWrapper>
    )
}

const StepWrapper = styled.li`
    display: flex;
    align-items: center;
    padding: 12px 24px;
`

const Circle = styled.span`
    width: 40px;
    height: 40px;
    border-radius: 50%;

    border: 2px solid
        ${(props) =>
        props.completed
            ? props.theme.inputForeground
            : props.active
                ? props.theme.inputForeground
                : props.theme.inputForegroundSecondary};

    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.inputForeground};
    background-color: ${(props) =>
        props.completed ? props.theme.inputForeground : "transparent"};
    font-weight: 500;
`

const Description = styled.p`
    color: ${(props) => props.theme.inputForeground};
    font-weight: 500;
    padding-left: 16px;
    display: none;
    @media screen and (min-width: 1024px) {
        display: flex;
    }
`

export default Step
