import { useContext } from "react"
import useSWR from "swr"
import { apiUrl, fetchWithToken } from "./fetcher"

import { FirebaseAuth } from "../providers/AuthProvider"

function useContractSigningStatus(contractId) {
    const { token } = useContext(FirebaseAuth)
    const { data, error } = useSWR(
        [`${apiUrl}/signing_status?contract_id=${contractId}`, token],
        fetchWithToken,
        {
            shouldRetryOnError: false
        }
    )

    return {
        status: data,
        isLoading: !error && !data,
        isError: error
    }
}

export { useContractSigningStatus }
