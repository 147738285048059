import React from "react"

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            fill="none"
            viewBox="0 0 12 8"
        >
            <path
                fill="#9CA3AF"
                d="M11 1.17a1 1 0 00-1.41 0L6 4.71 2.46 1.17a1 1 0 10-1.41 1.42l4.24 4.24a1 1 0 001.42 0L11 2.59a.998.998 0 000-1.42z"
            ></path>
        </svg>
    )
}

export default Icon
