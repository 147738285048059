import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import PhoneNumberInput from "../phoneNumberInput"
import { P_Small } from "../text"

import TextInput from "../textInput"

const InputGroup = ({
    onChange,
    onCountryCodeChange,
    countryCode,
    placeholder,
    value,
    title,
    type = null,
    icon = null,
    readonly = false,
    maxlength = null,
    required,
    shouldDisplayValidationErrors,
    name,
    id,
    error = ""
}) => (
    <Container>
        <Title>{title}</Title>
        {type === "phone" && (
            <PhoneNumberInput
                onChange={onChange}
                onCountryCodeChange={onCountryCodeChange}
                defaultCountry={countryCode}
                placeholder={placeholder}
                value={value}
                readonly={readonly}
                name={name}
                required={required}
                id={id}
                maxlength={maxlength}
                shouldDisplayValidationErrors={shouldDisplayValidationErrors}
            />
        )}
        {type !== "phone" && (
            <TextInput
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                type={type}
                icon={icon}
                readonly={readonly}
                name={name}
                required={required}
                id={id}
                maxlength={maxlength}
                shouldDisplayValidationErrors={shouldDisplayValidationErrors}
            />
        )}
        {error.length > 0 && <Error>{error}</Error>}
    </Container>
)

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    color: ${Colors.gray[500]};
`

const Title = styled(P_Small)`
    margin-bottom: 4px;
`

const Error = styled(P_Small)`
    color: ${Colors.red[500]};
    margin-top: 4px;
`

export default InputGroup
