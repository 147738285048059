import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import {
    addCardPaymentOrders,
    createPaymentIntent,
    createPaymentIntentBulkContracts,
    getVippsTransactionStatus,
    initiateVippsPayment,
    initiateVippsPaymentBulkContracts
} from "../utils/api"

import Colors from "../assets/colors"

import Button from "../components/button"
import CheckBoxLabel from "../components/checkBoxLabel"
import TextInput from "../components/textInput"
import Loader from "../components/loader"
import List from "../components/list"
import Notice from "../components/notice"
import RadioButtonLabelDesc from "../components/radioButtonLabelDescription"
import CaretLabelDescription from "../components/caretLabelDescription/CaretLabelDescription"
import PhoneNumberInput from "../components/phoneNumberInput"
import useInterval from "../hooks/useInterval"
import Spinner from "../components/Spinner"
import Icon from "../components/icon"
import { FirebaseAuth } from "../providers/AuthProvider"

export default function CheckoutForm({
    email,
    phone,
    onSucceeded = () => { },
    onCancel = () => { },
    couponId
}) {
    const { currentUser, token } = useContext(FirebaseAuth)
    const [succeeded, setSucceeded] = useState(false)
    const [error, setError] = useState(null)
    const [processing, setProcessing] = useState("")
    const [disabled, setDisabled] = useState(true)
    const [clientSecret, setClientSecret] = useState("")
    const stripe = useStripe()
    const elements = useElements()
    const [isPaymentIntentLoading, setIsPaymentIntentLoading] = useState(false)

    const [selectedPaymentCategory, setSelectedPaymentCategory] = useState(null)

    const [paymentMethods, setPaymentMethods] = useState([])
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)

    const [shouldSaveCardDetails, setShouldSaveCardDetails] = useState(false)
    const [nameOnCard, setNameOnCard] = useState("")

    const [inTestMode, setInTestMode] = useState(false)

    const [isVippsAvailable, setIsVippsAvailable] = useState(true)

    const [vippsError, setVippsError] = useState(null)
    const [vippsPaymentUrl, setVippsPaymentUrl] = useState(null)
    const [vippsOrderId, setVippsOrderId] = useState(null)
    const [shouldPollForTransactionStatus, setShouldPollForTransactionStatus] =
        useState(false)
    const [vippsTransactionCompleted, setVippsTransactionCompleted] =
        useState(false)

    let pollingCounter = 0
    const [pollingInterval, setPollingInterval] = useState(2000)

    useInterval(async () => {
        let transactionStatus
        if (shouldPollForTransactionStatus && vippsOrderId) {
            pollingCounter++
            if (pollingCounter % 5 === 0) {
                setPollingInterval(5000)
            } else {
                setPollingInterval(2000)
            }
            const token = localStorage.getItem("lawyered.user.token")
            transactionStatus = await getVippsTransactionStatus(
                token,
                vippsOrderId,
                pollingCounter
            )
            if (transactionStatus && transactionStatus.status === "SALE") {
                
                //add order records when payment via vipps
                let contractId = localStorage.getItem("lawyered.current.current_contract_id")
                let bulkContractId = localStorage.getItem("lawyered.current.bulk_contract_id")
                const paymentOrderReq = {
                    amount: 0, //while vipps payment not getting the payment amount
                    userId: currentUser.uid,
                    status: "succeeded",
                    couponId
                }
                if(contractId){
                    paymentOrderReq.contractId = contractId
                }else{
                    paymentOrderReq.bulkContractId = bulkContractId
                }

                addCardPaymentOrders(token, paymentOrderReq)


                setVippsTransactionCompleted(true)
                setShouldPollForTransactionStatus(false)
                setProcessing(false)
                setSucceeded(true)
                onSucceeded()

            } else {
                if (
                    transactionStatus &&
                    transactionStatus.status === "REJECTED"
                ) {
                    setVippsError("Betaling feilet. Prøv igjen.")
                } else if (
                    transactionStatus &&
                    transactionStatus.status === "CANCELLED"
                ) {
                    setVippsError("Betaling avbrutt. Prøv igjen.")
                }
                if (
                    transactionStatus &&
                    (transactionStatus.status === "REJECTED" ||
                        transactionStatus.status === "CANCELLED")
                ) {
                    setVippsPaymentUrl(null)
                    setVippsTransactionCompleted(false)
                    setShouldPollForTransactionStatus(false)
                    setProcessing(false)
                    setSucceeded(false)
                }
            }
        }
    }, pollingInterval)

    useEffect(() => {
        // Create PaymentIntent as soon as the selectedPaymentCategory is set
        const token = localStorage.getItem("lawyered.user.token")
        let contractId = localStorage.getItem("lawyered.current.contract_id")
        let bulkContractId = localStorage.getItem("lawyered.current.bulk_contract_id")

        if (selectedPaymentCategory === "card" && clientSecret === "" && contractId) {
            setIsPaymentIntentLoading(true)
            let stripeCustomerId = localStorage.getItem(
                "lawyered.user.stripe_customer_id"
            )

            createPaymentIntent(contractId, stripeCustomerId, couponId, token)
                .then((response) => {
                    console.log(`createPaymentIntent :: response`, response)
                    setClientSecret(response.clientSecret)
                    setPaymentMethods(response.paymentMethods)
                    localStorage.setItem(
                        "lawyered.user.stripe_customer_id",
                        response.customerId
                    )
                    setInTestMode(!response.liveMode)
                    setIsPaymentIntentLoading(false)
                })
                .catch((error) => {
                    console.error("createPaymentIntent :: error", error)
                })
        }



        //bulk contract purchasing
        if (selectedPaymentCategory === "card" && clientSecret === "" && bulkContractId) {
            setIsPaymentIntentLoading(true)
            let stripeCustomerId = localStorage.getItem(
                "lawyered.user.stripe_customer_id"
            )

            createPaymentIntentBulkContracts(bulkContractId, stripeCustomerId, couponId, token)
                .then((response) => {
                    console.log(`createPaymentIntent :: response`, response)
                    setClientSecret(response.clientSecret)
                    setPaymentMethods(response.paymentMethods)
                    localStorage.setItem(
                        "lawyered.user.stripe_customer_id",
                        response.customerId
                    )
                    setInTestMode(!response.liveMode)
                    setIsPaymentIntentLoading(false)
                })
                .catch((error) => {
                    console.error("createPaymentIntent :: error", error)
                })
        }


    }, [selectedPaymentCategory])

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    }
    console.log(couponId, 'outer')

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty)
        setError(event.error ? event.error.message : "")
    }

    const handleSubmit = async (ev) => {
        ev.preventDefault()
        setProcessing(true)
        setVippsError(null)

        if (selectedPaymentCategory === "card") {
            const billingDetails = {
                name: nameOnCard
            }
            if (email) {
                billingDetails.email = email
            }
            if (phone) {
                billingDetails.phone = phone
            }
            const payload = await stripe.confirmCardPayment(clientSecret, {
                payment_method: selectedPaymentMethod
                    ? selectedPaymentMethod
                    : {
                        card: elements.getElement(CardElement),
                        billing_details: billingDetails
                    },
                setup_future_usage: shouldSaveCardDetails ? "on_session" : null
            })

            if (payload.error) {
                setError(`Payment failed ${payload.error.message}`)
                setProcessing(false)
            } else {

                //add order records when payment via card
                let contractId = localStorage.getItem("lawyered.current.current_contract_id")
                let bulkContractId = localStorage.getItem("lawyered.current.bulk_contract_id")
                const paymentOrderReq = {
                    amount: payload.paymentIntent.amount,
                    userId: currentUser.uid,
                    status: payload.paymentIntent.status,
                    couponId
                }
                if(contractId){
                    paymentOrderReq.contractId = contractId
                }else{
                    paymentOrderReq.bulkContractId = bulkContractId
                }

                addCardPaymentOrders(token, paymentOrderReq)

                setError(null)
                setProcessing(false)
                setSucceeded(true)
                onSucceeded()


            }
        } else if (selectedPaymentCategory === "vipps") {
            const token = localStorage.getItem("lawyered.user.token")
            const contractId = localStorage.getItem(
                "lawyered.current.contract_id"
            )
            let bulkContractId = localStorage.getItem("lawyered.current.bulk_contract_id")
                console.log(couponId, 'couponIdcouponId')
            if(bulkContractId){
                const initiationResult = await initiateVippsPaymentBulkContracts(
                    token,
                    phoneNumber,
                    bulkContractId,
                    couponId
                )
                setProcessing(false)
                setVippsOrderId(initiationResult.order_id)
                setVippsPaymentUrl(initiationResult.payment_url)
            }
            console.log(couponId, 'couponId')
            
            if(contractId){

                const initiationResult = await initiateVippsPayment(
                    token,
                    phoneNumber,
                    contractId,
                    couponId
                )
                setProcessing(false)
                setVippsOrderId(initiationResult.order_id)
                setVippsPaymentUrl(initiationResult.payment_url)
            }
            setShouldPollForTransactionStatus(true)
        }
    }

    const handleSaveCardDetailsChange = (selected) => {
        setShouldSaveCardDetails(selected)
    }

    const handleNameOnCardChange = (event) => {
        setNameOnCard(event.target.value)
    }

    const [countryCode] = useState("NO")
    const [countryLandCode, setCountryLandCode] = useState("+47")
    const [phoneNumber, setPhoneNumber] = useState(phone || "")

    if (vippsPaymentUrl) {
        return (
            <Container>
                <PaymentInFrame
                    src={vippsPaymentUrl}
                    width="100%"
                    height="400"
                />
            </Container>
        )
    }

    return (
        <Container>
            <form id="payment-form" onSubmit={handleSubmit}>
                {vippsError && <Notice error text={vippsError} />}
                <List>
                    {isVippsAvailable && (
                        <CaretLabelDescription
                            key={`payment-method-vipps`}
                            title="Vipps"
                            onChange={() => {
                                setDisabled(false)
                                setSelectedPaymentCategory("vipps")
                            }}
                            selected={selectedPaymentCategory === "vipps"}
                            icon={"VippsMark"}
                            iconProps={{ width: 30 }}
                        />
                    )}
                    {selectedPaymentCategory === "vipps" && (
                        <VippsPhoneNumberWrapper>
                            <PhoneNumberInput
                                onChange={(event) =>
                                    setPhoneNumber(event.target.value)
                                }
                                onCountryCodeChange={() => { }}
                                defaultCountry={countryCode}
                                isCountryChangeable={false}
                                placeholder={"Telefonnummer"}
                                value={phoneNumber}
                                name={"input-phone-vipps"}
                                required={true}
                                id={"input-phone-vipps"}
                                maxlength={8}
                            />
                        </VippsPhoneNumberWrapper>
                    )}
                    <CaretLabelDescription
                        key={`payment-method-stripe`}
                        title="Kort"
                        onChange={() => {
                            setDisabled(false)
                            setSelectedPaymentCategory("card")
                        }}
                        selected={selectedPaymentCategory === "card"}
                        icon={"Card"}
                        iconProps={{ width: 24 }}
                    />
                    {!!paymentMethods?.length &&
                        selectedPaymentCategory === "card" && (
                            <>
                                {paymentMethods.map((pm) => {
                                    if (pm.type === "card") {
                                        return (
                                            <RadioButtonLabelDesc
                                                key={`payment-method-${pm.id}`}
                                                title={`${pm.card.brand.toUpperCase()}`}
                                                description={`**** ${pm.card.last4} (${pm.card.exp_month}/${pm.card.exp_year})`}
                                                onChange={() => {
                                                    setDisabled(false)
                                                    setSelectedPaymentMethod(
                                                        pm.id
                                                    )
                                                }}
                                                selected={
                                                    selectedPaymentMethod ===
                                                    pm.id
                                                }
                                            />
                                        )
                                    }
                                    return null
                                })}
                                <RadioButtonLabelDesc
                                    title={`Nytt betalingskort`}
                                    onChange={() => {
                                        setDisabled(true)
                                        setSelectedPaymentMethod(null)
                                    }}
                                    selected={selectedPaymentMethod === null}
                                />
                            </>
                        )}
                </List>

                {selectedPaymentCategory === "card" && (
                    <CardInfo visible={!selectedPaymentMethod}>
                        {inTestMode && (
                            <Notice warning text="Stripe test mode" />
                        )}
                        {isPaymentIntentLoading && (
                            <SpinnerWrapper>
                                <Spinner size="32" />
                            </SpinnerWrapper>
                        )}
                        <NameOnCard>
                            <TextInput
                                placeholder="Navn på kortet"
                                type="text"
                                value={nameOnCard}
                                onChange={handleNameOnCardChange}
                            />
                        </NameOnCard>
                        <CardElement
                            id="card-element"
                            options={cardStyle}
                            onChange={handleChange}
                            className="stripe-element"
                        />
                        {/* Show any error that happens when processing the payment */}
                        {error && (
                            <div className="card-error" role="alert">
                                {error}
                            </div>
                        )}
                        <SaveCardDetails>
                            <CheckBoxLabel
                                title="Lagre kortet til senere"
                                onChange={handleSaveCardDetailsChange}
                            />
                            <Icon icon="StripeBadge" width="100" />
                        </SaveCardDetails>
                        <BadgeWrapper></BadgeWrapper>
                    </CardInfo>
                )}

                <Button
                    disabled={processing || disabled || succeeded}
                    id="submit"
                    type="submit"
                    text={processing ? <Spinner size="24" /> : "Betal"}
                />
                <Button
                    onClick={onCancel}
                    secondary
                    small
                    text="Avbryt"
                    disabled={processing || !disabled || succeeded}
                />
            </form>
        </Container>
    )
}

const CardInfo = styled.div`
    display: ${(props) => (props.visible ? "block" : "none")};
    margin-top: 16px;
`

const NameOnCard = styled.div`
    margin-bottom: 16px;
`

const SaveCardDetails = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 0 16px;
`

const VippsPhoneNumberWrapper = styled.div`
    border-bottom: 1px solid ${Colors.gray[100]};
    padding: 8px 0;
    &:hover {
        background-color: ${Colors.white};
    }

    > div {
        border-radius: 0;
        border: none;
    }
`

const PaymentInFrame = styled.iframe`
    width: calc(100% + 32px);
    height: 400px;
    max-height: calc(100vh - 80px - 48px - 32px);
    margin-left: -16px;
    margin-right: -16px;
    border: 0;
`

const SpinnerWrapper = styled.div`
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
`

const BadgeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
`

const Container = styled.div`
    #root {
        display: flex;
        align-items: center;
    }

    form {
        align-self: center;
        padding: 0;

        width: 100%;
    }

    input.stripe-element {
        border-radius: 8px;
        margin-bottom: 6px;
        padding: 12px;
        border: 1px solid rgba(50, 50, 93, 0.1);
        max-height: 44px;
        font-size: 16px;
        width: 100%;
        background: ${Colors.white};
        box-sizing: border-box;
    }

    .result-message {
        line-height: 22px;
        font-size: 16px;
    }

    .result-message a {
        color: rgb(89, 111, 214);
        font-weight: 600;
        text-decoration: none;
    }

    .hidden {
        display: none;
    }

    .card-error {
        color: ${Colors.red[500]};
        font-size: 16px;
        line-height: 20px;
        margin-top: 12px;
        text-align: center;
    }

    #card-element {
        border-radius: 8px;
        padding: 12px;
        border: 1px solid rgba(50, 50, 93, 0.1);
        max-height: 44px;
        width: 100%;
        background: ${Colors.white};
        box-sizing: border-box;
    }

    #payment-request-button {
        margin-bottom: 32px;
    }

    button {
        margin-top: 16px;
    }

    @media only screen and (max-width: 600px) {
        form {
            width: 80vw;
        }
    }
`
