import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { H5, P } from "../../components/text"

import Button from "../../components/button"
import Spinner from "../../components/Spinner"

const Loading = ({ mail }) => {
    const onClick = () => {
        //TODO: Send mail
    }

    return (
        <Container>
            <Spinner />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export default Loading
