import React from "react"

const Mobile = ({ fill }) => {
    return (
        <svg
            width="28"
            height="40"
            viewBox="0 0 28 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.42 28.58L15.12 28.34C15.0085 28.2643 14.8874 28.2038 14.76 28.16L14.4 28C14.0756 27.9322 13.7395 27.946 13.4218 28.0403C13.1042 28.1346 12.8149 28.3063 12.58 28.54C12.4033 28.7344 12.2613 28.9576 12.16 29.2C12.0086 29.5644 11.9685 29.9654 12.0445 30.3526C12.1206 30.7398 12.3094 31.0959 12.5874 31.3759C12.8653 31.656 13.2199 31.8476 13.6065 31.9267C13.9931 32.0057 14.3944 31.9686 14.76 31.82C14.999 31.704 15.221 31.556 15.42 31.38C15.6975 31.0988 15.8855 30.7416 15.9603 30.3536C16.035 29.9656 15.9932 29.5642 15.84 29.2C15.7403 28.9687 15.5978 28.7584 15.42 28.58ZM22 0H6C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6V34C0 35.5913 0.632141 37.1174 1.75736 38.2426C2.88258 39.3679 4.4087 40 6 40H22C23.5913 40 25.1174 39.3679 26.2426 38.2426C27.3679 37.1174 28 35.5913 28 34V6C28 4.4087 27.3679 2.88258 26.2426 1.75736C25.1174 0.632141 23.5913 0 22 0ZM24 34C24 34.5304 23.7893 35.0391 23.4142 35.4142C23.0391 35.7893 22.5304 36 22 36H6C5.46957 36 4.96086 35.7893 4.58579 35.4142C4.21071 35.0391 4 34.5304 4 34V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H22C22.5304 4 23.0391 4.21071 23.4142 4.58579C23.7893 4.96086 24 5.46957 24 6V34Z"
                fill="#4C1D95"
            />
        </svg>
    )
}

export default Mobile
