import styled from "styled-components"

const CheckboxesContract = ({
    options, // [<CheckboxContract>]
    label
}) => {
    return (
        <Wrapper hasLabel={!!label}>
            {label && <label>{label}</label>}
            <Container hasLabel={!!label}>{options}</Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        align-items: inherit;
    }
    flex: ${(props) => (props.hasLabel ? "1 0 100%" : "1 1 auto")};
    margin: 16px 0;
    > label {
        display: flex;
        flex-basis: 40%;
        padding-right: 16px;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: right;
        padding-top: 4px;
    }
`

const Container = styled.div`
    display: flex;
    flex-basis: ${(props) => (props.hasLabel ? "60%" : "auto")};
    flex-wrap: wrap;
    > div {
        margin-bottom: ${(props) => (props.hasLabel ? "16px" : "0")};
    }
`

export default CheckboxesContract
