import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import Status from "../status"
import { P_Large_M, P_Small, P_Mini } from "../text"

const Contract = ({ date, description, status, title, highlight = false }) => {
    return (
        <Container highlight={highlight}>
            <LeftContainer>
                <div>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </div>
                <div>
                    {status &&
                        <Status status={status} />
                    }
                    <Date>{date}</Date>
                </div>
            </LeftContainer>
            {/*
            <RightContainer>
                <Icon icon={"Menu"} fill={Colors.gray[500]} />
            </RightContainer>
            */}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    padding: 16px;
    border-radius: 8px;
    background-color: ${(props) =>
        props.highlight
            ? props.theme.backgroundSecondary
            : props.theme.backgroundTertiary};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: ease-in-out 0.4s;
    width: 232px;
    min-height: 164px;
    justify-content: ${(props) => props.status ? 'space-between' : 'center'};

    &:hover {
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    }
`

const LeftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: ${(props) => props.status ? 'space-between' : 'center'};
`

const RightContainer = styled.div`
    padding: 0 2px 0 16px;
`

const Title = styled(P_Large_M)`
    color: ${(props) => props.theme.foregroundPrimary};
    margin: 0 0 4px 0;
`

const Description = styled(P_Small)`
    color: ${(props) => props.theme.foregroundPrimary};
    margin: 0 0 8px 0;
`

const Date = styled(P_Mini)`
    color: ${Colors.gray[500]};
    margin: 8px 0 0 0;
`

export default Contract
