import { useState, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import Spinner from "../Spinner"
import { P_Medium, P_Mini } from "../text"

const FileUploaderButton = ({
    refreshAcceptedFilesList = (files) => {},
    icon = "Document",
    label = "Velg en fil",
    multiple = false,
    maxSize = 10485760,
    accept = "image/jpeg, image/png"
}) => {
    const files = []
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            files.push(file)
        })
        refreshAcceptedFilesList(files)
    }, [])

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        onDrop,
        accept,
        maxFiles: multiple ? 10 : 1,
        maxSize,
        multiple
    })

    const [uploading, setUploading] = useState(false)

    if (uploading) {
        return (
            <Container>
                <SpinnerContainer>
                    <Spinner size="24" />
                </SpinnerContainer>
                <Title>{"Laster opp..."}</Title>
            </Container>
        )
    } else {
        return (
            <Container
                {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
            >
                <Input type="file" name="file" id="file" {...getInputProps()} />
                <Label htmlFor="file">
                    <Icon icon={icon} fill={Colors.gray[900]} />
                    {label}
                </Label>
            </Container>
        )
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${(props) => `1px solid ${Colors.gray[300]}`};
    box-sizing: border-box;
    border-radius: 8px;
`

const SpinnerContainer = styled.div`
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;

    @-moz-keyframes spin {
        100% {
            -moz-transform: rotate(360deg);
        }
    }
    @-webkit-keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
        }
    }
    @keyframes spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
`

const Title = styled(P_Medium)`
    color: ${Colors.gray[900]};
    margin: 12px 0 8px 0;
`

const Input = styled.input`
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
`

const Label = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    cursor: pointer;
    background-color: ${Colors.white};
    border-radius: 8px;
    border: none;
    color: ${Colors.gray[900]};
    font-weight: 500;
    font-size: 14px;
    width: calc(100% - 24px);
    > svg {
        margin: 0 12px 0 0;
    }
`

export default FileUploaderButton
