import { useState, useEffect, useRef } from "react"
import styled from "styled-components"

import * as Sentry from "@sentry/react"

import parse from "html-react-parser"

import TextInputContract from "../textInputContract"
import SelectContract from "../selectContract"
import { CheckboxesContract } from "../checkboxContract"
import CheckboxContract from "../checkboxContract"
import ContractPreview from "../contractPreview"
import TextareaContract from "../textareaContract"
import RadioButtonLabelGroupContract from "../radioButtonLabelGroupContract"

import Toggle from "../toggle"
import { P_Small_M, P_Large_M, H6 } from "../text"
import UploadedFile from "../uploadedFile"
import Icon from "../icon"
import { calculate } from "../../utils/calculate"

import { STEP } from "../../utils/constants"

import HTMLString from "../../utils/HTMLString"
import Colors from "../../assets/colors"
import Button from "../button"
import DateInputContract from "../dateInputContract"
import FileUploader from "../fileUploader"
import Notice from "../notice"
import { isObjectEmpty } from "../../utils/objectHelpers"
import HrWithText from "../hrWithText/HrWithText"
import Spinner from "../Spinner"
import FullscreenError from "../../containers/errors/FullscreenError"
import HelpTextComponent from "../helpTextComponent/HelpTextComponent"

const ContractForm = ({
    template,
    prefilledData,
    attachments,
    requestPreview,
    cancelPreview,
    contractPreviewContent,
    updateFormData,
    currentStep,
    isLoading,
    isFormValid,
    isUsingDraftData,
    didClearDraftData,
    checkFormValidity,
    shouldDisplayValidationErrors,
    generatedQueryString
}) => {
    const [formReady, setFormReady] = useState(false)
    const [formFields, setFormFields] = useState({})
    const [placeholderDict, setPlaceholderDict] = useState({})

    const [error, setError] = useState(false)

    let fieldCounter = 0
    const increaseFieldCounter = () => {
        fieldCounter = fieldCounter + 1
    }
    const fieldCount = () => {
        fieldCounter = fieldCounter + 1
        return fieldCounter
    }

    const getFieldsForState = async (template) => {
        const state = {}
        const placeholders = {}
        template.template.forEach((field) => {
            if (["input", "date", "select"].includes(field.category)) {
                if (field.repeatable) {
                    state[field.name] = formFields[field.name] || [""]
                } else {
                    state[field.name] = formFields[field.name] || ""
                }
                placeholders[field.name] = field.placeholder || null
            }
        })
        return Promise.resolve([state, placeholders])
    }

    const stepIs = (stepOrSteps) => {
        if (Array.isArray(stepOrSteps)) {
            return stepOrSteps.includes(currentStep)
        }
        return currentStep === stepOrSteps
    }

    const contractForm = useRef(null)

    useEffect(() => {
        async function init() {
            /* NOTE This might conflict with prefilling; sometimes fields are cleared */
            const [state, placeholders] = await getFieldsForState(template)
            setFormFields(state)
            setPlaceholderDict(placeholders)
            setFormReady(true)
        }
        init()
    }, [])

    let didValidateAfterPrefilling = false

    useEffect(() => {
        if (prefilledData.data && !isObjectEmpty(prefilledData.data)) {
            setFormFields(prefilledData.data)
        }
    }, [prefilledData])

    useEffect(() => {
        if (formReady && !didValidateAfterPrefilling) {
            updateFormData(formFields, contractForm.current)
            didValidateAfterPrefilling = true
        }
    }, [formReady])

    useEffect(() => {
        if (formReady) {
            updateFormData(formFields, contractForm.current)
        }
    }, [formFields])

    const handleChange = (e, fieldName) => {
        setFormFields({ ...formFields, [fieldName]: e.target.value })
    }

    const handleRepeatableChange = (e, fieldName, index) => {
        const values = formFields[fieldName]
        values[index] = e.target.value
        setFormFields({ ...formFields, [fieldName]: values })
    }

    const removeRepeatable = (e, fieldName, index) => {
        const values = formFields[fieldName]
        values.splice(index, 1)
        setFormFields({ ...formFields, [fieldName]: values })
    }

    const handleCheckboxChange = (fieldName, checked) => {
        if (checked) {
            setFormFields({ ...formFields, [fieldName]: fieldName })
        } else {
            setFormFields({ ...formFields, [fieldName]: null })
        }
    }

    const handleCheckboxMultipleChange = (fieldName, checked, fieldValue) => {
        let currentValues = formFields[fieldName]
        if (checked) {
            if (!currentValues) {
                setFormFields({ ...formFields, [fieldName]: [fieldValue] })
            } else {
                if (!currentValues.includes(fieldValue)) {
                    currentValues.push(fieldValue)
                    setFormFields({ ...formFields, [fieldName]: currentValues })
                }
            }
        } else {
            if (currentValues) {
                currentValues = currentValues.filter(
                    (item) => item !== fieldValue
                )
                setFormFields({ ...formFields, [fieldName]: currentValues })
            }
        }
    }

    const handleDateChange = (date, fieldName) => {
        setFormFields({ ...formFields, [fieldName]: date })
    }

    const [repeatables, setRepeatables] = useState({})

    const handleAddRepeatable = (event, field) => {
        event.preventDefault()
        const currentIndex = repeatables[field.name].length
        const newRepeatables = {
            [field.name]: [
                ...repeatables[field.name],
                <TextInputContract
                    onChange={(e) => {
                        handleRepeatableChange(e, field.name, currentIndex)
                    }}
                    id={`${field.name}-repeatable-${fieldCount()}`}
                    name={field.name}
                    size={field.size}
                    helpText={field.helptext}
                    label={field.label}
                    shouldDisplayValidationErrors={
                        shouldDisplayValidationErrors
                    }
                />
            ]
        }
        setFormFields({
            ...formFields,
            [field.name]: [...formFields[field.name], ""]
        })
        setRepeatables({ ...newRepeatables })
    }

    const generateElement = (field) => {
        if (field.category === "linebreaks") {
            return parse(HTMLString.linebreaks(fieldCount()))
        }

        if (field.category === "linebreak") {
            return parse(HTMLString.linebreak(fieldCount()))
        }

        if(field.category==="helptext") {
            return <HelpTextComponent helpText={field.text}  />
        }

        if (field.category === "separator") {
            return <HR />
        }

        if (field.category === "subheader") {
            return parse(HTMLString.subheader(field.text, fieldCount()))
        }

        if (field.category === "label") {
            const html = HTMLString.label(field, fieldCount())
            return parse(html)
        }

        if (field.category === "html") {
            return parse(field.html)
        }

        if (field.category === "checkbox") {
            const html = HTMLString.checkbox(field, fieldCount())
            return parse(html, {
                replace: (domNode) => {
                    return (
                        <CheckboxContract
                            {...domNode.attribs}
                            onChange={(name, value) =>
                                handleCheckboxChange(name, value)
                            }
                            value={formFields[field.name] || ""}
                            required={field.required ? true : false}
                            helpText={field.helptext}
                            name={field.name}
                            label={field.text}
                            checked={formFields[field.name] === field.name}
                        />
                    )
                }
            })
        }

        if (field.category === "text") {
            if (field.type === "separator-with-text") {
                const html = HTMLString.separator_with_text(field, fieldCount())
                const el = parse(html, {
                    replace: (domNode) => {
                        return <HrWithText text={field.text} />
                    }
                })
                return el
            } else {
                const splitText = field.text.split(" ")
                let htmlWorker = ""
                if (field.type && field.type === "listitem") {
                    htmlWorker += HTMLString.listitem_indent(fieldCount())
                }
                splitText.forEach((word) => {
                    if (!!word.trim()) {
                        const html = HTMLString.text(
                            { text: word + " " },
                            fieldCount()
                        )
                        htmlWorker += html
                    }
                })
                return parse(htmlWorker)
            }
        }

        if (field.category === "input" && field.type === "date") {
            const html = HTMLString.input(field, fieldCount())
            const input = parse(html, {
                replace: (domNode) => {
                    return (
                        <DateInputContract
                            {...domNode.attribs}
                            onChange={(date) => {
                                handleDateChange(date, field.name)
                            }}
                            allowPastDate={field.allowPastDate}
                            allowFutureDate={field.allowFutureDate}
                            size={field.size}
                            required={field.required}
                            name={field.name}
                            helpText={field.helptext}
                            label={field.label}
                            shouldDisplayValidationErrors={
                                shouldDisplayValidationErrors
                            }
                            value={formFields[field.name] || ""}
                            suffix={field.suffix}
                            prefix={field.prefix}
                        />
                    )
                }
            })
            return input
        }

        /* NON-REPEATABLE TEXT/NUMBER INPUT */
        if (
            field.category === "input" &&
            (field.type === "text" || field.type === "number") &&
            !field.repeatable
        ) {
            const html = HTMLString.input(field, fieldCount())
            const input = parse(html, {
                replace: (domNode) => {
                    return (
                        <TextInputContract
                            {...domNode.attribs}
                            onChange={(e) => {
                                handleChange(e, field.name)
                            }}
                            size={field.size}
                            required={field.required}
                            name={field.name}
                            helpText={field.helptext}
                            label={field.label}
                            shouldDisplayValidationErrors={
                                shouldDisplayValidationErrors
                            }
                            value={formFields[field.name] || ""}
                            suffix={field.suffix}
                            prefix={field.prefix}
                        />
                    )
                }
            })
            return input
        }

        /* REPEATABLE TEXT/NUMBER INPUT */
        if (
            field.category === "input" &&
            (field.type === "text" || field.type === "number") &&
            field.repeatable
        ) {
            const inputs = []
            if (formFields[field.name] && formFields[field.name].length) {
                const reps = formFields[field.name]
                reps.forEach((repeatable, index) => {
                    const html = HTMLString.input(field, fieldCount())
                    const input = parse(html, {
                        replace: (domNode) => {
                            return (
                                <TextInputContract
                                    {...domNode.attribs}
                                    onChange={(e) => {
                                        handleRepeatableChange(
                                            e,
                                            field.name,
                                            index
                                        )
                                    }}
                                    size={field.size}
                                    required={field.required}
                                    name={field.name}
                                    helpText={field.helptext}
                                    label={field.label}
                                    shouldDisplayValidationErrors={
                                        shouldDisplayValidationErrors
                                    }
                                    value={formFields[field.name][index] || ""}
                                    suffix={field.suffix}
                                    prefix={field.prefix}
                                    dismissable={!!index}
                                    onDismiss={(e) => {
                                        console.log("removeRepeatable")
                                        removeRepeatable(e, field.name, index)
                                    }}
                                />
                            )
                        }
                    })

                    inputs.push(input)
                })
            }

            repeatables[field.name] = inputs

            return (
                <>
                    {repeatables[field.name]}
                    <AlignedButtonRow>
                        <Button
                            secondary
                            small
                            block={false}
                            icon="Plus"
                            text="Legg til"
                            onClick={(event) => {
                                handleAddRepeatable(event, field)
                            }}
                        />
                    </AlignedButtonRow>
                </>
            )

            return input
        }

        if (field.category === "input" && field.type === "textarea") {
            const html = HTMLString.textarea(field, fieldCount())
            return parse(html, {
                replace: (domNode) => {
                    return (
                        <TextareaContract
                            {...domNode.attribs}
                            onChange={(e) => handleChange(e, field.name)}
                            value={formFields[field.name] || ""}
                            required={field.required}
                            name={field.name}
                            helpText={field.helptext}
                            label={field.label}
                            shouldDisplayValidationErrors={
                                shouldDisplayValidationErrors
                            }
                            placeholder={field.placeholder}
                            suffix={field.suffix}
                            prefix={field.prefix}
                            size={field.size}
                        />
                    )
                }
            })
        }

        if (field.category === "calculation") {
            const html = HTMLString.input(field, fieldCount())
            const result = calculate(field, formFields)
            const output = isNaN(result)
                ? "0"
                : "kr. " + new Intl.NumberFormat("sv").format(result)

            return parse(html, {
                replace: (domNode) => {
                    console.log(`output ${field.name}`, output)
                    console.log(`result ${field.name}`, result)
                    return (
                        <TextInputContract
                            {...domNode.attribs}
                            readonly
                            asInput={false}
                            value={output}
                            required={field.required}
                            name={field.name}
                            helpText={field.helptext}
                            label={field.label}
                            shouldDisplayValidationErrors={
                                shouldDisplayValidationErrors
                            }
                            value={output}
                        />
                    )
                }
            })
        }

        if (field.category === "select") {
            if (field.type === "radiobuttons") {
                return (
                    <RadioButtonLabelGroupContract
                        options={field.options}
                        name={field.name}
                        onChange={(e) => handleChange(e, field.name)}
                        key={`radiogroup-${field.name}`}
                        selectedValue={formFields[field.name]}
                    />
                )
            } else {
                const html = HTMLString.select(field, fieldCount())
                return parse(html, {
                    replace: (domNode) => {
                        return (
                            <SelectContract
                                {...domNode.attribs}
                                list={field.options}
                                name={field.name}
                                onChange={(e) => handleChange(e, field.name)}
                                text={field.placeholder || field.text || ""}
                                label={field.label}
                                shouldDisplayValidationErrors={
                                    shouldDisplayValidationErrors
                                }
                                selectedValue={formFields[field.name] || ""}
                            />
                        )
                    }
                })
            }
        }

        if (field.category === "select_multiple") {
            let options
            if (field.reference) {
                const referenceValues = formFields[field.reference]
                if (
                    !referenceValues ||
                    !referenceValues.length ||
                    !referenceValues[0]
                ) {
                    return null
                }
                options = referenceValues.map((option) => {
                    if (!option) {
                        return
                    }
                    const html = HTMLString.checkboxMultiple(
                        option,
                        field.name,
                        fieldCount()
                    )
                    let checked = false
                    if (formFields[field.name]) {
                        checked = formFields[field.name].includes(option)
                    }
                    return parse(html, {
                        replace: (domNode) => {
                            return (
                                <CheckboxContract
                                    {...domNode.attribs}
                                    onChange={(name, checked) =>
                                        handleCheckboxMultipleChange(
                                            name,
                                            checked,
                                            option
                                        )
                                    }
                                    required={false}
                                    key={`input--${fieldCount()}`}
                                    value={option}
                                    helpText={field.helptext}
                                    label={option}
                                    shouldDisplayValidationErrors={
                                        shouldDisplayValidationErrors
                                    }
                                    checked={checked}
                                />
                            )
                        }
                    })
                })
            } else {
                options = field.options.map((option) => {
                    const html = HTMLString.checkboxMultiple(
                        option,
                        field.name,
                        fieldCount()
                    )
                    let checked = false
                    if (formFields[field.name]) {
                        checked = formFields[field.name].includes(option)
                    }
                    return parse(html, {
                        replace: (domNode) => {
                            return (
                                <CheckboxContract
                                    {...domNode.attribs}
                                    onChange={(name, checked) =>
                                        handleCheckboxMultipleChange(
                                            name,
                                            checked,
                                            option
                                        )
                                    }
                                    key={`input--${fieldCount()}`}
                                    value={option}
                                    required={false}
                                    helpText={field.helptext}
                                    label={option}
                                    shouldDisplayValidationErrors={
                                        shouldDisplayValidationErrors
                                    }
                                    checked={checked}
                                />
                            )
                        }
                    })
                })
            }

            return <CheckboxesContract options={options} label={field.label} />
        }

        if (field.category === "ref") {
            const html = HTMLString.ref(field, fieldCount())
            return parse(html, {
                replace: (domNode) => {
                    return (
                        <RefSpan {...domNode.attribs}>
                            {formFields[field.ref] ||
                                field.override_ref_placeholder ||
                                placeholderDict[field.ref]}
                        </RefSpan>
                    )
                }
            })
        }
    }

    /* 
        Evaluates a field and generates (a) proper element(s) from it. 
        Always returns an array of elements.
    */
    const evaluateTemplateField = (field) => {
        if (
            [
                "input",
                "text",
                "label",
                "html",
                "select",
                "select_multiple",
                "checkbox",
                "ref",
                "linebreaks",
                "linebreak",
                "separator",
                "subheader",
                "calculation",
                "helptext"
            ].includes(field.category) &&
            !field.hide_in_builder
        ) {
            const elements = []

            const el = generateElement(field, fieldCount())
            elements.push(el)

            return elements
        }

        if (field.category === "conditional") {
            const els = []
            let didTrigger = field.matches.includes(formFields[field.trigger])
            if (formFields[field.trigger] && field.trigger.includes("[]")) {
                let intersection = field.matches.filter((match) =>
                    formFields[field.trigger].includes(match)
                )
                if (!!intersection.length) {
                    didTrigger = true
                }
            }

            if (didTrigger) {
                const conditionalField = field.output_on_match

                if (conditionalField.category === "wrapper") {
                    const wrappedEls = evaluateTemplateFields(
                        conditionalField.fields
                    )
                    els.push(...wrappedEls)
                } else {
                    const el = generateElement(conditionalField, fieldCounter)
                    els.push(el)
                }
            }
            return els
        }
    }

    const evaluateTemplateFields = (fields, elements) => {
        elements = elements || []
        if (fields && fields.length) {
            fields.forEach((field) => {
                if (field.category === "wrapper") {
                    const wrappableElements = []
                    field.fields.forEach((field) => {
                        const f = evaluateTemplateField(field)
                        if (f) {
                            wrappableElements.push(...f)
                        }
                    })
                    elements.push(
                        <FieldWrapper>{[...wrappableElements]}</FieldWrapper>
                    )
                } else {
                    const f = evaluateTemplateField(field)
                    if (f) {
                        elements.push(...f)
                    }
                }
                increaseFieldCounter()
            })
        } else {
            Sentry.captureException(
                "Det oppstod en feil ved innlasting av kontraktsfelter."
            )
            setError(
                "Det oppstod en feil ved innlasting av kontraktsfelter. Prøv igjen."
            )
        }
        return elements
    }

    const parseAndGenerateObjectModel = (template) => {
        const elements = evaluateTemplateFields(template.template)
        return elements
    }

    const handlePreviewToggle = (selected) => {
        if (selected) {
            requestPreview()
        } else {
            cancelPreview()
        }
    }

    const handleClearDraft = () => {
        setFormFields({})
        didClearDraftData()
    }

    if (!formReady) {
        return <p>Laster...</p>
    }

    if (error) {
        return (
            <FullscreenError
                error={error}
                resetError={() => {
                    window.location.reload()
                }}
            />
        )
    }

    return (
        <>
            {isUsingDraftData && !contractPreviewContent && (
                <Notice
                    info
                    text="Vi hentet frem en kladd av kontrakten. Du kan også fjerne alt og begynne på nytt."
                    buttonText={"Begynn på nytt"}
                    buttonAction={handleClearDraft}
                />
            )}
            <StyledContractForm ref={contractForm} id="contractForm">
                <Wrapper>
                    {stepIs(STEP.SUMMARY) && (
                        <HeadingWithIcon>
                            <P_Large_M>
                                <Icon icon="Receipt" />
                                Oppsummering{" "}
                                {isLoading && <Spinner size="24" />}
                            </P_Large_M>
                        </HeadingWithIcon>
                    )}
                    <FormWrapper visible={!!contractPreviewContent}>
                        {!!contractPreviewContent && (
                            <ContractPreview
                                title={template.title}
                                content={contractPreviewContent}
                                isOverlay={false}
                            />
                        )}
                    </FormWrapper>
                    <FormWrapper
                        visible={stepIs(STEP.FORM) && !contractPreviewContent}
                    >
                        {parseAndGenerateObjectModel(template)}
                    </FormWrapper>
                </Wrapper>
                {stepIs(STEP.FORM) && (
                    <ToolsWrapper>
                        <P_Small_M>Forhåndsvisning</P_Small_M>
                        <Toggle
                            disabled={!!!isFormValid}
                            selected={false}
                            onChange={handlePreviewToggle}
                        />
                        <input
                            type="hidden"
                            name="generated_query_string"
                            disabled="disabled"
                            value={generatedQueryString}
                        />
                    </ToolsWrapper>
                )}
                {stepIs(STEP.SUMMARY) && (
                    <ToolsWrapper>
                        {attachments.map((attachment, index) => {
                            return (
                                <UploadedFile
                                    key={`uploaded-file-${index}`}
                                    title={attachment.name}
                                    size={attachment.size}
                                    dismissable={false}
                                />
                            )
                        })}
                    </ToolsWrapper>
                )}
            </StyledContractForm>
        </>
    )
}

const StyledContractForm = styled.form`
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: 768px) {
        padding-top: 24px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    position: relative;
`

const FormWrapper = styled.div`
    display: ${(props) => (props.visible ? "flex" : "none")};
    flex-wrap: wrap;
    h6 {
        width: 100%;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin: 16px 0 20px;
    }
    .spacer-vert {
        width: 100%;
        height: 24px;
    }
    .spacer-vert-br {
        width: 100%;
        height: 2px;
    }
    .spacer-horiz-listitem {
        width: 33px;
    }
    ul {
        width: 100%;
        margin-top: 0;
        li {
            font-size: 16px;
            line-height: 28px;
        }
    }
    > span {
        font-size: 16px;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 28px;
        flex: 0 0 auto;
        max-width: 100%;
        margin-bottom: 16px;
        white-space: break-spaces;
    }
`

const HR = styled.div`
    background-color: ${Colors.gray[300]};
    height: 1px;
    width: 100%;
    margin: 8px 0;
`

const ToolsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 2px solid ${Colors.gray[100]};
    padding: 24px 0 0;
    margin: 24px 0 0;
`

const RefSpan = styled.span`
    color: ${(props) => props.theme.inputForeground};
    margin-left: 0;
    margin-right: 4px;
`

const HeadingWithIcon = styled.div`
    p {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }
    p svg {
        padding-right: 8px;
    }
`

const FieldWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    span {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex-basis: 25%;
        padding-right: 16px;
        flex-grow: 0;
    }
`

const AlignedButtonRow = styled.div`
    width: 100%;
    margin-bottom: 32px;
    @media screen and (min-width: 768px) {
        margin-left: calc(40% + 16px);
    }
`

export default ContractForm
