import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { P_Mini_M } from "../text"

const STATUS = {
    SIGNED: "signed",
    PARTIALLY_SIGNED: "partialsigned",
    UNSIGNED: "unsigned"
}

const Status = ({ status }) => {
    const getTitle = () => {
        if (status === STATUS.SIGNED) {
            return "Signert"
        }
        if (status === STATUS.PARTIALLY_SIGNED) {
            return "Delvis signert"
        }
        if (status === STATUS.UNSIGNED) {
            return "Ikke signert"
        }
        return "Ukjent status"
    }

    return (
        <Container status={status}>
            <Title status={status}>{getTitle()}</Title>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.status === STATUS.SIGNED
            ? Colors.green[100]
            : props.status === STATUS.UNSIGNED
            ? Colors.red[100]
            : Colors.yellow[100]};
    padding: 8px 12px;
    border-radius: 8px;
`

const Title = styled(P_Mini_M)`
    color: ${(props) =>
        props.status === STATUS.SIGNED
            ? Colors.green[800]
            : props.status === STATUS.UNSIGNED
            ? Colors.red[800]
            : Colors.yellow[800]};
    margin: 0;
`

export default Status
