import { useContext, useState, useEffect } from "react"
import styled from "styled-components"

import Colors from "../assets/colors"
import { H5 } from "../components/text"

import Templates from "./Templates"

import WithSidebarMainContent from "../components/withSidebarMainContent"

import { FirebaseAuth } from "../providers/AuthProvider"
import { Link } from "react-router-dom"
import ActionCard from "../components/actionCard"

const CreateLanding = ({ asWidget = false, widgetClientImages = null }) => {
    const { refreshTokenError, getFriendlyErrorMessage } =
        useContext(FirebaseAuth)

    const [templatesFilter, setTemplatesFilter] = useState(null)

    useEffect(() => {
        const query = location.search
        const params = new URLSearchParams(query)

        if (params.has("templates_filter")) {
            const templatesFilterString = params.get("templates_filter")
            const templates = templatesFilterString.split(",")
            setTemplatesFilter(templates)
        }
    }, [])

    return (
        <WithSidebarMainContent>
            <TopContainer>
                <TopRow>
                    <Title>Opprett ny kontrakt fra mal</Title>
                    {widgetClientImages && widgetClientImages.mark && (
                        <img
                            src={widgetClientImages.mark.dataSource}
                            style={{ ...widgetClientImages.mark.attributes }}
                        />
                    )}
                </TopRow>
                {refreshTokenError && (
                    <p>{getFriendlyErrorMessage(refreshTokenError)}</p>
                )}
                <Templates filter={templatesFilter} />
            </TopContainer>

            {!asWidget && (
                <BottomContainer>
                    <Row>
                        <Link to="/upload">
                            <ActionCard
                                key="action-card-3"
                                icon={"Plus"}
                                title={"Last opp kontrakt"}
                                description={"Last opp egen PDF"}
                            />
                        </Link>
                    </Row>
                </BottomContainer>
            )}
        </WithSidebarMainContent>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }
`

const TopRow = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 16px;
    align-self: stretch;
`

const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 32px 48px 48px;
    }
    background-color: ${(props) => props.theme.backgroundSecondary};
`

const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;

    > * {
        margin-bottom: 16px;
    }

    > *:not(:last-child) {
        margin-right: 16px;
    }

    > * {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 16px;
`

export default CreateLanding
