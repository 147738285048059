import React, { useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"

const Toggle = ({ defaultSelected = false, disabled, onChange = () => { } }) => {
    const [selected, setSelected] = useState(defaultSelected)
    const onClick = () => {
        if (!disabled) {
            setSelected(!selected)
            onChange(!selected)
        }
    }

    return (
        <Container selected={selected} onClick={onClick} disabled={disabled}>
            <Circle selected={selected} />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.selected ? "flex-end" : "flex-start")};
    height: 24px;
    width: 44px;
    padding: 0 2px;
    border: 1px solid transparent;
    border-radius: 12px;
    background-color: ${(props) => props.selected ? props.theme.inputForeground : Colors.gray[200]};
    cursor: pointer;
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    &:hover {
        border: 1px solid ${(props) => props.disabled ? Colors.gray[200] : props.theme.inputForeground};
    }
`

const Circle = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
`

export default Toggle
