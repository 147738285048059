const theme = {
    backgroundPrimary: "#FFF2D9",
    backgroundSecondary: "#FFFAF0",
    backgroundSecondaryHover: "rgba(255, 242, 217, 0.8)",

    backgroundTertiary: "#FFFAF0", // Needed for e.g. Box-backgrounds, which are white for Lawyered

    foregroundPrimary: "#990000",
    foregroundHover: "rgba(153, 0, 0, 0.8)",
    foregroundSecondary: "#990000",

    inputForeground: "#990000",
    inputForegroundHover: "rgba(153, 0, 0, 0.8)",
    inputForegroundSecondary: "rgba(153, 0, 0, 0.2)"
}

export default theme
