import { Link } from "react-router-dom"
import styled from "styled-components"
import parse from "html-react-parser"

import Colors from "../../assets/colors"
import Button from "../../components/button"
import { H3, H4, H5, H6, P } from "../../components/text"
import { ContractsLoading } from "../Loading"

import { useTemplates } from "../../hooks/useTemplates"

import WithSidebarMainContent from "../../components/withSidebarMainContent"

const DocumentationTemplates = ({ path }) => {
    const { templates, isLoading, isError } = useTemplates()

    const getFieldDescription = (field) => {
        let description = ""
        if (field.name.includes("[]")) {
            description += `Multiple values (array style). Repeat by using e.g. <code>${field.name}=Espen&${field.name}=Christian</code>. `
        }
        if (field.category === "select") {
            const options = field.options.map((option) => {
                return `<code>${option}</code>`
            })
            description +=
                "One of the following exact values: " + options.join(", ")
        }
        if (field.category === "select_multiple") {
            if (field.reference) {
                description += `One or more of the options given in the <code>${field.reference}</code>-field. `
            } else {
                description +=
                    "One of the following exact values: " +
                    field.options
                        .map((option) => {
                            return <code>{option}</code>
                        })
                        .join(", ")
            }
        }
        if (field.category === "input" && field.type === "date") {
            description += "A date in the format YYYY-MM-DD. "
        }
        if (!description) {
            description = field.placeholder
        }
        if (description) {
            return <FieldDescription>{parse(description)}</FieldDescription>
        }
        return null
    }

    const getFieldCategory = (field) => {
        if (field.category === "input") {
            if (field.type === "text") {
                return "Text string"
            }
            if (field.type === "date") {
                return "Date"
            }
            if (field.type === "number") {
                return "Number"
            }
            if (field.type === "textarea") {
                return "Text string"
            }
            return field.category + "(***)"
        }
        if (field.category === "checkbox") {
            return "Boolean: 0 or 1"
        }
        if (field.category === "select") {
            return "A single predefined text value"
        }
        if (field.category === "select_multiple") {
            return "One or more predefined text value(s)"
        }
        return field.category + "(***)"
    }

    const getTemplateFields = (template) => {
        const rows = []
        template.fields.forEach((field) => {
            rows.push(
                <FieldWrapper>
                    <FieldHeading>
                        <code>{field.name}</code>
                    </FieldHeading>
                    <FieldCategory>{getFieldCategory(field)}</FieldCategory>

                    {getFieldDescription(field)}
                </FieldWrapper>
            )
        })
        return <Fields>{rows}</Fields>
    }

    const renderTemplatesList = () => {
        return (
            <TemplateList>
                {templates.map((template) => (
                    <li>
                        <a href={"#" + template.name}>{template.title}</a>
                    </li>
                ))}
            </TemplateList>
        )
    }

    const renderTemplatesData = () => {
        const rows = []
        templates.forEach((template) => {
            rows.push(
                <div>
                    <TemplateHeading id={template.name}>
                        {template.title}
                        <code>{template.name}</code>
                    </TemplateHeading>
                </div>
            )
        })
        return rows
    }

    return (
        <WithSidebarMainContent>
            <TopContainer>
                <Backlink to="/docs">&larr; Back to docs</Backlink>
                <Title>Templates</Title>
                <P>
                    Below is a list of available templates in our library, along
                    with their slugs (URL names).
                </P>
            </TopContainer>
            <BottomContainer>
                {isLoading && <ContractsLoading />}
                {!isLoading && !isError && renderTemplatesData()}
            </BottomContainer>
        </WithSidebarMainContent>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px;
    @media screen and (min-width: 768px) {
        padding: 32px 64px 40px 48px;
    }
    p {
        margin-bottom: 16px;
    }
`

const BottomContainer = styled.div`
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 0 32px 32px;
    @media screen and (min-width: 768px) {
        padding: 0 48px 32px 48px;
    }
`

const Backlink = styled(Link)`
    padding: 12px 12px 12px 0;
    margin-bottom: 36px;
    border-bottom: 2px solid transparent;
    &:hover {
        border-bottom: 2px solid ${Colors.black};
        color: ${Colors.black};
    }
`

const Title = styled(H3)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 16px;
`

const TemplateList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        display: inline-block;
        padding-right: 36px;
        a {
            padding: 12px 0;
            display: block;
            border-bottom: 2px solid transparent;
            &:hover {
                border-bottom: 2px solid ${Colors.black};
                color: ${Colors.black};
            }
        }
    }
`

const TemplateHeading = styled(Title)`
    font-size: 24px;
    code {
        padding-left: 32px;
        font-size: 18px;
    }
`

const Fields = styled.div``

const FieldHeading = styled(H6)`
    color: ${Colors.gray[700]};
`

const FieldWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 0 16px 0;
    border-bottom: 1px solid ${Colors.gray[300]};
    padding-bottom: 16px;
`

const FieldCategory = styled.p``

const FieldDescription = styled.p`
    code {
        background-color: ${Colors.gray[300]};
        padding: 0 4px;
        font-size: 16px;
    }
`

export default DocumentationTemplates
