import styled from "styled-components"
import Step from "./SingleStep"

const Progress = ({ steps }) => {
    return (
        <StyledProgress>
            {steps.map((step) => {
                return (
                    <Step
                        key={`progress-step-${step.no}`}
                        no={step.no}
                        description={step.description}
                        active={step.active}
                        completed={step.completed}
                    />
                )
            })}
        </StyledProgress>
    )
}

const StyledProgress = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
`

export default Progress
