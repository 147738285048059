import React from "react"
import { useTheme } from "styled-components"

function Caret({ fill, width = 12, direction = "right" }) {
    const theme = useTheme()
    let transformString = "rotate(0)"
    switch (direction) {
        case "down":
            transformString = "rotate(0)"
            break
        case "left":
            transformString = "rotate(90)"
            break
        case "up":
            transformString = "rotate(180)"
            break
        default:
            transformString = "rotate(-90)"
    }
    const height = width * (8 / 12)
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="none"
            transform={transformString}
            viewBox="0 0 12 8"
            style={
                {
                    /* transition: "all 0.5s ease-in-out" */
                }
            }
        >
            <path
                fill={fill || theme.inputForeground}
                d="M11 1.17a1 1 0 00-1.41 0L6 4.71 2.46 1.17a1 1 0 10-1.41 1.42l4.24 4.24a1 1 0 001.42 0L11 2.59a.998.998 0 000-1.42z"
            ></path>
        </svg>
    )
}

export default Caret
