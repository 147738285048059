import styled from "styled-components"

import Colors from "../assets/colors"
import {
    ApproveFile,
    Ball,
    Building,
    Cancel,
    Car,
    Checkmark,
    Declaration,
    Document,
    Dog,
    Heart,
    Home,
    House,
    Mail,
    Menu,
    Mobile,
    Money,
    Newspaper,
    Pen,
    Plus,
    Presentation,
    Spinner__DEPRECATED,
    Star
} from "../assets/icons"
import * as Flags from "../assets/icons/Flags"

import ActionCard from "./actionCard"
import Button from "./button"
import CategoryTag from "./categoryTag"
import CheckBox from "./checkBox"
import CheckBoxLabel from "./checkBoxLabel"
import CheckBoxLabelDesc from "./checkBoxLabelDescription"
import Contract from "./contract"
import ContractLine from "./contractLine"
import ContractTemplate from "./contractTemplate"
import FileUploader from "./fileUploader"
import Flag from "./flag"
import InputGroup from "./inputGroup"
import MenuItem from "./menuItem"
import Progress from "./progress/Progress"
import RadioButton from "./radioButton"
import RadioButtonLabel from "./radioButtonLabel"
import RadioButtonLabelDesc from "./radioButtonLabelDescription"
import SearchMenu from "./searchMenu"
import SearchResults from "./searchResults"
import Select from "./select"
import Spinner from "./Spinner"
import Status from "./status"
import Step from "./step"
import Tab from "./tab"
import TextInput from "./textInput"
import Title from "./title"
import Toggle from "./toggle"
import UploadedFile from "./uploadedFile"

const Components = () => {
    const renderAllFlags = () => {
        const list = []
        for (const key in Flags) {
            list.push(<Flag country={key} />)
        }
        return list
    }

    return (
        <Container>
            <Box>
                <Button text={"Button"} />
                <Button text={"Button"} icon={"Mail"} />
                <Button text={"Button"} icon={"Mail"} iconRight />
                <Button icon={"Plus"} />
                <Button text={"Button"} small />
                <Button text={"Button"} icon={"Mail"} small />
                <Button text={"Button"} icon={"Mail"} iconRight small />
                <Button icon={"Plus"} small />

                <Button text={"Button"} secondary />
                <Button text={"Button"} icon={"Mail"} secondary />
                <Button text={"Button"} icon={"Mail"} iconRight secondary />
                <Button icon={"Plus"} secondary />
                <Button text={"Button"} small secondary />
                <Button text={"Button"} icon={"Mail"} small secondary />
                <Button
                    text={"Button"}
                    icon={"Mail"}
                    iconRight
                    small
                    secondary
                />
                <Button icon={"Plus"} small secondary />
            </Box>

            <Box>
                <MenuItem text={"Dashboard"} />
                <MenuItem text={"Dashboard"} selected />
                <MenuItem text={"Dashboard"} icon={"Home"} />
                <MenuItem text={"Dashboard"} selected icon={"Home"} />
                <MenuItem text={"Dashboard"} count={1} />
                <MenuItem text={"Dashboard"} selected count={1} />
                <MenuItem text={"Dashboard"} icon={"Home"} count={1} />
                <MenuItem text={"Dashboard"} selected icon={"Home"} count={1} />
            </Box>

            <Box noBackground>
                <TextInput placeholder={"Name"} />
                <TextInput placeholder={"Email"} icon={"Mail"} type={"mail"} />
                <TextInput placeholder={"123 45 678"} type={"phone"} />
            </Box>

            <Box noBackground>
                <Select
                    text={"Select a color"}
                    list={["Green", "Red", "Blue", "Yellow", "Mora di ❤️"]}
                />
            </Box>

            <Box noBackground>
                <InputGroup title={"Navn"} placeholder={"Name 2"} />
                <InputGroup
                    title={"Telefonnummer"}
                    placeholder={"123 45 678"}
                    type={"phone"}
                    error={"Du må fylle ut telefonnummeret ditt"}
                />
            </Box>

            <Box noBackground>
                <RadioButton selected={false} />
                <CheckBox />
            </Box>

            <Box noBackground>
                <ApproveFile />
                <Ball />
                <Building />
                <Cancel />
                <Car />
                <Checkmark />
                <Declaration />
                <Document />
                <Dog />
                <Heart />
                <Home />
                <House />
                <Mail />
                <Menu />
                <Mobile />
                <Money />
                <Newspaper />
                <Pen />
                <Plus />
                <Presentation />
                <Spinner />
                <Star />
            </Box>

            <FlagsBox noBackground>{renderAllFlags()}</FlagsBox>

            <Box noBackground>
                <RadioButtonLabel title={"Option 1"} />
                <CheckBoxLabel title={"Option 1"} />
            </Box>

            <Box noBackground>
                <RadioButtonLabelDesc
                    title={"Option 1"}
                    description={"Description"}
                />
                <CheckBoxLabelDesc
                    title={"Option 1"}
                    description={"Description"}
                />
            </Box>

            <Box noBackground>
                <Tab title={"Tab"} selected={false} />
                <Tab title={"Tab"} selected={true} />
            </Box>

            <Box noBackground>
                <Status signed={true} />
                <Status signed={false} />
            </Box>

            <Box noBackground>
                <Toggle />
            </Box>

            <Box noBackground>
                <Step count={1} description={"Job details"} filled={false} />
                <Step count={1} description={"Job details"} filled={true} />
            </Box>

            <Box noBackground>
                <ActionCard
                    icon={"Presentation"}
                    title={"Se demo"}
                    description={"Beregnet tid: 2 minutter"}
                />
            </Box>

            <Box noBackground>
                <Contract
                    title={"Salgskontrakt"}
                    description={"Eiendom, Inkognitogata"}
                    signed={false}
                    date={"Opprettet 30. mars 2021"}
                />
                <Contract
                    title={"Salgskontrakt"}
                    description={"Eiendom, Inkognitogata"}
                    signed={true}
                    date={"Opprettet 30. mars 2021"}
                />
            </Box>

            <Box noBackground>
                <CategoryTag type={"health"} />
                <CategoryTag type={"declaration"} />
                <CategoryTag type={"economy"} />
                <CategoryTag type={"property"} />
                <CategoryTag type={"work"} />
                <CategoryTag type={"leisure"} />
                <CategoryTag type={"transportation"} />
                <CategoryTag type={"pet"} />
            </Box>

            <Box noBackground>
                <ContractTemplate
                    title={"Taushetserklæring"}
                    description={
                        "En taushetserklæring er en avtale om hemmelighold av informasjon som en avtalepart får tilgang til."
                    }
                />
            </Box>

            <Box noBackground>
                <SearchMenu />
            </Box>

            <Box noBackground>
                <SearchResults
                    title={"Oslo Origo (920 204 368)"}
                    subtitle={"0155 Oslo"}
                />
            </Box>

            <Box noBackground>
                <Title title={"Ny samtykkeerklæring"} />
                <Title title={"Ny samtykkeerklæring"} showIcon={false} />
            </Box>

            <Box noBackground>
                <FileUploader />
            </Box>

            <Box noBackground>
                <UploadedFile title={"Salgskontrakt.pdf"} size={3} />
            </Box>

            <Box noBackground>
                <ContractLine
                    title={"den som gir samtykke"}
                    disabled={false}
                    filled={true}
                />
                <ContractLine
                    title={"den som gir samtykke"}
                    disabled={true}
                    filled={true}
                />
                <ContractLine
                    title={"den som gir samtykke"}
                    disabled={false}
                    filled={false}
                />
                <ContractLine
                    title={"den som gir samtykke"}
                    disabled={true}
                    filled={false}
                />
            </Box>

            <Box noBackground>
                <Progress
                    steps={[
                        { no: 1, description: "Step one", active: true },
                        { no: 2, description: "Step two" },
                        { no: 3, description: "Step three" },
                        { no: "..." },
                        { no: 346, description: "Step 346" }
                    ]}
                />
            </Box>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    margin: 12px;
    background-color: ${(props) => (props.noBackground ? "" : Colors.white)};
    border-radius: 8px;
    border: ${(props) => (props.noBackground ? "1px dashed #7B61FF" : "")};

    > *:not(:last-child) {
        margin-bottom: 12px;
    }
`

const FlagsBox = styled(Box)`
    width: 200px;
    flex-direction: row;
    flex-wrap: wrap;

    > *:not(:last-child) {
        margin-right: 12px;
    }
`

export default Components
