import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import Icon from "../icon"
import { P_Small_M, P_Small } from "../text"

const CaretLabelDescription = ({
    description,
    onChange,
    selected,
    title,
    icon,
    iconProps = {}
}) => {
    return (
        <Container onClick={onChange} selected={selected}>
            <CaretWrapper>
                <Icon icon="Caret" direction={selected ? "down" : "right"} />
            </CaretWrapper>
            <RightContainer>
                <Text>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                </Text>
                {icon && (
                    <RightContainerIcon>
                        <Icon icon={icon} {...iconProps} />
                    </RightContainerIcon>
                )}
            </RightContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    padding: 16px;
    cursor: pointer;
    border: ${(props) =>
        props.selected
            ? "1px solid " + props.theme.inputForegroundSecondary
            : "1px solid transparent"};
    background-color: ${(props) =>
        props.selected ? props.theme.backgroundPrimary : props.theme.backgroundSecondary};
`

const CaretWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const RightContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    margin: 0 0 0 12px;
`

const Text = styled.div`
    flex-direction: column;
`

const RightContainerIcon = styled.div`
    display: flex;
`

const Title = styled(P_Small_M)`
    color: ${Colors.gray[700]};
`

const Description = styled(P_Small)`
    color: ${Colors.gray[500]};
    white-space: pre-wrap;
`

export default CaretLabelDescription
