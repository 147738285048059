import { NavLink } from "react-router-dom"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { H3, H5, P } from "../../components/text"

import WithSidebarMainContent from "../../components/withSidebarMainContent"

const DocumentationLanding = ({ path }) => {
    return (
        <WithSidebarMainContent>
            <TopContainer>
                <Title>Documentation</Title>
                <Subtitle>
                    Documentation for integrating against and using our
                    services.
                </Subtitle>
                <Links>
                    <NavLink to={`${path}/widget`}>
                        <H5>
                            Widget →
                            <small>
                                Use our widget to offer inline contract creation
                                via a button that opens a single contract
                                template.
                            </small>
                        </H5>
                    </NavLink>
                    <NavLink to={`${path}/iframe`}>
                        <H5>
                            Framed library →
                            <small>
                                Read how you can display a selection of contract
                                templates to your visitors.
                            </small>
                        </H5>
                    </NavLink>
                    <NavLink to={`${path}/upload`}>
                        <H5>
                            Framed uploader →
                            <small>
                                Learn about how you can use our Framed uploader
                                to offer users to sign their own PDFs.
                            </small>
                        </H5>
                    </NavLink>
                    <NavLink to={`${path}/templates`}>
                        <H5>
                            Templates →
                            <small>A list of our available templates</small>
                        </H5>
                    </NavLink>
                    <NavLink to={`${path}/prefill-url`}>
                        <H5>
                            Prefilling →
                            <small>
                                Prefill a contract template with URL query
                                parameters
                            </small>
                        </H5>
                    </NavLink>
                </Links>
            </TopContainer>
        </WithSidebarMainContent>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }
    a h5 {
        color: ${(props) => props.theme.foregroundPrimary};
    }
    a:hover h5 {
        color: ${(props) => props.theme.foregroundHover};
    }
    h5 small {
        font-size: 0.6em;
        margin-left: 0.5em;
    }
`

const Title = styled(H3)`
    color: ${Colors.black};
    line-height: 32px;
    margin-bottom: 16px;
`

const Subtitle = styled(P)`
    margin-bottom: 32px;
`

const Links = styled.div`
    display: flex;
    flex-direction: column;
    > * {
        margin: 12px 0;
    }
`

export default DocumentationLanding
