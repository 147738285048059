import styled from "styled-components"

import Button from "../components/button"
import Colors from "../assets/colors"
import Icon from "../components/icon"
import InputGroup from "../components/inputGroup"
import { H5, P_Large_M } from "../components/text"

const Preview = ({ contentKey, onDismiss = () => { } }) => {
    const getTitle = () => {
        if (contentKey === "Demo") {
            return "Se demo"
        } else if (contentKey === "CreateContract") {
            return "Create Contract"
        } else if (contentKey === "MobileApp") {
            return "Last ned mobilappen"
        } else if (contentKey === "Beta") {
            return "Bli betatester"
        } else if (contentKey === "Blog") {
            return "Blog"
        }
    }

    const renderContent = () => {
        if (contentKey === "Demo") {
        } else if (contentKey === "CreateContract") {
        } else if (contentKey === "MobileApp") {
            return (
                <>
                    <Row>
                        <QRContainer marginRight="12px">
                            <Icon icon={"QR_iOS"} />
                            <Icon icon={"AppStore"} />
                        </QRContainer>
                        <QRContainer marginLeft="12px">
                            <Icon icon={"QR_Android"} />
                            <Icon icon={"GooglePlay"} />
                        </QRContainer>
                    </Row>
                    <Subtitle>Send meg nedlastningslink på SMS</Subtitle>
                    <InputGroup
                        title={"Mobilnummer"}
                        placeholder={"Mobilnummer"}
                    />
                    <Button text={"Send"} />
                </>
            )
        } else if (contentKey === "Beta") {
            return (
                <>
                    <Subtitle>
                        Få med deg siste nytt og prøv nye
                        <br />
                        funksjoner før alle andre.
                    </Subtitle>
                    <InputGroup title={"E-post"} placeholder={"E-post"} />
                    <Button text={"Registrer deg"} />
                </>
            )
        } else if (contentKey === "Blog") {
        }
    }

    return (
        <Container>
            <Background onClick={onDismiss} />
            <InnerContainer>
                <Row>
                    <Title>{getTitle()}</Title>
                    <div onClick={onDismiss}>
                        <Icon icon={"Cancel"} />
                    </div>
                </Row>
                {renderContent()}
            </InnerContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
`

const Background = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
    background-color: rgba(107, 114, 128, 0.75);
`

const InnerContainer = styled.div`
    z-index: 1;
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
        0px 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const QRContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 32px;
    background-color: ${Colors.gray[50]};
    border-radius: 8px;
    margin-left: ${(props) => props.marginLeft || "0px"};
    margin-right: ${(props) => props.marginRight || "0px"};

    > *:nth-child(1) {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
`

const Subtitle = styled(P_Large_M)`
    color: ${Colors.gray[900]};
`

export default Preview
