import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { P, P_Small_M } from "../text"

const RadioButtonLabelContract = ({
    onChange,
    value,
    labelText,
    checked,
    name
}) => {
    return (
        <Wrapper>
            <Radio className="radio radio-before">
                <RadioInput className="radio__input">
                    <input
                        type="radio"
                        name={name}
                        value={value}
                        checked={checked}
                        onChange={onChange}
                    />
                    <RadioControl className="radio__control" />
                </RadioInput>
                <RadioLabel
                    as="span"
                    className="radio__label"
                    checked={checked}
                >
                    {labelText}
                </RadioLabel>
            </Radio>
        </Wrapper>
    )
}

const Wrapper = styled.div``

const Radio = styled.label`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 8px;
    font-size: 18px;
    padding: 8px 0;

    &:focus-within {
        .radio__label {
            opacity: 1;
        }
    }

    .radio__control {
        display: grid;
        place-items: center;
    }

    input + .radio__control::before {
        content: "";
        width: 8px;
        height: 8px;
        box-shadow: inset 8px 8px ${Colors.white};
        border-radius: 50%;
        transition: 125ms transform ease-in-out;
    }

    input:checked + .radio__control {
        background-color: ${(props) => props.theme.inputForeground};
    }
`

const RadioInput = styled.span`
    display: flex;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }
`

const RadioControl = styled.span`
    display: block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid ${Colors.gray[300]};
    transform: translateY(2px);
`

const RadioLabel = styled(P)`
    transition: 125ms all ease-in-out;
    opacity: ${(props) => (props.checked ? 1 : 0.6)};
`

export default RadioButtonLabelContract
