import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { H5, P } from "../../components/text"

import Button from "../../components/button"

const MailReceived = ({ mail }) => {
    const onClick = () => {
        //TODO: Send mail
    }

    return (
        <Container>
            <InnerContainer>
                <Title>{"Sjekk e-posten din!"}</Title>
                <Message>
                    {"Vi har sendt en e-post til "}
                    <br />
                    <Bold>{mail}</Bold>
                </Message>
                <Message>
                    {"Trykk "}
                    <Bold>{"Aktiver konto"}</Bold>
                    {", så vet vi"}
                    <br />
                    {"at du eier kontoen din."}
                </Message>
                <Button text={"Send på nytt"} secondary onClick={onClick} />
            </InnerContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const InnerContainer = styled.div`
    width: 312px;
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-align: center;
`

const Message = styled(P)`
    color: ${Colors.gray[500]};
    text-align: center;
`

const Bold = styled(Message)`
    font-weight: 700;
    display: inline;
`

export default MailReceived
