import React from "react"

const Star = ({ fill }) => {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M40 15.34C39.8736 14.9742 39.6439 14.6529 39.3386 14.4151C39.0333 14.1772 38.6656 14.033 38.28 14L26.9 12.34L21.8 2.00005C21.6362 1.6619 21.3805 1.37673 21.0621 1.17719C20.7438 0.977656 20.3757 0.871826 20 0.871826C19.6242 0.871826 19.2561 0.977656 18.9378 1.17719C18.6194 1.37673 18.3637 1.6619 18.2 2.00005L13.1 12.32L1.71995 14C1.3498 14.0527 1.0018 14.208 0.715455 14.4484C0.429108 14.6888 0.215875 15.0046 0.0999549 15.36C-0.00615519 15.7074 -0.0156773 16.0771 0.0724104 16.4294C0.160498 16.7818 0.34287 17.1035 0.599955 17.36L8.85995 25.36L6.85995 36.72C6.78856 37.095 6.82594 37.4826 6.9677 37.8369C7.10946 38.1913 7.34966 38.4978 7.65996 38.72C7.96239 38.9363 8.3191 39.0639 8.69005 39.0886C9.061 39.1133 9.4315 39.0342 9.75996 38.86L20 33.52L30.2 38.88C30.4806 39.0384 30.7977 39.1211 31.12 39.12C31.5436 39.1216 31.9568 38.9885 32.3 38.74C32.6102 38.5178 32.8505 38.2113 32.9922 37.8569C33.134 37.5026 33.1714 37.115 33.1 36.74L31.1 25.38L39.36 17.38C39.6487 17.1354 39.8621 16.8139 39.9754 16.4527C40.0887 16.0916 40.0972 15.7058 40 15.34ZM27.7 23.34C27.4654 23.5669 27.2899 23.8477 27.1888 24.158C27.0877 24.4683 27.0641 24.7985 27.12 25.12L28.56 33.5L21.04 29.5C20.7506 29.346 20.4278 29.2654 20.1 29.2654C19.7721 29.2654 19.4493 29.346 19.16 29.5L11.64 33.5L13.08 25.12C13.1358 24.7985 13.1122 24.4683 13.0111 24.158C12.91 23.8477 12.7345 23.5669 12.5 23.34L6.49996 17.34L14.92 16.12C15.244 16.075 15.552 15.9511 15.817 15.7593C16.082 15.5676 16.2959 15.3137 16.44 15.02L20 7.40005L23.76 15.04C23.904 15.3337 24.118 15.5876 24.383 15.7794C24.648 15.9711 24.956 16.095 25.28 16.14L33.7 17.36L27.7 23.34Z"
                fill="#4C1D95"
            />
        </svg>
    )
}

export default Star
