import styled from "styled-components"
import Colors from "../../assets/colors"

export const Overline = styled.p`
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P_Mini = styled.p`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P_Mini_M = styled.p`
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P_Small = styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P_Small_M = styled.p`
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P_Medium = styled.p`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P_Large = styled.p`
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const P_Large_M = styled.p`
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const H6 = styled.h6`
    font-size: 20px;
    font-weight: 400;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const H6_Medium = styled.h6`
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const H5 = styled.h5`
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const H4 = styled.h4`
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const H3 = styled.h3`
    font-size: 36px;
    line-height: 40px;
    font-weight: 600;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const H2 = styled.h2`
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const H1 = styled.h1`
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const Display = styled.h1`
    font-size: 72px;
    line-height: 72px;
    font-weight: 600;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const Display_Large = styled.h1`
    font-size: 96px;
    line-height: 92px;
    font-weight: 700;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`

export const Display_XL = styled.h1`
    font-size: 128px;
    line-height: 128px;
    font-weight: 700;
    color: ${(props) => props.color || Colors.black};
    margin: 0;
`
