import React, { useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { H5, P_Mini_M } from "../../components/text"

import A from "../../components/a"
import Button from "../../components/button"
import InputGroup from "../../components/inputGroup"

const Registration = ({
    initialName,
    initialEmail,
    initialPhoneNumber,
    onSubmit = () => {}
}) => {
    const [name, setName] = useState(initialName || "")
    const [email, setEmail] = useState(initialEmail || "")
    const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || "")

    const onNameChanged = (event) => setName(event.target.value)
    const onEmailChanged = (event) => setEmail(event.target.value)
    const onPhoneNumberChanged = (event) => setPhoneNumber(event.target.value)
    const onClick = (event) => {
        event.preventDefault()
        onSubmit(name, email, phoneNumber)
    }

    return (
        <Container>
            <form>
                <InnerContainer>
                    <Title>{"Snart klar..."}</Title>
                    <InputGroup
                        title={"Navn"}
                        placeholder={"Navn"}
                        onChange={onNameChanged}
                        value={name}
                    />
                    {initialEmail && (
                        <InputGroup
                            title={"E-post"}
                            placeholder={"E-post"}
                            onChange={onEmailChanged}
                            value={email}
                            readonly={initialEmail ? true : false}
                        />
                    )}
                    {initialPhoneNumber && (
                        <InputGroup
                            title={"Telefonnummer"}
                            placeholder={"Telefonnummer"}
                            onChange={onPhoneNumberChanged}
                            value={phoneNumber}
                            readonly={initialPhoneNumber ? true : false}
                        />
                    )}
                    <Button
                        text={"Registrer deg"}
                        onClick={(event) => onClick(event)}
                        type="submit"
                    />
                    <Message>
                        {"Ved registrering godtar du vår "}
                        <A
                            target="_blank"
                            href="https://lawyered.no/personvernerklaering"
                        >
                            {"personvernerklæring"}
                        </A>
                    </Message>
                </InnerContainer>
            </form>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

const InnerContainer = styled.div`
    width: 300px;
    @media screen and (min-width: 768px) {
        width: 360px;
    }
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-align: center;
`

const Message = styled(P_Mini_M)`
    color: ${Colors.gray[500]};
    text-align: center;
`

export default Registration
