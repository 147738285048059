import styled from "styled-components"

import { Route, useRouteMatch } from "react-router-dom"

import { useState } from "react"

import SignManual from "../containers/SignManual"
import SignLanding from "../containers/SignLanding"
import WithoutSidebar from "../components/withoutSidebar"
import SignSelection from "../containers/SignSelection"

const Sign = () => {
    // (https://reactrouter.com/web/example/nesting)
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path } = useRouteMatch()

    return (
        <>
            <WithoutSidebar>
                <Container
                    alignment={path === "/s" ? "flex-start" : "flex-end"}
                >
                    <Route exact path={path}>
                        <SignLanding />
                    </Route>
                    <Route exact path={`/s/:shortcode`}>
                        <SignSelection path={path} />
                    </Route>
                    <Route exact path={`/s/:shortcode/manual`}>
                        <SignManual />
                    </Route>
                </Container>
            </WithoutSidebar>
        </>
    )
}

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: ${(props) => props.alignment};
    background-color: ${(props) => props.theme.backgroundPrimary};
    @media screen and (min-width: 768px) {
        align-items: center;
    }
`

export default Sign
