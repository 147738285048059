import * as React from "react"
import { useTheme } from "styled-components"

function Arrow({ fill, width = 12, direction = "right" }) {
    const theme = useTheme()
    let transformString = "rotate(0)"
    switch (direction) {
        case "down":
            transformString = "rotate(90)"
            break
        case "left":
            transformString = "rotate(180)"
            break
        case "up":
            transformString = "rotate(270)"
            break
        default:
            transformString = "rotate(0)"
    }
    const height = width * (14 / 12)
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 12 14"
            fill="none"
            transform={transformString}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.92 6.62a1 1 0 00-.21-.33l-5-5a1.004 1.004 0 10-1.42 1.42L8.59 6H1a1 1 0 000 2h7.59l-3.3 3.29a1 1 0 00.325 1.639 1 1 0 001.095-.219l5-5a1 1 0 00.21-.33 1 1 0 000-.76z"
                fill={fill || theme.inputForeground}
            />
        </svg>
    )
}

export default Arrow
