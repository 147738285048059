import { useEffect, useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { P_Small_M, P_Small } from "../text"

import CheckBox from "../checkBox"

const CheckBoxLabelDesc = ({
    description,
    onChange,
    title,
    isSelected = false,
    inline = false
}) => {
    const [selected, setSelected] = useState(isSelected)

    const onClick = () => {
        setSelected(!selected)
        onChange(!selected)
    }

    useEffect(() => {
        setSelected(isSelected)
    }, [isSelected])

    return (
        <Container inline={inline}>
            <CheckBox onChange={onChange} isSelected={selected} />
            <RightContainer onClick={onClick}>
                <Title>{title}</Title>
                <Description>{description}</Description>
            </RightContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    background-color: ${(props) =>
        props.inline ? "transparent" : props.theme.backgroundSecondary};
    padding: ${(props) => (props.inline ? "8px 0 16px" : "16px")};
`

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 0 8px;
    cursor: pointer;
`

const Title = styled(P_Small_M)`
    color: ${Colors.gray[700]};
    margin: 0;
`

const Description = styled(P_Small)`
    color: ${Colors.gray[500]};
    margin: 0;
`

export default CheckBoxLabelDesc
