import { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import parse from "html-react-parser"

import { useContract } from "../hooks/useContracts"
import { useAttachments } from "../hooks/useAttachments"
import { useContractSigningStatus } from "../hooks/useContractSigningStatus"

import { FirebaseAuth } from "../providers/AuthProvider"

import WithSidebarMainContent from "../components/withSidebarMainContent"
import { H5, H6, Overline, P, P_Large_M, P_Small_M } from "../components/text"
import ContractPreview from "../components/contractPreview"
import UploadedFile from "../components/uploadedFile"
import Alert from "../components/alert"
import Button from "../components/button"
import A from "../components/a"
import Notice from "../components/notice"
import Icon from "../components/icon"
import Spinner from "../components/Spinner"
import SpinnerScreen from "../components/SpinnerScreen"
import SendSigningRequestNotification from "./SendSigningRequestNotification"

import { SIGNING_METHOD } from "../utils/constants"
import { getContractDownloadUrl, getSigningShortcodes } from "../utils/api"
import getManualSigningUrlQR from "../utils/qr"
import { renderError } from "../utils/errors"

import Colors from "../assets/colors"

const DisplayContract = (props) => {
    let { contract_id: contractId } = useParams()
    const { contract, isLoading, isError } = useContract(contractId)
    const { token } = useContext(FirebaseAuth)

    const {
        attachments,
        isLoading: isAttachmentsLoading,
        isError: isAttachmentsError
    } = useAttachments(contractId)

    const {
        status,
        isLoading: isStatusLoading,
        isError: isStatusError
    } = useContractSigningStatus(contractId)

    const [signingShortcodes, setSigningShortcodes] = useState(null)

    const [isDownloadUrlLoading, setIsDownloadUrlLoading] = useState(true)
    const [downloadUrl, setDownloadUrl] = useState(null)

    useEffect(() => {
        async function fetchSigningShortcodes() {
            const shortcodes = await getSigningShortcodes(contract.id, token)
            setSigningShortcodes(shortcodes.shortcodes)
        }
        async function fetchDownloadUrl() {
            const downloadUrl = await getContractDownloadUrl(contract.id, token)
            setDownloadUrl(downloadUrl.url)
            setIsDownloadUrlLoading(false)
        }
        if (
            contract &&
            contract.signatureType &&
            contract.signatureType == "manual" &&
            !contract.grantedAccess
        ) {
            fetchSigningShortcodes()
        }
        if (contract) {
            fetchDownloadUrl()
        }
    }, [contract])

    const renderAttachments = () => {
        if (isAttachmentsLoading) {
            return <Spinner size="32" />
        }
        if (isAttachmentsError) {
            return <p>{isAttachmentsError.toString()}</p>
        }
        return attachments.map((attachment, index) => {
            return (
                <a
                    href={attachment.url}
                    target="_blank"
                    rel="noreferrer"
                    key={`uploaded-file-${index}`}
                >
                    <UploadedFile
                        title={attachment.originalName}
                        size={attachment.size}
                        dismissable={false}
                    />
                </a>
            )
        })
    }

    const getSigningOrderSignerName = (documentSignature) => {
        return documentSignature.firstName + " " + documentSignature.lastName
    }

    const getNiceSignatureMethod = (signatureMethod) => {
        if (SIGNING_METHOD[signatureMethod]) {
            return SIGNING_METHOD[signatureMethod]
        }
        return signatureMethod
    }

    const getSignerName = (signerInfo) => {
        if (signerInfo.organizationInfo) {
            return signerInfo.organizationInfo.companyName
        } else {
            return (
                (signerInfo.firstName || "") + " " + (signerInfo.lastName || "")
            )
        }
    }

    const getSignerContactInfo = (signer) => {
        if (signer.signerInfo) {
            if (signer.signerInfo.email) {
                return signer.signerInfo.email
            } else if (signer.signerInfo.mobile) {
                return (
                    signer.signerInfo.mobile.countryCode +
                    " " +
                    signer.signerInfo.mobile.number?.replace(
                        `+${signer.signerInfo.mobile.countryCode}`,
                        ""
                    )
                )
            }
        } else if (signer.email) {
            return signer.email
        } else if (signer.mobile) {
            return (
                signer.mobile.countryCode +
                " " +
                signer.mobile.number?.replace(
                    `+${signer.mobile.countryCode}`,
                    ""
                )
            )
        }

        return null
    }

    const getCompleteSignedTimeString = (timeStr) => {
        return new Date(timeStr).toLocaleString("nb-NO", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        })
    }

    const getCompleteSignedTimeFromFirebaseString = (timeObj) => {
        const timestamp = new Date(
            (timeObj._seconds + timeObj._nanoseconds * 10 ** -9) * 1000
        )
        return timestamp.toLocaleString("nb-NO", {
            day: "2-digit",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit"
        })
    }

    const getWrappedSigningStatus = (signingOrderSigner, index) => {
        return (
            <Frame key={`signer-${index}`}>
                <Overline>PART {index + 1}</Overline>
                <P_Large_M>
                    {contract.signatureType !== "manual" &&
                        signingOrderSigner.documentSignature &&
                        getSigningOrderSignerName(
                            signingOrderSigner.documentSignature
                        )}
                    {/* Electronically signed */}
                    {contract.signatureType !== "manual" &&
                        !signingOrderSigner.documentSignature &&
                        signingOrderSigner.signerInfo &&
                        getSignerName(signingOrderSigner.signerInfo)}
                    {/* Manually signed */}
                    {contract.signatureType === "manual" &&
                        getSignerName(signingOrderSigner)}
                </P_Large_M>
                <P>{getSignerContactInfo(signingOrderSigner)}</P>
                {contract.signatureType !== "manual" &&
                    signingOrderSigner.documentSignature && (
                        <Notice
                            success
                            title={`Signert med ${getNiceSignatureMethod(
                                signingOrderSigner.documentSignature
                                    .signatureMethod
                            )}`}
                            text={`${getCompleteSignedTimeString(
                                signingOrderSigner.documentSignature.signedTime
                            )} fra IP-adresse ${
                                signingOrderSigner.documentSignature.clientIp
                            }`}
                        />
                    )}
                {contract.signatureType !== "manual" &&
                    !signingOrderSigner.documentSignature && (
                        <>
                            <Notice error title="Ikke signert" />
                        </>
                    )}
                {contract.signatureType === "manual" &&
                    signingOrderSigner.signature && (
                        <Notice
                            success
                            title={`Signert manuelt`}
                            text={`${getCompleteSignedTimeFromFirebaseString(
                                signingOrderSigner.created
                            )}`}
                        />
                    )}
                {contract.signatureType === "manual" &&
                    signingOrderSigner.signatureFile && (
                        <Notice
                            success
                            title={`Signert manuelt (opplastet signatur)`}
                            text={`${getCompleteSignedTimeFromFirebaseString(
                                signingOrderSigner.created
                            )}`}
                        />
                    )}
            </Frame>
        )
    }

    const renderQrCodeFrame = (qr, shortcode, contractReadingKey = null) => {
        return (
            <QRFrame key={`qr-${shortcode.code}`}>
                <P_Large_M>
                    {shortcode.signerInfo.firstName}{" "}
                    {shortcode.signerInfo.lastName}
                </P_Large_M>
                {(shortcode.signerInfo.email ||
                    shortcode.signerInfo.mobile) && (
                    <P>
                        {shortcode.signerInfo.email ||
                            shortcode.signerInfo.mobile.countryCode +
                                " " +
                                shortcode.signerInfo.mobile.number?.replace(
                                    `+${shortcode.signerInfo.mobile.countryCode}`,
                                    ""
                                )}
                    </P>
                )}
                <Alert className="alert" error title="Ikke signert" />
                <a
                    href={`/s/${shortcode.code}${
                        contractReadingKey
                            ? `?contract_reading_key=${contractReadingKey}`
                            : ""
                    }`}
                    target="_blank"
                >
                    <QR>
                        {qr}
                        <code>{shortcode.code}</code>
                    </QR>
                </a>
                <SendSigningRequestNotification shortcode={shortcode} />
            </QRFrame>
        )
    }

    const renderQrCodes = (shortcodes, contractReadingKey = null) => {
        const qrs = []
        shortcodes.forEach((shortcode) => {
            const qr = getManualSigningUrlQR(
                shortcode.code,
                contractReadingKey,
                0
            )
            const el = parse(qr)
            const fr = renderQrCodeFrame(el, shortcode, contractReadingKey)
            qrs.push(fr)
        })
        return qrs
    }

    const renderSigningStatus = () => {
        if (isStatusLoading) {
            return <Spinner size="24" />
        }

        if (
            (contract.signatureType !== "manual" && !status.signingOrder) ||
            (isStatusError && isStatusError.code === 404)
        ) {
            return (
                <Notice error text="Dokumentet er ikke sendt til signering" />
            )
        }

        if (isStatusError) {
            return <p>{isStatusError.toString()}</p>
        }

        return (
            <StatusFrames>
                {status.signingOrder?.signers.map(
                    (signingOrderSigner, index) => {
                        return getWrappedSigningStatus(
                            signingOrderSigner,
                            index
                        )
                    }
                )}
                {contract.signatureType === "manual" && (
                    <>
                        {signingShortcodes &&
                            signingShortcodes.length &&
                            renderQrCodes(
                                signingShortcodes,
                                contract.contractReadingKey
                            )}
                    </>
                )}
            </StatusFrames>
        )
    }

    const renderPaymentStatus = () => {
        if (contract?.paymentAmount === 0) {
            return
        }
        if (contract?.paymentData?.paid) {
            return <Notice success text="Betaling registrert" />
        }
        return <Notice error text="Betaling ikke registrert" />
    }

    const renderDownloadSignedDocument = () => {
        if (isDownloadUrlLoading) {
            return <Spinner size="24" text="Henter PDF..." />
        }
        if (!isDownloadUrlLoading && !downloadUrl) {
            return (
                <Notice
                    warning
                    text="Dokumentet er ikke klart for nedlasting"
                />
            )
        }
        return (
            <A href={downloadUrl} target="_blank">
                <Button text="Last ned signert dokument" />
            </A>
        )
    }

    if (isLoading) {
        return <SpinnerScreen />
    }

    if (isError) {
        return (
            <WithSidebarMainContent>
                <Container>{renderError(isError)}</Container>
            </WithSidebarMainContent>
        )
    }

    return (
        <WithSidebarMainContent>
            <Container>
                <H5>{contract.title}</H5>
                <Wrapper>
                    <ColumnWide>
                        <Box>
                            <HeadingWithIcon>
                                <P_Large_M>
                                    <Icon icon="Receipt" />
                                    Oppsummering
                                </P_Large_M>
                            </HeadingWithIcon>
                            {!contract.userPdfId && (
                                <ContractPreview
                                    title={contract.title}
                                    content={
                                        contract.contractHtml ||
                                        contract.contractText
                                    }
                                    showCopyProtectionText={false}
                                />
                            )}
                            {contract.userPdfId && contract.userPdfImages && (
                                <ContractPages>
                                    {contract.userPdfImages.map(
                                        (image, index) => {
                                            return (
                                                <ContractPage
                                                    key={`contract-page-${index}`}
                                                >
                                                    <ContractPageImage
                                                        src={image}
                                                    />
                                                    <P_Small_M>
                                                        Side {index + 1}
                                                    </P_Small_M>
                                                </ContractPage>
                                            )
                                        }
                                    )}
                                </ContractPages>
                            )}
                            <Footer>
                                <Attachments>
                                    {contract &&
                                        attachments &&
                                        attachments.length &&
                                        renderAttachments()}
                                </Attachments>
                            </Footer>
                        </Box>
                    </ColumnWide>
                    <ColumnNarrow>
                        <Box>
                            <HeadingWithIcon>
                                <P_Large_M>
                                    <Icon icon="PencilOnSheet" />
                                    Signeringsstatus
                                </P_Large_M>
                            </HeadingWithIcon>
                            {contract &&
                                !contract.grantedAccess &&
                                renderSigningStatus()}
                            {contract &&
                                !contract.grantedAccess &&
                                renderPaymentStatus()}
                            {contract && renderDownloadSignedDocument()}
                        </Box>
                    </ColumnNarrow>
                </Wrapper>
            </Container>
        </WithSidebarMainContent>
    )
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding: 32px 16px;
    @media screen and (min-width: 768px) {
        padding: 100px 24px 40px 48px;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: ${(props) => (props.horizontalOnMobile ? "row" : "column")};
    @media screen and (min-width: 1330px) {
        flex-direction: row;
    }
`

const Column = styled.div`
    flex: 1 1 auto;
    flex-direction: column;
    display: flex;
`

const ColumnWide = styled(Column)`
    max-width: 100%;
    @media screen and (min-width: 1330px) {
        width: 62%;
        max-width: 905px;
        min-width: 580px;
    }
`

const ColumnNarrow = styled(Column)`
    max-width: ${(props) => (props.vertical ? "350px" : "none")};
    @media screen and (min-width: 1330px) {
        max-width: 350px;
        min-width: 350px;
    }
`

const ColumnNarrowPlus = styled(Column)`
    min-width: 390px;
    @media screen and (min-width: 1330px) {
        max-width: 390px;
        min-width: 390px;
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: 24px;
    margin-top: 24px;
    margin-right: 0;
    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
    border-radius: 8px;
    border: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);

    > * {
        margin-bottom: 12px;
    }

    @media screen and (min-width: 768px) {
        margin-right: 24px;
    }
`

const StatusFrames = styled.div`
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 600px) {
        justify-content: flex-start;
    }
    > div {
        margin-right: 16px;
    }
    > div:nth-child(2) {
        margin-right: 0;
    }
`

const Frame = styled.div`
    border: 2px solid ${Colors.gray[100]};
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    @media screen and (min-width: 680px) {
        flex-basis: calc(50% - 48px - 16px);
        max-width: 232px;
    }
    @media screen and (min-width: 768px) {
        flex-basis: 100%;
    }
    @media screen and (min-width: 900px) {
        flex-basis: calc(50% - 48px - 16px);
    }
    @media screen and (min-width: 1024px) {
    }
    @media screen and (min-width: 1330px) {
        flex-basis: 100%;
    }
`

const FramedSuccess = styled(Frame)`
    border: none;
    background-color: ${Colors.green[100]};
`

const FramedError = styled(Frame)`
    border: none;
    background-color: ${Colors.red[100]};
`

const HeadingWithIcon = styled.div`
    p {
        display: flex;
        align-items: center;
    }
    p svg {
        padding-right: 8px;
    }
`

const Footer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 24px 0 0;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
`

const Attachments = styled.div`
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
`

const Actions = styled.div`
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    align-items: flex-end;
    > a {
        margin-top: 8px;
        &:hover {
            text-decoration: none;
        }
    }
    button {
        width: auto;
    }
`

const QRFrame = styled(Frame)`
    display: flex;
    button {
        margin-top: 8px;
    }
    .alert {
        margin: 16px 0;
    }
    a {
        border-radius: 8px;
        background-color: ${(props) => props.theme.backgroundPrimary};
        display: flex;
        padding: 12px;
        align-self: flex-start;
    }
    a:hover {
        background-color: ${Colors.white};
    }
`

const QR = styled.div`
    width: 96px;
    overflow: hidden;
    code {
        font-family: inherit;
        text-align: center;
        display: block;
        font-size: 12px;
        font-weight: 500;
        color: ${(props) => props.theme.foregroundPrimary};
    }
`

const ContractPages = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
`

const ContractPage = styled.li`
    height: 180px;
    padding-bottom: 36px;
    flex-grow: 0;
    margin: 0 16px 16px 0;
    border: 2px solid ${Colors.gray[100]};
    border-radius: 8px;
    overflow: hidden;
    p {
        padding: 8px 16px 12px;
        color: ${Colors.gray[500]};
        font-size: 12px;
    }
`

const ContractPageImage = styled.img`
    max-height: 100%;
    min-width: 100%;
    object-fit: contain;
    vertical-align: bottom;
`

export default DisplayContract
