import React from "react"
import styled from "styled-components/macro"
import Colors from "../../assets/colors"
import Icon from "../icon"
import { P, P_Medium } from "../text"

const Alert = ({
    success = false,
    warning = false,
    error = false,
    showIcon = true,
    title,
    text,
    className
}) => {
    const getIcon = () => {
        if (success) {
            return <Icon icon="Checkmark" width="18" fill={Colors.green[800]} />
        }
        if (warning) {
            return <Icon icon="Menu" fill={Colors.yellow[800]} />
        }
        if (error) {
            return <Icon icon="Cancel" width="18" fill={Colors.red[800]} />
        }
    }

    const content = (
        <>
            <P_Medium>
                {showIcon && getIcon()} {title}
            </P_Medium>
            {text && <P>{text}</P>}
        </>
    )

    if (success) {
        return <SuccessAlert className={className}>{content}</SuccessAlert>
    }
    if (warning) {
        return <WarningAlert className={className}>{content}</WarningAlert>
    }
    if (error) {
        return <ErrorAlert className={className}>{content}</ErrorAlert>
    }
}

const DefaultAlert = styled.div`
    border: 2px solid ${Colors.gray[100]};
    border-radius: 8px;
    padding: 16px;
    margin: 8px 0;
    display: flex;
    flex-direction: column;
    p {
        display: flex;
        flex: 1;
        align-items: center;
    }
    svg {
        margin-right: 8px;
    }
`

const SuccessAlert = styled(DefaultAlert)`
    border: none;
    background-color: ${Colors.green[100]};
    p {
        color: ${Colors.green[800]};
    }
`

const WarningAlert = styled(DefaultAlert)`
    border: none;
    background-color: ${Colors.yellow[100]};
    p {
        color: ${Colors.yellow[800]};
    }
`

const ErrorAlert = styled(DefaultAlert)`
    border: none;
    background-color: ${Colors.red[100]};
    p {
        color: ${Colors.red[800]};
    }
`

export default Alert
