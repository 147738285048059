import React from "react"
import * as Flags from "../../assets/icons/Flags"

const Flag = ({ country = "", onUpdate = (countryCode) => {} }) => {
    if (country.length > 0) {
        const Asset = Flags[country]
        return <Asset />
    } else {
        return null
    }
}

export default Flag
