import styled from "styled-components"

const WithSidebarMainContent = ({ children }) => {
    return <Content>{children}</Content>
}

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`

export default WithSidebarMainContent
