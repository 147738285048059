import React, { useState } from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"

import danger from "../../assets/icons/svg/Danger.svg"
import warning from "../../assets/icons/svg/Warning.svg"

const SelectContract = ({
    list,
    onChange = () => { },
    selectedValue = "",
    name,
    text,
    label,
    shouldDisplayValidationErrors,
    required = true
}) => {
    const [isFocused, setIsFocused] = useState(false)
    const onFocus = () => setIsFocused(true)
    const onBlur = () => setIsFocused(false)

    const renderList = () => {
        return list.map((item, index) => {
            return (
                <option value={item} key={index}>
                    {item}
                </option>
            )
        })
    }

    return (
        <Wrapper hasLabel={!!label}>
            {label && <label htmlFor={name}>{label}</label>}
            <Container>
                <Select
                    isFocused={isFocused}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={selectedValue}
                    required={required}
                    name={name}
                    className={shouldDisplayValidationErrors ? "invalid" : ""}
                >
                    <option value="" disabled hidden>
                        {text}
                    </option>
                    {renderList()}
                </Select>
            </Container>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex: ${(props) => (props.hasLabel ? "1 0 100%" : "0")};
    margin-bottom: 16px;
    label {
        display: flex;
        flex-basis: 40%;
        padding-right: 16px;
        justify-content: flex-end;
        align-items: center;
        text-align: right;
    }
`

const Container = styled.div`
    display: flex;
    flex-basis: 60%;
`

const Select = styled.select`
    display: flex;
    flex-basis: 60%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 20px 2px 4px;
    margin: 0 8px 0 2px;
    height: 30px;
    font-size: 16px;
    cursor: pointer;
    color: ${(props) => props.theme.selectForeground};
    background-color: ${(props) =>
        props.filled ? props.theme.backgroundPrimary : Colors.gray[50]};
    border: ${(props) =>
        props.isFocused
            ? "1px solid " + props.theme.selectForeground
            : "1px solid " + Colors.gray[300]};
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
        0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    &.invalid:invalid {
        border: 1px solid ${(props) => props.theme.selectForeground};
        background-image: url(${danger});
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) 7px;
        background-size: 18px 15px;
    }
`

export default SelectContract
