import styled from "styled-components"

import { useContext, useEffect, useState } from "react"
import { FirebaseAuth } from "../providers/AuthProvider"

import { Route, useRouteMatch } from "react-router-dom"

import WithSidebar from "../components/withSidebar"

import DashboardLanding from "../containers/DashboardLanding"
import Preview from "../containers/Preview"

const isEmpty = (obj) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false
    }
    return true
}

const Dashboard = () => {
    // (https://reactrouter.com/web/example/nesting)
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    let { path } = useRouteMatch()
    const { refreshTokenError } = useContext(FirebaseAuth)

    const [previewContentKey, setPreviewContentKey] = useState("")

    useEffect(() => {
        //clear all the localStorage information
        localStorage.clear();
    }, [])
    

    return (
        <>
            <WithSidebar path={path}>
                <Container>
                    <Route exact path={path}>
                        <DashboardLanding
                            path={path}
                            onItemPressed={(key) => setPreviewContentKey(key)}
                        />
                    </Route>
                </Container>
            </WithSidebar>
            {previewContentKey && (
                <Preview
                    contentKey={previewContentKey}
                    onDismiss={() => setPreviewContentKey("")}
                />
            )}
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
`

export default Dashboard
