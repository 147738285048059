export default {
    AD: {
        landCode: "376",
        name: "Andorra"
    },
    AE: {
        landCode: "971",
        name: "United Arab Emirates"
    },
    AF: {
        landCode: "93",
        name: "Afghanistan"
    },
    AG: {
        landCode: "+1-268",
        name: "Antigua and Barbuda"
    },
    AI: {
        landCode: "+1-264",
        name: "Anguilla"
    },
    AL: {
        landCode: "355",
        name: "Albania"
    },
    AM: {
        landCode: "374",
        name: "Armenia"
    },
    AO: {
        landCode: "244",
        name: "Angola"
    },
    AQ: {
        landCode: "",
        name: "Antarctica"
    },
    AR: {
        landCode: "54",
        name: "Argentina"
    },
    AS: {
        landCode: "+1-684",
        name: "American Samoa"
    },
    AT: {
        landCode: "43",
        name: "Austria"
    },
    AU: {
        landCode: "61",
        name: "Australia"
    },
    AW: {
        landCode: "297",
        name: "Aruba"
    },
    AX: {
        landCode: "+358-18",
        name: "Aland Islands"
    },
    AZ: {
        landCode: "994",
        name: "Azerbaijan"
    },
    BA: {
        landCode: "387",
        name: "Bosnia and Herzegovina"
    },
    BB: {
        landCode: "+1-246",
        name: "Barbados"
    },
    BD: {
        landCode: "880",
        name: "Bangladesh"
    },
    BE: {
        landCode: "32",
        name: "Belgium"
    },
    BF: {
        landCode: "226",
        name: "Burkina Faso"
    },
    BG: {
        landCode: "359",
        name: "Bulgaria"
    },
    BH: {
        landCode: "973",
        name: "Bahrain"
    },
    BI: {
        landCode: "257",
        name: "Burundi"
    },
    BJ: {
        landCode: "229",
        name: "Benin"
    },
    BL: {
        landCode: "590",
        name: "Saint Barthelemy"
    },
    BM: {
        landCode: "+1-441",
        name: "Bermuda"
    },
    BN: {
        landCode: "673",
        name: "Brunei"
    },
    BO: {
        landCode: "591",
        name: "Bolivia"
    },
    BQ: {
        landCode: "599",
        name: "Bonaire, Saint Eustatius and Saba "
    },
    BR: {
        landCode: "55",
        name: "Brazil"
    },
    BS: {
        landCode: "+1-242",
        name: "Bahamas"
    },
    BT: {
        landCode: "975",
        name: "Bhutan"
    },
    BV: {
        landCode: "",
        name: "Bouvet Island"
    },
    BW: {
        landCode: "267",
        name: "Botswana"
    },
    BY: {
        landCode: "375",
        name: "Belarus"
    },
    BZ: {
        landCode: "501",
        name: "Belize"
    },
    CA: {
        landCode: "1",
        name: "Canada"
    },
    CC: {
        landCode: "61",
        name: "Cocos Islands"
    },
    CD: {
        landCode: "243",
        name: "Democratic Republic of the Congo"
    },
    CF: {
        landCode: "236",
        name: "Central African Republic"
    },
    CG: {
        landCode: "242",
        name: "Republic of the Congo"
    },
    CH: {
        landCode: "41",
        name: "Switzerland"
    },
    CI: {
        landCode: "225",
        name: "Ivory Coast"
    },
    CK: {
        landCode: "682",
        name: "Cook Islands"
    },
    CL: {
        landCode: "56",
        name: "Chile"
    },
    CM: {
        landCode: "237",
        name: "Cameroon"
    },
    CN: {
        landCode: "86",
        name: "China"
    },
    CO: {
        landCode: "57",
        name: "Colombia"
    },
    CR: {
        landCode: "506",
        name: "Costa Rica"
    },
    CU: {
        landCode: "53",
        name: "Cuba"
    },
    CV: {
        landCode: "238",
        name: "Cape Verde"
    },
    CW: {
        landCode: "599",
        name: "Curacao"
    },
    CX: {
        landCode: "61",
        name: "Christmas Island"
    },
    CY: {
        landCode: "357",
        name: "Cyprus"
    },
    CZ: {
        landCode: "420",
        name: "Czech Republic"
    },
    DE: {
        landCode: "49",
        name: "Germany"
    },
    DJ: {
        landCode: "253",
        name: "Djibouti"
    },
    DK: {
        landCode: "45",
        name: "Denmark"
    },
    DM: {
        landCode: "+1-767",
        name: "Dominica"
    },
    DO: {
        landCode: "+1-809 and 1-829",
        name: "Dominican Republic"
    },
    DZ: {
        landCode: "213",
        name: "Algeria"
    },
    EC: {
        landCode: "593",
        name: "Ecuador"
    },
    EE: {
        landCode: "372",
        name: "Estonia"
    },
    EG: {
        landCode: "20",
        name: "Egypt"
    },
    EH: {
        landCode: "212",
        name: "Western Sahara"
    },
    ER: {
        landCode: "291",
        name: "Eritrea"
    },
    ES: {
        landCode: "34",
        name: "Spain"
    },
    ET: {
        landCode: "251",
        name: "Ethiopia"
    },
    FI: {
        landCode: "358",
        name: "Finland"
    },
    FJ: {
        landCode: "679",
        name: "Fiji"
    },
    FK: {
        landCode: "500",
        name: "Falkland Islands"
    },
    FM: {
        landCode: "691",
        name: "Micronesia"
    },
    FO: {
        landCode: "298",
        name: "Faroe Islands"
    },
    FR: {
        landCode: "33",
        name: "France"
    },
    GA: {
        landCode: "241",
        name: "Gabon"
    },
    GB: {
        landCode: "44",
        name: "United Kingdom"
    },
    GD: {
        landCode: "+1-473",
        name: "Grenada"
    },
    GE: {
        landCode: "995",
        name: "Georgia"
    },
    GF: {
        landCode: "594",
        name: "French Guiana"
    },
    GG: {
        landCode: "+44-1481",
        name: "Guernsey"
    },
    GH: {
        landCode: "233",
        name: "Ghana"
    },
    GI: {
        landCode: "350",
        name: "Gibraltar"
    },
    GL: {
        landCode: "299",
        name: "Greenland"
    },
    GM: {
        landCode: "220",
        name: "Gambia"
    },
    GN: {
        landCode: "224",
        name: "Guinea"
    },
    GP: {
        landCode: "590",
        name: "Guadeloupe"
    },
    GQ: {
        landCode: "240",
        name: "Equatorial Guinea"
    },
    GR: {
        landCode: "30",
        name: "Greece"
    },
    GS: {
        landCode: "",
        name: "South Georgia and the South Sandwich Islands"
    },
    GT: {
        landCode: "502",
        name: "Guatemala"
    },
    GU: {
        landCode: "+1-671",
        name: "Guam"
    },
    GW: {
        landCode: "245",
        name: "Guinea-Bissau"
    },
    GY: {
        landCode: "592",
        name: "Guyana"
    },
    HK: {
        landCode: "852",
        name: "Hong Kong"
    },
    HM: {
        landCode: " ",
        name: "Heard Island and McDonald Islands"
    },
    HN: {
        landCode: "504",
        name: "Honduras"
    },
    HR: {
        landCode: "385",
        name: "Croatia"
    },
    HT: {
        landCode: "509",
        name: "Haiti"
    },
    HU: {
        landCode: "36",
        name: "Hungary"
    },
    ID: {
        landCode: "62",
        name: "Indonesia"
    },
    IE: {
        landCode: "353",
        name: "Ireland"
    },
    IL: {
        landCode: "972",
        name: "Israel"
    },
    IM: {
        landCode: "+44-1624",
        name: "Isle of Man"
    },
    IN: {
        landCode: "91",
        name: "India"
    },
    IO: {
        landCode: "246",
        name: "British Indian Ocean Territory"
    },
    IQ: {
        landCode: "964",
        name: "Iraq"
    },
    IR: {
        landCode: "98",
        name: "Iran"
    },
    IS: {
        landCode: "354",
        name: "Iceland"
    },
    IT: {
        landCode: "39",
        name: "Italy"
    },
    JE: {
        landCode: "+44-1534",
        name: "Jersey"
    },
    JM: {
        landCode: "+1-876",
        name: "Jamaica"
    },
    JO: {
        landCode: "962",
        name: "Jordan"
    },
    JP: {
        landCode: "81",
        name: "Japan"
    },
    KE: {
        landCode: "254",
        name: "Kenya"
    },
    KG: {
        landCode: "996",
        name: "Kyrgyzstan"
    },
    KH: {
        landCode: "855",
        name: "Cambodia"
    },
    KI: {
        landCode: "686",
        name: "Kiribati"
    },
    KM: {
        landCode: "269",
        name: "Comoros"
    },
    KN: {
        landCode: "+1-869",
        name: "Saint Kitts and Nevis"
    },
    KP: {
        landCode: "850",
        name: "North Korea"
    },
    KR: {
        landCode: "82",
        name: "South Korea"
    },
    KW: {
        landCode: "965",
        name: "Kuwait"
    },
    KY: {
        landCode: "+1-345",
        name: "Cayman Islands"
    },
    KZ: {
        landCode: "7",
        name: "Kazakhstan"
    },
    LA: {
        landCode: "856",
        name: "Laos"
    },
    LB: {
        landCode: "961",
        name: "Lebanon"
    },
    LC: {
        landCode: "+1-758",
        name: "Saint Lucia"
    },
    LI: {
        landCode: "423",
        name: "Liechtenstein"
    },
    LK: {
        landCode: "94",
        name: "Sri Lanka"
    },
    LR: {
        landCode: "231",
        name: "Liberia"
    },
    LS: {
        landCode: "266",
        name: "Lesotho"
    },
    LT: {
        landCode: "370",
        name: "Lithuania"
    },
    LU: {
        landCode: "352",
        name: "Luxembourg"
    },
    LV: {
        landCode: "371",
        name: "Latvia"
    },
    LY: {
        landCode: "218",
        name: "Libya"
    },
    MA: {
        landCode: "212",
        name: "Morocco"
    },
    MC: {
        landCode: "377",
        name: "Monaco"
    },
    MD: {
        landCode: "373",
        name: "Moldova"
    },
    ME: {
        landCode: "382",
        name: "Montenegro"
    },
    MF: {
        landCode: "590",
        name: "Saint Martin"
    },
    MG: {
        landCode: "261",
        name: "Madagascar"
    },
    MH: {
        landCode: "692",
        name: "Marshall Islands"
    },
    MK: {
        landCode: "389",
        name: "Macedonia"
    },
    ML: {
        landCode: "223",
        name: "Mali"
    },
    MM: {
        landCode: "95",
        name: "Myanmar"
    },
    MN: {
        landCode: "976",
        name: "Mongolia"
    },
    MO: {
        landCode: "853",
        name: "Macao"
    },
    MP: {
        landCode: "+1-670",
        name: "Northern Mariana Islands"
    },
    MQ: {
        landCode: "596",
        name: "Martinique"
    },
    MR: {
        landCode: "222",
        name: "Mauritania"
    },
    MS: {
        landCode: "+1-664",
        name: "Montserrat"
    },
    MT: {
        landCode: "356",
        name: "Malta"
    },
    MU: {
        landCode: "230",
        name: "Mauritius"
    },
    MV: {
        landCode: "960",
        name: "Maldives"
    },
    MW: {
        landCode: "265",
        name: "Malawi"
    },
    MX: {
        landCode: "52",
        name: "Mexico"
    },
    MY: {
        landCode: "60",
        name: "Malaysia"
    },
    MZ: {
        landCode: "258",
        name: "Mozambique"
    },
    NA: {
        landCode: "264",
        name: "Namibia"
    },
    NC: {
        landCode: "687",
        name: "New Caledonia"
    },
    NE: {
        landCode: "227",
        name: "Niger"
    },
    NF: {
        landCode: "672",
        name: "Norfolk Island"
    },
    NG: {
        landCode: "234",
        name: "Nigeria"
    },
    NI: {
        landCode: "505",
        name: "Nicaragua"
    },
    NL: {
        landCode: "31",
        name: "Netherlands"
    },
    NO: {
        landCode: "47",
        name: "Norway"
    },
    NP: {
        landCode: "977",
        name: "Nepal"
    },
    NR: {
        landCode: "674",
        name: "Nauru"
    },
    NU: {
        landCode: "683",
        name: "Niue"
    },
    NZ: {
        landCode: "64",
        name: "New Zealand"
    },
    OM: {
        landCode: "968",
        name: "Oman"
    },
    PA: {
        landCode: "507",
        name: "Panama"
    },
    PE: {
        landCode: "51",
        name: "Peru"
    },
    PF: {
        landCode: "689",
        name: "French Polynesia"
    },
    PG: {
        landCode: "675",
        name: "Papua New Guinea"
    },
    PH: {
        landCode: "63",
        name: "Philippines"
    },
    PK: {
        landCode: "92",
        name: "Pakistan"
    },
    PL: {
        landCode: "48",
        name: "Poland"
    },
    PM: {
        landCode: "508",
        name: "Saint Pierre and Miquelon"
    },
    PN: {
        landCode: "870",
        name: "Pitcairn"
    },
    PR: {
        landCode: "+1-787 and 1-939",
        name: "Puerto Rico"
    },
    PS: {
        landCode: "970",
        name: "Palestinian Territory"
    },
    PT: {
        landCode: "351",
        name: "Portugal"
    },
    PW: {
        landCode: "680",
        name: "Palau"
    },
    PY: {
        landCode: "595",
        name: "Paraguay"
    },
    QA: {
        landCode: "974",
        name: "Qatar"
    },
    RE: {
        landCode: "262",
        name: "Reunion"
    },
    RO: {
        landCode: "40",
        name: "Romania"
    },
    RS: {
        landCode: "381",
        name: "Serbia"
    },
    RU: {
        landCode: "7",
        name: "Russia"
    },
    RW: {
        landCode: "250",
        name: "Rwanda"
    },
    SA: {
        landCode: "966",
        name: "Saudi Arabia"
    },
    SB: {
        landCode: "677",
        name: "Solomon Islands"
    },
    SC: {
        landCode: "248",
        name: "Seychelles"
    },
    SD: {
        landCode: "249",
        name: "Sudan"
    },
    SE: {
        landCode: "46",
        name: "Sweden"
    },
    SG: {
        landCode: "65",
        name: "Singapore"
    },
    SH: {
        landCode: "290",
        name: "Saint Helena"
    },
    SI: {
        landCode: "386",
        name: "Slovenia"
    },
    SJ: {
        landCode: "47",
        name: "Svalbard and Jan Mayen"
    },
    SK: {
        landCode: "421",
        name: "Slovakia"
    },
    SL: {
        landCode: "232",
        name: "Sierra Leone"
    },
    SM: {
        landCode: "378",
        name: "San Marino"
    },
    SN: {
        landCode: "221",
        name: "Senegal"
    },
    SO: {
        landCode: "252",
        name: "Somalia"
    },
    SR: {
        landCode: "597",
        name: "Suriname"
    },
    SS: {
        landCode: "211",
        name: "South Sudan"
    },
    ST: {
        landCode: "239",
        name: "Sao Tome and Principe"
    },
    SV: {
        landCode: "503",
        name: "El Salvador"
    },
    SX: {
        landCode: "599",
        name: "Sint Maarten"
    },
    SY: {
        landCode: "963",
        name: "Syria"
    },
    SZ: {
        landCode: "268",
        name: "Swaziland"
    },
    TC: {
        landCode: "+1-649",
        name: "Turks and Caicos Islands"
    },
    TD: {
        landCode: "235",
        name: "Chad"
    },
    TF: {
        landCode: "",
        name: "French Southern Territories"
    },
    TG: {
        landCode: "228",
        name: "Togo"
    },
    TH: {
        landCode: "66",
        name: "Thailand"
    },
    TJ: {
        landCode: "992",
        name: "Tajikistan"
    },
    TK: {
        landCode: "690",
        name: "Tokelau"
    },
    TL: {
        landCode: "670",
        name: "East Timor"
    },
    TM: {
        landCode: "993",
        name: "Turkmenistan"
    },
    TN: {
        landCode: "216",
        name: "Tunisia"
    },
    TO: {
        landCode: "676",
        name: "Tonga"
    },
    TR: {
        landCode: "90",
        name: "Turkey"
    },
    TT: {
        landCode: "+1-868",
        name: "Trinidad and Tobago"
    },
    TV: {
        landCode: "688",
        name: "Tuvalu"
    },
    TW: {
        landCode: "886",
        name: "Taiwan"
    },
    TZ: {
        landCode: "255",
        name: "Tanzania"
    },
    UA: {
        landCode: "380",
        name: "Ukraine"
    },
    UG: {
        landCode: "256",
        name: "Uganda"
    },
    UM: {
        landCode: "1",
        name: "United States Minor Outlying Islands"
    },
    US: {
        landCode: "1",
        name: "United States"
    },
    UY: {
        landCode: "598",
        name: "Uruguay"
    },
    UZ: {
        landCode: "998",
        name: "Uzbekistan"
    },
    VA: {
        landCode: "379",
        name: "Vatican"
    },
    VC: {
        landCode: "+1-784",
        name: "Saint Vincent and the Grenadines"
    },
    VE: {
        landCode: "58",
        name: "Venezuela"
    },
    VG: {
        landCode: "+1-284",
        name: "British Virgin Islands"
    },
    VI: {
        landCode: "+1-340",
        name: "U.S. Virgin Islands"
    },
    VN: {
        landCode: "84",
        name: "Vietnam"
    },
    VU: {
        landCode: "678",
        name: "Vanuatu"
    },
    WF: {
        landCode: "681",
        name: "Wallis and Futuna"
    },
    WS: {
        landCode: "685",
        name: "Samoa"
    },
    XK: {
        landCode: "",
        name: "Kosovo"
    },
    YE: {
        landCode: "967",
        name: "Yemen"
    },
    YT: {
        landCode: "262",
        name: "Mayotte"
    },
    ZA: {
        landCode: "27",
        name: "South Africa"
    },
    ZM: {
        landCode: "260",
        name: "Zambia"
    },
    ZW: {
        landCode: "263",
        name: "Zimbabwe"
    }
}
