import styled from "styled-components"

import Colors from "../../assets/colors"
import { H6 } from "../text"

const ContractLine = ({ disabled, filled, title }) => {
    return (
        <Container disabled={disabled} filled={filled}>
            <Title filled={filled}>{title}</Title>
        </Container>
    )
}

const Container = styled.div`
    padding: 8px;
    background-color: ${(props) => props.filled ? props.theme.backgroundPrimary : props.theme.backgroundSecondary};
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    border: 1px solid ${Colors.gray[200]};
    opacity: ${(props) => props.disabled ? 0.4 : 1};
`

const Title = styled(H6)`
    color: ${(props) => props.filled ? props.theme.foregroundSecondary : Colors.gray[700]};
    margin: 0 12px;
`

export default ContractLine
