import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import CategoryTag from "../categoryTag"
import Colors from "../../assets/colors"
import { P_Large_M, P_Small } from "../text"
import Button from "../button"

const ContractTemplate = ({ description, title, category, price, template_url }) => {
    return (
        <Container>
            <div>
                <Title>{title}</Title>
                <Description>{description}</Description>
                <CategoryTag type={category} />

            </div>
            <Link 
            to={{
                pathname: template_url,
                state: {"updatedTemplatePrice": price}
              }}
            style={{width: "-webkit-fill-available"}}
            >
                <Button
                    text={`Kjøp NOK ${price}`}
                    // onClick={() => buyContractsButtonHandler(item, item?.id)}
                    primary
                />
            </Link>

        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.backgroundTertiary};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    transition: ease-in-out 0.4s;

    &:hover {
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
            0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    }

    @media screen and (min-width: 600px) {
        width: 232px;
    }

    @media screen and (min-width: 768px) {
        width: 232px;
    }
`

const Title = styled(P_Large_M)`
    color: ${(props) => props.theme.foregroundPrimary};
    margin: 0 0 4px 0;
`

const Description = styled(P_Small)`
    color: ${(props) => props.theme.foregroundPrimary};
    margin: 0 0 8px 0;
`

export default ContractTemplate
