const search = async (searchString) => {
    const response = await fetch(
        `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${searchString}`
    )
    const results = await response.json()
    if (results._embedded && results._embedded.enheter) {
        return results._embedded.enheter
    }
    return []
}

export { search }
