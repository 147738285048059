import React from "react"
import styled, { keyframes } from "styled-components"
import Colors from "../assets/colors"
import { P_Small, P_Small_M } from "./text"

const Spinner = ({
    size = 50,
    text = null,
    inline = false,
    textColor = Colors.black
}) => {
    return (
        <Outer inline={inline}>
            <SpinnerWrapper size={size} inline={inline}>
                <StyledSpinner size={size} />
            </SpinnerWrapper>
            {text && <P_Small_M color={textColor}>{text}</P_Small_M>}
        </Outer>
    )
}

const Outer = styled.span`
    display: flex;
    flex-direction: ${(props) => (props.inline ? "row" : "column")};
    justify-content: ${(props) => (props.inline ? "inherit" : "center")};
    align-items: center;
    flex: 1;
    margin-top: ${(props) => (props.inline ? 0 : "16px")};
    padding-bottom: ${(props) => (props.inline ? 0 : "8px")};
`

const SpinnerWrapper = styled.span`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    position: relative;
    margin-bottom: ${(props) => (props.inline ? 0 : "8px")};
    margin-right: ${(props) => (props.inline ? "8px" : 0)};
`

const spin = keyframes`
  from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
`

const spinnerWidth = 4

const StyledSpinner = styled.span`
    display: block;
    position: absolute;
    top: calc(50% - ${(props) => spinnerWidth / 2}px);
    left: calc(50% - ${(props) => spinnerWidth / 2}px);
    height: ${(props) => props.size - spinnerWidth * 2}px;
    width: ${(props) => props.size - spinnerWidth * 2}px;
    margin: -${(props) => (props.size - spinnerWidth) / 2}px 0 0 -${(props) =>
        (props.size - spinnerWidth) / 2}px;
    border: ${spinnerWidth}px transparent solid;
    border-top: ${spinnerWidth}px ${(props) => props.theme.foregroundPrimary} solid;
    border-radius: 50%;
    -webkit-animation: ${spin} 1.5s infinite linear;
    animation: ${spin} 1.5s infinite linear;
`

export default Spinner
