import useSWR from "swr";
import { apiUrl, fetchWithToken } from "./fetcher";
import { useContext } from "react";
import { FirebaseAuth } from "../providers/AuthProvider";

function usePaymentOrders(contract_id) {
  const { token } = useContext(FirebaseAuth);

  // Check if contract_id is defined before making the API request
  const shouldFetchData = contract_id !== undefined;

  const { data, error } = useSWR(
    shouldFetchData
      ? [`${apiUrl}/payment_order?contract_id=${contract_id}&order_status_for=BULK_CONTRACT`, token]
      : null,
    shouldFetchData ? fetchWithToken : null
  );

  return {
    orders: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export { usePaymentOrders };
