import { useState, useEffect } from "react"
import styled from "styled-components"

import { Route, useRouteMatch, useLocation } from "react-router-dom"

import WithSidebar from "../components/withSidebar"

import CreateContract from "../containers/CreateContract"
import CreateLanding from "../containers/CreateLanding"
import SpinnerScreen from "../components/SpinnerScreen"
import Notice from "../components/notice"

import { FirstSignerProvider } from "../providers/FirstSignerProvider"
import { checkClientAccess } from "../utils/api"

const Create = ({ setTheme = () => {} }) => {
    let { path } = useRouteMatch()
    const location = useLocation()

    const [widgetClientId, setWidgetClientId] = useState(null)
    const [widgetApiKey, setWidgetApiKey] = useState(null)
    const [widgetExternalReference, setWidgetExternalReference] = useState(null)
    const [widgetClientImages, setWidgetClientImages] = useState(null)
    const [didClientCheck, setDidClientCheck] = useState(false)
    const [isClientAuthorized, setIsClientAuthorized] = useState(false)
    const [clientAuthError, setClientAuthError] = useState(null)

    useEffect(() => {
        const query = location.search
        const params = new URLSearchParams(query)

        async function checkAuth(clientId, apiKey) {
            const token = localStorage.getItem("lawyered.user.token")
            const result = await checkClientAccess(clientId, apiKey, token)
            if (result.success) {
                setIsClientAuthorized(true)
                setWidgetExternalReference(params.get("external_reference"))
                setWidgetClientImages(result.data?.images)
                setTheme(result.data?.theme)
            } else {
                setClientAuthError(result.error.message)
            }
            setDidClientCheck(true)
        }

        if (params.has("client_id")) {
            const clientId = params.get("client_id")
            const apiKey = params.get("api_key")
            setWidgetClientId(clientId)
            setWidgetApiKey(apiKey)
            checkAuth(clientId, apiKey)
        } else {
            setDidClientCheck(true)
        }
    }, [location])

    if (!didClientCheck) {
        return <SpinnerScreen spinnerSize="48" />
    }

    if (clientAuthError) {
        return (
            <Container>
                <Notice error text={clientAuthError} />
            </Container>
        )
    }

    if (didClientCheck && widgetClientId && isClientAuthorized) {
        return (
            <Container asWidget>
                <Route exact path={path}>
                    <CreateLanding
                        asWidget
                        widgetClientId={widgetClientId}
                        widgetExternalReference={widgetExternalReference}
                        widgetClientImages={widgetClientImages}
                    />
                </Route>
                <Route exact path={`/create/:template_name`}>
                    <FirstSignerProvider>
                        <CreateContract
                            asWidget
                            widgetClientId={widgetClientId}
                            widgetExternalReference={widgetExternalReference}
                            widgetClientImages={widgetClientImages}
                            isClientAuthorized={isClientAuthorized}
                        />
                    </FirstSignerProvider>
                </Route>
            </Container>
        )
    }

    if (didClientCheck && widgetClientId && !isClientAuthorized) {
        return (
            <Container>
                <Notice
                    error
                    text="Client ID/API-key mismatch, or missing authorization."
                />
            </Container>
        )
    }

    return (
        <>
            <WithSidebar path={path}>
                <Container>
                    <Route exact path={path}>
                        <CreateLanding path={path} />
                    </Route>
                    <Route exact path={`/create/:template_name`}>
                        <FirstSignerProvider>
                            <CreateContract />
                        </FirstSignerProvider>
                    </Route>
                </Container>
            </WithSidebar>
        </>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: ${(props) => (props.asWidget ? "none" : "none")};
    margin: ${(props) => (props.asWidget ? "0 auto" : "0")};
`

export default Create
