import styled, { useTheme } from "styled-components"
import { useParams, useLocation, useHistory, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import QRCode from "qrcode-svg"
import parse from "html-react-parser"

import Colors from "../assets/colors"

import { H4, P, P_Medium } from "../components/text"
import Spinner from "../components/Spinner"
import Icon from "../components/icon"
import Button, { ButtonBordered } from "../components/button"
import { FileUploaderButton } from "../components/fileUploader"

import {
    uploadSignatureImage,
    getShortcodeMeta,
    getContractTextForManualSigning
} from "../utils/api"
import { __error } from "../utils/errors"

const SignSelection = ({ path }) => {
    let { shortcode } = useParams()
    const location = useLocation()
    const history = useHistory()
    const theme = useTheme()

    const [loading, setLoading] = useState(null)
    const [isCompleted, setIsCompleted] = useState(false)
    const [error, setError] = useState(null)

    const [qr, setQr] = useState(null)

    const [contractData, setContractData] = useState(null)

    const [contractReadingKey, setContractReadingKey] = useState(null)
    const [showContractContentLink, setShowContractContentLink] =
        useState(false)
    const [showContractContent, setShowContractContent] = useState(false)
    const [contractContent, setContractContent] = useState(null)

    const [contractPdfUrl, setContractPdfUrl] = useState(null)

    const [isFetchingContract, setIsFetchingContract] = useState(false)

    const baseUrl =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")

    function getManualSigningUrlQR(shortcode, padding = 4) {
        const qrcode = new QRCode({
            content: baseUrl + "/s/" + shortcode + "/manual",
            padding,
            width: 256,
            height: 256,
            color: theme.foregroundPrimary,
            background: "transparent",
            ecl: "M",
            join: true,
            container: "svg-viewbox"
        })
        return qrcode.svg()
    }

    async function getContractDetails(contractReadingKey) {
        setIsFetchingContract(true)
        const contractData = await getContractTextForManualSigning(
            shortcode,
            contractReadingKey
        )
        if (contractData.success) {
            if (contractData.contract.pdf) {
                setContractPdfUrl(contractData.contract.pdf)
            } else {
                setContractContent(contractData.contract.html)
            }
            setIsFetchingContract(false)
        } else {
            setError("En feil oppstod ved henting av kontrakten.")
        }
    }

    useEffect(() => {
        const query = location.search
        const params = new URLSearchParams(query)
        if (!!params.get("completed")) {
            setIsCompleted(true)
        }
        if (!!params.get("contract_reading_key")) {
            setShowContractContentLink(true)
            setContractReadingKey(params.get("contract_reading_key"))
            getContractDetails(params.get("contract_reading_key"))
        }
    }, [location])

    useEffect(() => {
        async function fetchShortcodeMeta() {
            const result = await getShortcodeMeta(shortcode)
            setLoading("Henter info...")
            if (result.success) {
                setContractData(result.contract)
                setLoading(null)
            } else {
                setLoading(null)
                setError(__error(result.error?.code))
            }
        }

        function getQr() {
            const qr = getManualSigningUrlQR(shortcode)
            const el = parse(qr)
            setQr(el)
        }

        if (shortcode) {
            fetchShortcodeMeta()
            getQr()
        }
    }, [shortcode])

    const openContract = async (event) => {
        if (!contractPdfUrl && contractContent) {
            event.preventDefault()
            setShowContractContent(true)
        }
    }

    // Upload selected image for/as signature
    const fileSelected = async (files) => {
        setLoading("Laster opp signatur...")
        const uploadResult = await uploadSignatureImage(shortcode, files[0])
        if (uploadResult.success) {
            history.push(`/s/${shortcode}?completed=1`)
        } else {
            if (uploadResult.error) {
                setError(__error(uploadResult.error.code))
            } else {
                // TODO Sentry this
                setError("En uhåndtert feil oppstod")
            }
        }
        setLoading(null)
    }

    if (loading) {
        return (
            <Fullscreen>
                <Spinner />
                <P_Medium>{loading}</P_Medium>
            </Fullscreen>
        )
    }

    if (isCompleted) {
        return (
            <Fullscreen>
                <Circle bg={Colors.green[600]}>
                    <Icon icon="Checkmark" width="21" fill={Colors.white} />
                </Circle>
                <P_Medium>
                    {contractData && `«${contractData.contractTitle}» signert`}
                </P_Medium>
            </Fullscreen>
        )
    }

    if (error) {
        return (
            <Fullscreen>
                <Circle bg={Colors.red[400]}>
                    <Icon icon="Checkmark" width="21" fill={Colors.white} />
                </Circle>
                <P_Medium>{error}</P_Medium>
            </Fullscreen>
        )
    }

    return (
        <TopContainer>
            <Title>Signér dokument</Title>
            <P>
                {contractData
                    ? `«${contractData.contractTitle}» er klar`
                    : "Dokumentet er klart"}{" "}
                for signering.
            </P>
            {showContractContentLink && (
                <>
                    <LinkButton
                        onClick={(event) => {
                            openContract(event)
                        }}
                        href={contractPdfUrl || "#"}
                    >
                        {!isFetchingContract ? (
                            "Les kontrakten"
                        ) : (
                            <Spinner size="18" inline />
                        )}
                    </LinkButton>
                </>
            )}
            <P>Hvordan vil du signere?</P>
            <ItemWrapper>
                <Item>
                    {qr && (
                        <QRWrapper>
                            <QR>{qr}</QR>
                            <Legend>
                                Skann QR-koden for enklere fingersignering på
                                mobil
                            </Legend>
                        </QRWrapper>
                    )}
                    <Link to={`${path}/${shortcode}/manual`}>
                        <ButtonBordered
                            text={"Signér med finger"}
                            icon={"Finger"}
                        />
                    </Link>
                </Item>
                <FileUploaderButton
                    icon={"Upload"}
                    label={"Last opp signatur"}
                    refreshAcceptedFilesList={fileSelected}
                />
            </ItemWrapper>
            {showContractContent && (
                <Overlay>
                    <ContractContent>
                        <div className="close-contract-content">
                            <Button
                                icon="Cancel"
                                onClick={() => setShowContractContent(false)}
                            />
                        </div>
                        <Title>{contractData?.contractTitle}</Title>
                        {parse(contractContent)}
                    </ContractContent>
                </Overlay>
            )}
        </TopContainer>
    )
}

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
    padding: 32px;
    max-width: 380px;
    align-self: center;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }
    input {
        margin: 16px 0;
    }
    .read-contract-button {
        margin: 16px 0;
    }
    > h4 {
        text-align: center;
        width: 100%;
    }
    > p {
        width: 100%;
        text-align: center;
    }
`

const Overlay = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    .close-contract-content {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 16px;
    }
`

const ContractContent = styled.div`
    border-radius: 8px;
    width: calc(100% - 64px);
    height: calc(100% - 64px);
    overflow: scroll;

    margin: 16px;
    padding: 16px;
    background-color: ${(props) => props.theme.backgroundSecondary};

    @media screen and (min-width: 768px) {
        padding: 32px;
        width: 400px;
        height: 80%;
    }

    ins {
        text-decoration: none;
        font-weight: 600;
    }
`

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    > * {
        margin-top: 16px;
    }
`

const Item = styled.div`
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.backgroundSecondary};
`

const Title = styled(H4)`
    color: ${(props) => props.theme.foregroundPrimary};
    line-height: 32px;
    margin-bottom: 16px;
`

const Circle = styled.span`
    display: flex;
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => props.bg};
    border-radius: 50%;
`

const Fullscreen = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.backgroundPrimary};
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        padding: 8px 32px;
        text-align: center;
    }
`

const QRWrapper = styled.div`
    justify-content: center;
    display: none;
    visibility: hidden;
    @media screen and (min-width: 480px) {
        display: flex;
        align-self: center;
        visibility: visible;
    }
`
const Legend = styled.p`
    font-size: 14px;
    display: flex;
    padding-right: 16px;
    flex-basis: 50%;
    max-width: 100px;
    font-style: italic;
    align-self: center;
    line-height: 1.35;
`

const QR = styled.div`
    width: 128px;
    border-radius: 8px;
    overflow: hidden;
`

const LinkButton = styled.a`
    margin: 12px 0;
    color: ${(props) =>
        props.secondary
            ? props.theme.foregroundSecondary
            : Colors.white} !important;
    font-family: "Inter";
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 12px 0;
    background-color: ${(props) =>
        props.secondary
            ? props.theme.backgroundSecondary
            : props.theme.inputForeground};
    border-radius: 8px;
    border: none;

    svg {
        margin: ${(props) => (props.iconRight ? "0 0 0 12px" : "0 12px 0 0")};
    }

    &:hover {
        background-color: ${(props) =>
            props.secondary
                ? props.theme.backgroundSecondaryHover
                : props.theme.inputForegroundHover};
    }
`

export default SignSelection
