import styled from "styled-components"
import Colors from "../../assets/colors"

const WithoutSidebar = ({ path, children }) => {
    return (
        <PageWrapper>
            <Main>{children}</Main>
        </PageWrapper>
    )
}

const PageWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`

const Main = styled.div`
    display: flex;
    flex: 1;
    background-color: ${Colors.white};
    align-items: flex-start;
`

export default WithoutSidebar
