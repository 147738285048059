import { useEffect, useState, useContext } from "react"
import styled, { useTheme } from "styled-components"

import { FirebaseAuth } from "../providers/AuthProvider"
import { useFirstSigner } from "../providers/FirstSignerProvider"
import { getAuthUserData } from "../utils/api"

import RadioButtonLabelGroup from "../components/radioButtonLabelGroup"
import InputGroup from "../components/inputGroup"
import CheckBoxLabelDesc from "../components/checkBoxLabelDescription"
import Icon from "../components/icon"
import { P_Small_M } from "../components/text"
import Colors from "../assets/colors"

const ContractSigner = ({
    index,
    onUpdate,
    onDelete,
    signer,
    firstSignerEnabled = true,
    shouldDisplayValidationErrors,
    type = "privat"
}) => {
    const theme = useTheme()
    const [formType, setFormType] = useState(signer.type)
    const [signerData, setSignerData] = useState(signer)

    const {
        dispatch,
        state: { firstSignerIndex }
    } = useFirstSigner()

    const { currentUser, token } = useContext(FirebaseAuth)
    const [userData, setUserData] = useState({})

    useEffect(() => {
        async function getUser() {
            const userData = await getAuthUserData(token)
            const dbData = userData?.userData?.dbData
            setUserData({
                name: dbData?.name || null,
                email: dbData?.email || currentUser.email || null
            })
        }
        if (token) {
            console.log(`getting user`)
            getUser()
        }
    }, [])

    const handleOnSelectionChange = (groupName, currentSelection) => {
        setFormType(currentSelection)
        setSignerData({ ...signerData, type: currentSelection })
    }

    const handleInputChange = (event, key) => {
        setSignerData({ ...signerData, [key]: event.target.value })
    }

    const handleDeleteClick = () => {
        onDelete(index)
    }

    const handleIsFirstSignerChange = (index, selected) => {
        if (!currentUser.isAnonymous && selected) {
            if (userData.email) {
                setSignerData({ ...signerData, email: userData.email })
            }
            if (userData.name) {
                setSignerData({ ...signerData, name: userData.name })
            }
        }
        if (selected) {
            dispatch({ type: "setIndex", firstSignerIndex: index })
        } else {
            dispatch({ type: "unsetIndex", firstSignerIndex: index })
        }
    }

    useEffect(() => {
        onUpdate(signerData)
    }, [signerData])

    return (
        <>
            <Shaded>
                <RadioButtonLabelGroup
                    groupName={`part${index + 1}`}
                    title={`Part ${index + 1}`}
                    onSelectionChange={handleOnSelectionChange}
                    selected={signer.type}
                    titleColor={theme.foregroundPrimary}
                    options={[
                        {
                            title: "Privatperson",
                            value: "privat"
                        },
                        { title: "Bedrift", value: "bedrift" }
                    ]}
                />
                {firstSignerEnabled && (
                    <div>
                        <CheckBoxLabelDesc
                            inline
                            description={
                                "Én av partene kan signere kontrakten med én gang."
                            }
                            onChange={(selected) => {
                                handleIsFirstSignerChange(index, selected)
                            }}
                            isSelected={firstSignerIndex === index}
                            title={"Dette er meg"}
                        />
                    </div>
                )}
                {formType === "privat" && (
                    <div>
                        <InputGroup
                            icon="Mail"
                            onChange={(event) => {
                                handleInputChange(event, "name")
                            }}
                            value={signerData.name}
                            placeholder="Navn"
                            title="Navn"
                            name={`name${index + 1}`}
                            shouldDisplayValidationErrors={
                                shouldDisplayValidationErrors
                            }
                            required
                        />
                    </div>
                )}
                {formType === "bedrift" && (
                    <div>
                        <InputGroup
                            icon="Mail"
                            onChange={(event) => {
                                handleInputChange(event, "companyName")
                            }}
                            value={signerData.companyName}
                            placeholder="Bedriftens navn"
                            title="Bedriftens navn"
                            name={`companyName${index + 1}`}
                            shouldDisplayValidationErrors={
                                shouldDisplayValidationErrors
                            }
                            required
                        />
                    </div>
                )}

                <div>
                    <InputGroup
                        icon="Mail"
                        onChange={(event) => {
                            handleInputChange(event, "email")
                        }}
                        value={signerData.email}
                        placeholder="E-post"
                        title="E-post"
                        type="email"
                        name={`email${index + 1}`}
                        shouldDisplayValidationErrors={
                            shouldDisplayValidationErrors
                        }
                        required={firstSignerIndex !== index}
                    />
                </div>
                {onDelete && (
                    <ActionButton onClick={handleDeleteClick}>
                        <P_Small_M>
                            <Icon width="16" icon="Trash" />
                            Slett part
                        </P_Small_M>
                    </ActionButton>
                )}
            </Shaded>
        </>
    )
}

const ActionButton = styled.button`
    cursor: pointer;
    p {
        color: ${Colors.red[700]};
        display: flex;
    }
    p svg {
        margin-right: 8px;
    }
    background: ${Colors.red[50]};
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    flex: 0 1 auto;
    border: none;
    padding: 11px 13px;
    align-self: flex-start;
`

const Shaded = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: ${(props) => (props.big ? "48px" : "16px")};
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 16px;
    background-color: ${Colors.gray[50]};
    border-radius: 8px;
    border: none;
    box-shadow: none;

    > * {
        margin-bottom: 12px;
    }
`

export default ContractSigner
