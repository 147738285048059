import React, { useContext, useEffect, useRef, useState } from 'react'
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from '../../containers/CheckoutForm'
import { H2, H5, P } from '../text';
import { loadStripe } from "@stripe/stripe-js"
import { STRIPE } from '../../utils/constants'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import styles from './bulk-contract-payment.module.css';
import Colors from '../../assets/colors';
import Icon from '../icon';
import Spinner from '../Spinner';
import { FirebaseAuth } from '../../providers/AuthProvider';
import { toast } from 'react-toastify';
import { useBulkContracts } from '../../hooks/useBulkContracts';


const getStripeKey = () => {
    const l = window.location.hostname
    if (l === "app.lawyered.no" || l === "lawyered-no.web.app") {
        return STRIPE.PK_PROD
    }
    return STRIPE.PK_TEST
}

const stripe = loadStripe(getStripeKey())


export default function BulkContractPaymentModal(props) {
    const history = useHistory()
    const { currentUser, token } = useContext(FirebaseAuth)
    const { refetchData } = useBulkContracts();

    const [phoneNumberForCheckout, setPhoneNumberForCheckout] = useState(null)


    const [completedFlows, setCompletedFlows] = useState({
        generateContract: true,
        uploadAttachments: true,
        generatePdf: true,
        addSigners: true,
        payment: false,
        signing: true
    })
    const [paymentStatusMessage, setPaymentStatusMessage] = useState("")

    useEffect(() => {
        setPaymentStatusMessage("")
    }, [])
    


    const getPhoneNumberForCheckout = () => {
        return currentUser.phoneNumber || phoneNumberForCheckout || ""
    }


    const onPaymentSucceeded = () => {
        // setPaymentStatusMessage("Your payment has been done!")
        
        setTimeout(() => {
            document.getElementById("closeBulkPaymentModal").click();
            //recall bulkContractData
            refetchData()
        }, 3000);
     
       
        toast.success("Betaling godkjent", {
            position: toast.POSITION.TOP_RIGHT
          });

    }

    const onPaymentCancel = () => {
        setPaymentStatusMessage("Oops! something went wrong while payment confirmation. Please check after sometime.")
        // setShowLoader(false)
        // toStep(STEP.SUMMARY)
    }



    useEffect(() => {
        if (props.isOpen === true) {
            document.getElementById("openBulkPaymentModal").click();
        }
    }, [props])

    const toggleModal = () => {
        props.setIsOpen(false);
    }





    return (
        <>
            <button type="button" className="btn btn-primary d-none" id="openBulkPaymentModal" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button>

            <div className="modal fade" id="exampleModal" tabIndex="-1" data-bs-backdrop="static" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-0 pb-2">
                        <div className="modal-header border-0">
                            <button type="button" className={`btn-close`} id="closeBulkPaymentModal" data-bs-dismiss="modal" aria-label="Close" onClick={() => toggleModal()}></button>
                        </div>
                        <div className="modal-body text-center">

                            {paymentStatusMessage?
                            <h2 className='py-5'>{paymentStatusMessage}</h2>
                            :
                            <div className='px-2'>
                            {/* card payment element started */}
                            <FullscreenInnerHeader>
                            <H5>Betaling</H5>
                            {!completedFlows.payment && (
                                <KeyFigure>
                                    <H2>
                                        {props?.paymentAmount} kr
                                    </H2>
                                    <P>
                                        {props.paymentTitle}
                                    </P>
                                </KeyFigure>
                            )}
                            {completedFlows.payment && (
                                <BigIconWithText>
                                    <Icon
                                        icon="Checkmark"
                                        fill={Colors.green[500]}
                                        width="122"
                                    />
                                    <P_Large_M>
                                        Betaling fullført
                                    </P_Large_M>

                                    <Spinner
                                        size="32"
                                        text="Sjekker signeringsvalg og lagrer fil..."
                                    />
                                    {currentStatusSubText && (
                                        <P_Small_M
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            {currentStatusSubText}
                                        </P_Small_M>
                                    )}
                                </BigIconWithText>
                            )}
                        </FullscreenInnerHeader>
                        {completedFlows.generateContract &&
                            completedFlows.uploadAttachments &&
                            completedFlows.generatePdf &&
                            completedFlows.addSigners &&
                            !completedFlows.payment && (
                                <>
                                    <Elements stripe={stripe}>
                                        <CheckoutForm
                                            email={
                                                currentUser.email
                                            }
                                            phone={getPhoneNumberForCheckout()}
                                            onSucceeded={
                                                onPaymentSucceeded
                                            }
                                            onCancel={
                                                onPaymentCancel
                                            }
                                            couponId={localStorage.getItem('lawyered.bulk.contract.coupon')}
                                        />
                                    </Elements>
                                </>
                            )}
                            {/* card payment element end */}

                            </div>

                            }

                            
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


const FullscreenInnerHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: 24px 0;
    align-items: center;
    text-align: center;
    > p {
        margin-top: 8px;
    }
`

const KeyFigure = styled.div`
    text-align: center;
    h2 {
        margin-bottom: 8px;
    }
    p {
        color: ${Colors.gray[500]};
    }
`

