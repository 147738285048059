import { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { FirebaseAuth } from "../providers/AuthProvider"

import Colors from "../assets/colors"
import { H5, P_Mini_M } from "../components/text"
import Notice from "../components/notice"
import { updateUserData } from "../utils/api"

const MagicLinking = () => {
    const [email, setEmail] = useState("")

    const {
        handleLinking,
        isLinkingCompleted,
        errors,
        authStateChangedWithUserReady,
        token,
        getFriendlyErrorMessage
    } = useContext(FirebaseAuth)

    useEffect(() => {
        if (authStateChangedWithUserReady) {
            // We need to wait for a user-object to be ready to
            // be able to convert it into a permanent user.
            const storedEmail = localStorage.getItem("lawyered.user.email")
            if (storedEmail) {
                setEmail(storedEmail)
                handleLinking(window.location.href)
            }
        }
    }, [authStateChangedWithUserReady])

    useEffect(() => {
        async function linkingCb() {
            const storedEmail = localStorage.getItem("lawyered.user.email")
            await updateUserData(token, {
                email: storedEmail
            })
            window.location.href = "/settings"
        }

        if (isLinkingCompleted) {
            linkingCb()
        }
    }, [isLinkingCompleted])

    return (
        <Container>
            <InnerContainer>
                <Title>{"Oppdaterer e-post-adressen din..."}</Title>
                <Message>
                    Kobler <Bold>{email}</Bold> til brukeren din...
                </Message>
                {errors.length > 0
                    ? errors.map((error, index) => (
                        <Notice
                            key={`error-${index}`}
                            error
                            text={getFriendlyErrorMessage(error)}
                        />
                    ))
                    : null}
            </InnerContainer>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    height: 100vh;
    width: 100vh;
    background-color: ${(props) => props.theme.backgroundPrimary};
    justify-content: center;
    align-items: center;
`

const InnerContainer = styled.div`
    width: 360px;
    padding: 24px;
    background-color: ${Colors.white};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const Title = styled(H5)`
    color: ${(props) => props.theme.foregroundPrimary};
    text-align: center;
`

const Message = styled(P_Mini_M)`
    color: ${Colors.gray[500]};
    text-align: center;
`

const Bold = styled(Message)`
    font-weight: 700;
    display: inline;
`

export default MagicLinking
