const QRCode = require("qrcode-svg")
const { useTheme } = require("styled-components")

const baseUrl =
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")

function getManualSigningUrlQR(
    shortcode,
    contractReadingKey = null,
    padding = 4
) {
    const theme = useTheme()
    const qrcode = new QRCode({
        content:
            baseUrl +
            "/s/" +
            shortcode +
            (contractReadingKey
                ? `?contract_reading_key=${contractReadingKey}`
                : ""),
        padding,
        width: 256,
        height: 256,
        color: theme.foregroundPrimary,
        background: "transparent",
        ecl: "M",
        join: true,
        container: "svg-viewbox"
    })
    return qrcode.svg()
}

module.exports = getManualSigningUrlQR
