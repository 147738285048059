import { useContext, useEffect, useState } from "react"
import { useRouteMatch, Link } from "react-router-dom"
import { FirebaseAuth } from "../providers/AuthProvider"
import { auth } from "../firebase/firebaseConfig"
import styled, { useTheme } from "styled-components"

import Colors from "../assets/colors"

import { H5, P_Large_M, P_Medium, P_Mini_M, P_Small } from "../components/text"
import Button from "../components/button"
import Icon from "../components/icon"
import InputGroup from "../components/inputGroup"
import WithSidebar from "../components/withSidebar"
import A from "../components/a"
import { SettingsLoading } from "../containers/Loading"

import { getAuthUserData, getStripeCustomer } from "../utils/api"

const Settings = () => {
    const theme = useTheme()
    let { path } = useRouteMatch()

    const { currentUser, token } = useContext(FirebaseAuth)

    const [isLoading, setIsLoading] = useState(true)

    const [companyName, setCompanyName] = useState("")
    const [companyNumber, setCompanyNumber] = useState("")
    const [hasCompanyData, setHasCompanyData] = useState(false)

    const [contactName, setContactName] = useState("")
    const [contactEmail, setContactEmail] = useState("")

    const [paymentCards, setPaymentCards] = useState([])

    useEffect(() => {
        async function getData() {
            const authUserData = await getAuthUserData(token)
            setContactName(authUserData.userData?.dbData?.name || "")
            setContactEmail(authUserData.userData?.dbData?.email || "")

            const stripeCustomerData = await getStripeCustomer(token)
            const paymentCards = stripeCustomerData.paymentMethods?.filter(
                (pm) => pm.type === "card"
            )

            if (paymentCards) {
                setPaymentCards(
                    paymentCards.map((pm) => {
                        return {
                            type: pm.card.brand.toUpperCase(),
                            hiddenNumber: "**** " + pm.card.last4,
                            expiryDate:
                                pm.card.exp_month + "/" + pm.card.exp_year,
                            selected: true
                        }
                    })
                )
            }

            setIsLoading(false)
        }

        if (!currentUser.isAnonymous) {
            getData()
        } else {
            setIsLoading(false)
        }
    }, [])

    const renderContactInformation = () => {
        return (
            <Box>
                {hasCompanyData && (
                    <>
                        <Row>
                            <Icon
                                icon="Building"
                                fill={theme.foregroundSecondary}
                                width={20}
                                height={20}
                            />
                            <P_Large_M>Bedriftsinfo</P_Large_M>
                        </Row>
                        <div>
                            <P_Large_M>{companyName}</P_Large_M>
                            <CompanyNumber>{companyNumber}</CompanyNumber>
                        </div>
                        <InputGroup
                            title="Adresse"
                            placeholder="Adresse"
                            onChange={(value) => {}}
                            value={""}
                        />
                        <InputGroup
                            title="Postnummer"
                            placeholder="Postnummer"
                            onChange={(value) => {}}
                            value={""}
                        />
                        <InputGroup
                            title="By"
                            placeholder="By"
                            onChange={(value) => {}}
                            value={""}
                        />
                        <Line />
                    </>
                )}
                <Row>
                    <Icon
                        icon="Phone"
                        fill={theme.foregroundSecondary}
                        width={20}
                        height={20}
                    />
                    <P_Large_M>Kontaktinfo</P_Large_M>
                </Row>
                <InputGroup title="Navn" value={contactName} />
                {contactEmail && (
                    <InputGroup
                        icon="Mail"
                        title="E-post-adresse"
                        value={contactEmail}
                        readonly
                    />
                )}
            </Box>
        )
    }

    const renderPaymentInformation = () => {
        return (
            <Box>
                <Row>
                    <Icon
                        icon="Card"
                        fill={theme.foregroundSecondary}
                        width={20}
                        height={20}
                    />
                    <P_Large_M>Betalingsinfo</P_Large_M>
                </Row>
                <>
                    {paymentCards.map((item, index) => {
                        return (
                            <Card key={`paymentcard-${index}`}>
                                <RowWithSpace>
                                    <P_Medium>{item.type}</P_Medium>
                                    <Icon icon="Trash" />
                                </RowWithSpace>
                                <RowWithSpace>
                                    <CardInfo>{item.hiddenNumber}</CardInfo>
                                    <CardInfo>{item.expiryDate}</CardInfo>
                                </RowWithSpace>
                                <Message>
                                    Håndtert av{" "}
                                    <A
                                        target="stripe_tab"
                                        href="https://stripe.com"
                                    >
                                        Stripe
                                    </A>
                                </Message>
                            </Card>
                        )
                    })}
                </>
                {!!paymentCards && (
                    <Message>
                        Ingen betalingsmetoder registrert. Du kan lagre
                        <br />
                        betalingskort for senere bruk når du betaler for
                        <br />
                        signering.
                    </Message>
                )}
            </Box>
        )
    }

    return (
        <WithSidebar currentUser={currentUser} token={token} path={path}>
            <Container>
                <Title>Innstillinger</Title>
                <Content>
                    {isLoading && <SettingsLoading />}
                    {!isLoading &&
                        !currentUser.isAnonymous &&
                        renderContactInformation()}
                    {currentUser.isAnonymous && (
                        <Link to="/onboarding">
                            <Button text="Logg inn eller registrer deg" />
                        </Link>
                    )}
                    {!isLoading &&
                        !currentUser.isAnonymous &&
                        renderPaymentInformation()}
                </Content>
            </Container>
        </WithSidebar>
    )
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 16px;
    @media screen and (min-width: 768px) {
        padding: 100px 64px 40px 48px;
    }
`

const Content = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-wrap: wrap;
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin: 0 0 24px 0;
    @media screen and (min-width: 768px) {
        margin: 0 24px 24px 0;
        min-width: 360px;
    }

    background-color: ${(props) =>
        props.bg ? props.bg : props.theme.backgroundTertiary};
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`

const Row = styled.div`
    display: flex;
    align-items: center;

    > *:not(:last-child) {
        margin-right: 16px;
    }
`

const RowWithSpace = styled(Row)`
    width: 100%;
    justify-content: space-between;
`

const Line = styled.div`
    display: flex;
    height: 2px;
    width: 100%;
    background-color: ${Colors.gray[100]};
`

const Title = styled(H5)`
    margin-bottom: 24px;
`

const CompanyNumber = styled(P_Large_M)`
    color: ${Colors.gray[500]};
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: ${(props) => props.theme.backgroundPrimary};
    border: 2px solid ${Colors.gray[100]};
    border-radius: 8px;
    margin-bottom: 16px;

    > *:not(:last-child) {
        margin-bottom: 16px;
    }
`

const CardInfo = styled(P_Medium)`
    color: ${Colors.gray[600]};
`

const Message = styled(P_Mini_M)`
    color: ${Colors.gray[500]};
    text-align: center;
`

const Fullscreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${(props) => props.theme.backgroundPrimary};
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

const FullscreenInner = styled(Box)`
    width: ${(props) =>
        props.wide ? "calc(480px - 32px)" : "calc(360px - 32px)"};
    margin: 0;
    transition: width 225ms ease-out;
    button {
        margin-top: 16px;
    }
`

const FullscreenInnerHeader = styled.div`
    display: flex;
    flex-direction: column;
    align-items: initial;
    padding: 24px 0 0;
    align-items: center;
    text-align: center;
`

export default Settings
