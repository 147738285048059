import { useState } from "react"
import styled from "styled-components"
import RadioButtonLabelContract from "./RadioButtonLabelContract"

const RadioButtonLabelGroupContract = ({
    options,
    name,
    selectedValue,
    onChange
}) => {
    const [selected, setSelected] = useState(selectedValue)
    const handleChange = (event) => {
        setSelected(event.target.value)
        onChange(event)
    }
    return (
        <Wrapper role="radiogroup" name={name}>
            {options.map((option, index) => {
                return (
                    <RadioButtonLabelContract
                        key={`radio-option-${name}-${index}`}
                        onChange={handleChange}
                        value={option}
                        labelText={option}
                        name={name}
                        checked={selected === option}
                    />
                )
            })}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    flex: 1 0 auto;
`

export default RadioButtonLabelGroupContract
