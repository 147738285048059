import React from "react"
import styled from "styled-components"

import Colors from "../../assets/colors"
import { P_Small_M } from "../text"

import RadioButton from "../radioButton"

const RadioButtonLabel = ({ onChange, title, selected }) => {
    return (
        <Container>
            <RadioButton onChange={onChange} selected={selected} />
            <Title>{title}</Title>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
`

const Title = styled(P_Small_M)`
    margin: 0 0 0 8px;
    color: ${Colors.gray[700]};
`

export default RadioButtonLabel
