import styled from "styled-components"

import Colors from "../../assets/colors"
import { P_Small_M } from "../text"

const DividerWithText = ({ text }) => {
    return (
        <Divider>
            <Line />
            <P_Small_M>{text}</P_Small_M>
            <Line />
        </Divider>
    )
}

const Divider = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    margin: 16px 4px;
    p {
        color: ${Colors.gray[500]};
        padding: 0 8px;
    }
`

const Line = styled.div`
    display: flex;
    flex: 1;
    background-color: ${Colors.gray[300]};
    height: 1px;
    margin-top: 1px;
`

export default DividerWithText
