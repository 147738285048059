import styled from "styled-components"
import Colors from "../../assets/colors"

import { P_Small, P_Small_M } from "../text"

const SearchResults = ({ onClick, title, subtitle }) => {
    return (
        <Container onClick={onClick}>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    cursor: pointer;

    &:hover {
        background-color: ${(props) => props.theme.backgroundPrimary};
    }
`

const Title = styled(P_Small)`
    color: ${(props) => props.theme.foregroundPrimary};
    margin: 0 8px 0 0;
`

const Subtitle = styled(P_Small_M)`
    color: ${Colors.gray[400]};
    margin: 0;
`

export default SearchResults
