import React from "react"
import { useTheme } from "styled-components"

const ApproveFile = () => {
    const theme = useTheme()
    return (
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 6.94C15.9896 6.84813 15.9695 6.75763 15.94 6.67V6.58C15.8919 6.47718 15.8278 6.38267 15.75 6.3L9.75 0.3C9.66734 0.222216 9.57282 0.158081 9.47 0.11C9.44015 0.10576 9.40985 0.10576 9.38 0.11C9.27841 0.0517412 9.16622 0.0143442 9.05 0H3C2.20435 0 1.44129 0.316071 0.87868 0.87868C0.316071 1.44129 0 2.20435 0 3V17C0 17.7956 0.316071 18.5587 0.87868 19.1213C1.44129 19.6839 2.20435 20 3 20H13C13.7956 20 14.5587 19.6839 15.1213 19.1213C15.6839 18.5587 16 17.7956 16 17V7C16 7 16 7 16 6.94ZM10 3.41L12.59 6H11C10.7348 6 10.4804 5.89464 10.2929 5.70711C10.1054 5.51957 10 5.26522 10 5V3.41ZM14 17C14 17.2652 13.8946 17.5196 13.7071 17.7071C13.5196 17.8946 13.2652 18 13 18H3C2.73478 18 2.48043 17.8946 2.29289 17.7071C2.10536 17.5196 2 17.2652 2 17V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H8V5C8 5.79565 8.31607 6.55871 8.87868 7.12132C9.44129 7.68393 10.2044 8 11 8H14V17ZM10.29 10.29L7 13.59L5.71 12.29C5.5217 12.1017 5.2663 11.9959 5 11.9959C4.7337 11.9959 4.4783 12.1017 4.29 12.29C4.1017 12.4783 3.99591 12.7337 3.99591 13C3.99591 13.2663 4.1017 13.5217 4.29 13.71L6.29 15.71C6.38296 15.8037 6.49356 15.8781 6.61542 15.9289C6.73728 15.9797 6.86799 16.0058 7 16.0058C7.13201 16.0058 7.26272 15.9797 7.38458 15.9289C7.50644 15.8781 7.61704 15.8037 7.71 15.71L11.71 11.71C11.8983 11.5217 12.0041 11.2663 12.0041 11C12.0041 10.7337 11.8983 10.4783 11.71 10.29C11.5217 10.1017 11.2663 9.99591 11 9.99591C10.7337 9.99591 10.4783 10.1017 10.29 10.29Z" fill={theme.inputForeground} />
        </svg>
    )
}

export default ApproveFile
